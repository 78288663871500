import React from "react";
import Grid from "@mui/material/Grid";
import QRCodeComponent from '../../../components/qr_code_component/QRCodeComponent'
import { DivBackgroundStyled, ScrollableDivStyled } from "../../../biz_styles/ReusableStyles";
import { ImgCardStyled } from "../../../biz_styles/PointsShopStyle";
import { getBizColorNumber, DivCenterStyled, DivCenterHorizontalStyled, PStyled, P3Styled, H3Styled, POpacityStyled, Toast } from "../../../biz_styles/bizStyle";
import { SpacerStyled, DivCenter2Styled, DivDashedStyled } from "../../../biz_styles/ReusableStyles";
import { DivImgItemProfileWrapper3Styled } from '../../../biz_styles/ProfileStyle';
import { returnImageURL, getSvgUrlForFileName, getRewardRedeemUrl, getFormatedDateSQL, hyphenateText, checkIfNullUndefinedEmpty, valueBarSpacer, bottomNavigationViewSpacer } from "../../../shared/static_helper/StaticHelper";
import { useStoreProvider } from '../../../shared/providers/StoreContext'
import { getLanguage } from "../../../shared/redux/GlueStoreData";
import BarcodeComponent from "../../../components/barcode_component/BarCodeComponent";

export default function LoyaltyInfoScreen({ currentObjFromList }) {

    /* Logic functions - start */
    const getLang = getLanguage();
    const bizObject = useStoreProvider().biz

    const currentObj = currentObjFromList.data;

    function getProgramType() {

        return currentObj.program_type;
    }

    function getStampNumber() {

        return currentObj.stamp_number;
    }

    function getRewardNumber() {

        return currentObj.reward_number;
    }

    function getCustomerCardRedeemed() {

        return currentObj.customer_card_redeemed;
    }

    function getCustomerCardRedeemedDate() {

        return currentObj.customer_card_redeemed_date;
    }

    function getCustomerCardCode() {

        return currentObj.customer_card_code;
    }

    function getCustomerBarcode() {

        return currentObj.bar_code;
    }

    function getDescription() {

        return currentObj.description;
    }

    function isPrepaidBenefit() {
        return currentObj.benefit_type == "points_redeem" && currentObj.benefit_behavior == "cart_discount";
    }

    function getCouponStampCardTypeLabel() {

        var stampNumber = getStampNumber();
        var rewardNumber = getRewardNumber();

        var cardTypeLabel = '';

        switch (getProgramType()) {

            case 'plus':

                cardTypeLabel = stampNumber + ' + ' + rewardNumber;
                break;

            case 'discount':

                cardTypeLabel = stampNumber + ' + ' + rewardNumber + '%';
                break;

            case 'gift':

                cardTypeLabel = stampNumber + ' + 🎁';
                break;
            default:
        }

        return cardTypeLabel;
    }

    function getStampClick() {

        Toast.fire({
            allowOutsideClick: false,
            title: '',
            html: getStampClickText(),
            icon: 'info',
            confirmButtonText: getLang.ok,
            iconColor: getBizColorNumber(3),
            confirmButtonColor: getBizColorNumber(3),
        });
    }

    function getStampClickText() {

        var textToReplace = getLang.you_get_stamps_and_points;

        return textToReplace.replaceAll('#bizname#', bizObject.biz_short_name);
    }

    function getDescriptionLabel() {

        var description = getDescription().replaceAll("\\\\'", "'").replaceAll("\\\\\"", "\"");
        var stampItems = currentObj.stamp_items;

        if (!checkIfNullUndefinedEmpty(stampItems)) {

            description += '\n' + getLang.stamp_items + '\n' + stampItems;
        }

        return description;
    }
    /* Logic functions - end */

    /* UI - start */
    /* UI - without UI functions - start */
    return (
        <DivBackgroundStyled className="main-content-holder">

            <ScrollableDivStyled height='100vh'>

                {valueBarSpacer()}

                {imageLayout()}

                {spacer()}

                <div style={{ marginLeft: '20px', marginRight: '20px' }}>

                    {headerLayout()}

                    {spacer()}

                    {prepaidStatusLayout()}

                    {spacer()}

                    {stampsQrLayout()}

                    <hr style={{ backgroundColor: getBizColorNumber(2) + '66' }} />

                    {descriptionLayout()}

                    {rewarderItemsLayout()}

                    {expiringLayout()}

                    {disclaimerLayout()}

                    {bottomNavigationViewSpacer()}

                </div>

            </ScrollableDivStyled>

        </DivBackgroundStyled>
    )
    /* UI - without UI functions - end */

    /* UI - with UI functions - start */
    function imageLayout() {

        var imageUrl = currentObj.image;
        var combinedURL = 'https://devapi.getglue.co/bizImages/' + imageUrl;

        return <div style={{ position: 'relative' }}>

            <ImgCardStyled
                style={{ height: '256px' }}
                src={returnImageURL(combinedURL, imageUrl)}
                alt='' />

            {getCouponStampCardTypeLabel() === '' ?

                <div /> :

                cardTypeLayout()}

        </div>
    }

    function cardTypeLayout() {

        return <DivCenterStyled
            padding='0px'
            style={{ height: '40px', width: '120px', backgroundColor: getBizColorNumber(3), position: 'absolute', top: '180px', right: '0px' }}>

            <P3Styled>{getCouponStampCardTypeLabel()}</P3Styled>

        </DivCenterStyled>;
    }

    function headerLayout() {

        return <H3Styled
            textAlign='left'>
            {currentObj.header}
        </H3Styled>
    }

    function stampsQrLayout() {

        return getCustomerCardCode() === '' ?

            loyaltyStampsLayout() :

            loyaltyQrCodeLayout()
    }

    function loyaltyStampsLayout() {

        return <DivCenter2Styled>

            <Grid
                container spacing={1} columns={5}
                style={{ width: '70%' }}>

                {loyaltyStampsLayoutList()}

            </Grid>

        </DivCenter2Styled>;
    }

    function loyaltyStampsLayoutList() {

        const stampNumberForLoop = parseInt(getStampNumber()) + 2;

        var stampLayout = [];

        for (var i = 1; i < stampNumberForLoop; i++) {

            stampLayout.push(handleStapmLayout(i));
        }

        return stampLayout;
    }

    function handleStapmLayout(index) {

        var stamps = currentObj.stamps;
        var stampNumber = parseInt(getStampNumber());

        var stampLayout;

        if (index <= stamps) {

            stampLayout = stapmLayout(index,
                index,
                getBizColorNumber(2),
                getBizColorNumber(2) + '33',
                {},
                undefined);
        } else if (index === (stamps + 1) &&
            index !== (stampNumber + 1)) {

            stampLayout = stapmLayout(index,
                index,
                getBizColorNumber(1),
                getBizColorNumber(3),
                {},
                getStampClick);
        } else if (index < (stampNumber + 1)) {

            stampLayout = stapmLayout(index,
                index,
                getBizColorNumber(2) + '33',
                getBizColorNumber(4),
                { border: '1px solid ' + getBizColorNumber(2) + '33', borderRadius: '50px' },
                getStampClick);
        } else {

            var programType = getProgramType();
            var mrewardNumber = getRewardNumber();

            var stampCardText = '';

            switch (programType) {

                case 'plus':

                    stampCardText = getLang.price_free;
                    break;

                case 'discount':

                    stampCardText = mrewardNumber + '%';
                    break;

                case 'gift':

                    stampCardText = '🎁';
                    break;
                default:
            }

            stampLayout = stapmLayout(index,
                stampCardText,
                getBizColorNumber(3),
                getBizColorNumber(4),
                { backgroundImage: `url(${getSvgUrlForFileName('stampouter.svg')})` },
                undefined);
        }

        return stampLayout;
    }

    function stapmLayout(index, text, textColor, backgroundColor, style, click) {

        return <Grid
            item xs={1}
            direction='row'
            justifyContent='center'
            alignItems='center'
            key={index}>

            <DivImgItemProfileWrapper3Styled
                backgroundColor={backgroundColor}
                style={style}
                onClick={click}>

                <span
                    style={{ color: textColor, display: 'table-cell', verticalAlign: 'middle' }}>
                    {text}
                </span>

            </DivImgItemProfileWrapper3Styled>

        </Grid>
    }

    function loyaltyQrCodeLayout() {

        var loyaltyCodeLabel = '';
        var loyaltyCodeNumber = '';
        var qrCode = '';
        var barcodeValue = '';

        var customerCardRedeemed = getCustomerCardRedeemed();
        var customerCardRedeemedDate = getCustomerCardRedeemedDate();

        if (customerCardRedeemed === '0') {

            if (getProgramType() === 'other') {

                var customerCardCode = getCustomerCardCode();
                barcodeValue = getCustomerBarcode();
                
                loyaltyCodeLabel = getLang.your_personal_code;
                var displayCode = barcodeValue != '' ? barcodeValue : customerCardCode;
                loyaltyCodeNumber = hyphenateText(displayCode, 4);
                qrCode = getRewardRedeemUrl(customerCardCode);
                
            } else {

                loyaltyCodeLabel = getLang.redeemable_on_purchase;
            }
        } else {

            loyaltyCodeLabel = getLang.redeemed_on + ' ' + getFormatedDateSQL(customerCardRedeemedDate);
        }

        return <DivDashedStyled>

            <DivCenterHorizontalStyled>

                <DivCenterStyled>

                    {paragraphLabel(loyaltyCodeLabel)}

                    {paragraphLabel(loyaltyCodeNumber)}

                </DivCenterStyled>

                <QRCodeComponent size={70} value={qrCode} />

            </DivCenterHorizontalStyled>

            {barcode(barcodeValue)}

        </DivDashedStyled>
    }

    function descriptionLayout() {

        var description = getDescription();

        return description !== '' ?

            <div>

                {fullParagraphLayout(getLang.description, getDescriptionLabel())}

                {spacer()}

            </div> :

            <div />
    }

    function rewarderItemsLayout() {

        var rewardedItems = currentObj.reward_items;

        return !checkIfNullUndefinedEmpty(rewardedItems) ?

            <div>

                {fullParagraphLayout(getLang.rewarder_items, rewardedItems)}

                {spacer()}

            </div> :

            <div />
    }

    function expiringLayout() {

        var validTo = currentObj.valid_to;

        return validTo !== '' ?

            <div>

                {fullParagraphLayout(getLang.expiring, getFormatedDateSQL(currentObj.valid_from) + ' - ' + getFormatedDateSQL(validTo))}

                {spacer()}

            </div> :

            <div />
    }

    function prepaidStatusLayout() {
        var isPrepaid = isPrepaidBenefit();

        return isPrepaid ? 
        <div>            
            {fullParagraphLayout(getLang.prepaid_remaining_budget, getStampNumber() + bizObject.ess_currency_symbol)}

            {spacer()}

            {fullParagraphLayout(getLang.prepaid_original_budget, getRewardNumber() + bizObject.ess_currency_symbol)}

            {spacer()}
        </div> :
        ""
    }

    function disclaimerLayout() {

        var disclaimer = currentObj.disclaimer;

        return disclaimer !== '' ?

            <div>

                {fullParagraphLayout(getLang.disclaimer, disclaimer.replaceAll("\\\\'", "'").replaceAll("\\\\\"", "\""))}

                {spacer()}

            </div> :

            <div />
    }

    function fullParagraphLayout(title, label) {

        return <div>

            {paragraphTitle(title)}

            {paragraphLabel(label)}

        </div>
    }

    function paragraphTitle(title) {

        return paragraphTitleStyle(title)
    }

    function paragraphTitleStyle(title, style) {

        return <POpacityStyled
            style={style}
            opacity={'66'}
            textAlign='left'
            marginBottom={'0px'}>
            {title}
        </POpacityStyled>
    }

    function paragraphLabel(label) {

        return paragraphLabelStyle(label)
    }

    function paragraphLabelStyle(label, style) {

        return <PStyled
            style={style}
            textAlign='left'
            marginBottom='0px'>
            {label}
        </PStyled>
    }

    function spacer() {

        return <SpacerStyled height='20' />
    }

    function barcode(value){
        return value == '' ? "" 
        : <DivCenterHorizontalStyled>
        
            <BarcodeComponent value={value}/>

        </DivCenterHorizontalStyled>
    }
    /* UI - with UI functions - end */
    /* UI - end */
}