import PropTypes from 'prop-types';

export const GetBizObject = {
  biz_hash: PropTypes.string,
  biz_country_code: PropTypes.string,
  biz_theme_color1: PropTypes.string,
  biz_theme_color2: PropTypes.string,
  biz_theme_color3: PropTypes.string,
  biz_theme_color4: PropTypes.string,
  biz_theme_color5: PropTypes.string,
  biz_theme_name: PropTypes.string,
  biz_theme_font: PropTypes.string,
  biz_theme_font_bold: PropTypes.string,
  biz_fields: PropTypes.arrayOf(
    PropTypes.shape({
      cfi_field_id: PropTypes.string,
      cfi_req: PropTypes.string,
      cfi_order: PropTypes.string,
      cfi_type: PropTypes.string,
      cfi_label: PropTypes.string
    })
  ),
  bizPrivacyUrl: PropTypes.string,
  biz_waiting_title: PropTypes.string,
  biz_waiting_message: PropTypes.string,
  biz_waiting_signed: PropTypes.string,
  biz_short_name: PropTypes.string,
  biz_logo: PropTypes.string,
  biz_welcome_title: PropTypes.string,
  email_member_enabled: PropTypes.string,
  biz_membership_mode: PropTypes.string,
  biz_mainmenu_view_type: PropTypes.string,
  biz_login_message: PropTypes.string,
  biz_modules: PropTypes.arrayOf(
    PropTypes.shape({
      biz_mod_instance_id: PropTypes.string,
      row_id: PropTypes.string,
      biz_mod_mod_id: PropTypes.string,
      biz_mod_mod_name: PropTypes.string,
      biz_mod_mod_pic: PropTypes.string,
      biz_mod_mod_large_pic: PropTypes.string,
      mod_reg_type: PropTypes.string,
      biz_mod_has_reviews: PropTypes.string,
      ms_view_type: PropTypes.string
    })
  ),
  tabbar: PropTypes.arrayOf(
    PropTypes.shape({
      fu_id: PropTypes.string,
      fu_label: PropTypes.string,
      fu_icon: PropTypes.string,
      fu_index: PropTypes.string,
      fuselector: PropTypes.string,
      fu_info: PropTypes.string,
      fu_bool1: PropTypes.string,
      fu_bool2: PropTypes.string,
      fu_bool3: PropTypes.string,
      fu_bool4: PropTypes.string
    })
  ),
  pzArray: PropTypes.arrayOf(
    PropTypes.shape({
      type: PropTypes.string,
      label: PropTypes.string
    })
  ),
  biz_mod_mod_name: PropTypes.any,
  show_cart: PropTypes.number,
  biz_register_title: PropTypes.string,
  biz_register_message: PropTypes.string,
  biz_welcome_message: PropTypes.string,
  ess_currency: PropTypes.string,
  biz_terms_url: PropTypes.string,
  biz_navigation: PropTypes.arrayOf(
    PropTypes.shape({
      nav_entity_type: PropTypes.string,
      nav_entity_id: PropTypes.string,
      nav_index: PropTypes.string,
      nav_label: PropTypes.string,
      nav_icon: PropTypes.string,
      nav_selector: PropTypes.string,
      nav_selector_android: PropTypes.string
    })
  ),
  paymentOptions: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      data: PropTypes.shape({
          account: PropTypes.string,
          pk: PropTypes.string,
        })
    })
  )
}