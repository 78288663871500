import { Circles } from 'react-loader-spinner'
import React from 'react'
import { getBizColorNumber } from '../../biz_styles/bizStyle'
import './LoadingComponent.css'

function LoadingComponent({ isLoading }) {
    return (
            <Circles
                height="100"
                width="100"
                color={getBizColorNumber(3)}
                ariaLabel="circles-loading"
                wrapperStyle={{}}
                wrapperClass="glue-spinner-style"
                visible={isLoading}
            />
    );
}

export default LoadingComponent