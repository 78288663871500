import styled from 'styled-components'
import { getBizColorNumber } from './bizStyle';
import { ButtonStyled } from './ReusableStyles';

    export const TitleStyled = styled.p`
    position: relative;
    font-size: 22px;
    font-weight: bold;
    color: ${()=>getBizColorNumber(2)};
    margin-left: auto;
    margin-right: auto;
    padding-bottom: 20px;`


    export const InviteButtonStyled = styled(ButtonStyled)`
        position: fixed;
        width: 90%;
        right: 0%;
        bottom: ${(props) => typeof (props.bottom) !== 'undefined' ? props.bottom : 0}px`

    export const AbsInviteButtonStyled = styled(ButtonStyled)`
        position: absolute;
        width: 90%;
        right: 0%;
        bottom: ${(props) => typeof (props.bottom) !== 'undefined' ? props.bottom : 0}px`