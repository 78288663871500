import styled from 'styled-components'
import { getBizColorNumber } from '../biz_styles/bizStyle'
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';

export const CardElementsContainer = styled.div`
border:1px solid ${(props) => props.isValid ?"#b8b8b8":"red"};       
border-radius: 5px;
padding: 10px;
`

export const ToggleButtonGroupStyled = styled(ToggleButtonGroup)`
&.MuiToggleButtonGroup-root {
  width: 100%;
  border-radius: 5px;
}
`
export const ToggleButtonStyled = styled(ToggleButton)`
&.MuiButtonBase-root {
    color: black;
    border-color: #b8b8b8;
    border-radius: 5px;
  }
  &.MuiButtonBase-root.MuiToggleButton-root {
      width: 100%;
  }
  &.MuiButtonBase-root.MuiToggleButton-root.Mui-selected {
    background-color: ${() => getBizColorNumber(3)};
  }
`

export const PStyled = styled.p`
    color: 'black';
    margin-top: ${(props) => props.marginTop};
    margin-bottom: ${(props) => props.marginBottom};
    float: ${(props) => props.float};
    text-align: ${(props) => props.textAlign};
    white-space: pre-wrap;`

export const POpacityStyled = styled(PStyled)`
color: ${(props) => 'black' + props.opacity};
margin-top: ${(props) => props.marginTop};
margin-bottom: ${(props) => props.marginBottom};
float: ${(props) => props.float};
text-align: ${(props) => props.textAlign};
white-space: pre-wrap;
`

export const KeyPStyled = styled.span`
    color: black;
    font-size: ${(props) => props.fontSize};
    position: relative;`

export const ValuePStyled = styled.span`
    position: relative;
    width: ${(props) => props.width};
    height: ${(props) => props.fontSize}px;
    overflow: hidden;
    text-align: right;
    color: black;
    background-color: transparent;
    font-size: ${(props) => props.fontSize};
    float: right;
    direction:ltr`
