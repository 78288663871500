import React from 'react';
import Grid from "@mui/material/Grid";
import { SpacerStyled } from '../../biz_styles/ReusableStyles'
import {DivModuleItemStyled, DivModuleImageStyled, ModuleImageStyled,IconImageStyled, DivModuleNameStyled, DivAllPagesComponentStyled} from '../../biz_styles/AllPagesStyle'
import { useNavigate } from "react-router-dom"
import { useStoreProvider } from '../../shared/providers/StoreContext'
import { getBizColorNumber } from '../../biz_styles/bizStyle'



function Pages() {

  const bizObject = useStoreProvider().biz
  const navigate = useNavigate()
  const modules = bizObject.biz_modules
  const incentiveModules = modules.filter(
    (moduleId) => moduleId.biz_mod_mod_id === "6" || moduleId.biz_mod_mod_id === "26" || 
      moduleId.biz_mod_mod_id === "10" || moduleId.biz_mod_mod_id === "18" || moduleId.biz_mod_mod_id === "11" || 
      moduleId.biz_mod_mod_id === "32"
  );


  const moduleRoutes = []
  moduleRoutes['6']='../pages/stampCards'
  moduleRoutes['26']='../pages/coupons'
  moduleRoutes['10']='../pages/subscriptions'
  moduleRoutes['11']='../pages/muiltipass'
  moduleRoutes['18']='../pages/pointsShop'

  function getModImage(mod) {

    //If the user has not replaced the image - use default icon
    if(mod.biz_mod_mod_pic.includes('mod')){
      return 'https://storage.googleapis.com/bbassets/mod_images/' + mod.biz_mod_mod_pic;
    }
    //use custom image
    return mod.biz_mod_mod_pic; 
  }

  function getModImagePadding(mod){
    //use default icon padding
    if(mod.biz_mod_mod_pic.includes('mod')){
      return '25';
    }
    //use custom image padding
    return 0; 
  }

  function getModColor(mod){
      //use default icon padding
      if(mod.biz_mod_mod_pic.includes('mod')){
        return getBizColorNumber(2);
      }
      //use custom image padding
      return 'transparent';     
  }

  return(
    <DivAllPagesComponentStyled id='scroll-parent'>
      <SpacerStyled height='80' />
    <Grid container spacing={3} columns={12}>
        {incentiveModules.map((module) =>
          <Grid item xs={4} spacing={1} key={module.biz_mod_mod_id}>
          <DivModuleItemStyled onClick={() => {
            if(module.biz_mod_mod_id == 32){
              navigate(`../pages/customForms/${module.row_id}`)
            } else {
              navigate(moduleRoutes[module.biz_mod_mod_id])
            }
          } }>
            <DivModuleImageStyled>
              <IconImageStyled src={getModImage(module)} padding={getModImagePadding(module)} color={getModColor(module)}></IconImageStyled>
            </DivModuleImageStyled>
            <DivModuleNameStyled>
              {module.biz_mod_mod_name}
            </DivModuleNameStyled>
          </DivModuleItemStyled>
          </Grid>
        )}
    </Grid>
    </DivAllPagesComponentStyled>
  )}
  

  export default Pages;