import styled from "styled-components";
import { getBizColorNumber, DivCenterStyled, getLoginImage } from "./bizStyle";
import { isDark } from "../shared/static_helper/StaticHelper";
import { Link } from "react-router-dom";

// Create a Button component that'll render a <section> tag with some styles
export const WelcomeBackground = styled.div`
  background-repeat: no-repeat;
  background-image: url(${() => getLoginImage()});
  background-position: center;
  position: relative;
  height: calc(var(--vh, 1vh) * 100);
  background-size: cover;
`;

export const LoginDivStyled = styled(DivCenterStyled)`
  height: 100%;
  padding: 0;
  background:${()=> isDark(getBizColorNumber(1)) ? 'rgb(0, 0, 0, 0.75)' : 'rgb(245, 245, 245, 0.75)' };
`;

export const NextButtonStyled = styled.button`
  color: ${(props) =>
    props.disabled ? "#ffffff" : () => getBizColorNumber(1)};
  background-color: ${(props) =>
    props.disabled ? "#d8d8d8" : () => getBizColorNumber(3)};
  font-size: 24px;
  width: 100%;
  height: 57px;
  padding: 5px;
  border: 0px;
  border-radius: 10px;
`;

export const HeaderDiv = styled.div`
  padding-top: 10%;
`;

export const EmailInputStyled = styled.input.attrs((props) => ({
  type: "email",
}))`
  color: ${() => getBizColorNumber(2)};
  background-color:${() => getBizColorNumber(4)};
  font-size: 18px;
  width: 100%;
  height: 57px;
  padding: 0.25em 1em;
  border: 1px solid ${(props) => (props.isEmailValid ? "#d0ceceb3" : "red")};
  border-radius: 10px;
`;

export const NameInputStyled = styled.input`
  color: ${() => getBizColorNumber(2)};
  background-color:${() => getBizColorNumber(4)};
  font-size: 18px;
  width: 100%;
  padding: 0.25em 1em;
  border: 1px solid ${(props) => (props.isNameValid ? "#d0ceceb3" : "red")};
  border-radius: 10px;
  height: 57px;
`;

export const DivColorStyled = styled.div`
  border: 1px solid ${(props) => (props.isValid ? "#d0ceceb3" : "red")};
  border-radius: 10px;
`;

export const CheckBoxText = styled.span`
  letter-spacing: 0;
  color: ${() => getBizColorNumber(2)};
`;

export const PrivacyPolicyText = styled.p`
  display: inline;
  color: ${() => getBizColorNumber(3)};
`;

export const TextStyled = styled.p`
    color: ${() => getBizColorNumber(2)};`

export const LinkStyled = styled(Link)`
  color: ${() => getBizColorNumber(1)};
  background-color:${() => getBizColorNumber(3)};`

export const LinkTextStyled = styled(Link)`
  color: ${() => getBizColorNumber(3)};`


