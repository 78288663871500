import React from 'react';
import { getBizColorNumber, InputTextFieldFormStyled } from '../../../biz_styles/bizStyle';
import './ClassicInputComponent.css';

export default function ClassicInputComponent({ className, defaultValue, placeholder, onChange, onClick }) {

    /* UI - start */
    /* UI - without UI functions - start */
    return (
        <InputTextFieldFormStyled
            key={defaultValue}
            className={className}
            style={{ backgroundColor: 'transparent', color: getBizColorNumber(2), borderColor: getBizColorNumber(2) }}
            defaultValue={defaultValue}
            placeholder={placeholder}
            onChange={onChange}
            onClick={onClick} />
    )
    /* UI - without UI functions - end */
    /* UI - end */
}