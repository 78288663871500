import { legacy_createStore as createStore, applyMiddleware, compose } from "redux";
import thunk from "redux-thunk";
import { persistStore, persistReducer } from 'redux-persist';
import localforage from "redux-persist/lib/storage";
import rootReducer from '../reducers/index'

const persistConfig = {
   key: 'root',
   storage: localforage,
};

const persistedReducer = persistReducer(persistConfig, rootReducer);
let middleware = applyMiddleware(thunk);

if (window.__REDUX_DEVTOOLS_EXTENSION__) {
   const devtools = window.__REDUX_DEVTOOLS_EXTENSION__();
   middleware = compose(
      middleware,
      devtools
   );
}

const store = createStore(persistedReducer, middleware);
const persistor = persistStore(store);

export { store, persistor };