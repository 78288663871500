import * as ActionTypes from "../actions/actionTypes";

const initialState = {
    results: [{ languageData: '' }]
};

function languageReducer(state = initialState, action) {

    switch (action.type) {

        case ActionTypes.GET_LANGUAGE_DATA:
            return {
                ...state,
                results: state.results.map((res, i) => i === 0 ? { ...res, languageData: action.payload } : res)
            };

        default:
            return state
    }
}

export default languageReducer