import React from 'react';
import Drawer from '@mui/material/Drawer';
import './DrawerComponent.css'
import { getBizColorNumber } from '../../biz_styles/bizStyle';
import { appMaxWidth } from '../../App';
import { getBiz } from '../../shared/redux/GlueStoreData'
//import { SwipeableDrawer } from '@mui/material';

export const DrawerComponent = ({ children, isDrawerOpen, closeDrawer, zIndexVar }) => {

  return (
    <div >

      <Drawer style={{ zIndex: zIndexVar }}

        anchor={'bottom'}
        open={isDrawerOpen}
        onClose={() => closeDrawer}
        PaperProps={{
          sx: {
            maxWidth: appMaxWidth,
            height: getBiz().club_active == 1 ? '98%' : '100%',
            width: "100%",
            marginLeft: 'auto',
            marginRight: 'auto',
            borderTopLeftRadius: getBiz().club_active == 1 ? '15px' : '0px',
            borderTopRightRadius: getBiz().club_active == 1 ? '15px' : '0px'
          },
        }}>

        {children}

        {getBiz().club_active == 1 ?
          <div className='handle-container' onClick={closeDrawer} >
            <div className='handle-div' style={{ backgroundColor: getBizColorNumber(2) }}></div>
          </div>
          : ""
        }

      </Drawer>

    </div>
  );
}

