import React, { useState, useEffect } from 'react'
import { useNavigate } from "react-router-dom";
import useWebSocket from 'react-use-websocket'
import ScratchCardComponent from '../../components/scratch_card/ScratchCardComponent'
import { Toast, getBizColorNumber } from '../../biz_styles/bizStyle'
import { getCustomer, getLanguage } from '../redux/GlueStoreData'
import { getClientAfterUpdate, getScratchCardImage, claimScratchReward } from '../../api/ApiRequests';
import { isSandbox } from '../static_helper/StaticHelper'
import RewardLayerComponent from '../../components/reward_layer_component/RewardLayerComponent'
import { ContactPageSharp } from '@mui/icons-material';

var alertsArray = []
var scratchImage = ''
var scratchCardData = ''
var pointsData = ''
var pointsFromPushData = ''
var rewardFromPushData = ''
var membershipData = ''
var chatData = ''
var pushData = ''
var rewardObject = {}

export const BizWebSocket = ({ onSocketUpdate = () => {} }) => {



  const [socketUrl, setSocketUrl] = useState(isSandbox() ? process.env.REACT_APP_SOCKET_SANDBOX_ADDRESS : process.env.REACT_APP_SOCKET_PROD_ADDRESS)

  const [isHandShakeCompleted, setIsHandShakeCompleted] = useState(false)
  const [isAlertShown, setIsAlertShown] = useState(false)

  const [showScratchCard, setShowScratchCard] = useState(false)
  const [showRewardLayer, setShowRewardLayer] = useState(false)

  const customerObject = getCustomer();

  
  
  const navigate = useNavigate();

  const { sendMessage, sendJsonMessage, lastMessage, lastJsonMessage, readyState, getWebSocket } = useWebSocket(socketUrl, {

    onOpen: () => {
      console.log('BizWebSockect -> onOpen event')
    },

    onClose: () => {
      console.log('BizWebSockect -> onClose event')
      setIsHandShakeCompleted(false)
    },

    onError: () => {
      console.log('BizWebSockect -> onError event')
      setIsHandShakeCompleted(false)
    },

    onMessage: ({ data }) => {

      const receivedData = JSON.parse(data)

      if (receivedData.code === 1) {
        handleMessageReceived(data)
      } else if (receivedData.error) {
        console.error(receivedData.error);
        return;
      }

    },

    //Will attempt to reconnect on all close events, such as server shutting down.
    shouldReconnect: (closeEvent) => true,
  })


  //Will send handshake message only if readyState is equal to 1 and isHandShakeCompleted is false.
  useEffect(() => {

    if (readyState === 1 && !isHandShakeCompleted) {
      const interval = setInterval(() => {
        sendJsonMessage({ type: "handshake", data: customerObject.socket_id });
      }, 2 * 1000);

      return () => {
        clearInterval(interval);
      }
    }
  })

  useEffect(() => {
    const interval = setInterval(() => {
      sendJsonMessage({ type: "heartBeat", data: '' });
    }, 5 * 1000);

    return () => {
      clearInterval(interval);
    }
  }
  )

  const checkIfThereIsAlertToShow = () => {

    // setShowRewardLayer(rewardObject.type != "")

    if (alertsArray.length > 0) {

      alertsArray.forEach(type => {

        switch (type) {

          case 'point':
            showPointsAlert()
            break

          case 'points_from_friend':
            showPointsAlert()
            break

          case 'membership':
            showMembershipAlert()
            break

          case 'points_from_push':
            showPointsFromPushAlert()
            getClientAfterChange()
            break

          default:

        }

      });

    }
  }

  const handleMessageReceived = (data) => {

    const messageData = JSON.parse(data)

    // console.log('BizWebSockect -> onMessage event -> ' + data)

    switch (messageData.type) {

      case 'handshake_complete':
        setIsHandShakeCompleted(true)
        break

      case 'serverRequest':
        // console.log('BizWebSockect -> handle server request event')
        break

      case 'point':
        pointsData = messageData.data
        // alertsArray.push(messageData.type)
        getRewardObjectFromPointsSocket(messageData.data)
        setShowRewardLayer(true)
        getClientAfterChange()
        break

      case 'points_from_friend':
        pointsData = messageData.data
        getRewardObjectFromPointsSocket(messageData.data)
        setShowRewardLayer(true)
        getClientAfterChange()
        break

      case 'scratch':
        scratchCardData = messageData.data
        getScratchCardImageFromApi()
        break

      case 'scratch_empty':
        break

      case 'benefit_granted':
        getClientAfterChange()
        break

      case 'membership':
        membershipData = messageData.data
        alertsArray.push(messageData.type)
        getClientAfterChange()
        break

      case 'chat_message':
        chatData = messageData.data
        break

      case 'push':
        pushData = messageData.data
        handlePushMessageReceived()
        break

      case 'clientBadgesData':
        getClientAfterChange()
        // {"type":"clientBadgesData","data":{"badges":{"appointments":"0","benefits":"2","orders":"2","notifications":"0","requests":"0","documents":"4","membership":"0","wishlist":"0","invitefriends":"0","subscriptions":"0","punchpass":"1","loyalty_cards":"0","chat":"3"}},"code":1,"timecode":1669284745}
        break

      default:

    }

    checkIfThereIsAlertToShow()

    
  }

  const handlePushMessageReceived = () => {

    // console.log('BizWebSockect -> handle push message event -> type = ' + pushData.push_type)

    switch (pushData.push_type) {

      case 8:
        pointsFromPushData = pushData.extra_params
        getRewardObjectFromPointsPushSocket(pushData.extra_params)
        setShowRewardLayer(true)
        getClientAfterChange()
        break

      case 9:
        scratchCardData = pushData.extra_params
        getScratchCardImageFromApi()
        break
      
      case 13:
        rewardFromPushData = pushData.extra_params
        getRewardObjectFromRewardsPushSocket(pushData.extra_params)
        setShowRewardLayer(true)
        getClientAfterChange()
        break;

      default:

    }

    onSocketUpdate(pushData);

  }

  const showPointsAlert = () => {
    const getLang = getLanguage();
    if (!isAlertShown) {
      Toast.fire({
        allowOutsideClick: false,
        title: getLang.congratulations + '!',
        text: getLang.you_just_won + ' ' + String(pointsData.amount) + ' ' + getLang.points.toLowerCase(),
        icon: 'success',
        confirmButtonText: getLang.ok,
        iconColor: getBizColorNumber(3),
        confirmButtonColor: getBizColorNumber(3),
        didOpen: () => {
          setIsAlertShown(true)
        },
      }).then((result) => {
        setIsAlertShown(false)
        alertsArray = alertsArray.filter(item => item !== 'point')
        checkIfThereIsAlertToShow()
      })
    }
  }

  const showPointsFromPushAlert = () => {
    const getLang = getLanguage();
    if (!isAlertShown) {
      Toast.fire({
        allowOutsideClick: false,
        title: pointsFromPushData.inapp_title,
        text: pointsFromPushData.inapp_subtitle + " " + pointsFromPushData.reward_label,
        icon: 'success',
        confirmButtonText: getLang.ok,
        iconColor: getBizColorNumber(3),
        confirmButtonColor: getBizColorNumber(3),
        didOpen: () => {
          setIsAlertShown(true)
        },
      }).then((result) => {
        setIsAlertShown(false)
        alertsArray = alertsArray.filter(item => item !== 'points_from_push')
        checkIfThereIsAlertToShow()
      })
    }
  }

  const showMembershipAlert = () => {
    const getLang = getLanguage();
    if (!isAlertShown) {
      Toast.fire({
        allowOutsideClick: false,
        title: getLang.congratulations + '!',
        text: membershipData.text,
        icon: 'success',
        confirmButtonText: getLang.ok,
        iconColor: getBizColorNumber(3),
        confirmButtonColor: getBizColorNumber(3),
        didOpen: () => {
          setIsAlertShown(true)
        },
      }).then((result) => {
        setIsAlertShown(false)
        alertsArray = alertsArray.filter(item => item !== 'membership')
        checkIfThereIsAlertToShow()
      })
    }
  }


  const showScrathCardAlert = () => {
    return (
      <ScratchCardComponent cardData={scratchCardData} newImg={scratchImage} showScratchCard={setShowScratchCard} />
    )
  }

  async function getClientAfterChange() {
    await getClientAfterUpdate();
  }

  async function getScratchCardImageFromApi() {

    const colorFromData = scratchCardData.card.back_data.replace('#', '');
    const scratchCardImageRes = await getScratchCardImage(colorFromData);

    if (scratchCardImageRes) {
      scratchImage = scratchCardImageRes
      setShowScratchCard(true)
    }

    const claimScratchRewardRes = await claimScratchReward(scratchCardData.card.cscard_id)

    getClientAfterChange()

  }

  function closeDialog(){
    setShowRewardLayer(false)
  }

  function initRewardObject(){
    rewardObject.type = ''
    rewardObject.amount = 0
    rewardObject.reason = ""
    rewardObject.reward_label = ""
  }

  function getRewardObjectFromPointsSocket(data) {
    initRewardObject()
    rewardObject.type = "points"
    rewardObject.amount = data.amount
    rewardObject.reason = data.text
  }

  function getRewardObjectFromPointsPushSocket(data) {
    initRewardObject()
    rewardObject.type = "points"
    rewardObject.amount = data.received_points
    rewardObject.reason = data.points_reason_text
  }

  function getRewardObjectFromRewardsPushSocket(data) {
    initRewardObject()
    rewardObject.type = "benefit"
    rewardObject.reward_label = data.reward_label
    rewardObject.reason = typeof(data.reward_reason) !== "undefined" ? data.reward_reason : ""
  }

  return (

    <div>

      {showScratchCard ? showScrathCardAlert() : ''}
      
      {showRewardLayer ? <RewardLayerComponent onClose={closeDialog} rewardsData={rewardObject} /> : null}
      
    </div>

  )

}