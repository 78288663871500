import styled from 'styled-components'

export const H5ConfirmationMeesageStyled = styled.h5`
    text-align: center;`

export const PConfirmationButtonStyled = styled.p`
    cursor: pointer;
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 10px;
    color: ${(props) => props.isOk ? 'green' : 'red'}`