import React, { useState, useRef } from 'react'
import { useNavigate } from "react-router-dom"
import apiClient from '../../api/apiClient'
import { DivCenterStyled, Toast, getBizColorNumber } from '../../biz_styles/bizStyle'
import { NextButtonStyled, EmailInputStyled, LoginDivStyled, WelcomeBackground } from '../../biz_styles/LoginStyle'
import LoadingComponent from '../../components/loading_components/LoadingComponent'
import LoginHeader from '../login/LoginFormHeader'
import { useStoreProvider } from '../../shared/providers/StoreContext'
import ReCAPTCHA from "react-google-recaptcha"
import { getLanguage } from '../../shared/redux/GlueStoreData'


function exclusiveLoginForm() {

    const [email, setEmail] = useState("");
    const [isValid, setIsValid] = useState(true);
    const [isLoading, setIsLoading] = useState(false);
    const [isDisabled, setisDisabled] = useState(true);
    const captchaRef = useRef()
    const getLang = getLanguage();

    const navigate = useNavigate();

    const bizObject = useStoreProvider().biz


    const validateEmail = (e) => {
        return new RegExp( /[a-zA-Z0-9]+[\.]?([a-zA-Z0-9]+)?[\@][a-z]{3,15}[\.][a-z]{2,5}/g).test(e); // eslint-disable-line
    }

    const handleOnChange = e => {
        e.preventDefault();
        setEmail(e.target.value)
        setIsValid(validateEmail(e.target.value))
        if(validateEmail(e.target.value)){
            setisDisabled(false)
        } else{
            setisDisabled(true)
        }
    }

    const onSubmitEmail = async () => {
        const token = await captchaRef.current.executeAsync();
        if (isValid) {
            setIsLoading(true)
            submitExclusiveRegister(token)        
        } 
        else {
            showError(getLang.error,getLang.invalid_email)
            captchaRef.current.reset();
        }
      };

      async function submitExclusiveRegister(captchaToken) {
        try {
            const postData = {
                email: email,
                token:captchaToken
            };

            const res = await apiClient.post("/submitExclusiveRegister", postData);

            if (res.status === 200) {
                if (res.data.responseCode === 1) {
                    setIsLoading(false)
                    if(res.data.responseMessage === 'sms_code_sent'){
                        navigate("../LoginFormSmsCode",{state: {email}})
                    }
                    else if(res.data.responseMessage === 'email_code_sent'){
                        navigate("../verifyEmail")
                    }
                }
                else if(res.data.responseMessage === 'no_email_in_system'){
                    // TODO create screen -> contact biz owner to receive an invite to the club.
                    setIsLoading(false)
                    navigate("../contactBizOwner")
                }
                else{
                    setIsLoading(false)
                    showError(getLang.sorry, getLang.something_went_wrong_try_again)
                    captchaRef.current.reset();
                }
            } else {
                setIsLoading(false)
                showError(getLang.sorry, getLang.something_went_wrong_try_again)
                captchaRef.current.reset();
            }

        } catch (err) {
            showError(getLang.sorry, getLang.something_went_wrong_try_again)
            captchaRef.current.reset();
        }
    }

    function showError(title,text) {
        Toast.fire({
          title: title,
          text: text,
          icon: 'error',
          confirmButtonColor: getBizColorNumber(3),
          confirmButtonText: getLang.ok
        })
      }
    


    return (
        <WelcomeBackground>
            <LoadingComponent isLoading={isLoading} />
            <LoginDivStyled>
                <LoginHeader
                    title={bizObject.biz_welcome_title}
                    subtitle={getLang.lets_verify_identity_to_connect_to_your_members_profile} />
                <DivCenterStyled>
                    <EmailInputStyled isEmailValid={isValid} placeholder={getLang.getLang} onChange={handleOnChange} />
                </DivCenterStyled>
                <DivCenterStyled >
                    <ReCAPTCHA
                    sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
                    ref={captchaRef}
                    size="invisible"
                    />
                    <NextButtonStyled onClick={onSubmitEmail} disabled={isDisabled}>{getLang.next}</NextButtonStyled>
                </DivCenterStyled>
            </LoginDivStyled>
        </WelcomeBackground>

    )

}

export default exclusiveLoginForm