import styled from 'styled-components'
import { getBizColorNumber } from './bizStyle';

// div - start
export const DivMainStyled = styled.div`
    width: 100%;
    background-color: ${() => getBizColorNumber(1)};
    max-width: 550px;
    padding: 2%;
    margin-left: auto;
    margin-right: auto;
    position: ${(props) => (props.position === null || props.position === undefined) ? 'relative' : props.position };`

export const DivMain2Styled = styled(DivMainStyled)`
    padding: 0%;`

export const DivMain3Styled = styled(DivMainStyled)`
    overflow-y: auto;
    padding:0px;`

export const DivReadPrivacyPolicyStyled = styled.div`
    display: flex;
    margin-top: 2%;`

export const DivButtonsStyled = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 5%;`

export const DivImgItemProfileWrapperStyled = styled.div`
    border-radius: 50%;
    height: 40px;
    width: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: ${() => getBizColorNumber(4)}`

export const DivImgItemProfileWrapper2Styled = styled(DivImgItemProfileWrapperStyled)`
    height: 70px;
    width: 70px;
    background-color: ${() => getBizColorNumber(4)}`

export const DivImgItemProfileWrapper3Styled = styled(DivImgItemProfileWrapperStyled)`
    background-color: ${(props) => props.backgroundColor};`

    export const DivColor1Styled = styled.div`
    background-color: ${() => getBizColorNumber(1)};
    max-width: 550px;
    padding: 2%;
    margin-left: auto;
    margin-right: auto;
    position: relative;`

export const DivColor4Styled = styled.div`
    background-color: ${() => getBizColorNumber(4)};
    max-width: 550px;
    padding: 2%;
    margin-left: auto;
    margin-right: auto;
    position: relative;`
// div - end

// p - start
export const PActiveNumberItemProfileStyled = styled.div`
    width: 40px;
    font-size: 10px;
    margin: 1px;
    color: ${() => getBizColorNumber(1)};
    background-color: ${() => getBizColorNumber(5)}`
// p - end

// button - start
export const ButtonStyled = styled.button`
    cursor: pointer;
    width: 30%;
    border: 2px solid grey;
    background-color: ${(props) => getBizColorNumber(props.isVerify ? 5 : 4)
        .replaceAll('#', '#' +
            (props.isVerify ?
                (props.isAgreed ? '' : '66') :
                ''))};
    color: ${(props) => getBizColorNumber(props.isVerify ? 1 : 3)};`

export const ButtonSaveStyled = styled.button`
    width: 100%;
    background-color: ${() => getBizColorNumber(5)};
    color: ${() => getBizColorNumber(1)};`
// button - end

// img - start
export const ImgCloseEditProfileStyled = styled.img`
    position: absolute;
    right: 20px;
    top: 20px;
    height: 17.5px;
    width: 17.5px;
    z-index: 1`

export const ImgDynamicStyled = styled.img`
    position: relative;
    alt: "";
    border-radius: 100px;
    margin: ${(props) => props.margin}px;     
    height: ${(props) => props.height}px;
    width: ${(props) => props.width}px`

export const Img40Styled = styled.img`
    height: 40px;
    width: 40px;`

export const Img20Styled = styled(Img40Styled)`
    height: 65px;
    width: 65px;
    border-radius: 32.5px;`
// imge - end

