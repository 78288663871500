import styled from 'styled-components'
import { getBizColorNumber } from './bizStyle';

  export const NextButtonStyled = styled.button`
  color: ${(props)=>props.disabled?'#ffffff':() => getBizColorNumber(2)};
  background-color: ${(props)=>props.disabled?'#d8d8d8':() => getBizColorNumber(3)};
  font-size: 15px;
  width: 100%;
  padding: 5px;
  border: 0px;
  border-radius: 10px;`

  export const OverlayContainerStyled = styled.div`
  position: absolute;
  height:100%;
  width:100%;
  top:0px;
  left:0px;
  margin: 0px;
  background-color:${() => getBizColorNumber(4)};
  opacity: ${(props)=>props.show ?'90%':'0%'};
  border-radius: 10px;
  border-width:  1px;
  border-color:rgba(117, 91, 90,0.5);
  border-style:ridge;`