import React, { useEffect, useState } from "react";
import Iframe from 'react-iframe'
import { ColorSpacerStyled, DivCenterStyled, SpacerStyled } from "../../biz_styles/ReusableStyles";
import { getBizColorNumber, H5Styled } from "../../biz_styles/bizStyle";
import { getLevelData } from "../../api/ApiRequests";
import { checkIfNullUndefined } from "../../shared/static_helper/StaticHelper";
import { getLanguage } from "../../shared/redux/GlueStoreData";
import './WebBrowserScreen.css'
import { useStoreProvider } from "../../shared/providers/StoreContext";

export default function WebBrowserScreen({ type, currentObjParam, customUrl }) {

    /* Logic functions - start */
    const getLang = getLanguage();
    const bizObject = useStoreProvider().biz

    const [url, setUrl] = useState('');

    const currentObj = checkIfNullUndefined(currentObjParam) ? {} : currentObjParam;

    const params = {
        mod_id: currentObj.mod_id,
        level_no: currentObj.level_no,
        parent: currentObj.parent,
        qs: '',
        subcategory: '',
        prodId: currentObj.prod_id,
        itemsPerPage: '0',
        existingItems: '0',
    };

    useEffect(() => {

        setUrlFunc();
    }, []);

    function setUrlFunc() {

        switch (type) {

            case 'privacy-policy':

                setUrl(bizObject.bizPrivacyUrl)
                break;

            case 'custom':
                setUrl(customUrl)
                break;
            
            default:

                getLevelDataApi();
                break;
        }
    }

    async function getLevelDataApi() {

        const responseData = await getLevelData(params);

        if (!checkIfNullUndefined(responseData)) {

            setUrl(responseData.rows[0].md_head);
        }
    }

    function getTitleLabel() {

        var titleLabel = ' ';

        switch (type) {

            case 'privacy-policy':

                titleLabel = getLang.privacy_policy_terms;
                break;

            default:

                titleLabel = ' '
                break;
        }

        return titleLabel;
    }
    /* Logic functions - end */

    /* UI - start */
    /* UI - without UI functions - start */
    return (
        <DivCenterStyled
            className='personal-data-holder'
            style={{ backgroundColor: getBizColorNumber(1) }}>

            {/* <div
                style={{
                    textAlign: 'center',
                    marginTop: '60px',
                    marginBottom: '10px'
                }} /> */}

            <SpacerStyled height={'50'} /> 
            {/* height={'26'} /> */}


            <H5Styled
                className='h5-title-label-web-browser'>
                {getTitleLabel()}
            </H5Styled>

            <ColorSpacerStyled style={{  opacity: 0.1 }} height={'1'} color={getBizColorNumber(2)}/>

            <Iframe
                className='iframe-web-browser'
                url={url}
                width='100%'
                frameBorder={1}
                />

        </DivCenterStyled>
    )
    /* UI - without UI functions - end */
    /* UI - end */
}
