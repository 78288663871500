import styled from 'styled-components'
import { getBizColorNumber } from './bizStyle';
import IconTint from "react-icon-tint";
import { TitleStyled } from './ReusableStyles';

export const PageTitleStyled = styled(TitleStyled)``

export const DivPaddingTop = styled.div`
    padding-top: 25%;`

export const DivListStyled = styled.div`
    background:linear-gradient(rgba(255,255,255,0),${() => getBizColorNumber(1)});
    height:100vh ;
    padding-top: 18%;
    `

export const DivLockedStyled = styled.div`
    background: ${() => getBizColorNumber(2)+ '50'};
    color: ${() => getBizColorNumber(3)};
    z-index: 1;
    position: absolute;
    width: 100%;
    height: 100%;
    padding-top: 90%;
    font-weight: bold;
    font-size: larger;
    text-align:center;
    border-radius: 5px;
    backdrop-filter: blur(2px);
    `

export const DivCardStyled = styled.div`
    position: relative;
    float: left;
    margin-left:10px;`

export const DivXYCenterStyled = styled.div`
    position: relative;
    left: 50%; 
    top: 50%; 
    transform: translate(-50%, -50%);`

export const ContainerCardStyled = styled(DivCardStyled)`
    width: ${(props) => props.width};
    height: ${(props) => props.height};
    // background:${() => getBizColorNumber(2)};
    border-radius: 5px;
    `

export const DivTitlecStyled = styled.div`
    position: absolute;
    top: 167px;
    max-width: 100%;
    margin: auto;
    padding: 8px;
    // background:${() => getBizColorNumber(3)};
    color:${() => getBizColorNumber(2)};`


export const DivCenterStyled = styled.div`
    position: absolute;
    bottom: 122px;
    margin: auto;
    padding-left: 8px;
    padding-right: 8px;
    font-size: 20px;
    // background:${() => getBizColorNumber(2)};
    color:${() => getBizColorNumber(3)};`


export const DivCustomYStyled = styled(DivCenterStyled)`
top: ${(props) => props.top}px;
`


export const DivPriceStyled = styled.div`
    position: absolute;
    top: 25%;
    left: 0%;
    font-size: 15px;
    color:${() => getBizColorNumber(2)};
    width: 80%;
    margin: auto;`

export const DescTitleStyled = styled.p`
    color:${() => getBizColorNumber(2)};`

export const SizeableFontPStyled = styled(DescTitleStyled)`
    font-size: ${(props) => props.size}px;
    padding: ${(props) => props.padding}px;
    opacity: ${(props) => props.opacity};
    `

export const DivDescStyled = styled.div`
    height: 15%;
    max-width: 100%;
    color:${() => getBizColorNumber(2)};`

export const LabelCardStyled = styled.p`
    position: absolute;
    bottom: 0%;
    left: 0%;
    margin-bottom: 0px;
    margin-right: 1px;
    padding-left: 8px;
    padding-right: 8px;
    background-color:${() => getBizColorNumber(3)};
    color:${() => getBizColorNumber(1)};`

export const CardTopImageStyled = styled.img`
    border-radius: 5px 5px 0 0;
    height: 100%;
    width: 100%;`

export const DivTopImageStyled = styled.div`
    position: absolute;
    left: 0%;
    top: 0%;
    padding-left: 1px;
    padding-right: 1px;
    height: 167px;
    width: 100%;`

export const SvgIconStyle = styled.div`
    position: absolute;
    left: 10%;
    top: 65%;`

export const CardImageStyled = styled(IconTint).attrs(props => ({
    color:getBizColorNumber(4)
  }))`
`










