import React, { useState, useEffect } from 'react';
import TopFormComponent from './edit_profile_components/top_form_component/TopFormComponent';
import TitleFieldFormComponent from './edit_profile_components/title_field_form_component/TitleFieldFormComponent';
import TextFieldFormComponent from './edit_profile_components/text_field_form_component/TextFieldFormComponent';
import EmailTextFieldFormComponent from './edit_profile_components/email_text_field_form_component/EmailTextFieldFormComponent';
import PhoneTextFieldFormComponent from './edit_profile_components/phone_text_field_form_component/PhoneTextFieldFormComponent';
import ChangePhoneFormComponent from './edit_profile_components/change_phone_form_component/ChangePhoneFormComponent';
import DateTextFieldFormComponent from './edit_profile_components/date_text_field_form_component/DateTextFieldFormComponent';
import GenderRadioFormComponent from './edit_profile_components/gender_radio_form_component/GenderRadioFormComponent';
import VerifyPhoneNumberScreen from '../verify_phone_number/VerifyPhoneNumberScreen';
import DialogTransitionComponent from '../../components/dialog_components/dialog_transition_component/DialogTransitionComponent';
import Select from 'react-select'
import utf8 from "utf8";
import LoadingComponent from '../../components/loading_components/LoadingComponent';
import { SpacerStyled, DivCenterStyled, ScrollableDivStyled } from '../../biz_styles/ReusableStyles';
import { getBizColorNumber, PStyled, SpanPrivacyPolicyStyled, Toast } from '../../biz_styles/bizStyle'
import { DivMain2Styled, ButtonSaveStyled, DivMainStyled } from '../../biz_styles/ProfileStyle';
import { resizeFile, createFile, validateEmail, checkIfNullUndefined, imageExists } from '../../shared/static_helper/StaticHelper'
import { updateCustomer, getClientAfterUpdate, deleteClient, signout } from '../../api/ApiRequests';
import { useStoreProvider } from '../../shared/providers/StoreContext'
import { getLanguage, clearStoreData } from '../../shared/redux/GlueStoreData';
import './EditProfileScreen.css';
import { DrawerComponent } from '../../components/drawer/DrawerComponent';
import WebBrowserScreen from '../privacy_policy/WebBrowserScreen';
import { InviteButtonStyled,AbsInviteButtonStyled } from '../../biz_styles/PersonalPointsStyle';
import { color } from '@mui/system';
import Swal from 'sweetalert2';

export default function EditProfileScreen({ dialogClosed, isDialog }) {

    /* Logic functions - start */
    const staticData = require('../../assets/json_files/static_data.json');

    const custPicId = '11';
    const custPhone1Id = '9';
    const custCountryId = '7';
    const custStateId = '5';

    const getLang = getLanguage();
    const bizObject = useStoreProvider().biz
    const customerObject = useStoreProvider().cust

    const [showDialogScreen, setShowDialogScreen] = useState(false);
    const [isDrawerOpen, setIsDrawerOpen] = useState(false);
    const [phoneCodeIsVerified, setPhoneCodeIsVerified] = useState(customerObject.cust_status === 'member');
    const [phoneNumber9, setPhoneNumber9] = useState(getCurrentValueFromGetCustomer(custPhone1Id));
    const [showStatesList, setShowStatesList] = useState(getComboBoxItem(custCountryId, getCurrentValueFromGetCustomer(custCountryId)));
    const [isLoadingSaveCustomer, setIsLoadingSaveCustomer] = useState(false);
    const [mapBizFieldsJSON, setMapBizFieldsJSON] = useState(new Map()); // eslint-disable-line

    // const inputStyle = {
    //     '& label': {
    //         color: getBizColorNumber(2),
    //     },
    //     '& input': {
    //         color: getBizColorNumber(2),
    //     },
    //     '& label.Mui-focused': {
    //         color: getBizColorNumber(2),
    //     },
    //     '& .MuiInput-underline:before': {
    //         borderBottomColor: getBizColorNumber(2),
    //     },
    //     '& .MuiInput-underline:after': {
    //         borderBottomColor: getBizColorNumber(2),
    //     },
    //     '& .MuiOutlinedInput-root': {
    //         '& fieldset': {
    //             borderColor: 'red',
    //         },
    //         '&.Mui-focused fieldset': {
    //             borderColor: getBizColorNumber(2),
    //         },
    //     }
    // }

    const styles = {
        option: (styles, { data }) => {
          return {
            ...styles,
            backgroundColor: getBizColorNumber(4),
            color: getBizColorNumber(2),
            ":hover": {
              backgroundColor: getBizColorNumber(5)
            },
    
          };
        },
        control: (styles = {}) => {
          return {
            ...styles,
            margin: "4px 0 0",
            borderRadius: "8px",
            border: "1px solid",
            style:{color:'red'},
            backgroundColor: 'transparent',
            borderColor: getBizColorNumber(2) + '!important',
            " .css-1dimb5e-singleValue":{
                color: getBizColorNumber(2) + '!important'
            },
            " .css-166bipr-Input":{
                color: getBizColorNumber(2) + '!important'
            }
          };
        }
      };

    useEffect(() => {

        fillMapBizFieldsJSON();
    }, []);

    async function fillMapBizFieldsJSON() {

        var bizFields = bizObject.biz_fields;

        for (var i = 0; i < bizFields.length; i++) {

            var cfiFieldId = bizFields[i].cfi_field_id;

            var currentValue = getCurrentValueFromGetCustomer(cfiFieldId);

            if (cfiFieldId === custPicId) {

                mapBizFieldsJSON['image'] = '';
                if (imageExists(currentValue)) {

                    const file = await createFile(currentValue);

                    const resizedImage = await resizeFile(file);

                    mapBizFieldsJSON['image'] = resizedImage;
                } else {

                    mapBizFieldsJSON['image'] = '';
                }
            }
            else {

                handleOnChangeForSavingData(cfiFieldId, currentValue);
            }
        }
    }

    function getComboBoxItem(cfiId, value) {

        var currntItem = '';

        var comboBoxList = getComboBoxData(cfiId);

        for (var i = 0; i < comboBoxList.length; i++) {

            var comboBoxListValue = String(comboBoxList[i].value);

            if (value === comboBoxListValue) {

                currntItem = comboBoxListValue;
            }
        }

        return currntItem;
    }

    function openDialogVerifyPhoneNumberScreen() {

        setShowDialogScreen(true);
    }

    function dialogVerifyPhoneNumberScreenClosed(closedWithSuccess, phoneNumber) {

        if (closedWithSuccess) {
            setPhoneCodeIsVerified(true);
            if (phoneNumber !== 'backdropClick') {
                setPhoneNumber9(phoneNumber);
            }
            handleOnChangeForSavingData(custPhone1Id, phoneNumber)
        }

        setShowDialogScreen(false);
    }

    function openDrawer() {

        setIsDrawerOpen(true);
    }

    function closeDrawer() {

        setIsDrawerOpen(false);
    }

    function handleCountryNStateSelected(cfiFieldId, selection) {

        var selectionValue = String(selection.value);

        if (cfiFieldId === custCountryId) {

            updateShowStatesList(selectionValue);
        }

        handleOnChangeForSavingData(cfiFieldId, selectionValue)
    }

    function updateShowStatesList(value) {

        var showStatesListVar = value === '1';

        setShowStatesList(showStatesListVar);

        if (!showStatesListVar) {

            mapBizFieldsJSON['cust_state'] = utf8.encode('');
        }
    }

    function getCurrentFDbName(cfiFieldId) {

        var currentFDbName = 0;

        var data = staticData[3].data;

        for (var i = 0; i < data.length; i++) {

            var dataItem = data[i];

            if (dataItem[0] === parseInt(cfiFieldId)) {

                currentFDbName = dataItem[2];

                break;
            }
        }

        return currentFDbName;
    }

    function getCountriesList() {

        return handleGetCountriesStatesList(0);
    }

    function getStatesList() {

        return handleGetCountriesStatesList(1);
    }

    function handleGetCountriesStatesList(index) {

        var list = [
            {
                value: 0,
                label: ''
            }
        ];

        var data = staticData[index].data;

        for (var i = 0; i < data.length; i++) {

            var dataItem = data[i];

            list.push({
                value: dataItem[0],
                label: dataItem[1]
            })
        }

        return list;
    }

    function getComboBoxData(cfiFieldId) {

        var getListSelect = [];

        if (cfiFieldId === custCountryId) {

            getListSelect = getCountriesList();
        }
        else if (cfiFieldId === custStateId) {

            getListSelect = showStatesList ? getStatesList() : [];
        }

        return getListSelect;
    }

    function handleOnChangeForSavingData(cfiFieldId, itemValue) {

        var getCurrentFDbNameVar = getCurrentFDbName(cfiFieldId);
        var itemValueVar = checkIfNullUndefined(itemValue) ? '' : itemValue;
        
        mapBizFieldsJSON[getCurrentFDbNameVar] = (itemValueVar);
    }

    function getCurrentValueFromGetCustomer(cfiFieldId) {

        return customerObject[getCurrentFDbName(cfiFieldId)];
    }

    async function handleOnChangeForSavingDataImage(img) {

        const resizedImage = await resizeFile(img);

        mapBizFieldsJSON['image'] = resizedImage;
    }

    async function saveCustomer() {

        var custFirstName = (mapBizFieldsJSON['cust_first_name']);
        var custPhone1 = utf8.decode(mapBizFieldsJSON['cust_phone1']);
        var custEmail = utf8.decode(mapBizFieldsJSON['cust_email']);

        if (custFirstName === '') {
            showError(getLang.error, getLang.please_fill_valid_full_name);
        }
        else if (custPhone1 === '') {
            showError(getLang.error, getLang.please_fill_valid_full_name);
            showError(getLang.error, getLang.please_verify_your_phone_number);
        }
        else if (custEmail !== '' &&
            !validateEmail(custEmail)) {
            showError(getLang.error, getLang.wrong_email_address_please_check_and_try_again);
        }
        else {

            setIsLoadingSaveCustomer(true);

            const responseCode = await updateCustomer(mapBizFieldsJSON);

            if (responseCode === 1) {

                getClientAfterSave();
            }
            else {

                setIsLoadingSaveCustomer(false);
                showError(getLang.error, getLang.communication_error_please_try_again)
            }
        }
    }

    async function getClientAfterSave() {

        const responseCode = await getClientAfterUpdate();

        if (responseCode === 1) {

            setIsLoadingSaveCustomer(false);

            dialogClosed();
        }
        else {

            setIsLoadingSaveCustomer(false);
            showError(getLang.error, getLang.communication_error_please_try_again);
        }
    }

    function showDeleteAlertMessage() {
        Toast.fire({
            allowOutsideClick: false,
            title: getLang.destructive_action,
            html: getLang.are_you_sure_you_wish_to_delete_your_account,
            confirmButtonText: getLang.delete_everything,
            showCancelButton: true,
            cancelButtonText: getLang.cancel,
            reverseButtons: true,
            iconColor: getBizColorNumber(3),
            confirmButtonColor: getBizColorNumber(3),
        }).then(async (result) => {
            if (result.isConfirmed) {
                Toast.fire({
                    title: getLang.please_wait,
                    didOpen: () => {
                        Swal.showLoading()
                    }
                })

                deleteAccount()


            }
        })
    }

    function showSignoutAlertMessage() {
        Toast.fire({
            allowOutsideClick: false,
            title: getLang.sign_out,
            html: getLang.are_you_sure_you_wish_to_sign_out,
            confirmButtonText: getLang.yes,
            showCancelButton: true,
            cancelButtonText: getLang.cancel,
            reverseButtons: true,
            iconColor: getBizColorNumber(3),
            confirmButtonColor: getBizColorNumber(3),
        }).then(async (result) => {
            if (result.isConfirmed) {
                Toast.fire({
                    title: getLang.please_wait,
                    didOpen: () => {
                        Swal.showLoading()
                    }
                })

                logout()
            }
        })
    }

    async function deleteAccount() {

        const responseCode = await deleteClient();

        if (responseCode === 1) {
            clearStoreData()
        }
    }

    async function logout() {

        const responseCode = await signout();

        if (responseCode === 1) {
            clearStoreData()
        }
    }

    function getHeight() {

        if (isDialog) {
            return '100vh'
        } else {
            return 'calc(100vh - 100px)'
        }
    }

    /* Logic functions - end */

    /* UI - start */
    /* UI - without UI functions - start */
    return (
        <DivMainStyled>

            <LoadingComponent isLoading={isLoadingSaveCustomer} />

            <ScrollableDivStyled id="scrollableDiv" height={getHeight()} >

                <DivMain2Styled>

                    <SpacerStyled height='100' />

                    

                    <div className='div-inner-form'>

                        {listLayoutEditProfileItems()}

                    </div>

                    <DivCenterStyled style={{ paddingBottom: 10 }}>
                        <SpanPrivacyPolicyStyled
                            onClick={openDrawer}>
                            {getLang.privacy_policy_terms}
                        </SpanPrivacyPolicyStyled>
                    </DivCenterStyled>

                    <DivCenterStyled style={{ paddingBottom: 10, color: 'red' }} onClick={showDeleteAlertMessage}>
                        <span style={{ cursor: 'pointer' }}>{getLang.delete_my_account}</span>
                    </DivCenterStyled>

                    <DivCenterStyled style={{ paddingBottom: 55, color: getBizColorNumber(3) }} onClick={showSignoutAlertMessage}>
                        <span style={{ cursor: 'pointer' }}>{getLang.sign_out}</span>
                    </DivCenterStyled>

                    <SpacerStyled height='50' />



                    {dialogVerifyPhoneNumberScreen()}

                    <DrawerComponent zIndexVar={1300} children={getCurrentDrawerChild()} isDrawerOpen={isDrawerOpen} closeDrawer={closeDrawer} />

                </DivMain2Styled>
            </ScrollableDivStyled>
            {isDialog ? 
            <InviteButtonStyled onClick={saveCustomer}>{getLang.save}</InviteButtonStyled>
            : <AbsInviteButtonStyled onClick={saveCustomer}>{getLang.save}</AbsInviteButtonStyled>}
        </DivMainStyled>
    )
    /* UI - without UI functions - end */

    /* UI - with UI functions - start */
    function listLayoutEditProfileItems() {

        return bizObject.biz_fields
            .map(
                (bizFieldItem) =>

                    <div key={bizFieldItem.cfi_field_id}>
                        {cellForItem(bizFieldItem)}
                    </div>
            )
    }

    function cellForItem(bizFieldItem) {

        var cfiType = bizFieldItem.cfi_type;
        var cfiFieldId = bizFieldItem.cfi_field_id;
        var currentValue = getCurrentValueFromGetCustomer(cfiFieldId);

        if (cfiType === 'image') {

            return <div>

                <TopFormComponent
                    imageUrl={currentValue}
                    name={customerObject.cust_first_name}
                    onChange={(img) => handleOnChangeForSavingDataImage(img)} />

                {spacer()}

            </div>
        }
        else if (cfiType === 'textfieldname' ||
            cfiType === 'textfieldemail' ||
            cfiType === 'textfieldphone' ||
            cfiType === 'date' ||
            cfiType === 'combobox' ||
            cfiType === 'radiobox') {

            return <div>

                {/* <TitleFieldFormComponent
                    value={bizFieldItem.cfi_label}
                    requireParam={bizFieldItem.cfi_req} /> */}

                {(() => {

                    if (cfiType === 'textfieldname') {

                        return <div>
                            <TitleFieldFormComponent
                                value={bizFieldItem.cfi_label}
                                requireParam={bizFieldItem.cfi_req} />

                            <TextFieldFormComponent
                                defaultValue={currentValue}
                                onChange={(e) => handleOnChangeForSavingData(cfiFieldId, e.target.value)} />
                        </div>
                    }
                    else if (cfiType === 'textfieldemail') {

                        return <div>
                            <TitleFieldFormComponent
                                value={bizFieldItem.cfi_label}
                                requireParam={bizFieldItem.cfi_req} />

                            <EmailTextFieldFormComponent
                                defaultValue={currentValue}
                                onChange={(e) => handleOnChangeForSavingData(cfiFieldId, e.target.value)} />
                        </div>


                    }
                    else if (cfiType === 'textfieldphone') {

                        var cfiFieldIdIs9 = cfiFieldId === custPhone1Id;
                            
                        return <div>

                            <TitleFieldFormComponent className='ltr-input'
                                value={bizFieldItem.cfi_label}
                                requireParam={bizFieldItem.cfi_req} />

                            {cfiFieldIdIs9 ?

                                <div>

                                    <PStyled className='ltr-input float-start'
                                        onClick={openDialogVerifyPhoneNumberScreen}>
                                        {phoneNumber9}
                                    </PStyled>

                                    <ChangePhoneFormComponent
                                        openDialog={openDialogVerifyPhoneNumberScreen}
                                        isVerified={phoneCodeIsVerified} />

                                </div> :

                                <PhoneTextFieldFormComponent className='ltr-input'
                                    defaultValue={currentValue}                                    
                                    onChange={(e) => handleOnChangeForSavingData(cfiFieldId, e.target.value)} />}

                        </div>
                    }
                    else if (cfiType === 'date') {

                        return <DateTextFieldFormComponent
                            label={bizFieldItem.cfi_label}
                            defaultValue={currentValue}
                            onChange={(date) => handleOnChangeForSavingData(cfiFieldId, date)} />
                    }
                    else if (cfiType === 'combobox') {

                        var getListSelect = getComboBoxData(cfiFieldId);

                        return <div>

                            <TitleFieldFormComponent
                                value={bizFieldItem.cfi_label}
                                requireParam={bizFieldItem.cfi_req} />

                            <Select
                            styles={styles}
                                options={getListSelect}
                                defaultValue={getListSelect.find(({ value }) => value === parseInt(currentValue))}
                                onChange={(selection) => handleCountryNStateSelected(cfiFieldId, selection)} />

                        </div>
                    }
                    else if (cfiType === 'radiobox') {

                        return <div>

                            <TitleFieldFormComponent
                                value={bizFieldItem.cfi_label}
                                requireParam={bizFieldItem.cfi_req} />

                            <GenderRadioFormComponent
                                value={currentValue === '' ? '0' : parseInt(currentValue)}
                                onChange={(value) => handleOnChangeForSavingData(cfiFieldId, value)} />    

                        </div>
                    }

                    return <div />
                })()}

                {spacer()}

            </div>
        }
    };

    function dialogVerifyPhoneNumberScreen() {

        return <DialogTransitionComponent
            open={showDialogScreen}
            dialogClosed={dialogVerifyPhoneNumberScreenClosed}
            children={<VerifyPhoneNumberScreen dialogClosed={dialogVerifyPhoneNumberScreenClosed} />} />
    }

    function getCurrentDrawerChild() {

        return <WebBrowserScreen type='privacy-policy' />
    }

    function spacer() {

        return <SpacerStyled height='20' />
    }

    function showError(title, text) {
        Toast.fire({
            title: title,
            text: text,
            icon: 'error',
            confirmButtonColor: getBizColorNumber(3),
            confirmButtonText: getLang.ok
        })
    }
    /* UI - with UI functions - end */
    /* UI - end */
}
