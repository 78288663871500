import PropTypes from 'prop-types';

export const GetClientObject = {
  biz_hash: PropTypes.string, 
  cust_current_points: PropTypes.string,
  cust_total_points: PropTypes.string,
  cust_status: PropTypes.string,
  cust_first_name: PropTypes.string,
  cust_email: PropTypes.string,
  cust_birth_date: PropTypes.string,
  cust_weding_date: PropTypes.string,
  cust_address: PropTypes.string,
  cust_city: PropTypes.string,
  cust_country: PropTypes.string,
  cust_state: PropTypes.string,
  cust_zip: PropTypes.string,
  cust_gender: PropTypes.string,
  cust_phone2: PropTypes.string,
  socket_id: PropTypes.string,
  membership: PropTypes.shape({
    next_membership_level: PropTypes.string,
    next_membership_id: PropTypes.string,
    next_membership_name: PropTypes.string,
    next_membership_req_points: PropTypes.string,
    next_membership_back_color: PropTypes.string,
    points_to_next_level: PropTypes.number,
    total_points: PropTypes.string,
    current_points: PropTypes.string,
    membership_discount: PropTypes.string,
    membership_name: PropTypes.string,
    membership_color: PropTypes.string,
    membership_text_color: PropTypes.string,
    membership_notes: PropTypes.string,
    bm_id: PropTypes.string,
    bm_biz_id: PropTypes.string,
    bm_mship_level: PropTypes.string,
    bm_name: PropTypes.string,
    bm_notes: PropTypes.string,
    bm_back_color: PropTypes.string,
    bm_text_color: PropTypes.string,
    bm_img: PropTypes.string,
    bm_min_points: PropTypes.string,
    bm_active: PropTypes.string,
    bm_accelerate_factor: PropTypes.string,
    bm_accelerate_rate: PropTypes.string,
    bm_status: PropTypes.string,
    benefits: PropTypes.arrayOf(
      PropTypes.shape({
        mben_id: PropTypes.string,
        mben_mb_id: PropTypes.string,
        mben_biz_id: PropTypes.string,
        mben_text: PropTypes.string,
        mben_type: PropTypes.string,
        mben_entity_id: PropTypes.string,
        mben_image: PropTypes.string,
        mben_title: PropTypes.string,
        mben_amount: PropTypes.string
      })
    )
  }),
  cust_active_mods: PropTypes.array,
  badges: PropTypes.shape({
    appointments: PropTypes.string,
    benefits: PropTypes.string,
    orders: PropTypes.string,
    notifications: PropTypes.string,
    requests: PropTypes.string,
    documents: PropTypes.string,
    membership: PropTypes.string,
    wishlist: PropTypes.string,
    invitefriends: PropTypes.string,
    subscriptions: PropTypes.string,
    punchpass: PropTypes.string,
    loyalty_cards: PropTypes.string,
    chat: PropTypes.string
  }),
  cust_pic: PropTypes.string,
  cust_unique_id: PropTypes.string,
  cust_phone1: PropTypes.string
}