import React from 'react';
//import { Link } from "react-router-dom"
import { LoginDivStyled, NextButtonStyled, WelcomeBackground } from '../../biz_styles/LoginStyle'
import { TitleStyled } from '../../biz_styles/bizStyle'
import { useStoreProvider } from '../../shared/providers/StoreContext'
import { getLanguage } from '../../shared/redux/GlueStoreData';

function ContactBizOwner() {

  const bizObject = useStoreProvider().biz
  const getLang = getLanguage();

  return (
    <WelcomeBackground>
      <LoginDivStyled style={{padding: 50}}>
        <TitleStyled>{getLang.contact} {bizObject.biz_short_name} {getLang.to_receive_an_invite_to_the_club}</TitleStyled>
        <NextButtonStyled>{getLang.contact} {bizObject.biz_short_name}</NextButtonStyled>
        {/* <Link to="../register">Resend Email</Link> */}
      </LoginDivStyled>
    </WelcomeBackground>
  );
}

export default ContactBizOwner;
