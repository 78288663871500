import styled from 'styled-components'
import { getBizColorNumber } from './bizStyle';
import IconTint from "react-icon-tint";
import { 
    CardImageStyled,
    DivListStyled,
    DivCardStyled,
    DivTitlecStyled,
    DivPriceStyled,
    DivDescStyled,
    LabelCardStyled,
    DivTopImageStyled,
    PageTitleStyled

} from './IncentivesStyle';

export const MultiPageTitleStyled = styled(PageTitleStyled)``

export const MultiDivListStyled = styled(DivListStyled)``

export const MultiDivCardStyled = styled(DivCardStyled)``

export const MultiImageStyled = styled(CardImageStyled)``

export const MultiLabelCardStyled = styled(LabelCardStyled)``

export const MultiDivTitlecStyled = styled(DivTitlecStyled)`
    top: 40%;`

export const MultiDivPriceStyled = styled(DivPriceStyled)`
    margin: 0 0.5%;
    top: 25%;
    font-size: 15px;
    width: fit-content;
    padding: 0 3%;
    background-color:${() => getBizColorNumber(3)};
    color:${() => getBizColorNumber(1)};`

export const MultiDivDescStyled = styled(DivDescStyled)`
    height: auto;`


export const CardTopImageStyled = styled(IconTint).attrs(props => ({ 
    color:getBizColorNumber(3)
  }))`
    height: 100%;
    width: 100%;`

export const MultiDivTopImageStyled = styled(DivTopImageStyled)`
    left: 7%;
    top: 5.7%;
    height: 30%;
    width: 86%;
    border-radius: 10px;
    overflow: hidden;`

export const MultiPriceText = styled.p`
    margin-bottom: 0px;
    font-size:1.2rem;`

export const MultiEntriesText = styled.p`
    margin-bottom: 0px;
    font-size:0.8rem;`











