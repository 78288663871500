import * as ActionTypes from "../actions/actionTypes";

function bizReducer(state = {}, action) {

    switch (action.type) {

        case ActionTypes.GET_BIZ_DATA: {
            return {
                ...state,
                [action.key]: action.payload
              };
        }

        default:
            return state
    }

}


export default bizReducer