import React, { useEffect } from 'react'
import { DivCenterStyled, LogoRoundedContainerStyled } from '../../biz_styles/ReusableStyles'
import { getBizColorNumber, getBizBackgroundImage, Toast } from '../../biz_styles/bizStyle'
import { IconStyledSvgWaitingList } from '../../biz_styles/IconsStyles';
import { TitleStyled, SubTitleStyled, BottomLabelStyled, DivLogoutStyled, LogoutLabelStyled } from '../../biz_styles/WaitingListStyle'
import { useStoreProvider } from '../../shared/providers/StoreContext'
import { unsubscribe } from '../../api/ApiRequests';
import { clearStoreData, getLanguage, isLoggedIn } from '../../shared/redux/GlueStoreData'
import Swal from 'sweetalert2'
import { useNavigate } from "react-router-dom"



const unsubscribeScreen = () => {

  const bizObject = useStoreProvider().biz
  const customerObject = useStoreProvider().cust
  const navigate = useNavigate()
  const getLang = getLanguage();

  useEffect(() => {
    const currentURL = window.location.href;
    const updatedURL = currentURL.replace(/\/unsubscribe/gi, '');
    window.history.replaceState({}, document.title, updatedURL);
  }, []);

  return (
    <div className="bg-color-view" style={{ height: '100%', marginTop: 0, background: getBizColorNumber(1), opacity: 0.7 }}>


      <DivCenterStyled style={{ position: 'relative' }}>
        
        <LogoRoundedContainerStyled src={bizObject.biz_logo} />

        <TitleStyled style={{ paddingTop: 20 }}>{<strong>{getLang.biz_unsubscribe_title.replace('#phone#',customerObject.cust_phone1)} <div style={{direction:"ltr"}}>{customerObject.cust_phone1}</div></strong>}</TitleStyled>
        <SubTitleStyled>{getLang.biz_unsubscribe_message}</SubTitleStyled>
        
        <BottomLabelStyled>{bizObject.biz_short_name}</BottomLabelStyled>

        <DivLogoutStyled style={{ position: 'relative', textAlign: 'center', paddingRight: 0, paddingTop: 50 }}>
        <LogoutLabelStyled onClick={() => showUnsubscribeAlertMessage()}>{getLang.action_unsubscribe}</LogoutLabelStyled>
      </DivLogoutStyled>
        

      </DivCenterStyled>
    </div>
  )

}

function showUnsubscribeAlertMessage() {

  const getLang = getLanguage();

  Toast.fire({
    allowOutsideClick: false,
    title: getLang.unsubscribe,
    html: getLang.are_you_sure_you_wish_to_unsubscribe,
    confirmButtonText: getLang.yes,
    showCancelButton: true,
    cancelButtonText: getLang.cancel,
    reverseButtons: true,
    iconColor: getBizColorNumber(3),
    confirmButtonColor: getBizColorNumber(3),
  }).then(async (result) => {
    if (result.isConfirmed) {
      Toast.fire({
        title: getLang.please_wait,
        didOpen: () => {
          Swal.showLoading()
        }
      })

      logout()
    }
  })
}

async function logout() {
  const responseCode = await unsubscribe();

  if (responseCode === 1) {
    clearStoreData()
  }
}

export default unsubscribeScreen
