import React, { Component } from 'react';
import { KeyPStyled ,ValuePStyled } from '../../../../biz_styles/CartStyle';

function KeyValueCell(props) {
    return (
      <div className="key-value-holder">
        <KeyPStyled opacity={props.keyOpacity !== undefined ? props.keyOpacity : '66'} fontSize={props.fontSize !== undefined ? props.fontSize : '16px'}>
          {props.keyText}
        </KeyPStyled>
        <ValuePStyled fontSize={props.fontSize !== undefined ? props.fontSize : '16px'} width={props.width !== undefined ? props.width : 'auto'}>
          {props.valueText}
        </ValuePStyled>
      </div>
    );
  }
  
  export default KeyValueCell;