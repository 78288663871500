/**Login Routes */
import SplashScreen from './screens/splash/SplashScreen'
import WelcomeScreen from './screens/weclome/WelcomeScreen';
import RegistrationFormEmail from './screens/registration/RegistrationFormEmail';
import RegistrationFormPhone from './screens/registration/RegistrationFormPhone';
import RegistrationFormPhoneApproved from './screens/registration/RegistrationFormPhoneApproved';
import NoBizScreen from './screens/errors/NoBizScreen'
import LoginFormSmsCode from './screens/login/LoginFormSmsCode'
import LoginFormEmail from './screens/login/LoginFormEmail'
import LoginFormPhone from './screens/login/LoginFormPhone';
import EMailNotFound from './screens/registration/EmailNotFound';
import VerifyEmail from './screens/registration/VerifyEmail';
import ExclusiveEmailLoginForm from './screens/exclusive_login/ExclusiveEmailLoginForm';
import ExclusivePhoneLoginForm from './screens/exclusive_login/ExclusivePhoneLoginForm';
import LinkExpired from './screens/registration/LinkExpired'
import EmailVerified from './screens/registration/EmailVerified';
import ContactBizOwner from './screens/exclusive_login/ContactBizOwner';
import RegisterAfterVerification from './screens/registration/RegisterAfterVerification';
import WaitingListScreen from './screens/waiting_list/WaitingListScreen';
import unsubscribeScreen from './screens/unsubcribe/unsubscribeScreen';
import DeleteUserDataByEmailScreen from './screens/delete_user_data/DeleteUserDataByEmail';
import DeleteUserDataByPhoneScreen from './screens/delete_user_data/DeleteUserDataByPhone';
import DeleteUserDataSmsCode from './screens/delete_user_data/DeleteUserDataSmsCode';

/**App Routes */
import ProfileScreen from './screens/profile/ProfileScreen'
import EditProfileScreen from './screens/edit_profile/EditProfileScreen'
import Pages from './screens/pages/Pages';
import More from './screens/more/More';
import PersonalPoints from './screens/personal_points/PersonalPoints';
import ReferralsPointsScreen from './screens/personal_points/referrals_points/ReferralsPointsScreen'
import StampCards from './screens/pages/stamp_cards/StampCards';
import Coupons from './screens/pages/coupons/Coupons.jsx';
import Subscriptions from './screens/pages/subscriptions/Subscriptions';
import Muiltipass from './screens/pages/muiltipass/Muiltipass';
import PointsShop from './screens/pages/pointsShop/PointsShop';
import InfoScreen from './screens/Info/InfoScreen';
import PersonalDataScreen from './screens/personal_data/PersonalDataScreen';
import SubscriptionMultipassInfoScreen from './screens/Info/subscription_multipass_info/SubscriptionMultipassInfoScreen';
import CustomFormsScreen from './screens/custom_forms/CustomFormsScreen';
import rating from './screens/rating/rating';
import review from './screens/rating/review';
import Home from './screens/home/Home';
import { getLanguage } from './shared/redux/GlueStoreData'
import { getInjectedBizData } from './shared/static_helper/StaticHelper';
import React from 'react';

const getLang = getLanguage();

function getModuleNameByNavSelector(navSelector) {

  const bizNavigation = getInjectedBizData().biz_navigation;

  for (var i = 0; i < bizNavigation.length; i++) {
    if (bizNavigation[i].nav_selector == navSelector) {
      return bizNavigation[i].nav_label;
    }
  }

  return '';
}

function getModuleNameById(modId) {

  const bizModules = getInjectedBizData().biz_modules;

  for (var i = 0; i < bizModules.length; i++) {
    if (bizModules[i].biz_mod_mod_id === modId) {
      return bizModules[i].biz_mod_mod_name;
    }
  }

  return '';
}

const loginRoutes = [
  {
    path: '/',
    element: SplashScreen,
    exact: true
  },
  {
    path: 'welcome',
    element: WelcomeScreen,
    exact: true
  },
  {
    path: 'login',
    element: LoginFormPhone,
    exact: true
  },
  {
    path: 'registerEmail',
    element: RegistrationFormEmail,
    exact: true
  },
  {
    path: 'registerPhone',
    element: RegistrationFormPhone,
    exact: true
  },
  {
    path: 'registerPhoneApproved',
    element: RegistrationFormPhoneApproved,
    exact: true
  },
  {
    path: 'LoginFormSmsCode',
    element: LoginFormSmsCode,
    exact: true
  },
  {
    path: 'LoginFormEmail',
    element: LoginFormEmail,
    exact: true
  },
  {
    path: 'exclusiveLoginEmail',
    element: ExclusiveEmailLoginForm,
    exact: true
  },
  {
    path: 'exclusiveLoginPhone',
    element: ExclusivePhoneLoginForm,
    exact: true
  },
  {
    path: 'contactBizOwner',
    element: ContactBizOwner,
    exact: true
  },
  {
    path: 'emailNotFound',
    element: EMailNotFound,
    exact: true
  },
  {
    path: 'verifyEmail',
    element: VerifyEmail,
    exact: true
  },
  {
    path: 'reactivateBiz',
    element: NoBizScreen,
    exact: true
  },
  {
    path: 'PersonalPoints',
    element: PersonalPoints,
    exact: true
  },
  {
    path: 'referralsPoints',
    element: ReferralsPointsScreen,
    exact: true
  },
  {
    path: 'LinkExpired',
    element: LinkExpired,
    exact: true
  },
  {
    path: 'EmailVerified',
    element: EmailVerified,
    exact: true
  },
  {
    path: 'RegisterAfterVerification',
    element: RegisterAfterVerification,
    exact: true
  },
  {
    path: 'WaitingList',
    element: WaitingListScreen,
    exact: true
  },
  {
    path: 'deleteUserDataByEmail',
    element: DeleteUserDataByEmailScreen,
    exact: true
  },
  {
    path: 'deleteUserDataByPhone',
    element: DeleteUserDataByPhoneScreen,
    exact: true
  },
  {
    path: 'deleteUserDataSmsCode',
    element: DeleteUserDataSmsCode,
    exact: true
  },
  {
    path: 'unsubscribe',
    element: unsubscribeScreen,
    exact: true
  },
  {
    path: 'rating',
    element: rating,
    exact: true
  },
  {
    path: 'review/:ratingId',
    element: review,
    exact: true
  },
  {
    path: 'infoScreen/:fromPersonalZone/:md_level_no/:md_mod_id/:md_row_id',
    element: InfoScreen,
    exact: true,
    screenTitle: ''
  },
  {
    path: 'personalData',
    element: PersonalDataScreen,
    exact: true,
    screenTitle: ''
  }

]

const routes = [
  {
    path: '/main',
    element: Home,
    exact: true,
    screenTitle: getInjectedBizData().main_menu_label
  },
  {
    path: '/main/profile',
    element: ProfileScreen,
    exact: true,
    screenTitle: ''
  },
  {
    path: '/main/editProfile',
    element: EditProfileScreen,
    exact: true,
    screenTitle: ''
  },
  {
    path: '/main/pages',
    element: Pages,
    exact: true,
    screenTitle: getModuleNameByNavSelector('showModules')
  },
  {
    path: '/main/more',
    element: More,
    exact: true,
    screenTitle: getModuleNameByNavSelector('showMore')
  },
  {
    path: '/main/pages/stampCards',
    element: StampCards,
    exact: true,
    screenTitle: getModuleNameById('6')
  },
  {
    path: '/main/pages/coupons',
    element: Coupons,
    exact: true,
    screenTitle: getModuleNameById('26')
  },
  {
    path: '/main/pages/subscriptions',
    element: Subscriptions,
    exact: true,
    screenTitle: getModuleNameById('10')
  },
  {
    path: '/main/pages/muiltipass',
    element: Muiltipass,
    exact: true,
    screenTitle: getModuleNameById('11')
  },
  {
    path: '/main/pages/pointsShop',
    element: PointsShop,
    exact: true,
    screenTitle: getModuleNameById('18')
  },
  {
    path: '/main/pages/infoScreen/:fromPersonalZone/:md_level_no/:md_mod_id/:md_row_id',
    element: InfoScreen,
    exact: true,
    screenTitle: ''
  },
  {
    path: '/main/pages/subscriptionMultipassInfoScreen/:fromPersonalZone/:md_level_no/:md_mod_id/:md_row_id',
    element: SubscriptionMultipassInfoScreen,
    exact: true,
    screenTitle: ''
  },
  {
    path: '/main/pages/customForms/:form_id',
    element: CustomFormsScreen,
    exact: true,
    screenTitle: ''
  },
]


export { routes, loginRoutes };
