import React, { useEffect, useState } from 'react';
import BottomNav from './components/navigation/bottom_navigation/BottomNav'
import ValueBar from './components/navigation/valueBar/ValueBar'
import { Outlet, useNavigate, useLocation } from 'react-router-dom';
import { GlobalStyle, MainStyled, getBizColorNumber, getBizBackgroundImage, getMagicMenuImage, SpanPrivacyPolicyMenuStyled, SideMenuSeperatorStyled, BacgroundGradientStyled, BacgroundColorViewStyled } from "./biz_styles/bizStyle"
import { BizWebSocket } from './shared/web_socket/BizWebSocket'
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import { getLanguage, getStripePk } from './shared/redux/GlueStoreData'
import { getBiz, getClientAfterUpdate, getLanguageCollection, signout } from './api/ApiRequests';
import { isLoggedIn, setIsLoggedIn, clearStoreData } from './shared/redux/GlueStoreData'
import { useStoreProvider } from './shared/providers/StoreContext'
import { setBodyColor } from './biz_styles/bizStyle'
import SideBar from './components/side_bar/SideBar'
import { reveal as SideMenu } from './burger_lib/BurgerMenu'
import { scaleRotate as MagicMenu } from './burger_lib/BurgerMenu'
import ca from 'date-fns/esm/locale/ca/index.js';
import { getInjectedBizData, inviteFriends, openHref, openInNewTab } from './shared/static_helper/StaticHelper';
import RowDiv from './components/row_div/RowDiv';
import { height } from '@mui/system';
import { colors, ListItemIcon, ListItemText, MenuItem, MenuList, Typography, withStyles } from '@mui/material';
import { IconStyledSvg } from './biz_styles/IconsStyles';
import { ContentCut } from '@mui/icons-material';
import { MenuTitleStyled, MenuTitleWithoutColorStyled, SpacerStyled, TitleStyled } from './biz_styles/ReusableStyles';
import { routes } from './routes'
import { style } from 'glamor';
import WebBrowserScreen from './screens/privacy_policy/WebBrowserScreen';
import { DrawerComponent } from './components/drawer/DrawerComponent';
import { ImgDynamicStyled } from './biz_styles/ProfileStyle';
import { left } from 'glamor';

var retrySignoutCounter = 0
var valueBarTitle = ""


function Main() {

  const [menuOpen, setMenuOpen] = useState(false);

  const [scrollValue, setScrollValue] = useState(0)

  const [stripePromise, setStripePromise] = useState(null)

  const [selectedIndex, setSelectedIndex] = useState(0);

  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  const [currentItemDataClicked, setCurrentItemDataClicked] = useState({});

  var showHamburgerButton = true;

  var showAvatarButton = true;

  var showTopValueBar = false;

  var showBottomValueBar = false;

  var showTabBar = false;

  var showSideBar = false;

  var showSideMenu = false;

  var showMagicMenu = false;


  // Start - Change screen title
  const currentLocation = useLocation();
  const { pathname } = currentLocation

  // Default empty title
  if (pathname != '/main') {
    setValueBarTitle('')
  }

  routes.forEach(currentRoute => {
    if (pathname == currentRoute.path) {
      setValueBarTitle(currentRoute.screenTitle)
    }
  })

  function setValueBarTitle(newTitle) {
    valueBarTitle = newTitle
  }
  // End - Change screen title

  const magicMenuBackround = getMagicMenuImage()

  var magicMenuStyles = {
    bmMenu: {
      background: 'transparent',
    },
    bmMenuWrap: {
      width: '50%',
    },
    bmOverlay: {
      position: 'fixed',
      zIndex: 0,
      background: 'url(' + magicMenuBackround + ')',
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat'
    },
    bmItemList: {
      height: '100%',
      width: '45%',
      marginTop: '175px'
    }
  }

  var sideMenuStyles = {

    bmMenu: {
      background: getBizColorNumber(1)
    },
    bmItemList: {
      //marginTop: '100%'
    },
    bmItem: {
      display: 'inline-block'
    }
  }

  const getLang = getLanguage();
  const navigate = useNavigate()
  const location = useLocation();

  const bizObject = useStoreProvider().biz
  const customerObject = useStoreProvider().cust;

  var pk = getStripePk()

  // Used for set menu selection.
  useEffect(() => {

    for (var i = 0; i < bizObject.biz_navigation.length; i++) {

      if ('..' + location.pathname == '../main') {
        setSelectedIndex(0)
        return
      } else if ('..' + location.pathname == bizObject.biz_navigation[i].web_member_path) {
        setSelectedIndex(Number(bizObject.biz_navigation[i].nav_index) + 1)
        return
      }
    }

  })

  // Reset value bar scroll position after any navigation.
  useEffect(() => {
    setScrollValue(0)
  }, [location]);

  useEffect(() => {
    setStripePromise(loadStripe(pk))
  }, []);

  // Fetch updated data after refresh.
  useEffect(() => {
    
    if (isLoggedIn()) {
      setBodyColor()
      getBiz()
      getClientAfterUpdate()
      getLanguageCollection()
    }

    if (bizObject.club_active == 0){
      navigate('../')
    }
    
  }, []);

  async function logout() {
    retrySignoutCounter = 0
    const responseCode = await signout();

    return responseCode

  }

  function setScroll(event) {
    setScrollValue(event.currentTarget.scrollTop)
  }

  useEffect(() => {
    const div = document.getElementById("scroll-parent");
    if (div) {
      div.addEventListener("scroll", setScroll);
      return () => div.removeEventListener("scroll", setScroll);
    }

  });

  function toggleMenu() {

    setMenuOpen(!menuOpen)
  }

  function closeMenu() {

    if (menuOpen) {
      setMenuOpen(false)
    }
  }

  function openDrawer() {

    setIsDrawerOpen(true);
  }

  function closeDrawer() {

    setIsDrawerOpen(false);
  }

  function appContent() {

    const div = document.getElementById("react-burger-menu-btn");
    if (div != null) {
      div.style.height = 0;
    }

    return (

      <div>
        {screenBackground()}
        <Elements stripe={stripePromise}>
          <BizWebSocket />
          <GlobalStyle />
          <ValueBar anchor='top' title={valueBarTitle} scrollPosition={scrollValue} menuPressed={toggleMenu} show={showTopValueBar} showHamburgerButton={showHamburgerButton} showAvatar={showAvatarButton} />
          <MainStyled>
            {showSideBar ? <SideBar isOpen={menuOpen} closeDrawer={toggleMenu} /> : null}
            <Outlet />
          </MainStyled>
          {showTabBar ? <BottomNav /> : null}
          {showBottomValueBar ? <ValueBar anchor='bottom' /> : null}
        </Elements >
      </div>
    )
  }

  function appLayout() {

    switch (bizObject.biz_layout) {

      case '5': //Tabbar + Top Value Bar
        showHamburgerButton = false
        showAvatarButton = false
        showTopValueBar = true
        showTabBar = true
        break;
      case '6': //Side Bar + Top Value Bar
        showTopValueBar = true
        showSideBar = true
        break;
      case '7': //Side Menu + Top Value Bar
        showTopValueBar = true
        showSideMenu = true
        break;
      case '8': //Magic Menu + Top Value Bar
        showTopValueBar = true
        showMagicMenu = true
        break;
      case '9': //Side Bar + Bottom Value Bar
        showBottomValueBar = true
        showSideBar = true
        break;
      case '10': //Side Menu + Bottom Value Bar
        showBottomValueBar = true
        showSideMenu = true
        break;
      case '11': //Magic Menu + Bottom Value Bar
        showBottomValueBar = true
        showMagicMenu = true
        break;

      default:
        console.log('Got unknown layout')
        break;
    }
  }

  function navigateFromMenu(navItem, listIndex) {
    setSelectedIndex(listIndex);

    if (navItem.nav_index == 1) {
      navigate('../main/profile');
    } else {
      navigate(navItem.web_member_path)
    }

    toggleMenu()
  }

  function handleUtilityItemClick(tabbarItem) {

    switch (tabbarItem.fu_id) {

      case '1':
        openHref(`tel:${bizObject.biz_office_tele}`)
        break
      case '2':
        openHref(`sms:${bizObject.biz_office_tele}`)
        break
      case '4':
        openHref(`mailto:${bizObject.biz_email}?&subject=App Contact Us`)
        break

      case '9':
      case '10':

        const obj = {
          mod_id: '35',
          level_no: '1',
          parent: '0',
          prod_id: tabbarItem.fu_info,
        }

        setCurrentItemDataClicked(obj);
        openDrawer();
        break;

      case '11':

        navigate(`./pages/customForms/${tabbarItem.fu_info}`);
        break;

      case '12':

        inviteFriends();
        break;

      default:

        break;
    }

    toggleMenu()
  }


  function navigateToHome() {
    setSelectedIndex(0);
    navigate('../main')
    toggleMenu()
  }

  const menuItemStyle = {
    color: getBizColorNumber(2),
    "&.Mui-selected": {
      color: getBizColorNumber(3)
    },
    ".Mui-selected svg path": {
      fill: getBizColorNumber(3)
    }
  }

  function sideMenuListDataSource() {

    return <>

      <div style={{ position: 'relative', height: '164px', width: '100%', backgroundColor: getBizColorNumber(4) }}>

        <SpacerStyled height={'22'} />
        <div style={{ position: 'relative', width: '100px', height: '100px', borderColor: getBizColorNumber(3), borderWidth: '3px', borderStyle: 'solid', borderRadius: '50px', marginLeft: 'auto', marginRight: 'auto' }}>
          <ImgDynamicStyled style={{ position: 'absolute', left: '-3px', top: '-3px' }} width={'100px'} height={'100'} src={customerObject.cust_pic} alt='' />
        </div>

        <SpacerStyled height={'10'} />
        <MenuTitleWithoutColorStyled style={{ color: getBizColorNumber(2), textAlign: 'center' }}>{customerObject.cust_first_name}</MenuTitleWithoutColorStyled>

      </div>

      {magicMenuListDataSource()}

    </>
  }

  function magicMenuListDataSource() {

    return <>

      <MenuItem sx={menuItemStyle} selected={selectedIndex === 0} onClick={() => navigateToHome()}>
        {selectedIndex === 0 ?
          <IconStyledSvg name='home.svg' cssName='fill-color3' size='24' /> :
          <IconStyledSvg name='home.svg' cssName='fill-color2' size='24' />}
        <ListItemText primary={<MenuTitleWithoutColorStyled style={{ textAlign: 'left', paddingTop: '10px', paddingLeft: '10px' }}>{bizObject.main_menu_label}</MenuTitleWithoutColorStyled>}></ListItemText>
      </MenuItem>


      {/* biz_navigation collection */}
      {bizObject.biz_navigation
        .map(
          (item, index) =>
            shouldIgnoreSideMenuItem(item) ? null :
              <MenuItem sx={menuItemStyle} selected={selectedIndex === index + 1} key={item.nav_index} onClick={() => navigateFromMenu(item, index + 1)}>
                {selectedIndex === index + 1 ?
                  <IconStyledSvg name={item.nav_icon} cssName='fill-color3' size='24' /> :
                  <IconStyledSvg name={item.nav_icon} cssName='fill-color2' size='24' />}
                <ListItemText primary={<MenuTitleWithoutColorStyled style={{ textAlign: 'left', paddingTop: '10px', paddingLeft: '10px' }}>{item.nav_label}</MenuTitleWithoutColorStyled>}></ListItemText>
              </MenuItem>
        )}

      {/* <SpacerStyled height={'15'} />
      <MenuItem>
        <ListItemText primary={<SideMenuSeperatorStyled style={{ textAlign: 'left', paddingTop: '10px', paddingLeft: '10px' }}>{getLang.more}</SideMenuSeperatorStyled>}></ListItemText>
      </MenuItem>
      <SpacerStyled height={'15'} /> */}

      {/* tabbar collection */}
      {listLayoutItems()}

      {/* Privacy Policy */}
      <MenuItem onClick={() => openInNewTab(bizObject.bizPrivacyUrl)}>
        <ListItemText primary={<SpanPrivacyPolicyMenuStyled style={{ textAlign: 'left', paddingTop: '10px', paddingLeft: '10px' }}>{getLang.privacy_policy_terms}</SpanPrivacyPolicyMenuStyled>}></ListItemText>
      </MenuItem>

    </>
  }

  function shouldIgnoreSideMenuItem(item) {

    if (item.nav_selector == 'showMore' || item.nav_entity_id == '31') {
      return true;
    }

    if (item.nav_entity_id == '0' && item.nav_selector == 'showModules') {
      return false;
    }

    if (item.nav_entity_id == '0' || item.nav_entity_id == '6' || item.nav_entity_id == '10' || item.nav_entity_id == '11' || item.nav_entity_id == '18' || item.nav_entity_id == '26') {
      return false;
    }

    return true;
  }

  function listLayoutItems() {

    return bizObject.tabbar.filter(
      (item) =>
        item.fu_id === '1' ||
        item.fu_id === '2' ||
        item.fu_id === '4' ||
        item.fu_id === '9' ||
        item.fu_id === '10' ||
        item.fu_id === '11' ||
        item.fu_id === '12'
    ).map(
      (tabbarItem) =>

        <div key={tabbarItem.fu_id}>
          <MenuItem key={tabbarItem.fu_index} onClick={() => handleUtilityItemClick(tabbarItem)}>
            <IconStyledSvg name={tabbarItem.fu_id + '.svg'} cssName='fill-color2' size='24' />
            <ListItemText primary={<MenuTitleStyled style={{ textAlign: 'left', paddingTop: '10px', paddingLeft: '10px' }}>{tabbarItem.fu_label}</MenuTitleStyled>}></ListItemText>
          </MenuItem>
        </div>
    )
  }

  function getCurrentDrawerChild() {

    return <WebBrowserScreen type='classic-url' currentObjParam={currentItemDataClicked} />
  }


  function getComponentsToRender() {


    if (isLoggedIn()) {

      appLayout();

      return (
        <div id="outer-container" style={{ background: getBizColorNumber(1) }}>

          {showSideMenu ?
            <SideMenu styles={sideMenuStyles} onClose={toggleMenu} isOpen={menuOpen} pageWrapId={"page-wrap"} outerContainerId={"outer-container"}>
              {sideMenuListDataSource()}
            </SideMenu>
            : null}

          {showMagicMenu ?
            <MagicMenu styles={magicMenuStyles} onClose={toggleMenu} isOpen={menuOpen} pageWrapId={"page-wrap"} outerContainerId={"outer-container"}>
              {magicMenuListDataSource()}
            </MagicMenu>
            : null}

          <main onClick={closeMenu} id="page-wrap">
            {appContent()}
          </main>

          <DrawerComponent children={getCurrentDrawerChild()} isDrawerOpen={isDrawerOpen} closeDrawer={closeDrawer} />

        </div>

      )
    } else {
      useEffect(() => {
        if (retrySignoutCounter >= 2) {
          logout()
        }
        retrySignoutCounter++
        navigate('../')
      }, []);
    }
  }

  return (
    getComponentsToRender()
  )

  function screenBackground () {
    return (
      <div>
  
        <BacgroundColorViewStyled style={{ background: getBizColorNumber(1), opacity: 1 }}></BacgroundColorViewStyled>
        <div style={{ position: 'absolute', objectFit: 'scale-down', width: '100%', height: '130%', background: 'url(' + getBizBackgroundImage() + ') no-repeat', opacity: backgroundImageOpacity(), backgroundSize: 'contain' }}></div>
  
        <>
          <BacgroundGradientStyled style={{ background: 'linear-gradient(rgba(0, 0, 0, 0),' + getBizColorNumber(1), opacity: 1 }} ></BacgroundGradientStyled>
          <BacgroundColorViewStyled style={{ background: getBizColorNumber(1), opacity: 1 }}></BacgroundColorViewStyled>
        </>
  
      </div>
    )
  }

  function backgroundImageOpacity(){

    var value = parseFloat(6 / scrollValue);

    // console.log(value);

    if(value > 1.0){
      return 1.0;
    }
    else if(value < 0.15){
      return 0.0;
    }

    return value;
  }
}



export default Main