import React from 'react';
import QRCode from "react-qr-code";

export default function QRCodeComponent({ size, value }) {

    return value === '' ?

        <div /> :

        <div style={{backgroundColor: 'white', padding: '3px', maxWidth: size + 6}}>
            <QRCode
                size={size}
                value={value} />
        </div>

}