import React from 'react';
import { TitleStyled, DivCenterStyled } from '../../biz_styles/bizStyle'
import { NextButtonStyled, WelcomeBackground, LoginDivStyled } from '../../biz_styles/LoginStyle'
import { useNavigate } from "react-router-dom"
import { getLanguage } from '../../shared/redux/GlueStoreData';



function LinkExpired() {
  const navigate = useNavigate()
  const getLang = getLanguage();


  return (
    <WelcomeBackground>
      <LoginDivStyled>
        <DivCenterStyled style={{padding: 40}}>
          <TitleStyled>{getLang.link_expired}</TitleStyled>
          <NextButtonStyled onClick={() => navigate("../registerEmail")}>{getLang.start_again}</NextButtonStyled>
        </DivCenterStyled>
      </LoginDivStyled>
    </WelcomeBackground>
  );
}

export default LinkExpired;
