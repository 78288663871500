import { CartTitleStyled, DivCenterStyled } from '../../../biz_styles/bizStyle';
import { ScrollableDivStyled } from '../../../biz_styles/ReusableStyles';
import { CartHolderDivStyled } from '../../../biz_styles/AllPagesStyle';
import React, { useState, useEffect } from 'react'
import { addRewardToCart, getCartDiscounts } from '../../../api/ApiRequests';
import { Skeleton } from '@mui/material';
import { PStyled } from '../../../biz_styles/OrderStyle';
import PersonalRewardCell from '../../personal_data/cells/presonal_reward_cell/PersonalRewardCell';
import coupon from '../../../images/coupon.png';
import LoadingComponent from '../../../components/loading_components/LoadingComponent';
import { getLanguage } from '../../../shared/redux/GlueStoreData';

export default function CartDiscount({ onCompletion }) {

    const [discountsData, setDiscountsData] = React.useState({});
    const [isLoadingData, setIsLoadingData] = useState(true);
    const [isLoading, setIsLoading] = useState(false);
    const getLang = getLanguage();

    

    useEffect(() => {

        (async () => {
            setDiscountsData(await getCartDiscounts())
            setIsLoadingData(false)
        })()

    }, []);

    // useEffect(() => {

    // }, [cartRewardResult])

    function screenContent() {

        return <ScrollableDivStyled id="scrollableDiv" height='calc(100vh - 70px)' >

            <CartTitleStyled className="cart-title" leftPadding='30'>{getLang.pick_a_reward}</CartTitleStyled>

            {isLoadingData ?
                <>
                    <Skeleton animation="wave" variant="rectangular" width='92%' height='340px' style={{ margin: 20 }} />
                </>
                :
                discountsCount() > 0 ? showListLayout() : showEmptyListLayout()}

        </ScrollableDivStyled>
    }
    
    function showListLayout() {
        
        return discountsData.map(obj => <PersonalRewardCell
            onClick={() => cellClickedWithObject(obj)}
            key={obj.card_id}
            title={obj.header}
            benefitType={obj.benefit_type}
            image={obj.image}
            iconType={coupon}
            canUseNow={true}/>)
    }

    async function cellClickedWithObject(obj) {
        
        setIsLoading(true);

        const response = await addRewardToCart({ recordId: obj.card_id });

        onCompletion(response);
    }

    function showEmptyListLayout() {

        return <DivCenterStyled style={{ height: 'inherit', width: '100%', display: 'table' }}>
            <PStyled style={{ display: 'table-cell', verticalAlign: 'middle', color:'black' }}>{getLang.there_are_no_available_discounts}</PStyled>
        </DivCenterStyled>
    }

    function discountsCount() {

        return Object.entries(discountsData).length;
    }

    return (
        
        <CartHolderDivStyled>
 
        <LoadingComponent isLoading={isLoading} />
        {screenContent()}

        </CartHolderDivStyled>
        
    );
}
