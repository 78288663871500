import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import React, { useState, useRef, useEffect } from 'react'
import { useNavigate } from "react-router-dom"
import { DivCenterStyled, Toast, getBizColorNumber } from '../../biz_styles/bizStyle'
import { NextButtonStyled, LoginDivStyled, DivColorStyled, WelcomeBackground,TextStyled } from '../../biz_styles/LoginStyle'
import { isValidPhoneNumber, AsYouType } from 'libphonenumber-js'
import { useStoreProvider } from '../../shared/providers/StoreContext'
import LoginHeader from '../login/LoginFormHeader'
import LoadingComponent from '../../components/loading_components/LoadingComponent'
import { submitPhoneAfterVerification } from '../../api/ApiRequests'
import ReCAPTCHA from "react-google-recaptcha";
import '../login/LoginScreen.css';
import { getLanguage, isLoggedIn } from '../../shared/redux/GlueStoreData';


function redirectIfNeeded() {
    const navigate = useNavigate();
    if (isLoggedIn()) {
        
        useEffect(() => {
            navigate('../main')
          }, []);
      }
  }
  

function RegisterAfterVerification() {

    //const [countryCode, setCountryCode] = useState(Object);
    const [phoneNumber, setPhoneNumber] = useState('');
    const [isValid, setIsValid] = useState(true);
    const [isLoading, setIsLoading] = useState(false);
    const [isDisabled, setisDisabled] = useState(true);
    const captchaRef = useRef();
    const getLang = getLanguage();


    const navigate = useNavigate();

    const asYouType = new AsYouType()
    const bizObject = useStoreProvider().biz
    const emailMemberEnabled = bizObject.email_member_enabled === '1'

    function handleOnChange(value, data, event, formattedValue) {
        asYouType.input(formattedValue)
        if(formattedValue !== ''){
        setPhoneNumber(asYouType.getNumberValue())
        asYouType.reset();
        }
        setIsValid(isValidPhoneNumber(formattedValue));
        setisDisabled(!isValidPhoneNumber(formattedValue));
    }

    const onSubmitPhoneNumber = async () => {
        const token = await captchaRef.current.executeAsync();
        if (isValid) {
            setIsLoading(true)
            submitAfterVerification(token);
        } else {
            captchaRef.current.reset();
            showError(getLang.error, getLang.invalid_phone_number)
        }
    };

    async function submitAfterVerification(captchaToken) {
        const res =  await submitPhoneAfterVerification(phoneNumber,captchaToken)
            if (res !== undefined) {
                setIsLoading(false)
                captchaRef.current.reset();
                navigate("../LoginFormSmsCode",{state: {phoneNumber}})
            } else {
                showError(getLang.sorry, getLang.something_went_wrong_try_again)
                captchaRef.current.reset();
                setIsLoading(false)
            }
    } 

    function showError(title,text) {
        Toast.fire({
          title: title,
          text: text,
          icon: 'error',
          confirmButtonText: getLang.ok,
          confirmButtonColor: getBizColorNumber(3)
        })
      }

    return (
        <WelcomeBackground>
            {redirectIfNeeded()}
            <div className='parent-div'>
                <LoadingComponent isLoading={isLoading} />

                <LoginDivStyled>
                    <LoginHeader
                        title={getLang.enter_your_phone_number}
                        subtitle={getLang.to_receive_texts_with_your_club_benefits}/>
                    <DivCenterStyled>
                        <DivColorStyled isValid={isValid}>
                        <PhoneInput
                            name='phone'
                            required={true}
                            autoFocus={true}
                            country={bizObject.biz_country_code.toLowerCase()}
                            countryCodeEditable={false}
                            autocompleteSearch={true}
                            enableSearch={true}
                            disableSearchIcon={true}
                            value={phoneNumber}
                            onChange={handleOnChange}
                        />
                        </DivColorStyled>
                    </DivCenterStyled>
                    <TextStyled>{getLang.confirm_your_number}</TextStyled>
                    <DivCenterStyled>
                        <ReCAPTCHA
                            sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
                            ref={captchaRef}
                            size="invisible"
                        />
                        <NextButtonStyled onClick={onSubmitPhoneNumber} disabled={isDisabled}>{getLang.next}</NextButtonStyled>
                    </DivCenterStyled>

                </LoginDivStyled >
            </div>
        </WelcomeBackground>

    )

}


export default RegisterAfterVerification