import { Checkbox, FormControlLabel, Skeleton, TextField } from '@mui/material';
import { CartTitleStyled, DivHorizontalStyled, getBizColorNumber, PColorStyled, PStyled, Toast } from '../../biz_styles/bizStyle';
import { BottomContainerDivStyled, CartButtonStyled, CartInvertedButtonStyled, CellCardStyled, DivCenterStyled, RowDivStyled, ScrollableDivStyled, SpacerStyled } from '../../biz_styles/ReusableStyles'
import React, { useState, useEffect } from 'react'
import CollapseComponent from '../../components/collapse_component/CollapseComponent'
import KeyValueCell from './cells/key_value_cell/KeyValueCell';
import ProductCell from './cells/product_cell/ProductCell';
import IsracardFieldsCell from './cells/isracard_fields_cell/isracardFieldsCell';
import Switch from "react-switch";
import { CardElementsContainer, ToggleButtonGroupStyled, ToggleButtonStyled } from '../../biz_styles/CartStyle'
import { useStripe, useElements, CardElement } from '@stripe/react-stripe-js';
import CartDiscount from './drawers/CartDiscount';
import { DrawerComponent } from '../../components/drawer/DrawerComponent';
import { CartHolderDivStyled } from '../../biz_styles/AllPagesStyle';
import { addItemToCart, setStripeOrder, setCustomerOrder,setIsracardOrder } from '../../api/ApiRequests';
import { getBiz, getCustomer, getLanguage, getStripePk, isCashEnabled, getCCProcessor, isCreditCardEnabled } from '../../shared/redux/GlueStoreData';
import LoadingComponent from '../../components/loading_components/LoadingComponent';
import WebBrowserScreen from '../privacy_policy/WebBrowserScreen';
import { checkIfNullUndefined } from '../../shared/static_helper/StaticHelper';


var stripePayMethodId = ''
var stripePayIntentId = ''
var stripeLast4Digits = 0
var errorMessage = "Something went wrong! Please use another payment method."

export default function Cart({ itemType, itemId, onClose }) {

    const biz = getBiz();
    const cust = getCustomer();
    const getLang = getLanguage();

    let [cartData, setCartData] = React.useState({});
    const [isExpanded, setExpanded] = React.useState(true);
    const [isBuyerExpanded, setBuyerExpanded] = React.useState(false);
    const [isCartDiscountOpen, setCartDiscountOpen] = React.useState(false);
    const [isCartDiscountDrawerOpen, setCartDiscountDrawerOpen] = React.useState(false);

    const [drawerType, setDrawerType] = React.useState('');

    const [isLoadingData, setIsLoadingData] = useState(true);
    const [isValidBuyerInfo, setIsValidBuyerInfo] = React.useState(true);
    const [isValidCreditCard, setIsValidCreditCard] = React.useState(true);
    const pkExists = getStripePk() != 'error';
    const hasCreditCard = isCreditCardEnabled();
    const isCashOptionEnabled = isCashEnabled()
    const [paymentMethodType, setPaymentMethodType] = React.useState(pkExists ? 'CreditCard' : 'Cash'); //CreditCard / Cash
    const ccProcessor = getCCProcessor();//Stripe/Isracard/Empty
    const [fullName, setFullName] = React.useState(cust.cust_first_name);
    const [cardHolderName, setCardHolderName] = React.useState(cust.cust_first_name);
    const [cardHolderID, setCardHolderID] = React.useState('');
    const [email, setEmail] = React.useState(cust.cust_email !== undefined ? cust.cust_email : '');
    const [isTermsChecked, setIsTermsChecked] = useState(false);
    const [buyerComments, setBuyerComments] = React.useState('');
    const [paymentMethodObj, setPaymentMethodObj] = React.useState({});
    const [isLoading, setIsLoading] = useState(false);
    const [isValidEmail,setIsValidEmail] = useState(true);
    const [isValidName,setIsValidName] = useState(true);
    const [isCompleteCreditCard, setIsCompleteCreditCard] = React.useState(false);

    const [isSubscriptionPurchase, setSubscriptionPurchase] = useState(false); 

    const stripe = useStripe();
    const elements = useElements();
    const isracardManager = window['glue_payme'];
    
    
    const params = {
        type: itemType,
        item_id: itemId,
    };

    const handleCreditCardChange = (change) => {

        setIsValidCreditCard(change.complete)
        setIsCompleteCreditCard(change.complete)

        if (change.complete) {

            stripe.createPaymentMethod({
                type: change.elementType,
                card: elements.getElement(CardElement),
                billing_details: {
                    name: fullName,
                    email: email,
                    phone: cust.cust_phone1,
                    address: {
                        city: cust.city,
                        country: 'IL',
                        line1: '',
                        line2: '',
                        state: '',
                    }
                },
            }).then(
                function (result) {
                    if (result.paymentMethod) {
                        stripePayMethodId = result.paymentMethod.id
                        stripeLast4Digits = result.paymentMethod.card.last4
                        setPaymentMethodObj(result)
                    } else if (result.error) {

                    }
                });
        } else if (change.error) {
            // console.log('Input error -> ' + change.error.code)
        }

    }    


    useEffect(() => {

        setupCart();
        
        if(ccProcessor == "isracard360"){
            isracardManager.init_payme(getStripePk());
        }
    }, []);

    async function setupCart() {
    
        const data = (await addItemToCart(params));
        setCartData(data)
        setCartDiscountOpen(data.cartRewardUsed !== null)
        setIsLoadingData(false)
        setSubscriptionPurchase(data.itemsList[0].itemType == 4)
    }



    async function setInitialCartData() {

        setCartData(await addItemToCart(params))
        setCartDiscountOpen(false)
        setIsLoadingData(false)
    }

    function summaryChildren() {

        return <ScrollableDivStyled id="scrollableDiv" height='calc(100vh - 335px)' >

            {isLoadingData ?
                <>
                    <Skeleton animation="wave" variant="rectangular" width='100%' height='210px' style={{ marginLeft: 0, marginTop: 20, marginBottom: 20 }} />
                    <Skeleton animation="wave" variant="rectangular" width='100%' height='50px' style={{ marginLeft: 0, marginTop: 20, marginBottom: 20 }} />
                </>
                :
                itemsInCart() > 0 ? showSummaryLayout() : showEmptyCartLayout()}

        </ScrollableDivStyled>
    }

    function itemsInCart() {

        return Object.entries(cartData.itemsList).length;
    }

    function mainButtonDisabledState() {

        if (!isBuyerExpanded) {
            if (isLoadingData) {
                return true;
            }
            return itemsInCart() === 0;
        }
        return false;
    }

    useEffect(() => {
        updateBuyerInfoState();
    }, [fullName, email, isTermsChecked, isValidCreditCard, paymentMethodType, buyerComments, paymentMethodObj, cartData])

    function updateBuyerInfoState() {

        setIsValidBuyerInfo(true);
    }

    function showSummaryLayout() {

        return <>

            {cartData.itemsList.map(obj => <ProductCell className="cart-product-holder"
                onClick={() => this.cellClickedWithObject(obj)}
                key={obj.itemId}
                title={obj.itemHeader}
                attributes={obj.itemDescription}
                image={obj.itemImage}
                price={obj.itemPrice}
                currency={biz.ess_currency}
                isSubscriptionLayout={isSubscriptionOrPunchPass()}
            />)}
            
            {cartDiscountSection()}
            <SpacerStyled height={'40'} />
            {summarySection()}
        </>

    }

    function isSubscriptionOrPunchPass() {
        return cartData.itemsList[0].itemType == 4 || cartData.itemsList[0].itemType == 5;
    }

    function showEmptyCartLayout() {

        return <DivCenterStyled style={{ height: 'inherit', width: '100%', display: 'table' }}>
            <PStyled style={{ display: 'table-cell', verticalAlign: 'middle', color:'black'}}>{getLang.there_are_no_products_in_your_shopping_cart}</PStyled>
        </DivCenterStyled>
    }

    function cartDiscountSection() {
        return <>

            <CellCardStyled height={isCartDiscountOpen ? '150' : '50'} >

                <DivHorizontalStyled>

                    <RowDivStyled marginLeft='20' marginRight='20'>

                        <PStyled marginTop='12px' style={{color: 'black'}}>{getLang.use_a_cart_discount}</PStyled>

                        <div style={{ position: 'static', width: 'auto', float: 'right', backgroundColor: 'transparent', marginTop: '-40px' }}>
                            <Switch onChange={toggleCartDiscount} checked={isCartDiscountOpen} uncheckedIcon={null} checkedIcon={null} onColor={getBizColorNumber(3)} />
                        </div>

                        <KeyValueCell keyText={getLang.coupon + ':'} valueText={cartData.cartRewardUsed !== null ? cartData.cartRewardUsed.header : '0'} keyOpacity={'66'} fontSize='20' width={'250px'} />
                        <KeyValueCell keyText={getLang.value + ':'} valueText={getCartDiscountValue()} keyOpacity={'66'} />
                        <SpacerStyled height={'10'} />

                    </RowDivStyled>
                </DivHorizontalStyled>

            </CellCardStyled>

        </>
    }

    

    function getCartDiscountValue() {

        const unit = cartData.cartDiscountUnit;

        if (unit === 'percent') {
            return cartData.cartDiscountValue + '%'
        }
        else {
            if(biz.biz_language == 'he'){
                return '-' + biz.ess_currency_symbol + cartData.cartDiscountValue;
            }
            else{
                return '-' + cartData.cartDiscountValue + biz.ess_currency;
            }
            
        }
    }

    function toggleCartDiscount(checked) {

        if (checked) {
            setDrawerType('cart_discount');
            setCartDiscountDrawerOpen(true);
        }
        else {
            setInitialCartData();
        }
    }

    const validateEmail = (e) => {
        return new RegExp( /[a-zA-Z0-9]+[\.]?([a-zA-Z0-9]+)?[\@][a-z]{3,15}[\.][a-z]{2,5}/g).test(e); // eslint-disable-line
    }
    const validateName = (e) => {
        if (e.trim().length !== 0) {
          return true;
        }
        return false;
      };

    const handleEmailOnChange = e => {
        setIsValidEmail(validateEmail(e.target.value))
        setEmail(e.target.value)
    }
    const handleNameOnChange = e => {
        setIsValidName(validateName(e.target.value))
        setFullName(e.target.value)
        setCardHolderName(e.target.value)
    }
    const handleCardHolderNameOnChange = e => {
        setIsValidName(validateName(e.target.value))
        setCardHolderName(e.target.value)
    }

    const handleCardHolderIDOnChange = e => {
        setCardHolderID(e.target.value)
    }


    function buyerInfoChildren() {

        return <ScrollableDivStyled id="scrollableDiv" height='calc(100vh - 400px)' >

            <SpacerStyled height={'20'} />

            <TextField fullWidth id="standard-basic" label={getLang.reseller_contact_name} defaultValue={fullName} variant="standard" onChange={handleNameOnChange} style={{borderBottom:isValidName? '':'1px solid red'}}/>

            <SpacerStyled height={'20'} />

            <TextField className="ltr-field" fullWidth id="standard-basic" label={getLang.email_required} defaultValue={email} variant="standard" onChange={handleEmailOnChange} style={{borderBottom:isValidEmail? '':'1px solid red'}}/>

            {pkExists ? <SpacerStyled height={'40'} /> : <SpacerStyled height={'0'} />}

            {pkExists && isCashOptionEnabled && !isSubscriptionPurchase ?
                <ToggleButtonGroupStyled exclusive value={paymentMethodType} onChange={(e) => setPaymentMethodType(e.target.value)}>
                    <ToggleButtonStyled value="CreditCard">{getLang.pay_source_type_card}</ToggleButtonStyled>
                    <ToggleButtonStyled value="Cash">{getLang.invoice_pay_method_cash}</ToggleButtonStyled>
                </ToggleButtonGroupStyled>
                : ''}

            <SpacerStyled height={'20'} />

            {paymentMethodType === 'CreditCard' ?
                <CardElementsContainer isValid = {isValidCreditCard}>
                    <SpacerStyled height={'10'} />
                    {ccProcessor == "stripe" ? (<CardElement options={{ hidePostalCode: true }} onChange={handleCreditCardChange} />) : (
                    <div class="card-fields-holder">
                        <SpacerStyled height={'20'} />            
                        <TextField  fullWidth id="cardHolderName" label={getLang.cc_card_holder_name}  variant="standard" onChange={handleCardHolderNameOnChange}/>
                        <SpacerStyled height={'20'} />
                        <TextField  fullWidth id="cardHolderIDNumber" label={getLang.cc_card_holder_id_number}  variant="standard" onChange={handleCardHolderIDOnChange}/>                        
                        <SpacerStyled height={'20'} />
                        <div class="card-field" id="cardNumber" data-placeholder={getLang.cc_card_number}></div>
                        <SpacerStyled height={'20'} />
                        <div class="float-start half-width card-field" id="cardExpiration"></div>
                        <div class="float-end half-width card-field" id="cardCVV"></div>
                        <SpacerStyled height={'20'} />
                    </div>)}
                    <SpacerStyled height={'10'} />
                </CardElementsContainer>
                : ''}

            <SpacerStyled height={'20'} />
            <TextField fullWidth multiline rows='3' id="standard-basic" label={getLang.notes} variant="outlined" onChange={(e) => (setBuyerComments(e.target.value))} />
            <div className="checkbox-row">
                <FormControlLabel style={{position:'relative',marginTop:'8px'}} control={<Checkbox checked={isTermsChecked} onChange={(e) => setIsTermsChecked(!isTermsChecked)} inputProps={{ 'aria-label': 'controlled' }} />} label={getLang.i_have_read_and_agree_to_the} />
                <span style={{position:'relative',height:'20px', color:getBizColorNumber(3),cursor:'pointer',lineHeight:'58px',height:'58px'}} onClick={termsClicked} >{getLang.the_terms}</span>
            </div>            
            <SpacerStyled height={'40'} />

            {summarySection()}

        </ScrollableDivStyled>
    }

    function termsClicked() {
        
        setDrawerType('terms');
        setCartDiscountDrawerOpen(true);
    }

    function summarySection() {

        return <>
            <KeyValueCell keyText={getLang.subtotal} valueText={showValueWithCurrency(cartData.subtotal)} />
            <KeyValueCell keyText={getLang.cart_discount} valueText={'-' + showValueWithCurrency(cartData.cartDiscountReduction)} />
            <KeyValueCell keyText={getLang.membership_discount} valueText={'-' + showValueWithCurrency(cartData.memberShipReduction)} />
            {cartData.showShipping ? <KeyValueCell keyText={getLang.shipping} valueText='xxx' /> : null}
            <KeyValueCell keyText={getLang.tax} valueText={showValueWithCurrency(cartData.taxes)} />

            <SpacerStyled height={'20'} />

            <KeyValueCell keyText={getLang.total} valueText={showValueWithCurrency(cartData.total)} fontSize='20px' />

            <SpacerStyled height={'40'} />
        </>
    }

    function showValueWithCurrency(value) {
        if(biz.biz_language == 'he'){
            return biz.ess_currency_symbol + parseFloat(value).toFixed(2);
        }
        else{
            return parseFloat(value).toFixed(2) + ' ' + biz.ess_currency;
        }
        
    }

    function bottomCartButtonSection() {

        return <BottomContainerDivStyled height='100'>

            {isBuyerExpanded ? null : <CartButtonStyled width='calc(50% - 20px)' onClick={onClose} >{getLang.keep_shopping}</CartButtonStyled>}

            <CartInvertedButtonStyled
                disabled={mainButtonDisabledState()}
                opacity={mainButtonDisabledState() ? 0.5 : 1}
                width={isBuyerExpanded ? 'calc(100% - 25px)' : 'calc(50% - 20px)'}
                onClick={checkoutClicked}>{isBuyerExpanded ? getLang.purchase : getLang.checkout}
            </CartInvertedButtonStyled>

            <DivCenterStyled style={{ marginTop: '10px' }}>
                {paymentMethodType == 'Cash' && isBuyerExpanded ? <p>{getLang.pay_with_cash}</p> : isBuyerExpanded && paymentMethodType == 'CreditCard' ? <p>{getLang.pay_with_credit_card}</p> : ''}
            </DivCenterStyled>

        </BottomContainerDivStyled>
    }

    function checkoutClicked() {

        if (!isBuyerExpanded) {
            setExpanded(false)
            setBuyerExpanded(true)
        }
        else {
            if(!isValidEmail || email.length == 0){
                setIsValidEmail(false)
                showError(getLang.email_is_not_valid) 
                return;
            }
            if(fullName.length == 0){
                showError(getLang.name_is_not_valid) 
                return;
            }
            if(!isTermsChecked){
                showError(getLang.you_need_to_confirm_the_terms) 
                return;
            }
            if (paymentMethodType === 'CreditCard') {
                if(ccProcessor == "stripe"){
                    if(!isValidCreditCard || !isCompleteCreditCard){
                        setIsValidCreditCard(false)
                        showError(getLang.credit_card_is_not_valid) 
                        return;
                    }
                    purchase();
                }
                if(ccProcessor == "isracard360"){                    
                    var splitName = cardHolderName.split(" ");
                    var lastName = splitName.length > 1 ? splitName[splitName.length - 1] : "";
                    var saleData = {
                        payerFirstName: splitName[0],
                        payerLastName: lastName,
                        payerEmail: email,
                        payerPhone: cust.cust_phone1,
                        payerSocialId: cardHolderID,
                        total:{
                            label:"Order from web member",
                            amount:{
                                currency:biz.ess_currency
                            }                            
                        }
                    }
                    
                    //isracardManager.tokenize(getStripePk(),saleData)
                    window['glue_payme_tokenize'](saleData)
                    .then((result) => {
                        if (result.code === 1) {
                            setIsValidCreditCard(true)

                            purchaseIsracard(result.data);
                        }
                        else {
                            setIsValidCreditCard(false)
                            showError(getLang.credit_card_is_not_valid) 
                            return;
                          }
                    })
                    .catch((error) => {
                        setIsValidCreditCard(false)
                        showError(getLang.credit_card_is_not_valid) 
                        return;
                    });                    
                }
            } else {
                    purchaseWithCash();
                }
            }
    }

    function summaryHeaderClicked() {
        setExpanded(true)
        setBuyerExpanded(false)
    }

    function buyerInfoHeaderClicked() {
        setExpanded(false)
        setBuyerExpanded(true)
    }

    function getCurrentDrawerChild() {

        switch (drawerType) {
            case 'terms': {
                return <WebBrowserScreen type='custom' customUrl={biz.biz_terms_url} />
            }
            case 'cart_discount':{
                return <CartDiscount onCompletion={(data) => reCalculateCart(data)} />
            }
        }
        
    }

    function reCalculateCart(newCartData) {

        closeDrawer();

        setCartData(newCartData);

        setCartDiscountOpen(newCartData.cartDiscountValue !== 0);
    }

    function closeDrawer() {
        setCartDiscountDrawerOpen(false);
    }

    async function purchaseWithCash() {

        setIsLoading(true);

        const params = {
            payment_method_id: stripePayMethodId,
            pay_intent_id: stripePayIntentId,
            orderId: '',
            shipState: '',
            shipCity: '',
            shipLine1: '',
            shipLine2: '',
            shipZip: '',
            shipName: '',
            shipPhone: '',
            comments: buyerComments,
            paymentMethodType: paymentMethodType,
            paymentMethodLastDigits: '',
            cvvNumber: '',
            ipAddress: '',
            phone: cust.cust_phone1,
            email: email,
            name: fullName,
            shipMethodId: '',
            shipMethodPrice: '',
            cartBenefitRedeem: !checkIfNullUndefined(cartData.cartRewardUsed) ? cartData.cartRewardUsed.customerCardCode : ""
        }

        const res = await setCustomerOrder(params);

        setIsLoading(false);

        if (res.responseCode === 1) {
            showConfirmation();
            onClose()
        } else {
            showError(errorMessage)
        }
    }

    async function purchase() {

        setIsLoading(true);

        const params = {
            payment_method_id: stripePayMethodId,
            pay_intent_id: stripePayIntentId,
            orderId: '',
            shipState: '',
            shipCity: '',
            shipLine1: '',
            shipLine2: '',
            shipZip: '',
            shipName: '',
            shipPhone: '',
            comments: buyerComments,
            paymentMethodType: paymentMethodType,
            paymentMethodLastDigits: stripeLast4Digits,
            cvvNumber: '',
            ipAddress: '',
            phone: cust.cust_phone1,
            email: email,
            name: fullName,
            shipMethodId: '',
            shipMethodPrice: '',
            cartBenefitRedeem: !checkIfNullUndefined(cartData.cartRewardUsed) ? cartData.cartRewardUsed.customerCardCode : ""
        }

        const res = await setStripeOrder(params);

        setIsLoading(false);

        if (res.responseCode === 1) {
            stripePayIntentId = ''

            showConfirmation();
            onClose()
        } else if (res.responseCode === 0) {

            if (res.responseData.is_action_required === 1) {
                stripe
                    .handleCardAction(res.responseData.client_secret)
                    .then(function (result) {

                        if (result.paymentIntent) {
                            //Handle result.paymentIntent
                            stripePayIntentId = result.paymentIntent.id
                            stripePayMethodId = ''
                            purchase()
                        } else if (result.error) {
                            showError(errorMessage)
                            //Handle result.error
                        }

                    });
            } else {
                showError(errorMessage)
            }
        } else if (res.responseCode === -1) {
            showError(errorMessage)
        }
    }

    async function purchaseIsracard(tokenData) {

        setIsLoading(true);

        const params = {
            buyer_key: tokenData.token,
            pay_intent_id: "",
            orderId: '',
            shipState: '',
            shipCity: '',
            shipLine1: '',
            shipLine2: '',
            shipZip: '',
            shipName: '',
            shipPhone: '',
            comments: buyerComments,
            paymentMethodType: paymentMethodType,
            paymentMethodLastDigits: stripeLast4Digits,
            cvvNumber: '',
            ipAddress: '',
            phone: cust.cust_phone1,
            email: email,
            name: fullName,
            shipMethodId: '',
            shipMethodPrice: '',
            cartBenefitRedeem: !checkIfNullUndefined(cartData.cartRewardUsed) ? cartData.cartRewardUsed.customerCardCode : ""
        }

        const res = await setIsracardOrder(params);

        setIsLoading(false);

        if (res.responseCode === 1) {

            showConfirmation();
            onClose()
        } else if (res.responseCode === 0) {

            showError(errorMessage)
        } else if (res.responseCode === -1) {
            showError(errorMessage)
        }
    }

    function showConfirmation() {
        
        Toast.fire({
            confirmButtonColor: getBizColorNumber(3),
            title: getLang.its_yours,
            text: getLang.thank_you_for_your_purchase_we_are_sure_youll_enjoy_it,
            icon: 'success',
            confirmButtonText: getLang.ok_got_it
        })
    }

    function showError(message) {
        Toast.fire({
            confirmButtonColor: getBizColorNumber(3),
            title: getLang.sorry,
            text: message,
            icon: 'error',
            confirmButtonText: getLang.ok
        })

    }

    return (
        <>
            <CartHolderDivStyled>

                <DrawerComponent children={getCurrentDrawerChild()} isDrawerOpen={isCartDiscountDrawerOpen} closeDrawer={closeDrawer}></DrawerComponent>

                <CartTitleStyled className="cart-title">{getLang.shopping_cart}</CartTitleStyled>

                <CollapseComponent isActive={isExpanded} sectionTitle={getLang.summary} children={summaryChildren()} onSectionClick={() => summaryHeaderClicked()} />

                <CollapseComponent isActive={isBuyerExpanded} sectionTitle={getLang.buyer_info} children={buyerInfoChildren()} onSectionClick={() => buyerInfoHeaderClicked()} />

                {bottomCartButtonSection()}

            </CartHolderDivStyled>

            <LoadingComponent isLoading={isLoading} />
        </>
    );
}
