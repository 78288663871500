import { StyledPhoneInput } from "../../biz_styles/ReusableStyles"
import React, { useState, useRef, useEffect } from "react";
import { useNavigate, Link } from "react-router-dom";
import { DivCenterStyled, Toast, getBizColorNumber } from "../../biz_styles/bizStyle";
import {
  NextButtonStyled,
  LoginDivStyled,
  DivColorStyled,
  WelcomeBackground,
  TextStyled,
  LinkTextStyled
} from "../../biz_styles/LoginStyle";
import { isValidPhoneNumber, AsYouType } from "libphonenumber-js";
import { useStoreProvider } from "../../shared/providers/StoreContext";
import LoginHeader from "./LoginFormHeader";
import LoadingComponent from "../../components/loading_components/LoadingComponent";
import { validateClubMemberByPhone } from "../../api/ApiRequests";
import ReCAPTCHA from "react-google-recaptcha";
import { getLanguage, isLoggedIn } from '../../shared/redux/GlueStoreData'
import "./LoginScreen.css";


function LoginFormPhone() {
  //const [countryCode, setCountryCode] = useState(Object);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [isValid, setIsValid] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [isDisabled, setisDisabled] = useState(true);
  const captchaRef = useRef();
  const navigate = useNavigate();
  const getLang = getLanguage();


  const asYouType = new AsYouType();

  const bizObject = useStoreProvider().biz;
  const emailMemberEnabled = bizObject.email_member_enabled === "1";

  const colorOpacity = 20;

  const dropDownStyle = {
    color: getBizColorNumber(2),
    backgroundColor: getBizColorNumber(4),
    borderRadius: "10px",
    borderColor: 'transparent'
  };


  function handleOnChange(value, data, event, formattedValue) {
    asYouType.input(formattedValue);
    if (formattedValue !== "") {
      setPhoneNumber(asYouType.getNumberValue());
      asYouType.reset();
    }
    setIsValid(isValidPhoneNumber(formattedValue));
    setisDisabled(!isValidPhoneNumber(formattedValue));
  }

  const onSubmitPhoneNumber = async () => {
    const token = await captchaRef.current.executeAsync();
    if (isValid) {
      setIsLoading(true);
      validateClubMember(token);
    } else {
      showError(getLang.error, getLang.invalid_phone_number);
      captchaRef.current.reset();
    }
  };

  async function validateClubMember(captchaToken) {
    const res = await validateClubMemberByPhone(phoneNumber, captchaToken);
    if (res !== undefined) {
      setIsLoading(false);
      if(res.responseMessage == "email_sent"){
        var email = res.responseData.email;
        navigate("../LoginFormSmsCode",{ state: { email } });
      }
      else{
        navigate("../LoginFormSmsCode",{ state: { phoneNumber } });
      }
    } else {
      showError(
        getLang.sorry,
        getLang.phone_not_found
      );
      captchaRef.current.reset();
      setIsLoading(false);
    }
  }

  function showError(title, text) {
    Toast.fire({
      title: title,
      text: text,
      icon: "error",
      confirmButtonText: getLang.ok,
      confirmButtonColor: getBizColorNumber(3),
    });
  }

  const styles = {
    color: getBizColorNumber(2),
    background: getBizColorNumber(4),
    borderRadius: "10px",
  };

  function redirectIfNeeded() {
        
    if (isLoggedIn()) {
        
        useEffect(() => {
            navigate('../main')
          }, []);
      }
  }
  
  return (
    <WelcomeBackground>
      {redirectIfNeeded()}
      <div className="parent-div">
        <LoadingComponent isLoading={isLoading} />

        <LoginDivStyled>
          <LoginHeader
            title={getLang.lets_sign_you_in}
            subtitle={bizObject.biz_login_message}
          />
          <DivCenterStyled>
            <DivColorStyled isValid={isValid}>
              <StyledPhoneInput
                name="phone"
                required={true}
                autoFocus={true}
                country={bizObject.biz_country_code.toLowerCase()}
                enableTerritories={true}
                countryCodeEditable={false}
                autocompleteSearch={true}
                enableSearch={false}
                disableSearchIcon={true}
                inputStyle={{
                  fontSize: '18px !important',
                  background: getBizColorNumber(4),
                  borderRadius: "10px",
                  borderColor: 'transparent',
                  height: "57px !important",
                  color: getBizColorNumber(2)
                }}
                containerStyle={{
                  height: "57px",
                  borderRadius: "10px",
                }}
                // buttonStyle={buttonStyle}
                dropdownStyle={dropDownStyle}
                value={phoneNumber}
                onChange={handleOnChange}
              />
            </DivColorStyled>
          </DivCenterStyled>
          <TextStyled>{getLang.confirm_your_number}</TextStyled>
          <DivCenterStyled>
            <ReCAPTCHA
              sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
              ref={captchaRef}
              size="invisible"
            />
            <NextButtonStyled
              onClick={onSubmitPhoneNumber}
              disabled={isDisabled}
            >
            {getLang.next}
            </NextButtonStyled>
          </DivCenterStyled>
          {emailMemberEnabled ? (
            <TextStyled>
            {getLang.prefer_another_way}{" "}
              <LinkTextStyled to="../LoginFormEmail">{getLang.sign_in_with_email}</LinkTextStyled>
            </TextStyled>
          ) : (
            ""
          )}
        </LoginDivStyled>
      </div>
    </WelcomeBackground>
  );
}

export default LoginFormPhone;
