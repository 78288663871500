import React, { createContext, useContext, useState } from "react"
import { getBiz, getCustomer, isBizColorsChanged } from "../redux/GlueStoreData"
import {store} from '../redux/store'

const StoreContext = createContext()

export const useStoreProvider = () => useContext(StoreContext);

function StoreProvider({ children }) {
    
    const [bizObject, setBizObject] = useState(getBiz())
    const [custObject, setCustObject] = useState(getCustomer())
    const [isColorsChanged, setIsColorsChanged] = useState(!isBizColorsChanged())

    store.subscribe(() => {
        setBizObject(getBiz())
        setCustObject(getCustomer())
        setIsColorsChanged(!isBizColorsChanged())  
  })

    return (
        <StoreContext.Provider value={{ biz :bizObject, cust: custObject, isColorsChanged: isColorsChanged }}>
            {children}
        </StoreContext.Provider>
    )

}

export default StoreProvider

