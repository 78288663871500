import React from 'react';
import { PStyled } from '../../../../biz_styles/bizStyle';
import { IconStyledSvg } from '../../../../biz_styles/IconsStyles';
import { DivImgItemProfileWrapperStyled, PActiveNumberItemProfileStyled } from '../../../../biz_styles/ProfileStyle';
import { DrawerComponent } from '../../../../components/drawer/DrawerComponent';
import PersonalDataScreen from '../../../personal_data/PersonalDataScreen';
import './ItemProfileComponent.css';
import { getLanguage } from '../../../../shared/redux/GlueStoreData';
import { Badge } from "@mui/material";

class ItemProfileComponent extends React.Component {

    /* Logic functions - start */
    constructor(props) {
        super(props)

        this.getCustomerObject = props.getCustomerObject;

        this.pzArrayItem = props.pzArrayItem;
        this.pzArrayItemType = this.pzArrayItem.type;

        this.state = {
            isDrawerOpen: false,
            drawerType: '',
            getLang: getLanguage()
        }
    }

    getNumBadge = () => {

        return this.getCustomerObject.badges[this.pzArrayItemType];
    }

    isSubOrPP = () => {

        return this.pzArrayItemType === 'subscriptions' || this.pzArrayItemType === 'punchpass';
    }

    getItemSvgName = () => {

        var imageName = '';

        switch (this.pzArrayItemType) {

            case "subscriptions":
                imageName = "my_subscriptions.svg";
                break;

            case "punchpass":
                imageName = "my_punchpasses.svg";
                break;

            case "loyalty_cards":
                imageName = "my_loyalty.svg";
                break;
            case "orders":
                imageName = "Notepad.svg";
                break;
            default:
        }

        return imageName;
    }

    getCurrentDrawerChild() {

        switch (this.state.drawerType) {

            case "subscriptions":
                return <PersonalDataScreen contentType={this.state.drawerType} top_icon={'my_subscriptions'} label={this.state.getLang.subscriptions} api='subscriptions' rootElement='' noDataText={this.state.getLang.no_subscriptions_yet} />

            case "punchpass":
                return <PersonalDataScreen contentType={this.state.drawerType} top_icon={'my_punchpasses'} label={this.state.getLang.multipasses} api='punchpasses' rootElement='' noDataText={this.state.getLang.no_multipasses_yet} />

            case "loyalty_cards":
                return <PersonalDataScreen contentType={this.state.drawerType} top_icon={'my_loyalty'} label={this.state.getLang.my_stamp_cards} api='loyalty_cards' rootElement='rows' noDataText={this.state.getLang.no_stamps_on_your_cards_yet} />

            case "orders":
                return <PersonalDataScreen contentType={this.state.drawerType} top_icon={'Notepad'} label={this.state.getLang.my_orders} api='orders' rootElement='' noDataText={this.state.getLang.no_orders_yet} />
            default:

        }
    }

    closeDrawer = () => {

        this.setState({ isDrawerOpen: false });
    }

    openDrawerType = (type) => {

        this.setState({ drawerType: type, isDrawerOpen: true });
    }
    /* Logic functions - end */

    /* UI - start */
    /* UI - without UI functions - start */
    render() {

        return (

            <div>

                <DivImgItemProfileWrapperStyled onClick={() => this.openDrawerType(this.pzArrayItemType)}>
                    <Badge badgeContent={this.getNumBadge()} color="error" invisible={!(!this.isSubOrPP() && this.getNumBadge() > 0)}>
                        <IconStyledSvg cssName={'fill-color3'} name={this.getItemSvgName()} size='24' />
                    </Badge>
                </DivImgItemProfileWrapperStyled>

                <div className='div-p-active-number-item-profile-wrapper'>

                    <PActiveNumberItemProfileStyled
                        style={{
                            display: (this.isSubOrPP() && this.getNumBadge() > 0) ?
                                '' :
                                'none'
                        }}>
                        {this.getNumBadge() + this.state.getLang.active}
                    </PActiveNumberItemProfileStyled>

                </div>

                <PStyled className='p-label-profile'>
                    {this.pzArrayItem.label}
                </PStyled>

                <DrawerComponent children={this.getCurrentDrawerChild()} isDrawerOpen={this.state.isDrawerOpen} closeDrawer={this.closeDrawer} />


            </div>
        );
    }
    /* UI - without UI functions - end */
    /* UI - end */
}

export default ItemProfileComponent;