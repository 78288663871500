import React, { useEffect } from 'react'
import { DivCenterStyled, LogoRoundedContainerStyled } from '../../biz_styles/ReusableStyles'
import { getBizColorNumber, getBizBackgroundImage, Toast } from '../../biz_styles/bizStyle'
import { IconStyledSvgWaitingList } from '../../biz_styles/IconsStyles';
import { TitleStyled, SubTitleStyled, BottomLabelStyled, DivLogoutStyled, LogoutLabelStyled } from '../../biz_styles/WaitingListStyle'
import { useStoreProvider } from '../../shared/providers/StoreContext'
import { signout } from '../../api/ApiRequests';
import { clearStoreData, getLanguage, isLoggedIn } from '../../shared/redux/GlueStoreData'
import Swal from 'sweetalert2'
import { useNavigate } from "react-router-dom"


// const screenBackground = () => {
//   return (
//     <div>
//       <div style={{ background: getBizColorNumber(1), opacity: 0.5 }} className="image-container">
//         <img style={{ background: getBizColorNumber(1), opacity: 0.5 }} className='bg_image_view' src={getBizBackgroundImage()} alt="" />
//       </div>
//       <div className="gradient" style={{ background: 'linear-gradient(rgba(0, 0, 0, 0),' + getBizColorNumber(1), opacity: 0.5 }} ></div>
//       <div className="bg-color-view" style={{ background: getBizColorNumber(1), opacity: 0.5 }}></div>
//     </div>
//   )
// }

const WaitingListScreen = () => {

  const bizObject = useStoreProvider().biz
  const navigate = useNavigate()
  const getLang = getLanguage();

  useEffect(() => {
    if (!isLoggedIn()) {
      navigate('../')
    }
  }, []);


  return (
    <div className="bg-color-view" style={{ height: '100%', marginTop: 0, background: getBizColorNumber(1), opacity: 0.7 }}>
      
      {/* {screenBackground()} */}

      <DivLogoutStyled style={{ position: 'relative', textAlign: 'right', paddingRight: 20, paddingTop: 30 }}>
        <LogoutLabelStyled onClick={() => showSignoutAlertMessage()}>{getLang.action_logout}</LogoutLabelStyled>
      </DivLogoutStyled>


      <DivCenterStyled style={{ position: 'relative' }}>
        
        <LogoRoundedContainerStyled src={bizObject.biz_logo} />

        <TitleStyled style={{ paddingTop: 20 }}>{<strong>{bizObject.biz_waiting_title}</strong>}</TitleStyled>
        <SubTitleStyled>{bizObject.biz_waiting_message}</SubTitleStyled>

        <BottomLabelStyled>{bizObject.biz_waiting_signed}</BottomLabelStyled>
        <BottomLabelStyled>{bizObject.biz_short_name}</BottomLabelStyled>

        <div style={{ position: 'fixed', bottom: 0, height: 'auto', width: '100%', maxWidth: 550 }}>
          <IconStyledSvgWaitingList style={{}} name={'waiting_line.svg'} cssName={'fill-color2'} size='100%' />
        </div>

      </DivCenterStyled>
    </div>
  )

}

function showSignoutAlertMessage() {

  const getLang = getLanguage();

  Toast.fire({
    allowOutsideClick: false,
    title: getLang.sign_out,
    html: getLang.are_you_sure_you_wish_to_sign_out,
    confirmButtonText: getLang.yes,
    showCancelButton: true,
    cancelButtonText: getLang.cancel,
    reverseButtons: true,
    iconColor: getBizColorNumber(3),
    confirmButtonColor: getBizColorNumber(3),
  }).then(async (result) => {
    if (result.isConfirmed) {
      Toast.fire({
        title: getLang.please_wait,
        didOpen: () => {
          Swal.showLoading()
        }
      })

      logout()
    }
  })
}

async function logout() {
  const responseCode = await signout();

  if (responseCode === 1) {
    clearStoreData()
  }
}

export default WaitingListScreen
