import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from "react-router-dom";
import LoadingComponent from '../../components/loading_components/LoadingComponent';
import TextFieldFormComponent from '../edit_profile/edit_profile_components/text_field_form_component/TextFieldFormComponent';
import DateTextFieldFormComponent from '../edit_profile/edit_profile_components/date_text_field_form_component/DateTextFieldFormComponent';
import Checkbox from '@mui/material/Checkbox';
import Select from 'react-select'
import utf8 from "utf8";
import { getFieldsList, saveCustomForm } from '../../api/ApiRequests';
import { DivHorizontalStyled, getBizColorNumber, H3Styled, POpacityStyled, PStyled, Toast } from '../../biz_styles/bizStyle';
import { DivBackgroundStyled, ScrollableDivStyled, SpacerStyled } from '../../biz_styles/ReusableStyles';
import { bottomNavigationViewSpacer, checkIfNullUndefined, valueBarSpacer } from '../../shared/static_helper/StaticHelper';
import { ButtonSaveStyled, DivMainStyled } from '../../biz_styles/ProfileStyle';
import { getLanguage } from '../../shared/redux/GlueStoreData';
import './CustomFormsScreen.css';
import { InviteButtonStyled } from '../../biz_styles/PersonalPointsStyle';

export default function CustomFormsScreen() {

    /* Logic functions - start */
    const navigate = useNavigate();
    const paramsFromUrl = useParams();
    const getLang = getLanguage();

    const [isLoadingData, setIsLoadingData] = useState(true);
    const [screenData, setScreenData] = useState({});
    const [saveButtonClicked, setSaveButtonClicked] = useState(0);
    const [mapFieldsJSON, setMapFieldsJSON] = useState(new Map()); // eslint-disable-line

    const formidVar = paramsFromUrl.form_id;
    const formDataIdVar = '0';

    const params = {
        formid: formidVar,
        formDataId: formDataIdVar,
    };

    const styles = {
        option: (styles, { data }) => {
          return {
            ...styles,
            backgroundColor: getBizColorNumber(4),
            color: getBizColorNumber(2),
            ":hover": {
              backgroundColor: getBizColorNumber(5)
            },
    
          };
        },
        control: (styles = {}) => {
          return {
            ...styles,
            margin: "4px 0 0",
            borderRadius: "8px",
            border: "1px solid",
            style:{color:'red'},
            backgroundColor: 'transparent',
            borderColor: getBizColorNumber(2) + '!important',
            " .css-1dimb5e-singleValue":{
                color: getBizColorNumber(2) + '!important'
            },
            " .css-166bipr-Input":{
                color: getBizColorNumber(2) + '!important'
            }
          };
        }
      };


    useEffect(() => {

        getFieldsListApi();
    }, []);

    async function getFieldsListApi() {

        setIsLoadingData(true);

        const responseData = await getFieldsList(params);

        if (!checkIfNullUndefined(responseData)) {

            setScreenData(responseData);

            fillMapFieldsJSON(responseData);

            setIsLoadingData(false);
        }
    }

    function fillMapFieldsJSON(responseData) {

        mapFieldsJSON['formid'] = formidVar;

        const fieldsData = responseData.fields;

        for (var i = 0; i < fieldsData.length; i++) {

            const fieldData = fieldsData[i];

            handleOnChangeForSavingData(fieldData.fld_id, fieldData.fld_value);
        }
    }

    function getComboBoxData(fieldItem) {

        var getListSelect = [
            {
                value: 0,
                label: ''
            }
        ];

        const comboData = fieldItem.combo_data;

        for (var i = 0; i < comboData.length; i++) {

            var comboDataItem = comboData[i];

            getListSelect.push({
                value: comboDataItem.value_id,
                label: comboDataItem.value_label
            })
        }

        return getListSelect;
    }

    function handleOnChangeForSavingData(fieldId, fieldValue) {

        if (saveButtonClicked > 0) {

            setSaveButtonClicked(saveButtonClicked + 1);
        }

        var fieldValueVar = checkIfNullUndefined(fieldValue) ? '' : fieldValue;

        mapFieldsJSON['ffld_' + fieldId] = utf8.encode(fieldValueVar);
    }

    async function sendForm() {

        setSaveButtonClicked(saveButtonClicked + 1);

        if (checkIfFormIsValid()) {

            setIsLoadingData(true);

            const responseCode = await saveCustomForm(mapFieldsJSON);

            if (responseCode === 1) {

                showOk(getLang.great, getLang.thank_you_for_filling_the_form);

                navigate(-1);
            }
            else {

                setIsLoadingData(false);
                showError(getLang.error, getLang.communication_error_please_try_again);
            }
        }
    }

    function checkIfFormIsValid() {

        var formIsValid = true;

        if (!checkIfAtleastOneFieldIsFilled()) {

            formIsValid = false;
        } else if (!checkIfRequiredFieldsAreFilled()) {

            formIsValid = false;
        }

        return formIsValid;
    }

    function checkIfAtleastOneFieldIsFilled() {

        var formIsValid = true;
        var countUnFilledFields = 0;

        const fieldsData = screenData.fields;
        const fieldsLength = fieldsData.length;

        for (var i = 0; i < fieldsLength; i++) {

            const fieldData = fieldsData[i];

            if (mapFieldsJSON['ffld_' + fieldData.fld_id] === '') {

                countUnFilledFields++;
            }
        }

        if (fieldsLength === countUnFilledFields) {

            formIsValid = false;

            showError(getLang.error, getLang.please_fill_at_least_one_answer);
        }

        return formIsValid;
    }

    function checkIfRequiredFieldsAreFilled() {

        var formIsValid = true;

        const fieldsData = screenData.fields;

        for (var i = 0; i < fieldsData.length; i++) {

            const fieldData = fieldsData[i];

            if (fieldData.fld_required === '1' &&
                mapFieldsJSON['ffld_' + fieldData.fld_id] === '') {

                formIsValid = false;

                showError(getLang.error, getLang.please_fill_all_the_required_fields);

                break;
            }
        }

        return formIsValid;
    }

    function handleBorderElements(fieldItem) {

        return ((saveButtonClicked > 0 &&
            fieldItem.fld_required === '1' &&
            mapFieldsJSON['ffld_' + fieldItem.fld_id] === '')
            ? '1' : '0')
            + 'px solid red';
    }
    /* Logic functions - end */

    /* UI - start */
    /* UI - without UI functions - start */
    return <DivBackgroundStyled>

        {isLoadingData ?

            <LoadingComponent isLoading={isLoadingData} /> :

            <div>

                <ScrollableDivStyled
                    id='scroll-parent'
                    height='100vh'
                    style={{ marginLeft: '20px', marginRight: '20px' }}>

                    {valueBarSpacer()}

                    <H3Styled
                        textAlign='left'>
                        {screenData.header}
                    </H3Styled>

                    <POpacityStyled
                        textAlign='left'>
                        {screenData.description}
                    </POpacityStyled>

                    {spacer()}

                    {listLayoutItems()}

                    {spacer()}

                    <DivMainStyled>
                        <InviteButtonStyled
                            bottom='80'
                            onClick={sendForm}>
                            {getLang.send_contact_us}
                        </InviteButtonStyled>
                    </DivMainStyled>

                    {bottomNavigationViewSpacer()}

                </ScrollableDivStyled>

            </div>
        }

    </DivBackgroundStyled>
    /* UI - without UI functions - end */

    /* UI - with UI functions - start */
    function listLayoutItems() {

        return screenData.fields
            .map(
                (fieldItem) =>

                    <div key={fieldItem.fld_id}>
                        {layoutItem(fieldItem)}
                    </div>
            )
    }

    function layoutItem(fieldItem) {

        var element;

        var fldType = fieldItem.fld_type;

        switch (fldType) {

            case 'TEXT':

                element = textElement(fieldItem);
                break;

            case 'PARAGRAPH':

                element = paragraphElement(fieldItem);
                break;

            case 'DATE':

                element = dateElement(fieldItem);
                break;

            case 'CHECKBOX':

                element = checkBoxElement(fieldItem);
                break;

            case 'COMBO':

                element = comboElement(fieldItem);
                break;

            default:

                break;
        }

        return <div>

            {element}

            {spacer()}

        </div>
    }

    function textElement(fieldItem) {

        return <div style={{ border: handleBorderElements(fieldItem) }}>

            {paragraphTitle(fieldItem)}

            <TextFieldFormComponent
                defaultValue={fieldItem.fld_value}
                onChange={(e) => handleOnChangeForSavingData(fieldItem.fld_id, e.target.value)} />

        </div>
    }

    function paragraphElement(fieldItem) {

        return <div style={{ border: handleBorderElements(fieldItem) }}>

            {paragraphTitle(fieldItem)}

            <textarea
                style={{ width: '100%', backgroundColor: 'transparent', borderColor: getBizColorNumber(2) }}
                defaultValue={fieldItem.fld_value}
                onChange={(e) => handleOnChangeForSavingData(fieldItem.fld_id, e.target.value)} />

        </div>
    }

    function dateElement(fieldItem) {

        return <div style={{ border: handleBorderElements(fieldItem) }}>

            {paragraphTitle(fieldItem)}

            <DateTextFieldFormComponent
                defaultValue={fieldItem.fld_value}
                onChange={(date) => handleOnChangeForSavingData(fieldItem.fld_id, date)} />

        </div>
    }

    function checkBoxElement(fieldItem) {

        return <div>

            {paragraphTitle(fieldItem)}

            <DivHorizontalStyled>

                <Checkbox
                    defaultChecked={fieldItem.fld_value === '1'}
                    style={{ height: '20px', paddingTop: '13px', color: getBizColorNumber(2) }}
                    onChange={(event) => handleOnChangeForSavingData(fieldItem.fld_id, event.target.checked === true ? '1' : '0')} />

                <PStyled>{fieldItem.fld_label}</PStyled>

            </DivHorizontalStyled>

        </div>
    }

    function comboElement(fieldItem) {

        var getListSelect = getComboBoxData(fieldItem);

        return <div style={{ border: handleBorderElements(fieldItem) }}>

            {paragraphTitle(fieldItem)}

            <Select placeholder={getLang.select_placeholder}
            styles={styles}
                options={getListSelect}
                defaultValue={getListSelect.find(({ value }) => value === parseInt(fieldItem.fld_value))}
                onChange={(selection) => handleOnChangeForSavingData(fieldItem.fld_id, String(selection.value === 0 ? '' : selection.value))} />

        </div>
    }

    function paragraphTitle(fieldItem) {

        return <POpacityStyled textAlign='left'>

            <span
                style={{ color: getBizColorNumber(3) }}>
                {fieldItem.fld_required === '1' ? '* ' : ''}
            </span>

            {fieldItem.fld_label}

        </POpacityStyled>
    }

    function spacer() {

        return <SpacerStyled height='20' />
    }

    function showError(title, text) {
        Toast.fire({
            title: title,
            text: text,
            icon: 'error',
            confirmButtonColor: getBizColorNumber(3),
            confirmButtonText: getLang.ok
        })
    }

    function showOk(title, text) {
        Toast.fire({
            title: title,
            text: text,
            icon: 'success',
            confirmButtonColor: getBizColorNumber(3),
            confirmButtonText: getLang.ok
        })
    }
    /* UI - with UI functions - end */
    /* UI - end */
}