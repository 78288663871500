import React, { Component } from 'react';
import './PointShopItemCell.css';
import { getBizColorNumber } from '../../../../biz_styles/bizStyle';
import { getLanguage } from '../../../../shared/redux/GlueStoreData';
import { formattedNumber } from '../../../../shared/static_helper/StaticHelper';

  
class PointShopItemCell extends Component {
    state = {   
        getLang: getLanguage()
    } 
    render() { 
        return (
            <div className="point-shop-cell-holder" style={{backgroundColor: getBizColorNumber(4), width: this.props.width != null ? this.props.width+'px' : '335px'}}>
                
                <div className='row'>

                    <div className='cell-image-container-view'>
                        <img className='cell-image-view' src={this.props.image} alt="" />
                        <span className='cell-points-label' style={{backgroundColor: getBizColorNumber(3), color: getBizColorNumber(1)}}>{this.props.points + ' ' + this.state.getLang.points}</span>
                    </div>

                    <div>
                        <span className='point-shop-cell-title-label' style={{ color: getBizColorNumber(2)}}>{this.props.title}</span>
                        <span className='buy-button' style={{ color: getBizColorNumber(3)}} onClick={this.props.onClick} >{this.state.getLang.buy_now}</span>
                    </div>
                </div>
            </div>
        );
    }
}
 
export default PointShopItemCell;