import React, { Component } from 'react';
import ProgressBarView from '../../components/progress_bar/ProgressBarView'
import { DivCenterStyled } from '../../biz_styles/ReusableStyles'
import './PersonalMembership.css'
import { DivHorizontalStyled, getBizColorNumber, PStyled } from '../../biz_styles/bizStyle';
import { getCustomer } from '../../shared/redux/GlueStoreData';
import { getSvgUrlForFileName } from '../../shared/static_helper/StaticHelper';
import { IconStyledSvg } from '../../biz_styles/IconsStyles'
import { getLanguage } from '../../shared/redux/GlueStoreData';

class PersonalMembership extends Component {

    constructor(props) {
        super(props)

        this.customerVar = getCustomer();
        this.showProgresBar = Number(this.customerVar.membership.next_membership_req_points) > 0;
    }

    state = {
        getLang:getLanguage()
    }

    getMembershipText() {
        return this.customerVar.membership.membership_name;
    }

    getNextTierPoints() {
        return this.customerVar.membership.points_to_next_level;
    }

    getNextTierName() {
        return this.customerVar.membership.next_membership_name;
    }

    getCompletedProgressValue() {

        var totalPoints = parseInt(this.customerVar.membership.total_points);
        var pointsToNextLevel = parseInt(this.customerVar.membership.points_to_next_level);
        var maxProgress = pointsToNextLevel + totalPoints;

        return totalPoints / maxProgress * 100;
    }

    benefitsList() {

        return this.customerVar.membership.benefits
            .map(
                (item) =>
                    <div key={item.mben_id}>
                        {this.benefitsItem(item)}
                    </div>
            )
    }

    benefitsItem(item) {

        return <DivHorizontalStyled>

            {/* <div style={{ backgroundImage: `url(${getSvgUrlForFileName('stampouter.svg')})` }}> */}
            <div>
                <span
                    style={{ color: getBizColorNumber(2), display: 'table-cell', verticalAlign: 'middle' }}>
                    {item.mben_type === 'discount' ? item.mben_amount + '%' : '🎁'}
                </span>
            </div>

            <PStyled
                style={{ marginLeft: '10px' }}>
                {item.mben_title}
            </PStyled>

        </DivHorizontalStyled>
    }

    render() {
        return (
            <div className='personal-membership-holder' style={{ backgroundColor: getBizColorNumber(1) }}>

                <DivCenterStyled style={{ marginTop: 50 }}>
                    <IconStyledSvg name="TrophyDuotune.svg" cssName='fill-color2' size={42} />
                </DivCenterStyled>
                <div className="row no-margin">
                    <span className='membership-label' style={{ color: getBizColorNumber(2) }}>{this.getMembershipText()}</span>
                </div>

                {this.showProgresBar ?

                    <div>

                        <ProgressBarView className='bottom-progress-bar' bgcolor={getBizColorNumber(3)} completed={this.getCompletedProgressValue()} />

                        <div className="row no-margin">
                            <span className='membership-next-tier-text' style={{ color: getBizColorNumber(2), opacity: 0.5 }}>{this.getNextTierPoints()} {this.state.getLang.points_to} {this.getNextTierName()}</span>
                        </div>

                    </div> :

                    <div />}

                <div className="row no-margin">
                    <span className='membership-total-gained-points' style={{ color: getBizColorNumber(2) }}>{this.state.getLang.total_gained_points}</span>
                    <span className='membership-total-gained-points-value' style={{ color: getBizColorNumber(2) }}>{getCustomer().cust_total_points}</span>
                </div>

                <div className="row no-margin">
                    <span className='membership-points-balance' style={{ color: getBizColorNumber(2) }}>{this.state.getLang.points_balance}</span>
                    <span className='membership-points-balance-value' style={{ color: getBizColorNumber(2) }}>{getCustomer().cust_current_points}</span>
                </div>

                <hr style={{ backgroundColor: getBizColorNumber(2) + '66', marginTop: '0px', marginBottom: '40px' }} />

                <div style={{ margin: '0 40px' }}>

                    <span style={{ color: getBizColorNumber(2), opacity: 0.5 }}>{this.state.getLang.membership_benefits}</span>

                    {this.benefitsList()}

                </div>

            </div>
        );
    }
}

export default PersonalMembership;