import React from 'react';
import Moment from 'moment';
import Resizer from "react-image-file-resizer";
import { format } from "date-fns";
import { useEffect, useState } from 'react';
import { getBiz, getCustomer, getLanguage } from "../../shared/redux/GlueStoreData";
import { getInviteLink, inviteSent } from '../../api/ApiRequests';
import { SpacerStyled } from '../../biz_styles/ReusableStyles';
import { store } from '../redux/store'
import { bizColorsChangedAction } from "../../actions/bizActions"
import { IS_BIZ_COLOR_CHANGED } from '../../actions/actionTypes'

export var openMyLoyaltyCards = false;

export function getOpenMyLoyaltyCards() {

    return openMyLoyaltyCards;
}

export function setOpenMyLoyaltyCards(value) {

    openMyLoyaltyCards = value;
}

export const timeValueFromDate = (date) => {

    const formatDate = Moment(date).format('HHmm')

    return formatDate
};

export const convertTimestampToDate = (timestamp) => {

    return new Date(timestamp).toLocaleDateString();
}

export const convertTimestampToDateObject = (timestamp) => {

    return new Date(timestamp);
}

export function getFormattedDate(dateString, dateFormat) {

    return format(new Date(dateString), dateFormat);
}

export function getFormatedDateSQL(dateString) {

    return getFormattedDate(dateString, 'MM/dd/yyyy');
};

export function getFormatedDateServer(dateString) {

    return getFormattedDate(dateString, 'yyyy-MM-dd');
};

export function checkIfNullUndefined(value) {

    return value === null || value === undefined;
}

export function checkIfNullUndefinedEmpty(value) {

    return value === null || value === undefined || value === '';
}

export function convertFromStringToBoolean(value) {

    return value === 'true';
}

export const membershipName = () => {

    const cust = getCustomer();
    const getLang = getLanguage();

    let isMember = cust.cust_phone1.length > 0;

    if (!isMember) {
        return getLang.not_a_member;
    }

    if (cust.membership.bm_mship_level == 0 || cust.membership.membership_name.length == 0) {
        return getLang.member;
    }

    return cust.membership.membership_name;

};

export const useWindowDimensions = () => {

    const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

    useEffect(() => {
        function handleResize() {
            setWindowDimensions(getWindowDimensions());
        }

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return windowDimensions;
}

function getWindowDimensions() {

    const { innerWidth: width, innerHeight: height } = window;

    return {
        width,
        height
    };
}

export function isModuleExist(moduleNumber) {

    const getCustomerObjectBizModules = getBiz().biz_modules;

    for (var i = 0; i < getCustomerObjectBizModules.length; i++) {

        if (getCustomerObjectBizModules[i].biz_mod_mod_id === moduleNumber) {

            return true;
        }
    }

    return false;
}

export function getSvgUrlForFileName(filename) {

    // var url = 'https://storage.googleapis.com/getglue/images/web_member/new_web_member/SVG/' + filename + "?v=" + Date.now();// + '.svg';
    var url = 'https://storage.googleapis.com/getglue/images/web_member/new_web_member/SVG/' + filename;// + '.svg';
    return url;
}

export function getRewardRedeemUrl(url) {

    const browserUrl = window.location.href

    if(browserUrl.includes('easy.co.il')){
        return 'https://glue.easy.co.il/biz/eventAsync/rewardRedeem?uid=' + url;
    }
    else{
        return 'https://admin.glueloyalty.com/biz/eventAsync/rewardRedeem?uid=' + url;
    }
    
}

export function imageExists(imageUrl) {

    var http = new XMLHttpRequest();

    http.open('HEAD', imageUrl, false);
    http.send();

    return http.status != 404;
}

export const resizeFile = (file) =>

    new Promise((resolve) => {
        Resizer.imageFileResizer(
            file, // the file from input
            300, // width
            300, // height
            'PNG', // compress format WEBP, JPEG, PNG
            90, // quality
            0, // rotation
            (uri) => {
                resolve(uri);
            },
            'base64' // blob or base64 default base64
        );
    });

export async function createFile(url) {

    let response = await fetch(url);
    let data = await response.blob();
    let metadata = {
        type: 'image/png'
    };

    return new File([data], "file.png", metadata);
}

export const validateEmail = (e) => {

    return new RegExp(/[a-zA-Z0-9]+[\.]?([a-zA-Z0-9]+)?[\@][a-z]{3,15}[\.][a-z]{2,5}/g).test(e); // eslint-disable-line
}

export function getDateFormatWithDateFormat(dateString, dateFormat) {

    return Moment(dateString).format(dateFormat);
}

export function getYearMonthYearDateFormat(dateString) {

    return getFormattedDate(dateString, 'YYYY/MM/DD')
}

export function calculateDiffDaysFromToday(date2Var) {

    return calculateDiffDays(new Date(), date2Var)
}

export function calculateDiffDays(date1Var, date2Var) {

    var date1 = new Date(date1Var);
    var date2 = new Date(date2Var);

    var difference_in_time = date2.getTime() - date1.getTime();
    var difference_in_days = difference_in_time / (1000 * 3600 * 24);

    return Math.round(difference_in_days);
}

export function openInNewTab(url) {

    window.open(url, '_blank').focus();
}

export function hyphenateText(text, size) {

    var parts = '';
    var length = text.length;

    for (var i = 0; i < length; i += size) {

        parts += text.substring(i, Math.min(length, i + size)) + '-';
    }

    return parts.substring(0, parts.length - 1);
}

export function returnImageURL(combinedURL, imgUrl) {

    if (imgUrl.startsWith('http')) {

        return imgUrl;
    } else {

        return combinedURL;
    }
}

export async function inviteFriends() {

    const getBizShortName = getBiz().biz_short_name;
    const getLang = getLanguage();

    const responseData = await getInviteLink();

    if (!checkIfNullUndefined(responseData)) {

        var bodyText = getLang.i_am_enjoying_loads_of_benefits
            .replaceAll('#bizname#', getBizShortName)
            .replaceAll('#url#', responseData);

        shareFunc(getLang.i_found_an_app_you_might_like, bodyText);
    }
}

export function shareFunc(title, text) {

    const getLang = getLanguage();

    if (navigator.share) {

        navigator.share({
            title: title,
            text: text,
        })
            .then(() => {
                inviteSent()
            })
            .catch((error) => {

                console.error(getLang.contact_failed_message, error);
            });
    }
}

export function isDark(color) {
    const hex = color.replace('#', '');
    const c_r = parseInt(hex.substring(0, 0 + 2), 16);
    const c_g = parseInt(hex.substring(2, 2 + 2), 16);
    const c_b = parseInt(hex.substring(4, 4 + 2), 16);
    const brightness = ((c_r * 299) + (c_g * 587) + (c_b * 114)) / 1000;

    return brightness < 155;
}

export function valueBarSpacer() {

    return <SpacerStyled height='100' />
}

export function bottomNavigationViewSpacer() {

    return <SpacerStyled height='100' />
}

export function hasTopValueBar() {

    return parseInt(getBiz().biz_layout) <= 8
}

export function getModuleNameById(modId) {

    const bizModules = getBiz().biz_modules;

    for (var i = 0; i < bizModules.length; i++) {
        if (bizModules[i].biz_mod_mod_id === modId) {
            return bizModules[i].biz_mod_mod_name;
        }
    }

    return '';
}

export function getIconNameByType(type) {

    switch (type) {
        case 'subscriptions':
            return 'my_subscriptions.svg'

        case 'punchpass':
            return 'my_punchpasses.svg'

        case 'orders':
            return 'Notepad.svg'

        default:
            break;
    }

    return '';
}

export function checkIfBizColorsChanged(newColorsList) {
    if (checkIfNullUndefined(getBiz())) {
        return;
    }
    var isChanged = false

    const currentColorsList = [
        getBiz().biz_theme_color1,
        getBiz().biz_theme_color2,
        getBiz().biz_theme_color3,
        getBiz().biz_theme_color4,
        getBiz().biz_theme_color5
    ]

    for (var i = 0; i < currentColorsList.length; i++) {
        if (currentColorsList[i] != newColorsList[i]) {
            isChanged = true
        }
    }

    store.dispatch(bizColorsChangedAction(IS_BIZ_COLOR_CHANGED, isChanged))

}

export function getBizHashFromUrl() {
    var url = window.location.href

    // const bizHash1 = 'RE5Bd1gzUkpVcTlNNzZFcXZsQWkzZz09OjpzaGdGZmhqdnNsZDRma1Ri'
    // const bizHash2 = 'SmJLcFpFdHo5Z0YwZjlvVXdwT21VUT09OjpzaGdGZmhqdnNsZDRma1Ri'

    // if (url.includes('localhost')) {
    //     url = 'https://sandboxmarket.getglue.co/glue/biz/index/MWNrWUJKbUFucm1SNktHb2Y5K0RWdz09OjpzaGdGZmhqdnNsZDRma1Ri#/welcome'
    //     return url.substring(url.indexOf('index/') + 6, url.indexOf('#'));
    // } else {
        return window.bizHash
    // }

}

export function getInjectedBizData(){
    var url = window.location.href

    // if (url.includes('localhost')){
    //     return require('../../assets/json_files/hardcoded_biz_data_hameshapetz.json')
    // } else {
        return window.bizData;
    // }
}

export function getShowCustomerLoader(){
    var url = window.location.href

    if (url.includes('localhost')){
        return 1
    } else {
        return window.showCustLoader
    }
}

export function isSandbox() {
    return false;
    const url = window.location.href

    return url.includes('sandbox') || url.includes('localhost')
}

export function isLocalhost() {
    const url = window.location.href

    return url.includes('localhost')
}

export function isLayout122() {
    var bizObject = getBiz();
    return bizObject.biz_mainmenu_view_type === "Layout122";
}

export function openHref(href) {
    
    window.location.href = href
}

export function formattedNumber(number){

    var num = number;

    const options = {  maximumFractionDigits: 2   }   
    const formattedNumber = Intl.NumberFormat("en-US",options).format(num); 

    return formattedNumber;

}


