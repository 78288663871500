import React, { useState, useRef, useEffect } from 'react';
import { DivCenterStyled, Toast, getBizColorNumber } from '../../biz_styles/bizStyle'
import { WelcomeBackground, LoginDivStyled } from '../../biz_styles/LoginStyle'
import LoadingComponent from '../../components/loading_components/LoadingComponent'
import { useLocation, useNavigate } from "react-router-dom"
import { checkIfNullUndefined } from '../../shared/static_helper/StaticHelper';
import { validateClubMemberByEmail } from '../../api/ApiRequests';
import LoginHeader from '../login/LoginFormHeader';
import ReCAPTCHA from "react-google-recaptcha";
import { getLanguage, isLoggedIn } from "../../shared/redux/GlueStoreData";


function redirectIfNeeded() {
  const navigate = useNavigate();
  if (isLoggedIn()) {
      
      useEffect(() => {
          navigate('../main')
        }, []);
    }
}

function VerifyEmail() {
  const [isLoading, setIsLoading] = useState(false);
  const captchaRef = useRef();
  const useLocationParam = useLocation();
  const useLocationParamState = useLocationParam.state;
  const getLang = getLanguage();

  var email = ""

  if (!checkIfNullUndefined(useLocationParamState)) {
    if (!checkIfNullUndefined(useLocationParamState.email)) {
      email = useLocationParamState.email;
    }
  }

  async function SendEmailAgain(email) {
    const token = await captchaRef.current.executeAsync();
    setIsLoading(true)
    const res = await validateClubMemberByEmail(email, token)
    if (res === undefined) {
      setIsLoading(false)
      captchaRef.current.reset();
      showError(getLang.sorry, getLang.something_went_wrong_try_again)
    }
    captchaRef.current.reset();
    setIsLoading(false)
  }

  function showError(title, text) {
    Toast.fire({
      title: title,
      text: text,
      icon: "error",
      confirmButtonText: getLang.ok,
    });
  }

  return (
    <WelcomeBackground>
      {redirectIfNeeded()}
      <LoadingComponent isLoading={isLoading} />
      <LoginDivStyled>
        <DivCenterStyled>
          <LoginHeader
            title={getLang.verify_your_email_address}
            subtitle={getLang.email_sent_text}
          />
          <p onClick={() => SendEmailAgain(email)} style={{ color: getBizColorNumber(3), cursor: 'pointer' }}>{getLang.resend_code_email}</p>
          <ReCAPTCHA
            sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
            ref={captchaRef}
            size="invisible"
          />
        </DivCenterStyled>
      </LoginDivStyled>
    </WelcomeBackground>
  );
}

export default VerifyEmail;
