import React, { Component } from 'react';
import './PersonalDataScreen.css'
import { getBizColorNumber } from '../../biz_styles/bizStyle';
import PersonalRewardCell from './cells/presonal_reward_cell/PersonalRewardCell';
import PersonalOrersCell from './cells/personal_orders_cell/PersonalOrdersCell'
import Skeleton from '@mui/material/Skeleton'
import coupon from '../../images/coupon.png';
import loyalty_cards from '../../images/loyalty_cards.png';
import punch from '../../images/punch.png';
import subscriptions from '../../images/subscriptions.png';
import { PStyled } from '../../biz_styles/bizStyle';
import { BackButtonStyled, DivCenterStyled } from '../../biz_styles/ReusableStyles';
import { getProgramTypeForCard, getRewardTypeStringForType, statusStringForStatus } from '../../shared/loyalty_helper/LoyaltyHelper';
import { getLabelForPunchPass, getLabelForSubscription } from '../../shared/loyalty_helper/PunchPassSubscriptionHelper';
import { IconStyledSvg } from '../../biz_styles/IconsStyles';
import { getClientAfterUpdate, getPersonalzone, zeroCustomerBadge } from '../../api/ApiRequests'
import SubscriptionMultipassInfoScreen from '../Info/subscription_multipass_info/SubscriptionMultipassInfoScreen';
import FadeIn from 'react-fade-in';
import review_okay from '../../images/review_okay.png';
import InfoScreen from '../../screens/Info/InfoScreen';
import OrderInfo from '../Info/orders/OrderInfo';
import LoyaltyInfoScreen from '../../screens/Info/loyalty_info/LoyaltyInfoScreen'
import { checkIfNullUndefined, checkIfNullUndefinedEmpty, setOpenMyLoyaltyCards } from '../../shared/static_helper/StaticHelper';
import { getLanguage, getRedirectData, clearRedirectData, getBiz } from '../../shared/redux/GlueStoreData';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { BizWebSocket } from '../../shared/web_socket/BizWebSocket';

class PersonalDataScreen extends Component {

    state = {

        objToPass: null,
        showInnerScreen: false,
        dataDictionary: {},
        getLang: getLanguage()
    }


    componentDidMount() {

        this.getScreenData();

        const currentURL = window.location.href;
        const updatedURL = currentURL.replace(/\/redirect\/\d+/, '');
        window.history.replaceState(null, '', updatedURL);

    }

    dataIsready = () => {

        return Object.entries(this.state.dataDictionary).length > 0
    }

    dataIsEmpty = () => {

        return this.dataDictionaryRoot().length === 0
    }

    dataDictionaryRoot = () => {

        if (this.props.rootElement.length > 0) {
            return Object.entries(this.state.dataDictionary)[0][1][this.props.rootElement];
        }
        return Object.entries(this.state.dataDictionary)[0][1]
    }

    getPunchPassSubscriptionImage(md_icon) {

        return "https://storage.googleapis.com/getglue/images/web_member/new_web_member/incentives/texture_" + md_icon + ".png";

    }

    iconForType = (type) => {

        switch (type) {
            case "coupon":
                return coupon;
            case "loyalty":
                return loyalty_cards;
            case "subscription":
                return subscriptions;
            case "punchpass":
                return punch;
            default:
        }
    }

    

    shouldShowOverlayForObject = (obj) => {

        if (obj.md_mod_id == 10) {
            if (obj.cust_sub_data.csu_status === "cancelled" || obj.cust_sub_data.csu_status === "expired"
                || obj.cust_sub_data.csu_status === "ended") {
                return true
            }
        }
        else if (obj.md_mod_id == 11) {
            if (obj.cust_pass_data.cpp_status === "cancelled" || obj.cust_pass_data.cpp_status === "expired"
                || obj.cust_pass_data.cpp_status === "ended") {
                return true
            }
        }
        else if (!checkIfNullUndefined(obj.data.customer_card_redeemed) && obj.data.customer_card_redeemed > 0) {
            return true;
        }
        else if (!checkIfNullUndefined(obj.data.valid_to) && obj.data.valid_to !== undefined) {
        }
        return false;
    }

    hasMoreUses = (obj) => {

        // Subscription
        if (obj.md_mod_id == 10) {
            if (obj.extra_data.usage_rule_type === 'unlimited') {
                return true
            } else if (obj.cust_sub_data.csu_usage_uses_left > 0) {
                return true
            }
            return false
        }

        // Punchpass
        else if (obj.md_mod_id == 11) {
            if (obj.extra_data.usage_rule_type === 'unlimited') {
                return true
            } else if (obj.cust_pass_data.cpp_usage_rule_uses_left > 0) {
                return true
            }
            return false
        }
    }

    checkIfRedeemed = (obj) => {
        return !checkIfNullUndefined(obj.data.customer_card_redeemed) && obj.data.customer_card_redeemed > 0
    }

    handleSocketUpdate = (data) => {
        // Handle socket update data here
      
        // Trigger re-render if necessary
        if(data.push_type == 13){
            this.getScreenData();
        }        
      };

    cellForContentType = () => {

        switch (this.props.contentType) {
            case "benefits":
                return obj =>
                    <PersonalRewardCell
                        onClick={() => this.cellClickedWithObject(obj)}
                        key={obj.recordID}
                        title={obj.data.header}
                        benefitType={getRewardTypeStringForType(obj.benefit_type)}
                        benefitTypeText={getRewardTypeStringForType(obj.benefit_type)}
                        benefitBehavior={obj.data.benefit_behavior}
                        image={obj.data.image}
                        qr={!this.checkIfRedeemed(obj) ? obj.data.qr_url : undefined}
                        barcode = {obj.data.bar_code}
                        iconType={this.iconForType(obj.benefit_type)}
                        showOverlay={this.shouldShowOverlayForObject(obj)}
                        overlayText={this.state.getLang.already_redeemed}
                        canUseNow={true}
                        ref={ref => {
                            // Check if the cell's ID matches the desired ID
                            if (ref && !checkIfNullUndefinedEmpty(getRedirectData()) && obj.recordID === getRedirectData().extra_params.external_id) {
                                // Programmatically trigger the click event
                                this.cellClickedWithObject(obj);
                                clearRedirectData();
                            }
                        }} />
            case "loyalty_cards":
                return obj =>
                    <PersonalRewardCell onClick={() => this.cellClickedWithObject(obj)}
                        key={obj.recordID}
                        title={obj.md_head}
                        benefitType={getRewardTypeStringForType(obj.benefit_type)}
                        benefitTypeText={getRewardTypeStringForType(obj.benefit_type)}
                        image={obj.md_pic}
                        iconType={this.iconForType(obj.benefit_type)}
                        typeLabel={getProgramTypeForCard(obj)}
                        canUseNow={true}
                        ref={ref => {
                            // Check if the cell's ID matches the desired ID
                            if (ref && !checkIfNullUndefinedEmpty(getRedirectData()) && obj.recordID === getRedirectData().extra_params.external_id) {
                                // Programmatically trigger the click event
                                this.cellClickedWithObject(obj);
                                clearRedirectData();
                            }
                        }} />
            case "subscriptions":
                return obj =>
                    <PersonalRewardCell
                        onClick={() => this.cellClickedWithObject(obj)}
                        id={obj.cust_sub_data.csu_id}
                        title={obj.md_head}
                        benefitType={'subscription'}
                        benefitTypeText={this.state.getLang.subscription}
                        image={this.getPunchPassSubscriptionImage(obj.md_icon)}
                        qr={obj.cust_sub_data.csu_qr_url !== '' && obj.cust_sub_data.csu_status === 'active' ?
                            obj.cust_sub_data.csu_qr_url :
                            undefined}
                        iconType={this.iconForType('subscription')}
                        typeLabel={getLabelForSubscription(obj)}
                        status={obj.cust_sub_data.csu_status}
                        showOverlay={this.shouldShowOverlayForObject(obj)}
                        overlayText={statusStringForStatus(obj.cust_sub_data.csu_status)}
                        canUseNow={this.hasMoreUses(obj)}
                        ref={ref => {
                            // Check if the cell's ID matches the desired ID
                            if (ref && !checkIfNullUndefinedEmpty(getRedirectData()) && obj.recordID === getRedirectData().extra_params.external_id) {
                                // Programmatically trigger the click event
                                this.cellClickedWithObject(obj);
                                clearRedirectData();
                            }
                        }}
                    />
            case "punchpass":
                return obj =>
                    <PersonalRewardCell
                        onClick={() => this.cellClickedWithObject(obj)}
                        id={obj.cust_pass_data.cpp_id}
                        title={obj.md_head}
                        benefitType={'punchpass'}
                        benefitTypeText={this.state.getLang.multipass}
                        image={this.getPunchPassSubscriptionImage(obj.md_icon)}
                        qr={obj.cust_pass_data.cpp_qr_url !== '' && obj.cust_pass_data.cpp_status === 'active' ?
                            obj.cust_pass_data.cpp_qr_url :
                            undefined}
                        iconType={this.iconForType('punchpass')}
                        typeLabel={getLabelForPunchPass(obj.cust_pass_data)}
                        status={obj.cust_pass_data.cpp_status}
                        showOverlay={this.shouldShowOverlayForObject(obj)}
                        overlayText={statusStringForStatus(obj.cust_pass_data.cpp_status)}
                        canUseNow={this.hasMoreUses(obj)}
                            ref={ref => {
                            // Check if the cell's ID matches the desired ID
                            if (ref && !checkIfNullUndefinedEmpty(getRedirectData()) && obj.recordID === getRedirectData().extra_params.external_id) {
                                // Programmatically trigger the click event
                                this.cellClickedWithObject(obj);
                                clearRedirectData();
                            }
                        }}
                    />
            case "orders":
                return obj =>
                    <PersonalOrersCell
                        onClick={() => this.cellClickedWithObject(obj)}
                        key={obj.recordID}
                        object={obj}
                        canUseNow={true} />
            default:
        }
    }

    cellClickedWithObject = (obj) => {
        this.toggleInnerScreen(true, obj);
    }

    toggleInnerScreen = (show, obj) => {
        this.setState({ showInnerScreen: show, objToPass: obj });
    }

    async getScreenData() {

        const responseData = await getPersonalzone(this.props.api);

        if (!checkIfNullUndefined(responseData)) {

            this.setState({ dataDictionary: responseData });

            if (this.props.contentType !== 'subscriptions' ||
                this.props.contentType !== 'punchpass') {

                const responseCode = await zeroCustomerBadge(this.props.contentType);

                if (responseCode === 1) {

                    await getClientAfterUpdate();
                }
            }
        }
    }

    showListLayout = () => {

        return <>

            <div style={{ textAlign: 'center', marginTop: '60px', marginBottom: '10px' }}>
                <IconStyledSvg cssName={'fill-color3'} name={this.props.top_icon + '.svg'} size='48' />
            </div>

            <div className="row no-margin">
                <span className='personal-data-label' style={{ color: getBizColorNumber(2) }}>{this.props.label}</span>
            </div>

            <div className='personal-data-scroll-view'>

                {!this.dataIsready() ?
                    <div>
                        <Skeleton animation="wave" variant="rectangular" width='92%' height={240} style={{ backgroundColor: getBizColorNumber(4), margin: '30px auto', borderRadius: 10 }} />
                        <Skeleton animation="wave" variant="rectangular" width='92%' height={240} style={{ backgroundColor: getBizColorNumber(4), margin: '30px auto', borderRadius: 10 }} />
                    </div> :
                    (this.dataIsEmpty() ?

                        <FadeIn>
                            <DivCenterStyled >
                                <IconStyledSvg style={{ marginLeft: 'auto', marginRight: 'auto', marginBottom: '10px' }} name="SmileyMeh.svg" cssName={'fill-color2'} size='60' />
                            </DivCenterStyled>

                            <DivCenterStyled style={{ height: 'inherit', width: '100%', display: 'table' }}>
                                <PStyled style={{ display: 'table-cell', verticalAlign: 'middle' }}>{this.props.noDataText}</PStyled>
                            </DivCenterStyled>
                        </FadeIn>
                        :
                        this.dataDictionaryRoot().map(this.cellForContentType())
                    )
                }

                <div className="spacer"></div>
            </div>
        </>
    }

    innerScreenToShow(isPersonalZone, obj) {

        var infoScreen;

        if (this.props.contentType !== "orders") {

            if (this.props.contentType === "punchpass" || this.props.contentType === "subscriptions") {

                infoScreen = <SubscriptionMultipassInfoScreen fromPersonalZoneFromList={isPersonalZone} currentObjFromList={obj} />
            } else {

                var benefitType = obj.benefit_type;

                if (this.props.contentType === "benefits") {

                    switch (benefitType) {

                        case 'loyalty':
                        case 'coupon':

                            var modIdVar = '';

                            if (benefitType === 'loyalty') {

                                modIdVar = 6;
                            } else if (benefitType === 'coupon') {

                                modIdVar = 26;
                            }

                            obj['md_mod_id'] = modIdVar;
                            obj['md_level_no'] = 1;
                            obj['md_row_id'] = obj.recordID;
                            obj['md_program_type'] = obj.data.program_type;
                            obj['customer_card_code'] = obj.data.customer_card_code;
                            obj['customer_card_qr_link'] = !checkIfNullUndefined(obj.data.rewards_list[0]) ? obj.data.rewards_list[0].customer_card_qr_link : null;

                            infoScreen = <InfoScreen fromPersonalZoneFromList={isPersonalZone} currentObjFromList={obj} />
                            break;

                        case 'personal':
                        case 'points_redeem':
                        case 'scratch':
                        case 'cust_event':
                        case 'membership':

                            infoScreen = <LoyaltyInfoScreen currentObjFromList={obj} />
                            break;
                        default:
                    }
                } else {

                    var isPersonalZoneVar = isPersonalZone;

                    if (this.props.contentType === "loyalty_cards") {

                        isPersonalZoneVar = false;
                        setOpenMyLoyaltyCards(true);
                    }

                    infoScreen = <InfoScreen fromPersonalZoneFromList={isPersonalZoneVar} currentObjFromList={obj} />
                }
            }
        }
        else {

            infoScreen = <OrderInfo currentObj={obj} />
        }


        return <>

            {getBiz().club_active == 1 ?
                <ArrowBackIcon
                    style={{ width: 32, height: 32, color: getBizColorNumber(2), marginTop: 45, marginLeft: 20, marginRight: '90%' }}
                    onClick={() => this.toggleInnerScreen(false, null)} />
                : ""
            }

            {infoScreen}

        </>
    }

    render() {
        return (
            <div className='personal-data-holder' style={{ backgroundColor: getBizColorNumber(1) }}>
                {this.state.showInnerScreen ?
                    this.innerScreenToShow(true, this.state.objToPass)
                    :
                    <this.showListLayout />}
                    {getBiz().club_active == 1 ? "" : <BizWebSocket onSocketUpdate={this.handleSocketUpdate}/>}
            </div>
        );
    }
}

export default PersonalDataScreen;