import React, { useState } from "react";
// import DatePicker from "react-datepicker";
import { checkIfNullUndefinedEmpty, getFormatedDateServer } from "../../../../shared/static_helper/StaticHelper";
// import 'react-datepicker/dist/react-datepicker.css'
// import './DateTextFieldFormComponent.css';

import TextField from '@mui/material/TextField';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { getBizColorNumber } from "../../../../biz_styles/bizStyle";
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import { enUS,heIL } from '@mui/x-date-pickers/locales';	
import { getBiz } from "../../../../shared/redux/GlueStoreData";
import he from 'date-fns/locale/he';
import en from 'date-fns/locale/en-US';


export default function DateTextFieldFormComponent({label, defaultValue, onChange }) {

    var bizObject = getBiz();
    var localText = enUS.components.MuiLocalizationProvider.defaultProps.localeText
    var localLanguage = en;
    switch(bizObject.biz_language){
      case "he":
        var localLanguage = he;
        localText = heIL.components.MuiLocalizationProvider.defaultProps.localeText
        break;
    }

    /* Logic functions - start */
    const [defaultValueVar, setDefaultValueVar] = useState(checkIfNullUndefinedEmpty(defaultValue) ? null : new Date(defaultValue));

    const styles = {
        '& label': {
            color: getBizColorNumber(2),
          },
          '& input': {
            color: getBizColorNumber(2),
          },
        '& label.Mui-focused': {
            color: getBizColorNumber(2),
          },
          '& .MuiInput-underline:before': {
            borderBottomColor: getBizColorNumber(2),
          },
          '& .MuiInput-underline:after': {
            borderBottomColor: getBizColorNumber(2),
          },
          '& .MuiOutlinedInput-root': {
            '& fieldset': {
              borderColor: 'red',
            },
            '&.Mui-focused fieldset': {
              borderColor: getBizColorNumber(2),
            },
        }
    }

    function handleOnChange(newDate) {

        setDefaultValueVar(newDate);
        // onChange(`${date.getFullYear()}` + '-' + `${date.getMonth() + 1}` + '-' + `${date.getDate()}`); // eslint-disable-line

        onChange(getFormatedDateServer(newDate))
    }
    /* Logic functions - end */

    /* UI - start */
    /* UI - without UI functions - start */
    return <div className="date-picker-holder">

    <LocalizationProvider dateAdapter={AdapterDateFns} localeText={localText} locale={bizObject.biz_language} adapterLocale={localLanguage}>
      <MobileDatePicker
        label={label}
        value={defaultValueVar}
        onChange={(newValue) => {
          handleOnChange(newValue);
        }}
        renderInput={(params) => <TextField sx={styles} fullWidth id="standard-basic" variant="standard" {...params} />}
      />
    </LocalizationProvider>

        {/* <DatePicker
            selected={defaultValueVar}
            onChange={(date) => handleOnChange(date)}
            dateFormat='MM/dd/yyyy' /> */}

    </div>
    /* UI - without UI functions - end */
    /* UI - end */
}