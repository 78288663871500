import React, { useState, useRef, useEffect } from 'react'
import { useNavigate, Link } from "react-router-dom"
import { DivCenterStyled, Toast, getBizColorNumber } from '../../biz_styles/bizStyle'
import { NextButtonStyled, EmailInputStyled, LoginDivStyled, WelcomeBackground,LinkTextStyled } from '../../biz_styles/LoginStyle'
import LoadingComponent from '../../components/loading_components/LoadingComponent'
import LoginHeader from './LoginFormHeader'
import { useStoreProvider } from '../../shared/providers/StoreContext'
import { validateClubMemberByEmail } from '../../api/ApiRequests';
import ReCAPTCHA from "react-google-recaptcha"
import { getLanguage, isLoggedIn } from '../../shared/redux/GlueStoreData'





import './LoginScreen.css';


function LoginFormEmail() {

    const [email, setEmail] = useState("");
    const [isValid, setIsValid] = useState(true);
    const [isLoading, setIsLoading] = useState(false);
    const captchaRef = useRef()
    const [isDisabled, setisDisabled] = useState(true);
    const getLang = getLanguage();

    const navigate = useNavigate();

        const bizObject = useStoreProvider().biz


    const validateEmail = (e) => {
        return new RegExp( /[a-zA-Z0-9]+[\.]?([a-zA-Z0-9]+)?[\@][a-z]{3,15}[\.][a-z]{2,5}/g).test(e); // eslint-disable-line
    }

    const handleOnChange = e => {
        e.preventDefault();
        setEmail(e.target.value)
        setIsValid(validateEmail(e.target.value))
        setisDisabled(!validateEmail(e.target.value))
    }

    const onSubmitEmail = async() => {
        const token = await captchaRef.current.executeAsync();
        if (isValid) {
            setIsLoading(true)
            validateClubMember(token)        
        } 
        else {
            showError(getLang.error,getLang.invalid_email)
            captchaRef.current.reset();
        }
      };

      async function validateClubMember(captchaToken) {
        const res =  await validateClubMemberByEmail(email,captchaToken)
            if (res !== undefined) {
                setIsLoading(false)
                if(res.responseMessage === 'sms_code_sent'){
                    navigate("../LoginFormSmsCode",{state: {email}})
                }
                else if(res.responseMessage === 'email_code_sent'){
                    navigate("../verifyEmail",{state: {email}})
                }
                else if(res.responseMessage === 'no_email_in_system'){
                    navigate("../emailNotFound")
                }
                else{
                    showError(getLang.sorry, getLang.something_went_wrong_try_again)
                    captchaRef.current.reset();
                }
            } else {
                setIsLoading(false)
                showError(getLang.sorry, getLang.something_went_wrong_try_again)
                captchaRef.current.reset();
            }
    }

    function showError(title,text) {
        Toast.fire({
          title: title,
          text: text,
          icon: 'error',
          confirmButtonText: getLang.ok,
          confirmButtonColor: getBizColorNumber(3),
        })
      
      }
     
    function redirectIfNeeded() {
        
        if (isLoggedIn()) {
            
            useEffect(() => {
                navigate('../main')
              }, []);
          }
    }

    return (
        <WelcomeBackground>
            {redirectIfNeeded()}
            <div className='parent-div'>
            <LoadingComponent isLoading={isLoading} />

            <LoginDivStyled>
                <LoginHeader
                    title={getLang.lets_sign_you_in}
                    subtitle={bizObject.biz_login_message} />
                <DivCenterStyled>
                    <EmailInputStyled isEmailValid={isValid} onChange={handleOnChange} />
                </DivCenterStyled>

                <DivCenterStyled >
                    <ReCAPTCHA
                    sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
                    ref={captchaRef}
                    size="invisible"
                    />
                    <NextButtonStyled onClick={onSubmitEmail} disabled={isDisabled}>{getLang.next}</NextButtonStyled>
                </DivCenterStyled>
                <LinkTextStyled to="../login">{getLang.sign_in_with_phone_number}</LinkTextStyled>

            </LoginDivStyled>

            </div>
        </WelcomeBackground>

    )

}

export default LoginFormEmail