import styled from 'styled-components'
import { TextField } from '@mui/material';

export const LogoStyled = styled.img`  
  height: 80px;
  position: relative;
  `

export const BoldLabelStyled = styled.span`
  color: ${(props) => props.color};
  font-size: ${(props) => props.fontSize}px;
  position: relative;`

export const RegularLabelStyled = styled.span`
  color: ${(props) => props.color};
  font-size: ${(props) => props.fontSize}px;
  position: relative;`
  
export const SideAlignDiv = styled.div`
  text-align: ${(props) => props.alignment};
  width: 100%;
  position: relative;
  `

export const DivCenterStyled = styled.div`
  text-align: center;`

export const StarBoxStyled = styled.div`
  position: relative;
  background-color:${(props) => props.backgroundColor + 20} ;
  width: 60px;
  height: 60px;
  border-radius: 6px;
  border-width:  1px;
  border-color:${(props) => props.color};
  border-style:solid;
  text-align: center;
  margin: 6px;
  `

export const StarImgStyled = styled.img`
position: static;
backgroundColor: red;
height: 14px;
margin-top: 18px;
`

export const StarTitleStyled = styled.p`
  //font-family: 'Alef', sans-serif;
  font-size: 13px;
  color:${(props) => props.color};`

export const RowDivStyled = styled.div`
  position: relative;
  background-color: transparent;
  margin-right:${(props) => props.marginRight}px;
  margin-left:${(props) => props.marginLeft}px;
  padding: ${(props) => props.padding}px;
  width: 100%;`

export const SpacerStyled = styled.div`
    height: ${(props) => props.height}px;`

export const ErrorLineStyled = styled.div`
    height: 1px;
    background-color: ${(props) => props.color};
    `

export const HolderDivStyled = styled.div`
    position: relative;
    background-color:${(props) => props.color};
    height: 100%;
    text-align: center;
    overflow:scroll;`

export const GlueTextField = styled(TextField)`
  & label.Mui-focused {
    color: #000000;
  },
  & .MuiInput-underline:after{
    border-bottom-color: #000000;
  },
  & .MuiOutlinedInput-root {
    &.Mui-focused fieldset {
      border-color: white;
    }
  }
;`

