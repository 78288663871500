import React, { Component } from 'react';
import "bootstrap/dist/css/bootstrap.css"
import { IconStyledSvg } from '../../../../biz_styles/IconsStyles'
import "./TopHeaderCell.css";
import ProgressBarView from '../../../../components/progress_bar/ProgressBarView.jsx'
import { getBizColorNumber, DivHorizontalStyled, DivCenterHorizontalStyled } from '../../../../biz_styles/bizStyle';
import { getCustomer, getLanguage } from '../../../../shared/redux/GlueStoreData';
import { formattedNumber } from '../../../../shared/static_helper/StaticHelper.jsx';

class TopHeaderCell extends Component {



    constructor(props) {
        super(props)

        this.customerVar = getCustomer();
        this.getLang = getLanguage();
        this.showProgresBar = Number(this.customerVar.membership.next_membership_req_points) > 0;
    }

    getGreetText() {
        return this.props.greetText;
    }

    getPointsValueText() {
        return formattedNumber(this.props.points);
    }



    getNextTierPoints() {
        return formattedNumber(getCustomer().membership.points_to_next_level);
    }

    getNextTierName() {
        return this.customerVar.membership.next_membership_name;
    }

    getCompletedProgressValue() {

        var totalPoints = parseInt(this.customerVar.membership.total_points);
        var pointsToNextLevel = parseInt(this.customerVar.membership.points_to_next_level);
        var maxProgress = pointsToNextLevel + totalPoints;

        var percent = totalPoints / maxProgress * 100;

        if (percent == 0) {
            percent = 3
        }

        return percent;
    }

    getPointsToNextTierString(){

        var string = this.getLang.points_to_tier;
        string = string.replace("#amount#",this.getNextTierPoints());
        string = string.replace("#tier_name#",this.getNextTierName());

        return string;
    }
    
    render() { 
        return (

        <div className='top-header-container' style={{background: 'linear-gradient(' + getBizColorNumber(4) + ', rgba(0, 0, 0, 0))' }}>

            <div className="row">
                <div className='greet-label bold-font' style={{color: getBizColorNumber(2)}}>{this.getGreetText()},<br/>{this.customerVar.cust_first_name}</div>
                <div className='points-value-label' style={{color: getBizColorNumber(2)}}>{this.getPointsValueText() }</div>                
            </div>

            <div style={{paddingBottom: 8}}>
                <DivCenterHorizontalStyled>
                    <IconStyledSvg  name="StarDuotune.svg" cssName='fill-color2' size={34} />
                    <span className='points-balnace-text' style={{color: getBizColorNumber(2)}}>{this.getLang.points_balance}</span>
                </DivCenterHorizontalStyled>
            </div>
                
                {this.showProgresBar ?

                    <div>

                        <ProgressBarView className='bottom-progress-bar' bgcolor={getBizColorNumber(3)} completed={this.getCompletedProgressValue()} />

                        <div className="row">
                            <span className='next-tier-text' style={{ color: getBizColorNumber(2), opacity: 0.5 }}>{this.getPointsToNextTierString()}</span>
                        </div>

                    </div> :

                    <div />}

        </div>
            
        );
    }
}
 
export default TopHeaderCell;