import React, { useState } from 'react'
import { ParentDivStyled, TextDivStyled, HeaderDivStyled } from '../../biz_styles/ScratchCardStyle'
import { getLanguage } from '../../shared/redux/GlueStoreData';
import Dialog from '@mui/material/Dialog';
import Slide from '@mui/material/Slide';
import Confetti from 'react-confetti'
import 'reactjs-popup/dist/index.css';
import { GenericBoldTitleStyled, GenericTitleStyled, SpacerStyled } from '../../biz_styles/ReusableStyles';
import { LinkStyled } from '../../biz_styles/LoginStyle';
import { getBizColorNumber } from '../../biz_styles/bizStyle';
import { style } from 'glamor';

export default function RewardLayerComponent({rewardsData, onClose}) {

    // const [showConfetti, setShowConfetti] = useState(false)

    function playConfettiAnimation() {
        return (
            <Confetti
                width={window.innerWidth}
                height={window.innerHeight}
                numberOfPieces={300}
                recycle={false}
            />
        )
    }

    const getLang = getLanguage();

    var textToShow = ''

    // if (props.cardData.prize_type === 'points' || props.cardData.text === 'consolation') {
    //     textToShow = props.cardData.prize_value
    // } else {
    //     isPoints = false
    //     textToShow = getLang.the_gift_will_be_automatically_added;
    // }

   

    return (

        <Dialog className='rewards-popup-holder' PaperProps={{
            style: {
                position: 'absolute',
                width: '95%',
                backgroundColor: 'transparent',
                borderRadius: '22px',
                boxShadow: 'none',
                overflow: 'hidden',
                zIndex:'9999999'
            },
        }}  open={true} TransitionComponent={Transition} children={

            <ParentDivStyled style={{
                backgroundImage: 'url(https://storage.googleapis.com/getglue/images/web_member/new_web_member/you_win.png)',
                backgroundRepeat: 'no-repeat',
                backgroundSize: 'contain',
                paddingLeft: '90px'
            }}>
                    
                {playConfettiAnimation()}


                <HeaderDivStyled className='rewards-popup-text'>
                    <GenericBoldTitleStyled className='bold-font' fontSize='20'>{getLang.congratulations + '!'}</GenericBoldTitleStyled>
                </HeaderDivStyled>

                <div>
                    <TextDivStyled className='rewards-popup-text'>
                        <GenericTitleStyled fontSize='12'>{getLang.you_just_won}</GenericTitleStyled>
                    </TextDivStyled>
                    
                    <SpacerStyled height={'18'} />
                    {rewardsData.type == "points" ? 
                <TextDivStyled className='rewards-popup-text'>
                    <GenericTitleStyled fontSize='40'>{String(rewardsData.amount) + ' '}</GenericTitleStyled>
                    <GenericTitleStyled fontSize='20'>{getLang.points}</GenericTitleStyled>
                </TextDivStyled> :  
                <TextDivStyled className='rewards-popup-text'>
                    <GenericTitleStyled fontSize='20'>{String(rewardsData.reward_label)}</GenericTitleStyled>
                </TextDivStyled>  
                }
                    
                    
                    <SpacerStyled height={'32'} />
                    <TextDivStyled className='rewards-popup-text'>
                        <GenericTitleStyled fontSize='12' color='#A4A4A5'>{rewardsData.reason}</GenericTitleStyled>
                    </TextDivStyled>
                    

                    <div onClick={onClose} style={{cursor: 'pointer'}}>
                        <GenericTitleStyled className='rewards-popup-btn' style={{position:'absolute', padding:'20px', bottom:'0'}} fontSize='15' color={getBizColorNumber(3)}>{getLang.got_it}</GenericTitleStyled>
                    </div>

                </div>

            </ParentDivStyled>

        }>

        </Dialog>


    )
}

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});