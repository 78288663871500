import styled from 'styled-components'
import { getBizColorNumber } from './bizStyle';
import IconTint from "react-icon-tint";



export const DivModuleItemStyled = styled.div``


export const DivModuleImageStyled = styled.div`
    padding: 10px;
    text-align: center;`

export const ModuleImageStyled = styled.img`
    width: 100%;
    height: 100%;
    cursor: pointer;
    text-align: center;
    background:${() => getBizColorNumber(4)};
    padding: ${(props) => props.padding}%;
    border-radius: 10px;`

export const IconImageStyled = styled(IconTint)`
    width: 100%;
    height: 100%;
    cursor: pointer;
    text-align: center;
    background:${() => getBizColorNumber(4)};
    padding: ${(props) => props.padding}%;
    color=${(props) => props.color};
    border-radius: 10px;`


export const DivModuleNameStyled = styled.div`
    color: ${() => getBizColorNumber(2)};
    cursor: pointer;
    text-align: center;`

export const DivAllPagesComponentStyled = styled.div`
    height:calc(100vh - 80px) ;
    padding: 20% 2% 2% 2%;
    color: #ffffff;
    overflow: scroll;
    text-align: center;`

    
export const CartHolderDivStyled = styled.div`
position: relative;
background-color: #F2F2F2;
height: 100%;
overflow:hidden;`

export const BoosterFlameStyled = styled.img`
position: absolute;
width: 40px;
heighe: 40px;
top:-23px;
left:30px;
z-index: -1;
opacity: ${(props) => props.isHidden ? 0 : 1};
`
    
export const RatingGIFStyled = styled.img`
position: relative;
width: 100%;
heighe: 100%;
`

export const EmptyStateContainerStyled = styled.div`
position: relative;
width: 100%;
height: 100%;
margin-top: 20px;
background-color: transparent;
flex: 0 
`