import React, { useState, useEffect } from "react";
import { getLevelData } from "../../../api/ApiRequests";
import { SubsDivListStyled, SubsPageTitleStyled } from '../../../biz_styles/SubscriptionsStyle'
import { DivPaddingTop } from "../../../biz_styles/IncentivesStyle";
import HorizontalScroller from '../../../components/horizontal_scroller/HorizontalScroller';
import SubscriptionsCardItemCell from './SubscriptionsCardItemCell'
import { getModuleNameById } from '../../../shared/static_helper/StaticHelper'

function Subscriptions() {
  const [isLoadingData, setIsLoadingData] = useState(true);
  const [card, setCard] = useState([]);

  const params = {
    mod_id: "10",
    level_no: "1",
    parent: "0",
    qs: "",
    subcategory: "",
    prodId: "",
    itemsPerPage: "",
    existingItems: "0",
  };

  useEffect(() => {
    (async () => {
      setCard(await getLevelData(params));
      setIsLoadingData(false);
    })();
  }, []);


  return (
    <div>
      <DivPaddingTop>
        {/* <SubsPageTitleStyled>{getModuleNameById(params.mod_id)}</SubsPageTitleStyled> */}
      </DivPaddingTop>
      <SubsDivListStyled>
        <HorizontalScroller style={{ display: 'table-cell', verticalAlign: 'middle' }}
        cellWidth={250}
        scrollerHeight={465}
        dataIsready={!isLoadingData}
        data={isLoadingData ? null : card.rows}
        showEmptyState={!isLoadingData && card.rows.length == 0}
        cellClass={obj => <SubscriptionsCardItemCell obj={obj} key={obj.md_row_id}/>}
        />
      </SubsDivListStyled>
    </div>
  );
}

export default Subscriptions;
