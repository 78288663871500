import React, { useEffect } from 'react'
import { DivCenterStyled, LogoRoundedContainerStyled } from '../../biz_styles/ReusableStyles'
import { getBizColorNumber, getBizBackgroundImage, Toast } from '../../biz_styles/bizStyle'
import { IconStyledSvgWaitingList } from '../../biz_styles/IconsStyles';
import { TitleStyled, SubTitleStyled, BottomLabelStyled, DivLogoutStyled, LogoutLabelStyled } from '../../biz_styles/WaitingListStyle'
import { useStoreProvider } from '../../shared/providers/StoreContext'
import { deleteUserData } from '../../api/ApiRequests';
import { clearStoreData, getLanguage, isLoggedIn } from '../../shared/redux/GlueStoreData'
import { useNavigate } from "react-router-dom"
import Swal from 'sweetalert2';


const DeleteUserDataByEmailScreen = () => {

  const bizObject = useStoreProvider().biz
  const navigate = useNavigate()
  const getLang = getLanguage();

  useEffect(() => {
    if (!isLoggedIn()) {
      navigate('../')
    }
  }, []);


  return (
    <div className="bg-color-view" style={{ height: '100%', marginTop: 0, background: getBizColorNumber(1), opacity: 0.7 }}>
      
      {/* <DivLogoutStyled style={{ position: 'relative', textAlign: 'right', paddingRight: 20, paddingTop: 30 }}>
        <LogoutLabelStyled onClick={() => showDeleteAlertMessage()}>{getLang.action_logout}</LogoutLabelStyled>
      </DivLogoutStyled> */}


      <DivCenterStyled style={{ position: 'relative' }}>
        
        <LogoRoundedContainerStyled src={bizObject.biz_logo} />

        <TitleStyled style={{ paddingTop: 20 }}>{<strong>{bizObject.biz_waiting_title}</strong>}</TitleStyled>
        <SubTitleStyled>{bizObject.biz_waiting_message}</SubTitleStyled>

        <BottomLabelStyled>{bizObject.biz_waiting_signed}</BottomLabelStyled>
        <BottomLabelStyled>{bizObject.biz_short_name}</BottomLabelStyled>

        <div style={{ position: 'fixed', bottom: 0, height: 'auto', width: '100%', maxWidth: 550 }}>
          <IconStyledSvgWaitingList style={{}} name={'waiting_line.svg'} cssName={'fill-color2'} size='100%' />
        </div>

      </DivCenterStyled>
    </div>
  )

}

function showDeleteAlertMessage() {

  const getLang = getLanguage();

  Toast.fire({
    allowOutsideClick: false,
    title: getLang.sign_out,
    html: getLang.are_you_sure_you_wish_to_sign_out,
    confirmButtonText: getLang.yes,
    showCancelButton: true,
    cancelButtonText: getLang.cancel,
    reverseButtons: true,
    iconColor: getBizColorNumber(3),
    confirmButtonColor: getBizColorNumber(3),
  }).then(async (result) => {
    if (result.isConfirmed) {
      Toast.fire({
        title: getLang.please_wait,
        didOpen: () => {
          Swal.showLoading()
        }
      })

      deleteData()
    }
  })
}

async function deleteData() {
  const responseCode = await deleteUserData();

  if (responseCode === 1) {
    clearStoreData()
  }
}

export default DeleteUserDataByEmailScreen
