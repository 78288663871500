import React, { useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import './WelcomeScreen.css';
import { WelcomeBackground, LoginDivStyled, TextStyled, LinkStyled } from '../../biz_styles/LoginStyle';
import LoginFormHeader from '../login/LoginFormHeader'
import { useStoreProvider } from '../../shared/providers/StoreContext'
import { getBizColorNumber } from '../../biz_styles/bizStyle';
import { getLanguage, isLoggedIn } from '../../shared/redux/GlueStoreData';


function getComponentToRender() {

  const bizObject = useStoreProvider().biz
  const getLang = getLanguage();
  const emailMemberEnabled = bizObject.email_member_enabled === '1'
  const isExclusive = bizObject.biz_membership_mode === 'exclusive'

  if(!navigator.cookieEnabled){
    return (
      <div className="browser-issue-image">
        {getLang.browser_cookie_isse}
        <br/><br/>
        {getLang.browser_cookie_email_guidance}
      </div>
    )
  }
  else if (isExclusive) {
    if (emailMemberEnabled) {
      return (
        <div className="actions">
          <div className="login-btn">
            <LinkStyled to="../exclusiveLoginEmail">{getLang.sign_in}</LinkStyled>
          </div>
        </div>
      )
    } else {
      return (
        <div className="actions">
          <div className="login-btn">
            <LinkStyled to="../exclusiveLoginPhone">{getLang.sign_in}</LinkStyled>
          </div>
        </div>
      )
    }

  } else {
    return (
      <div className="actions">
        <div className="login-btn">
          {emailMemberEnabled ? <LinkStyled to="../registerEmail">{getLang.action_register}</LinkStyled> : <LinkStyled to="../registerPhone">{getLang.action_register}</LinkStyled>}
        </div>
        <div className="signin-btn">
          <TextStyled>{getLang.already_a_member} <Link style={{color: getBizColorNumber(3)}} to="../login">{getLang.sign_in}</Link></TextStyled>
        </div>
      </div>
    )

  }

}

function redirectIfNeeded() {
  const navigate = useNavigate();

  if (isLoggedIn()) {
      
      useEffect(() => {
          navigate('../main')
        }, []);
    }
}

function WelcomeScreen() {

  const bizObject = useStoreProvider().biz

  return (
    <WelcomeBackground>
      {redirectIfNeeded()}
      <LoginDivStyled>
          <LoginFormHeader
            title={bizObject.biz_welcome_title}
            subtitle={bizObject.biz_welcome_message}/>
          {getComponentToRender()}
      </LoginDivStyled>
    </WelcomeBackground>
  )
}

export default WelcomeScreen