import React from 'react';
import './GenericRowCell.css';
import { getBizColorNumber } from '../../../../biz_styles/bizStyle';
import { IconStyledSvg } from '../../../../biz_styles/IconsStyles'

export default function GenericRowCell(props) {
    
    return (

        <div className="generic-row-cell-holder" onClick={props.onClickEvent}>

            <div className='row'>

                <div className='image-container-view' style={{ backgroundColor: getBizColorNumber(4) }}>
                    <IconStyledSvg name={props.img1} cssName='fill-color3' size={84} />
                </div>

                <div>
                    <span className='title-label' style={{ color: getBizColorNumber(2) }}>{props.title}</span>
                    <span className='subtitle-label' style={{ color: getBizColorNumber(2) }}>{props.subtitle}</span>
                </div>
            </div>
        </div>
    );

}
