import React, { Component } from 'react';
import { RowDivStyled } from '../../biz_styles/ReusableStyles';

function RowDiv(props) {
    return (
      <RowDivStyled>
        {props.children}
      </RowDivStyled>
    );
  }
  
  export default RowDiv;