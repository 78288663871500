import React from "react";

export function UploadImageComponent({ text, style, onImageChanged }) {

    /* UI - start */
    /* UI - without UI functions - start */
    return (
        <div>

            <label
                htmlFor='filePicker'
                style={style}>
                {text}
            </label>

            <input
                id='filePicker'
                type='file'
                style={{ visibility: 'hidden' }}
                onChange={onImageChanged} />

        </div>
    );
    /* UI - without UI functions - end */
    /* UI - end */
}

export default UploadImageComponent;