import React from 'react'
import { ParentDivStyled, TextDivStyled, HeaderDivStyled } from '../../biz_styles/ScratchCardStyle'
import { GenericTitleStyled } from '../../biz_styles/ReusableStyles';
import { getLanguage } from '../../shared/redux/GlueStoreData';
import { getBizColorNumber } from '../../biz_styles/bizStyle';

export default function InnerScratchCardComponent(props) {

    const getLang = getLanguage();

    var textToShow = ''
    var isPoints = true
    
    if (props.cardData.prize_type === 'points' || props.cardData.text === 'consolation') {
        textToShow = props.cardData.prize_value
    } else {
        isPoints = false
        textToShow = getLang.the_gift_will_be_automatically_added;
    }

    return (

        <ParentDivStyled>

            <HeaderDivStyled>
                <h3>{getLang.congratulation}</h3>
            </HeaderDivStyled>

            { isPoints ?
                <TextDivStyled>
                    <p>{getLang.you_just_won} {textToShow} {getLang.points.toLowerCase()}</p>
                </TextDivStyled> :

                <div>

                    <TextDivStyled>
                        <p>{getLang.you_just_won} {props.cardData.prize_value}</p>
                    </TextDivStyled>
                    <TextDivStyled>
                        <p>{textToShow}</p>
                    </TextDivStyled>
                    <div onClick={props.onClose} style={{cursor: 'pointer'}}>
                        <GenericTitleStyled className='rewards-popup-btn' style={{position:'absolute', padding:'20px', bottom:'0'}} fontSize='15' color={getBizColorNumber(3)}>{getLang.got_it}</GenericTitleStyled>
                    </div>
                </div>
                
            }


        </ParentDivStyled>

    )
}