import React, { useEffect } from 'react';
import { Link, useNavigate } from "react-router-dom"
import { WelcomeBackground, LoginDivStyled } from '../../biz_styles/LoginStyle'
import { TitleStyled, DivCenterStyled, getBizColorNumber } from '../../biz_styles/bizStyle'
import { getLanguage, isLoggedIn } from '../../shared/redux/GlueStoreData';



function redirectIfNeeded() {
  const navigate = useNavigate();

  if (isLoggedIn()) {
      
      useEffect(() => {
          navigate('../main')
        }, []);
    }
}

function EMailNotFound() {
  const getLang = getLanguage();

  return (
    <WelcomeBackground>
      {redirectIfNeeded()}
      <LoginDivStyled>
        <DivCenterStyled>
          <div style={{height:80}}/>
          <TitleStyled>{getLang.it_seems_this_email}<br></br>{getLang.address_is_not_yet_listed}<br></br>{getLang.as_a_member}</TitleStyled>
          <br></br>
          <Link style={{ color: getBizColorNumber(3) }} to="../registerPhone">{getLang.sign_up}</Link>
        </DivCenterStyled>
      </LoginDivStyled>
    </WelcomeBackground>
  );
}

export default EMailNotFound;
