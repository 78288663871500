import React, { useState } from 'react'
import QRCodeComponent from '../../components/qr_code_component/QRCodeComponent';
import { IconStyledSvg, ImageStyledSvg } from '../../biz_styles/IconsStyles'
import { PStyled, DivHorizontalStyled, getBizColorNumber, SpanPrivacyPolicyMenuStyled, DivCenterHorizontalStyled, PBoldStyled } from '../../biz_styles/bizStyle';
import { DivColor1Styled, DivColor4Styled, DivImgItemProfileWrapper2Styled, Img20Styled } from '../../biz_styles/ProfileStyle';
import { useNavigate } from "react-router-dom"
import { useStoreProvider } from '../../shared/providers/StoreContext'
import { getLanguage } from '../../shared/redux/GlueStoreData';
import { isLayout122 } from '../../shared/static_helper/StaticHelper'
import { Badge } from "@mui/material";
import { DrawerComponent } from '../../components/drawer/DrawerComponent';
import EditProfileScreen from '../edit_profile/EditProfileScreen';
import MyNotifications from '../../screens/my_notifications/MyNotifications'
import './ProfilePopupScreen.css';
import { DivCenterStyled, DivDashedStyled, DivSideAlignStyled, RowDivStyled, SpacerStyled } from '../../biz_styles/ReusableStyles';
import RowDiv from '../../components/row_div/RowDiv';
import { Row } from 'react-bootstrap';
import { ViewColumn } from '@mui/icons-material';
import BarcodeComponent from '../../components/barcode_component/BarCodeComponent';

export default function ProfilePopupScreen({ dialogClosed }) {

    /* Logic functions - start */
    const navigate = useNavigate();

    const [showDialogEditProfileScreen, setShowDialogEditProfileScreen] = useState(false);
    const [showDialogMyNotifications, setShowDialogMyNotifications] = useState(false);


    const getLang = getLanguage();
    const bizObject = useStoreProvider().biz
    const customerObject = useStoreProvider().cust

    function layoutItemClick(id) {

        if (id === 1) {
            if (isLayout122()) {
                navigate('../main/profile');
                dialogClosed(id)
            } else {
                setShowDialogEditProfileScreen(true)
            }
        } else if (id === 2) {
            setShowDialogMyNotifications(true)
        }

    }

    function closeEditProfileDialog() {
        setShowDialogEditProfileScreen(false)
        dialogClosed(1)
    }

    function closeMyNotificationsDialog() {
        setShowDialogMyNotifications(false)
        dialogClosed(2)
    }

    function dialogEditProfileScreen() {

        return <DrawerComponent
            zIndexVar={1300} // Because of ProfilePopupScreen (this component)
            isDrawerOpen={showDialogEditProfileScreen}
            closeDrawer={closeMyNotificationsDialog}
            children={<EditProfileScreen isDialog={true} dialogClosed={closeEditProfileDialog} />} />
    }

    function dialogMyNotifications() {
        return (
            <DrawerComponent
                zIndexVar={1300} // Because of ProfilePopupScreen (this component)
                isDrawerOpen={showDialogMyNotifications}
                closeDrawer={closeMyNotificationsDialog}
                children={<MyNotifications closeDialog={closeMyNotificationsDialog} />} />
        )
    }

    function getNumBadgeNotifications() {

        return customerObject.badges['notifications'];
    }

    /* Logic functions - end */

    /* UI - start */
    /* UI - without UI functions - start */
    return (
        <div className='div-main-profile-popup-screen'>

            {dialogEditProfileScreen()}
            {dialogMyNotifications()}

            <div className='div-inner-main-profile-popup-screen' >

                <DivColor1Styled 
                    style={{
                        borderRadius: '10px',
                        paddingBottom: '5%',
                    }}>

                    {/* Bell */}
                    <div style={{position: 'absolute', left: '0', marginTop: '10px', marginLeft: '20px'}} onClick={() => {setShowDialogMyNotifications(true)}}>
                        <ImageStyledSvg cssName={'fill-color2'} name="ic_notifications_new.svg" width="36" height="36" />
                    </div>

                    {/* Badge */}
                    <div style={{position: 'absolute', left: '0', marginTop: '0px', marginLeft: '50px'}}>
                        <Badge badgeContent={getNumBadgeNotifications()} color="error" invisible={!(getNumBadgeNotifications() > 0)}></Badge>
                    </div>

                    {/* Icon, name & profile button */}
                    <Row style={{marginRight: '0px', marginTop: 8}}>
                        <Img20Styled
                            src={customerObject.cust_pic}
                            alt='' />
                        
                        <div>
                            <div>
                                <PBoldStyled
                                    style={{
                                        fontSize: '18px',
                                        textAlign: 'right'
                                    }}>
                                    {customerObject.cust_first_name}
                                </PBoldStyled>
                            </div>
                            <SpanPrivacyPolicyMenuStyled onClick={() => { myProfileClicked() }}
                                style={{
                                    fontSize: '14px',
                                    textAlign: 'right'
                                }}>
                                {getLang.my_profile}
                            </SpanPrivacyPolicyMenuStyled>
                        </div>

                    </Row>

                    

                    <SpacerStyled height={'38'} />

                    <DivDashedStyled backgroundColor={getBizColorNumber(4)} style={{marginLeft: '6px', marginRight: '6px'}}>

                        <DivCenterHorizontalStyled>

                            <DivCenterStyled>

                                <DivCenterHorizontalStyled>
                                <QRCodeComponent size={100} value={customerObject.cust_barcode ? customerObject.cust_barcode : customerObject.cust_unique_id} />
                                </DivCenterHorizontalStyled>
                                {customerObject.cust_barcode ? 
                                <DivCenterHorizontalStyled>
                                    <BarcodeComponent height={70} value={customerObject.cust_barcode} displayValue={false} />
                                </DivCenterHorizontalStyled>
                                : null}

                            </DivCenterStyled>

                        </DivCenterHorizontalStyled>

                    </DivDashedStyled>

                </DivColor1Styled>

            </div>

        </div>
    );
    /* UI - without UI functions - end */

    /* UI - with UI functions - start */
    function divImageWrapper() {
        return <DivImgItemProfileWrapper2Styled className='div-img-wrapper-profile-popup-screen'>

            <Img20Styled
                src={customerObject.cust_pic}
                alt='' />

        </DivImgItemProfileWrapper2Styled>;
    }

    function listLayout() {

        return <div style={{ marginLeft: '5%' }}>

            {listLayoutItems(1,
                'User.svg',
                bizObject.biz_mainmenu_view_type === '122' ?
                    getLang.my_account :
                    getLang.my_profile,
                0)}

            {listLayoutItems(2,
                'BellSimpleRinging.svg',
                getLang.notifications,
                getNumBadgeNotifications())}

            {bizObject.show_cart === '1' ?
                listLayoutItems(3,
                    'StarDuotune.svg',
                    getLang.shopping_cart,
                    0) :
                <div />}

        </div>;
    }

    function myProfileClicked() {
        if (isLayout122()) {
            navigate('../main/profile');
            dialogClosed(1)
        } else {
            setShowDialogEditProfileScreen(true)
        }
    }

    function listLayoutItems(id, imgName, label, badgeCount) {

        return <DivHorizontalStyled
            style={{
                marginTop: id === 1 ?
                    '0px' :
                    '20%'
            }}
            onClick={() => { layoutItemClick(id) }}>

            <Badge badgeContent={badgeCount} color="error" invisible={!(badgeCount > 0)}>
                <IconStyledSvg name={imgName} cssName='fill-color2' size={30} />
            </Badge>

            <PStyled
                style={{ marginLeft: '20px' }}>
                {label}
            </PStyled>

        </DivHorizontalStyled>
    }
    /* UI - with UI functions - end */
    /* UI - end */
}