import React, { useState, useEffect } from 'react';
import { BottomNavigation, BottomNavigationAction } from "@mui/material";
import ProfilePopupScreen from '../../../screens/profile_popup/ProfilePopupScreen';
import DialogTransitionComponent from '../../dialog_components/dialog_transition_component/DialogTransitionComponent';
import { DrawerComponent } from '../../drawer/DrawerComponent';
import MyNotifications from '../../../screens/my_notifications/MyNotifications'
import { useNavigate, useLocation } from "react-router-dom";
import { getBizColorNumber } from '../../../biz_styles/bizStyle';
import { useStoreProvider } from '../../../shared/providers/StoreContext'
import { IconStyledSvg } from '../../../biz_styles/IconsStyles';
import { Padding } from '@mui/icons-material';
import { getLanguage } from '../../../shared/redux/GlueStoreData';

function BottomNav() {

    const location = useLocation();
    const navigate = useNavigate();
    const [value, setValue] = useState(0)
    const [isPopupOpen, setIsPopupOpen] = useState(false)
    const bizObject = useStoreProvider().biz
    const getLang = getLanguage();

    const styles = {
        width: '100%',
        height: '70px',
        position: 'fixed',
        maxWidth: '550px',
        bottom: 0,
        background: getBizColorNumber(4),
        '& .MuiBottomNavigationAction-label': {
            fontSize: '0.70rem',
          },
    
        '& .Mui-selected': {
            '& .MuiBottomNavigationAction-label': {
              fontSize: '0.70rem',
              color: getBizColorNumber(3)
            }
        },
        "& .MuiBottomNavigationAction-root, .Mui-selected svg path": {
            fill: getBizColorNumber(3),
        },
        "& .MuiBottomNavigationAction-root, svg path": {
            fill: getBizColorNumber(2),
            color: getBizColorNumber(2),
        },
        "& .MuiButtonBase-root.MuiBottomNavigationAction-root":{
            padding:0,
        }
        
    }

    useEffect(() => {

        if (isPopupOpen || location.pathname == '/main/profile') {
            setValue(2)
            return
        } else {
            for (var i = 0; i < bizObject.biz_navigation.length; i++) {

                if ('..' + location.pathname == '../main') {
                    setValue(0)
                    return
                } else if ('..' + location.pathname == bizObject.biz_navigation[i].web_member_path) {
                    setValue(Number(bizObject.biz_navigation[i].nav_index) + 1)
                    return
                }
            }

        }

    })

    const handleChange = (event, newValue) => {

        setValue(newValue)

        if (newValue === 2) {
            setIsPopupOpen(true)
        }
        else if (newValue == 0) {
            navigate('../main')
        } else {
            navigate(bizObject.biz_navigation[newValue - 1].web_member_path)
        }
    }

    return (
        <div>
            <BottomNavigation
                showLabels
                sx={styles}
                value={value}
                onChange={(event, newValue) => handleChange(event, newValue)}>

                <BottomNavigationAction label={getLang.home} icon={<IconStyledSvg name='home.svg' size='30' />} />

                {bizObject.biz_navigation
                    .map(
                        (item) =>
                            <BottomNavigationAction key={item.nav_index} icon={<IconStyledSvg name={item.nav_icon} size='30' />} label={item.nav_label}  />
                    )}

            </BottomNavigation>

            {dialogScreen()}

        </div>
    )

    function dialogScreen() {

        return <DialogTransitionComponent
            open={isPopupOpen}
            dialogClosed={() => setIsPopupOpen(false)}
            children={<ProfilePopupScreen dialogClosed={(id) => {
                setIsPopupOpen(false)
            }} />} />
    }


}


export default BottomNav;