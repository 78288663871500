import React, { Component } from "react";
import "./PerkCell.css";
import {
  getBizColorNumber,
  DivHorizontalStyled,
  DivCenterStyled,
} from "../../../../biz_styles/bizStyle";
import { IconStyledSvg } from "../../../../biz_styles/IconsStyles";
import IconTint from "react-icon-tint";

export default function PerkCell(props) {
  return (
    <div className="membership-cell-holder">
      {props.type === "benefit_discount" ||
        props.type === "benefit_accelerate" ||
        props.type === "benefit_reward" ||
        props.type === "benefit_product" ||
        props.type === "benefit_service" ||
        props.type === "benefit_other" ||
        props.type === "benefit_text"      
        ? (
        <div className="membership_cell">
          <div className="perk-cell-icon-container border-color2">

          <IconStyledSvg name={props.type + '.svg'} cssName='fill-color2' size='24' /> 
          </div>
          <div>
          <span
            style={{
              color: getBizColorNumber(2),
              marginLeft: 10,
              marginTop: 3,
            }}
          >
            {props.title}
          </span>
          </div>
          <div>
            <span
              style={{
                color: getBizColorNumber(2),
                marginLeft: 10,
                marginTop: 3,
              }}
            >
              {props.subtitle}
            </span>
          </div>
        </div>
      ) : (
        ""
      )}
    </div>
  );
}
