import React, { useState } from 'react';
import { getLanguage } from '../../../../shared/redux/GlueStoreData';
import ClassicRadioComponent from '../../../../components/radio_components/ClassicRadioComponent';
import './GenderRadioFormComponent.css';

export default function GenderRadioFormComponent({ value, onChange }) {

    /* Logic functions - start */
    const getLang = getLanguage();

    const [valueVar, setValueVar] = useState(value);

    function handleOnChange(event) {

        var eventValue = event.target.value;

        setValueVar(eventValue);

        onChange(eventValue)
    }
    /* Logic functions - end */

    /* UI - start */
    /* UI - without UI functions - start */
    return (
        <div
            className='div-gender-radio-form'
            onChange={(e) => handleOnChange(e)}>

            <ClassicRadioComponent
                value='1'
                name='gender'
                valueStr={getLang.male}
                defaultChecked={valueVar === 1} />

            <div style={{ width: '2%' }} />

            <ClassicRadioComponent
                value='2'
                name='gender'
                valueStr={getLang.female}
                defaultChecked={valueVar === 2} />

        </div>
    )
    /* UI - without UI functions - end */
    /* UI - end */
}