import React, {useEffect, useLayoutEffect, useState, useRef } from 'react';
import useDraggableScroll from "use-draggable-scroll";
import { HorizontalScrollDivStyled, HorizontalCellDivStyled, SkeletonStyled } from '../../biz_styles/ReusableStyles';
import { getBizColorNumber, PStyled } from '../../biz_styles/bizStyle'
import { getLanguage } from '../../shared/redux/GlueStoreData';
import FadeIn from 'react-fade-in/lib/FadeIn';
import { IconStyledSvg } from '../../biz_styles/IconsStyles';
import { EmptyStateContainerStyled } from '../../biz_styles/AllPagesStyle';

export default function HorizontalScroller({ cellWidth, scrollerHeight, dataIsready, data, cellClass, showEmptyState, sidePadding }) {

    const [dummyData, setDummyData] = useState( // eslint-disable-line
        [
            { id: 1, title: "-", subtitle: "", image: "" },
            { id: 2, title: "-", subtitle: "", image: "" },
            { id: 3, title: "-", subtitle: "", image: "" }
        ])

    const ref = useRef(null);
    
    const [numbers, setNumbers] = useState([]);

    const [width, setWidth] = useState(0);
    const [height, setHeight] = useState(0);
  
    useLayoutEffect(() => {
      setWidth(ref.current.clientWidth);
      setHeight(ref.current.clientHeight);
    }, [numbers]);
  
    useEffect(() => {
      function handleWindowResize() {
        setWidth(ref.current.clientWidth);
        setHeight(ref.current.clientHeight);
      }
  
      window.addEventListener('resize', handleWindowResize);
  
      return () => {
        window.removeEventListener('resize', handleWindowResize);
      };
    }, []);

    const { onMouseDown } = useDraggableScroll(ref, { direction: 'horizontal' });

    //props:
    //cellWidth
    //scrollerHeight
    //dataIsready
    //data
    //dummyData
    //cellClass

    function skeletonCell() {
        return <SkeletonStyled className='horizontal-skeleton' animation="wave" variant="rectangular" width={cellWidth} height={scrollerHeight} style={{ backgroundColor: getBizColorNumber(4), borderRadius: 5}}/>
    }

    return (

        <HorizontalScrollDivStyled height={scrollerHeight} sidePadding={ sidePadding != null ? sidePadding : (width/2) - (cellWidth/2)}
            ref={ref}
            onMouseDown={onMouseDown}
        >

            {showEmptyState ?
                
                    <EmptyStateContainerStyled>
                        <FadeIn>
                             <IconStyledSvg style={{ marginLeft: 'auto', marginRight: 'auto', marginBottom: '10px' }} name="SmileyMeh.svg" cssName={'fill-color2'} size='60' />
                             <PStyled style={{position:'absolute', display: 'table-cell', verticalAlign: 'middle', width: '100%', textAlign: 'center' }}>{getLanguage().nothing_yet}</PStyled>
                        </FadeIn>
                    </EmptyStateContainerStyled>
                :
                <HorizontalCellDivStyled cellWidth={dataIsready ? (data.length * (cellWidth + 10))  : dummyData.length * (cellWidth + 10)}>

                    {!dataIsready ?
                        <React.Fragment>
                            {skeletonCell()}
                            {skeletonCell()}
                            {skeletonCell()}
                        </React.Fragment>
                        :
                        data.map( cellClass )}

                </HorizontalCellDivStyled>
            }

        </HorizontalScrollDivStyled>
    );
}