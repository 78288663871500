import React, { useEffect } from 'react';
import { TitleStyled, DivCenterStyled } from '../../biz_styles/bizStyle'
import { NextButtonStyled, WelcomeBackground, LoginDivStyled } from '../../biz_styles/LoginStyle'
import { useNavigate } from "react-router-dom"
import { getSvgUrlForFileName } from '../../shared/static_helper/StaticHelper';
import LoginHeader from '../login/LoginFormHeader';
import { getLanguage, isLoggedIn } from '../../shared/redux/GlueStoreData';


function redirectIfNeeded() {
  const navigate = useNavigate();
  if (isLoggedIn()) {
      
      useEffect(() => {
          navigate('../main')
        }, []);
    }
}

function EmailVerified() {
  const navigate = useNavigate()
  const getLang = getLanguage();


  return (
    <WelcomeBackground>
      {redirectIfNeeded()}
      <LoginDivStyled>
        <DivCenterStyled style={{padding: 40}}>
          <div style={{ background: '#50dc50', borderRadius: '50%', width: '10%', marginLeft: 'auto', marginRight: 'auto' }}>
            <img src={getSvgUrlForFileName('done.svg')}></img>
          </div>
          <LoginHeader
            title={getLang.email_verified_title}
            subtitle=""
          />
          <NextButtonStyled onClick={() => navigate("../RegisterAfterVerification")}>{getLang.next}</NextButtonStyled>
        </DivCenterStyled>
      </LoginDivStyled>
    </WelcomeBackground>
  );
}

export default EmailVerified;
