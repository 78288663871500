import React, { useEffect } from "react";
import RouterProvider from './RouterProvider';
import { Notifications } from 'react-push-notification';
import { AppStyled } from "./biz_styles/bizStyle";
import './App.css'
import StoreContext from './shared/providers/StoreContext'
import { checkIfNullUndefined } from './shared/static_helper/StaticHelper'
import { getBiz } from './shared/redux/GlueStoreData'

export const appMaxWidth = '550px'



function App() {

  // Use it for update fav icon and tab name dynamically.
  // useEffect(() => {
  //   let link = document.querySelector("link[rel~='icon']");
  //   if (!link) {
  //     link = document.createElement('link');
  //     link.rel = 'icon';
  //     document.getElementsByTagName('head')[0].appendChild(link);
  //   }
  //   if (!checkIfNullUndefined(getBiz())) {
  //     document.title = getBiz().biz_short_name
  //     link.href = getBiz().biz_logo;
  //   }
    
  // }, []);

  return (
    <StoreContext>
      <AppStyled>
        <Notifications />
        <RouterProvider />
      </AppStyled>
    </StoreContext>
  );
}



export default App;
