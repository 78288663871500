
import React  from 'react';
import KeyValueCell from '../../personal_data/cells/personal_orders_cell/KeyValueCell';
import { SpacerStyled } from '../../../biz_styles/ReusableStyles';
import { getBizColorNumber } from '../../../biz_styles/bizStyle'
import { OrderPersonalContainerView,
        OrderTitle,
        PStyled,
        TextAreaStyled,
        OrderImgDiv,
        OrderImg,
        QtyStyled,
        PriceStyled,
        ItemDetailsDiv,
        OrderPersonalCellHolder,
        HrStyled }
        from '../../../biz_styles/OrderStyle';
import { getBiz, getLanguage } from '../../../shared/redux/GlueStoreData';

export default function OrderInfo({currentObj}) {

    const getLang = getLanguage();
    const biz = getBiz();

    function getPeymentMethod(){
        var paymentMethod=""
        if(currentObj.cto_amount == 0 || currentObj.cto_amount == ""){
            paymentMethod = getLang.na
        }else{
            if(currentObj.tr_paymentMethodStatus == 1){
                paymentMethod = getLang.paid
            }else{
                paymentMethod = getLang.not_paid
            }

        }
        return paymentMethod
    }

    function showValueWithCurrency(value) {
        if(biz.biz_language == 'he'){
            if(value < 0){
                value = -1 * value;
                return '-' + biz.ess_currency_symbol + parseFloat(value).toFixed(2);    
            }
            return biz.ess_currency_symbol + parseFloat(value).toFixed(2);
        }
        else{
            return parseFloat(value).toFixed(2) + ' ' + biz.ess_currency;
        }
        
    }

    function cleanOrderNote(note){
        return note.replace('/n','<br/>');
    }

    return(
        <OrderPersonalCellHolder height="auto">
            <OrderPersonalContainerView>
                <div> {/*payment info section*/}
                    <OrderTitle>{getLang.payment_info_title}</OrderTitle> 
                    <SpacerStyled height={'15'} />
                    <KeyValueCell keyText={getLang.order_date} valueText={currentObj.cto_order_tyme} />
                    <SpacerStyled height={'15'} />
                    <KeyValueCell keyText={getLang.order_id} valueText={currentObj.cto_long_order_id} />
                    <SpacerStyled height={'15'} />
                    <KeyValueCell keyText={getLang.order_due_date} valueText={currentObj.cto_due_date } />
                    <SpacerStyled height={'15'} />
                    <KeyValueCell keyText={getLang.payment_method} valueText={currentObj.tr_paymentMethodType} />
                    <SpacerStyled height={'15'} />
                    <KeyValueCell keyText={getLang.payment_status} valueText={getPeymentMethod()} />
                    <SpacerStyled height={'15'} />
                    <HrStyled/>
                    <PStyled>{getLang.comments}</PStyled>
                    <TextAreaStyled value={cleanOrderNote(currentObj.cto_note)} disabled={true}></TextAreaStyled>
                    <SpacerStyled height={'15'} />
                    <HrStyled/>
                    <SpacerStyled height={'15'} />
                </div>
                <div> {/*Order Summary section*/}
                    <OrderTitle>{getLang.order_summary}</OrderTitle>
                    {currentObj.items.map((item) =>
                        <div key={item.tri_id}>
                            {(item.tri_type == 4 || item.tri_type == 5) ? null :
                                <OrderImgDiv>
                                    <OrderImg src={item.md_icon}></OrderImg>
                                </OrderImgDiv>
                            }
                            <ItemDetailsDiv className='order-summary-row' width={(item.tri_type == 4 || item.tri_type == 5) ? 100 : 65}>
                                <p style = {{color: getBizColorNumber(2)}}>{item.tri_name.length > 20 ? item.tri_name.substring(0, 20) + "..." : item.tri_name}</p>
                                <QtyStyled className='order-summary-row-qty'>{getLang.qty + ' ' + item.tri_quantity}</QtyStyled>
                                <PriceStyled className='order-summary-row-price'>{getLang.price + ' ' + showValueWithCurrency(item.tri_price)}</PriceStyled>
                            </ItemDetailsDiv>
                        </div>
                    )}
                    <SpacerStyled height={'100'} />
                    <HrStyled/>
                </div>
                <div> {/*Order Summary section*/}
                    <OrderTitle>{getLang.cart_discount}</OrderTitle>
                    <SpacerStyled height={'15'} />
                    <KeyValueCell keyText={getLang.subtotal} valueText={showValueWithCurrency(currentObj.cto_without_vat)} />
                    <SpacerStyled height={'15'} />
                    <KeyValueCell keyText={getLang.cart_discount} valueText={currentObj.cto_benefit_value === "" ?
                     showValueWithCurrency(0) : showValueWithCurrency(-1 * currentObj.cto_benefit_value)}/>
                    <SpacerStyled height={'15'} />
                    <KeyValueCell keyText={getLang.membership_discount} valueText={showValueWithCurrency(-1 * currentObj.cto_membership_discount)}/>
                    <SpacerStyled height={'15'} />
                    <KeyValueCell keyText={getLang.shipping} valueText={showValueWithCurrency(currentObj.cto_sipping)} />
                    <SpacerStyled height={'15'} />
                    <KeyValueCell keyText={getLang.tax} valueText={showValueWithCurrency(currentObj.cto_vat)}/>
                    <SpacerStyled height={'25'} />
                    <KeyValueCell keyText={getLang.total} valueText={showValueWithCurrency(currentObj.cto_amount)}/>
                    <SpacerStyled height={'25'} />
                </div>

            </OrderPersonalContainerView>
         </OrderPersonalCellHolder>
    )

    };