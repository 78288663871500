import React from "react";
import { useNavigate } from "react-router-dom"
import {
  SubsDivCardStyled,
  SubsImageStyled,
  SubsDivTopImageStyled,
  CardTopImageStyled,
  SubsLabelCardStyled,
  SubsDivTitlecStyled,
  SubsDivPriceStyled,
  SubsDivDescStyled,
  SubsPriceText,
  SubsEntriesText,
} from "../../../biz_styles/SubscriptionsStyle.jsx";

import {
  MultiDivPriceStyled,
  MultiPriceText,
  MultiEntriesText,
} from "../../../biz_styles/MultipassesStyle";

import { format } from "date-fns";
import { getLanguage } from '../../../shared/redux/GlueStoreData';
import { ContainerCardStyled, DivCustomYStyled, DivTitlecStyled, DivTopImageStyled, SizeableFontPStyled } from "../../../biz_styles/IncentivesStyle";
import { IconStyledSvg, ImageStyledSvg } from "../../../biz_styles/IconsStyles";
import { DivCenterStyled } from "../../../biz_styles/bizStyle.jsx";


function CouponsCardItemCell({ obj }) {

  const navigate = useNavigate();
  const getLang = getLanguage();


  //handle date(valid)
  const formattedDate =(startDate, endDate, period,periodUnit,periodNumber)=>{
    if(period==="unlimited"){
      return getLang.valid + ": " + getLang.unlimited_time 
    }
    else if(period==="limited"){
      if(periodNumber > 1){
        return getLang.valid + ": " +  periodNumber +" " + periodUnit + "s"
      }
      return getLang.valid + ": " +  periodNumber +" " + periodUnit 
    }
    else{
      var formatStartDate = ""
      var formatEndtDate = ""

      if(endDate === null && startDate === null){
        startDate = new Date(obj.md_date);
        formatStartDate = format(startDate, 'MMM dd,yyyy');
        return getLang.valid_from + ": " + formatStartDate;
      }
      else if (endDate === null){
        startDate = new Date(startDate);
        formatStartDate = format(startDate, 'MMM dd,yyyy');
        return getLang.valid_from + ": " + formatStartDate;
      }
      else if(startDate === null){
        endDate = new Date(endDate);
        formatEndtDate = format(endDate, 'MMM dd,yyyy');
        return getLang.valid_until + ": " + formatEndtDate;
      }
        startDate = new Date(startDate);
        endDate = new Date(endDate);
        formatStartDate = format(startDate, 'MMM dd,yyyy');
        formatEndtDate = format(endDate, 'MMM dd,yyyy');
        return getLang.valid + ": " + formatStartDate + " - " + formatEndtDate;
      }
    }

  //handle billing_period
  const billingPeriod =(billingType, billingPeriodUnit)=>{
    if(billingType === "auto renew"){
      return getLang.one_time
    }
    if(billingPeriodUnit === "month"){
      return getLang.month
    }
    return getLang.year
  }

  //handle limitations
  const Limitation =(usageRuleType, usageRulePeriodUnit,usageRuleCapacity)=>{
    if(usageRuleType === "unlimited"){
      return getLang.limitation + ": " + getLang.unlimited
    }
    if(usageRuleCapacity === "1"){
      return getLang.limitation + ": " + usageRuleCapacity + " " + getLang.entry_a + " " + usageRulePeriodUnit
    }
    else if(Number(usageRuleCapacity) > 1){
      return getLang.limitation + ": " + usageRuleCapacity + " " + getLang.uses_at + " " + usageRulePeriodUnit
    }
    }

  function openInfoScreen() {

    const fromPersonalZone = false;
    const currentObj = obj;

    navigate(`../pages/subscriptionMultipassInfoScreen/${fromPersonalZone}/${parseInt(currentObj.md_level_no)}/${currentObj.md_mod_id}/${currentObj.md_row_id}`,
      {
        state: {
          fromPersonalZone,
          currentObj
        }
      });
  }

  return (

    <ContainerCardStyled className="card-holder" width={250} height={465} onClick={() => openInfoScreen()}>

    <ImageStyledSvg cssName={'fill-color4'} name="incentive_card_custom.svg" width="250" height="465" />

    <DivTopImageStyled>
      <CardTopImageStyled src={"https://storage.googleapis.com/getglue/images/web_member/new_web_member/incentives/texture_" + obj.md_icon +".png"}></CardTopImageStyled>
      <MultiDivPriceStyled className="card-price-holder">
        <MultiPriceText>{obj.md_price + " " + obj.currency}</MultiPriceText>
        <SubsEntriesText>{billingPeriod(obj.md_billing_type,obj.md_billing_period_unit)}</SubsEntriesText>
      </MultiDivPriceStyled>
    </DivTopImageStyled>



    <DivTitlecStyled>
      <p>{obj.md_head > 31
             ? obj.md_head.substring(0, 31) + obj.md_head.substring(31, 200)
             : obj.md_head}</p>

      <SizeableFontPStyled size={13} opacity={0.5}>{formattedDate(obj.md_usage_period_start,obj.md_usage_period_end,obj.md_usage_period,obj.md_usage_period_unit,obj.md_usage_period_number)}</SizeableFontPStyled>
      {/* <SizeableFontPStyled size={13} opacity={0.5}>{Limitation(obj.md_usage_rule_type, obj.md_usage_rule_period_unit,obj.md_usage_rule_capacity)}</SizeableFontPStyled> */}

    </DivTitlecStyled>
    
    <DivCenterStyled className="card-status">
      <p>{obj.customer_card_stamp_no + "/" + obj.md_stamp_number + " " + getLang.punched}</p>
    </DivCenterStyled>


    <DivCustomYStyled className='row' top={335}>
        <IconStyledSvg cssName={'fill-color2'} name="book_new.svg" size='20' />
        <SizeableFontPStyled size={13} padding={6}>{getLang.description}</SizeableFontPStyled>       
        {/* <SubsEntriesText>{billingPeriod(obj.md_billing_type,obj.md_billing_period_unit)}</SubsEntriesText>          */}
    </DivCustomYStyled>

    <DivCustomYStyled top={370}>
        {/* <SizeableFontPStyled size={13} opacity={0.5}> {obj.md_desc.length > 100 ? obj.md_desc.substring(0,100) + "..." : obj.md_desc } </SizeableFontPStyled> */}
        <SizeableFontPStyled size={13} opacity={0.5}>{obj.md_desc.length > 100 ? obj.md_desc.substring(0,100) + "..." : obj.md_desc }</SizeableFontPStyled>
    </DivCustomYStyled>

</ContainerCardStyled>



    // <SubsDivCardStyled className="card-holder" onClick={() => openInfoScreen()}>
    //   <SubsImageStyled src="https://storage.googleapis.com/getglue/images/web_member/new_web_member/incentives/card_shadow.png" />

    //   <SubsDivTopImageStyled>
    //     <CardTopImageStyled src={"https://storage.googleapis.com/getglue/images/web_member/new_web_member/incentives/texture_" + obj.md_icon +".png"}></CardTopImageStyled>
    //   </SubsDivTopImageStyled>

    //   <SubsDivPriceStyled className="card-price-holder">
    //     <SubsPriceText>{obj.md_price + " " + obj.currency}</SubsPriceText>
    //     <SubsEntriesText>{billingPeriod(obj.md_billing_type,obj.md_billing_period_unit)}</SubsEntriesText>
    //   </SubsDivPriceStyled>

    //   <SubsDivTitlecStyled className="card-label">
    //     <p>
    //       {obj.md_head > 31
    //         ? obj.md_head.substring(0, 31) + obj.md_head.substring(31, 200)
    //         : obj.md_head}
    //     </p>
    //   </SubsDivTitlecStyled>

    //   <div className='card-bottom'>
    //     <div className='card-bottom-title'>          
    //     </div>
    //     <SubsDivDescStyled>
    //       <p>{formattedDate(obj.md_usage_period_start,obj.md_usage_period_end,obj.md_usage_period,obj.md_usage_period_unit,obj.md_usage_period_number)}</p>
    //       <p>{Limitation(obj.md_usage_rule_type, obj.md_usage_rule_period_unit,obj.md_usage_rule_capacity)}</p>
    //     </SubsDivDescStyled>
    //   </div>
    // </SubsDivCardStyled>
  );
}

export default CouponsCardItemCell;
