import styled from 'styled-components'
import { getBiz } from '../shared/redux/GlueStoreData'
import { createGlobalStyle } from "styled-components"
import { getInjectedBizData, isSandbox } from '../shared/static_helper/StaticHelper'
import Swal from 'sweetalert2'

export const getBizColorNumber = (number) => {

  var bizObject = getInjectedBizData();//getBiz();//getInjectedBizData(); //getBiz();

  switch (number) {
    case 1:
      return bizObject.biz_theme_color1;
    case 2:
      return bizObject.biz_theme_color2;
    case 3:
      return bizObject.biz_theme_color3;
    case 4:
      return bizObject.biz_theme_color4;
    case 5:
      return bizObject.biz_theme_color5;
    default:
      return number
  }
};

export const Toast = Swal.mixin({
  toast: false,
  // position: "top-end",
  // showConfirmButton: false,
  // timer: 3000,
  confirmButtonColor: getBizColorNumber(3),
  // didOpen: (toast) => {
  //   toast.onmouseenter = Swal.stopTimer;
  //   toast.onmouseleave = Swal.resumeTimer;
  // }
});

export const getLoginImage = () => {
  var bizObject = getBiz();
  return bizObject.welcome_background;
}

export const GlobalStyle = createGlobalStyle`
  .fill-color1 .icon-fill{
    fill:${() => getBizColorNumber(1)};
  }
  .fill-color1 .icon-fill-light{
    opacity:0.5;
    fill:${() => getBizColorNumber(1)};
  }

  .fill-color2 .icon-fill{
    fill:${() => getBizColorNumber(2)};
  }
  .fill-color2 .icon-fill-light{
    opacity:0.5;
    fill:${() => getBizColorNumber(2)};
  }

  .fill-color2 .st0{
    fill:${() => getBizColorNumber(2)};
  }
  

  .fill-color3 .icon-fill{
    fill:${() => getBizColorNumber(3)};
  }
  .fill-color3 .icon-fill-light{
    opacity:0.5;
    fill:${() => getBizColorNumber(3)};
  }

  .fill-color4 .icon-fill{
    fill:${() => getBizColorNumber(4)};
  }
  .fill-color4 .icon-fill-light{
    opacity:0.5;
    fill:${() => getBizColorNumber(4)};
  }

  .fill-color4 .card-bg{
    fill:${() => getBizColorNumber(4)};
  }

  .fill-color5 .icon-fill{
    fill:${() => getBizColorNumber(5)};
  }
  .fill-color5 .icon-fill-light{
    opacity:0.5;
    fill:${() => getBizColorNumber(5)};
  }

  .icon-stroke{
    stroke: ${() => getBizColorNumber(2)};

  }
  .icon-fill-back{
    fill:none;
  }

  .border-color1{
    border:1px solid ${() => getBizColorNumber(1)};
  }

  .border-color2{
    border:1px solid ${() => getBizColorNumber(2)};
  }
`

export const getBizBackgroundImage = () => {

  var bizObject = getBiz()
  var url = ''

  var suffix = "jpg"

  if (bizObject.biz_theme_live_background == 1) {
    suffix = "gif"
  }

  if (isSandbox()) {
    url = 'https://storage.googleapis.com/getglue_sandbox/templates/' + bizObject.biz_theme_name + '/new_background.' + suffix
  } else {
    url = 'https://storage.googleapis.com/getglue/templates/' + bizObject.biz_theme_name + '/new_background.' + suffix
  }

  return url
}

export const getMagicMenuImage = () => {

  var bizObject = getBiz()
  var url = ''

  var suffix = "jpg"

  if (bizObject.biz_theme_live_background == 1) {
    suffix = "gif"
  }

  if (isSandbox()) {
    url = 'https://storage.googleapis.com/getglue_sandbox/templates/' + bizObject.biz_theme_name + '/magicmenu_background.' + suffix
  } else {
    url = 'https://storage.googleapis.com/getglue/templates/' + bizObject.biz_theme_name + '/magicmenu_background.' + suffix
  }

  return url
}

export function setBodyColor() {
  document.body.style.backgroundColor = getBizColorNumber(1)
}

export const AppStyled = styled.div`
  height: 100vh;
  text-align: center;
  background-color: #fafafa;
  max-width: 550px;
  margin-left: auto;
  margin-right: auto;
  position: relative;
  overflow: hidden;`

export const MainStyled = styled.div`
  height: 100vh;
  position: relative;`

// Create a Title component that'll render an <h1> tag with some styles
export const TitleStyled = styled.h1`
    font-size: 1.5em;
    text-align: center;
    color: ${() => getBizColorNumber(2)};`

export const SubTitleStyled = styled.p`
    color: ${() => getBizColorNumber(2)};`

// Create a Wrapper component that'll render a <section> tag with some styles
export const WrapperStyled = styled.section`
    padding: 4em;
    background: papayawhip;`

// Create a Button component that'll render a <section> tag with some styles
export const ButtonStyled = styled.button`
    color: ${() => getBizColorNumber(2)};
    background-color: ${() => getBizColorNumber(3)};
    font-size: 15px;
    width: 200px;
    margin: 20px;
    display: block;
    padding: 0.25em 1em;
    border: 2px solid palevioletred;
    border-radius: 10px;`

// A new component based on Button, but with some override styles
export const ButtonFilled = styled(ButtonStyled)`
    color: green;
    border-color: tomato;`

export const DivCenterStyled = styled.div`
    text-align: center;
    padding: 10px 20px 10px 20px;`

export const SpanPrivacyPolicyStyled = styled.span`
    text-decoration-line: underline;
    color: ${() => getBizColorNumber(3)};
    cursor: pointer;`

export const SpanPrivacyPolicyMenuStyled = styled.h1`
    font-size: 16px;
    text-align: center;
    text-decoration-line: underline;
    color: ${() => getBizColorNumber(2)};
    cursor: pointer;`

export const SideMenuSeperatorStyled = styled.h1`
    font-size: 16px;
    text-align: center;
    color: ${() => getBizColorNumber(2)};
    `

export const PhoneLabelStyled = styled.a`
    text-decoration-line: underline;
    color: ${() => getBizColorNumber(3)};
    cursor: pointer;`

export const InputTextFieldFormStyled = styled.input`
    outline: 0;
    border-width: 0 0 2px;
    border-color: black;
    width: 100%;`

export const PStyled = styled.p`
    color: ${() => getBizColorNumber(2)};
    margin-top: ${(props) => props.marginTop};
    margin-bottom: ${(props) => props.marginBottom};
    float: ${(props) => props.float};
    white-space: pre-wrap;`

export const PBoldStyled = styled.p`
    color: ${() => getBizColorNumber(2)};
    margin-top: ${(props) => props.marginTop};
    margin-bottom: ${(props) => props.marginBottom};
    float: ${(props) => props.float};
    font-weight: bold;
    white-space: pre-wrap;`

export const POpacityStyled = styled(PStyled)`
    color: ${(props) => getBizColorNumber(2) + props.opacity};
    margin-bottom: ${(props) => props.marginBottom};`

export const P2Styled = styled(PStyled)`
    color: ${() => getBizColorNumber(3)};
    margin-bottom: ${(props) => props.marginBottom};`

export const P3Styled = styled(PStyled)`
    color: ${() => getBizColorNumber(1)};`

export const H5Styled = styled.h5`
    color: ${() => getBizColorNumber(2)};`

export const H3Styled = styled.h3`
    color: ${() => getBizColorNumber(2)};`

export const H32Styled = styled(H3Styled)`
    color: ${() => getBizColorNumber(3)};`

export const ButtonSimpleStyled = styled.button`
    height: 40px;
    color: ${() => getBizColorNumber(1)};
    border-color: ${() => getBizColorNumber(1)};
    background-color: ${() => getBizColorNumber(3)};
    text-transform: uppercase;`

export const DivHorizontalStyled = styled.div`
    display: flex;`

export const DivCenterHorizontalStyled = styled(DivHorizontalStyled)`
    justify-content: center;
    align-items: center;`

export const TypeSpanStyled = styled.span`
    color: ${() => getBizColorNumber(1)};
    background-color:  ${() => getBizColorNumber(3)};
    padding-top: 2px;
    padding-left: 10px;
    padding-right: 10px;
    text-align: center;
    height: 32px;
    font-size: 17px;
    position: absolute;`

export const CartTitleStyled = styled.p`
    color: black;
    background-color:  transparent;
    padding-left: ${(props) => typeof (props.leftPadding) !== 'undefined' ? props.leftPadding : 30}px;
    padding-top: 30px;
    padding-bottom: 20px;
    font-size: 22px;
    `
export const PColorStyled = styled(PStyled)`
    color: ${((props) => getBizColorNumber(props.color))};
    font-size: ${(props) => props.fontSize}px;
    margin-top: ${(props) => props.marginTop};
    margin-bottom: ${(props) => props.marginBottom};
    white-space: pre-wrap;`

    export const BacgroundGradientStyled = styled.div`
    position: absolute;
    margin-top: 30%;
    height:70px;
    width:100%;
    background:linear-gradient(transparent, rgb(95, 73, 71));
    background-color:orange;
    `
export const BacgroundColorViewStyled = styled.div`
    position: absolute;
    margin-top: calc(30% + 70px);
    width:100%;
    height:100%;
    background-color: rgb(95, 73, 71);
    `


