import React from "react";
import { useNavigate } from "react-router-dom"
import {
  DivCardStyled,
  DivLockedStyled,
  CardImageStyled,
  DivTopImageStyled,
  CardTopImageStyled,
  LabelCardStyled,
  DivTitlecStyled,
  DivPriceStyled,
  DivCenterStyled,
  DivDescStyled,
  DescTitleStyled,
  SvgIconStyle,
  ContainerCardStyled,
  SizeableFontPStyled,
  DivCustomYStyled
} from "../../../biz_styles/IncentivesStyle";
import { IconStyledSvg,ImageStyledSvg } from "../../../biz_styles/IconsStyles";
import { getLanguage } from "../../../shared/redux/GlueStoreData";



function CouponsCardItemCell({ obj, price }) {

  const navigate = useNavigate();
  const getLang = getLanguage();


  var couponClaimed = "";
  var couponDisabled = false;
  if (obj.cuponClaimed < 0) {
    couponClaimed = getLang.coupon_claimed_unlimited;
  } else if (obj.cuponClaimed === 0) {
    couponDisabled = true
    couponClaimed = obj.cuponClaimed + " " + getLang.left;
  } else {
    couponClaimed = obj.cuponClaimed + " " + getLang.left;
  }

  function openInfoScreen() {

    const fromPersonalZone = false;
    const currentObj = obj;

    navigate(`../pages/infoScreen/${fromPersonalZone}/${parseInt(currentObj.md_level_no)}/${currentObj.md_mod_id}/${currentObj.md_row_id}`,
      {
        state: {
          fromPersonalZone,
          currentObj
        }
      });
  }
  
  return (


    <ContainerCardStyled className="card-holder" width={250} height={465} onClick={() => openInfoScreen()}>
       {couponDisabled ? (
         <DivLockedStyled class="card-overlay">
           <p>{getLang.limit_reached}</p>
         </DivLockedStyled>
       ) : (
         ""
       )}
       
    <ImageStyledSvg cssName={'fill-color4'} name="incentive_card_custom.svg" width="250" height="465" />

    <DivTopImageStyled>
      <CardTopImageStyled src={obj.md_icon}></CardTopImageStyled>
      <LabelCardStyled className="card-label">{obj.cuponType}</LabelCardStyled>
    </DivTopImageStyled>

    <DivTitlecStyled>
      <p>
          {obj.md_head > 31
            ? obj.md_head.substring(0, 31) + obj.md_head.substring(31, 200)
            : obj.md_head}
      </p>
    </DivTitlecStyled>
    
    <DivCenterStyled className="card-status">
      <p>{price}</p>
    </DivCenterStyled>


    <DivCustomYStyled className='row' top={335}>
        <IconStyledSvg cssName={'fill-color2'} name="book_new.svg" size='20' />
        <SizeableFontPStyled size={13} padding={6}>{getLang.description}</SizeableFontPStyled>                
    </DivCustomYStyled>

    <DivCustomYStyled top={370}>
        <SizeableFontPStyled size={13} opacity={0.5}> {obj.md_desc.length > 80
              ? obj.md_desc.substring(0, 80) + "..."
              : obj.md_desc}{" "} </SizeableFontPStyled>
    </DivCustomYStyled>

    </ContainerCardStyled>
  );
}

export default CouponsCardItemCell;
