import React, { Component } from 'react';
import './PersonalOrdersCell.css';
import { getBizColorNumber } from '../../../../biz_styles/bizStyle';
import KeyValueCell from './KeyValueCell';
import { SpacerStyled, PersonalCellHolder, PersonalContainerView } from '../../../../biz_styles/ReusableStyles';
import { getBiz,getLanguage } from '../../../../shared/redux/GlueStoreData';

const PersonalOrdersCell = (props) => {
    const getLang = getLanguage();
    const biz = getBiz();

    function showValueWithCurrency(value) {
        if(biz.biz_language == 'he'){
            return biz.ess_currency_symbol + parseFloat(value).toFixed(2);
        }
        else{
            return parseFloat(value).toFixed(2) + ' ' + biz.ess_currency;
        }
        
    }

    return (
        <PersonalCellHolder height="240">
            <PersonalContainerView>
                <div className="personal-orders-details">
                    <KeyValueCell keyText={getLang.order_date} valueText={props.object.cto_order_tyme} />
                    <SpacerStyled height={'15'} />
                    <KeyValueCell keyText={getLang.order_id} valueText={props.object.cto_long_order_id} />
                    <SpacerStyled height={'15'} />
                    <KeyValueCell keyText={getLang.total} valueText={showValueWithCurrency(props.object.cto_amount)} />
                    <SpacerStyled height={'15'} />
                    <KeyValueCell keyText={getLang.status} valueText={props.object.status_name} />
                </div>
                <div className='personal-orders-link float-end' onClick={props.onClick}>
                    <p style={{ color: getBizColorNumber(3) }}>{getLang.details}</p>
                </div>
            </PersonalContainerView>
        </PersonalCellHolder>
    );
};

export default PersonalOrdersCell;