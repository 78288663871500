import React, { Component } from 'react';
import "./sectionTextCell.css"
import { getBizColorNumber } from '../../../../biz_styles/bizStyle';


class SectionTextCell extends Component {
    state = {  } 
    render() { 
        return (
            <div className='section-text-div'>
            <span className='section-text-label' style={{color: getBizColorNumber(2)}}>{this.props.title}</span>
            </div>
        );
    }
}
 
export default SectionTextCell;