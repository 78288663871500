import React from "react";
import { TextField } from "@mui/material";
import { DivHorizontalStyled, PStyled, P2Styled } from "../../biz_styles/bizStyle";
import { DivMainStyled } from "../../biz_styles/ProfileStyle";
import { H5ConfirmationMeesageStyled } from "../../biz_styles/ConfirmationMessageStyle";
import { getLanguage } from "../../shared/redux/GlueStoreData";

export default function CancelSubscriptionComponent({ dialogClosed }) {

    const getLang = getLanguage();

    var feedback = '';

    function dialogClosedFunc(isOk) {

        var feedbackVar = '';

        if (isOk) {

            feedbackVar = feedback;
        }

        dialogClosed(isOk, feedbackVar);
    }

    return (
        <DivMainStyled>

            <H5ConfirmationMeesageStyled>{getLang.cancellation_notes}</H5ConfirmationMeesageStyled>

            <PStyled>{getLang.we_would_appreciate_your_feedback}</PStyled>

            <TextField fullWidth multiline rows='3' id="standard-basic" variant="outlined" onChange={(e) => feedback = e.target.value} />

            <DivHorizontalStyled style={{ marginTop: '10px' }}>

                <P2Styled
                    onClick={() => dialogClosedFunc(false)}>
                    {getLang.close}
                </P2Styled>

                <P2Styled
                    style={{ marginLeft: '10px' }}
                    onClick={() => dialogClosedFunc(true)}>
                    {getLang.cancel_subscription}
                </P2Styled>

            </DivHorizontalStyled>

        </DivMainStyled>
    )
}
