import React from "react";

const ProgressBar = (props) => {
    const { bgcolor, completed } = props;
  
    let containerStyles = {
      height: 12,
      width: '90%',
      backgroundColor: "#e0e0de",
      borderRadius: 50,
      margin:'5%',
      maxWidth:'100%'
    }
  
    const fillerStyles = {
      height: '100%',
      width: `${completed}%`,
      backgroundColor: bgcolor,
      borderRadius: 'inherit',
      textAlign: 'right',
      maxWidth:'100%'
    }
  
    // const labelStyles = {
    //   padding: 5,
    //   color: 'white',
    //   fontWeight: 'bold'
    // }
  
    return (
      <div style={containerStyles}>
        <div style={fillerStyles}>
          {/* <span style={labelStyles}>{`${completed}%`}</span> */}
        </div>
      </div>
    );
  };
  
  export default ProgressBar;
  
