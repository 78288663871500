import React, { useEffect, useRef, useState } from 'react'
import { LogoRoundedContainerStyled, StyledPhoneInput } from '../../biz_styles/ReusableStyles'
import { getBizColorNumber, getBizBackgroundImage, DivCenterStyled, Toast } from '../../biz_styles/bizStyle'
import { useStoreProvider } from '../../shared/providers/StoreContext'
import { deleteUserData, validateClubMemberByPhone } from '../../api/ApiRequests';
import { clearStoreData, getLanguage, isLoggedIn } from '../../shared/redux/GlueStoreData'
import { useNavigate } from "react-router-dom"
import { isValidPhoneNumber, AsYouType } from "libphonenumber-js";
import {
  NextButtonStyled,
  LoginDivStyled,
  DivColorStyled,
  WelcomeBackground,
  TextStyled,
  LinkTextStyled
} from "../../biz_styles/LoginStyle";
import LoginHeader from "../login/LoginFormHeader";
import ReCAPTCHA from "react-google-recaptcha";
import LoadingComponent from "../../components/loading_components/LoadingComponent";
import "../login/LoginScreen.css";
import Swal from 'sweetalert2';

function DeleteUserDataByPhoneScreen(){

  const [phoneNumber, setPhoneNumber] = useState("");
  const [isValid, setIsValid] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [isDisabled, setisDisabled] = useState(true);
  const captchaRef = useRef();

  const bizObject = useStoreProvider().biz
  const navigate = useNavigate()
  const getLang = getLanguage();

  const asYouType = new AsYouType();

  const dropDownStyle = {
    color: getBizColorNumber(2),
    backgroundColor: getBizColorNumber(4),
    borderRadius: "10px",
    borderColor: 'transparent'
  };

  function handleOnChange(value, data, event, formattedValue) {
    asYouType.input(formattedValue);
    if (formattedValue !== "") {
      setPhoneNumber(asYouType.getNumberValue());
      asYouType.reset();
    }
    setIsValid(isValidPhoneNumber(formattedValue));
    setisDisabled(!isValidPhoneNumber(formattedValue));
  }

  const onSubmitPhoneNumber = async () => {
    const token = await captchaRef.current.executeAsync();
    if (isValid) {
      setIsLoading(true);
      validateClubMember(token);
    } else {
      showError(getLang.error, getLang.invalid_phone_number);
      captchaRef.current.reset();
    }
  };

  async function validateClubMember(captchaToken) {
    const res = await validateClubMemberByPhone(phoneNumber, captchaToken);
    if (res !== undefined) {
      setIsLoading(false);
      navigate("../deleteUserDataSmsCode", { state: { phoneNumber } });
    } else {
      showError(
        getLang.sorry,
        getLang.phone_not_found
      );
      captchaRef.current.reset();
      setIsLoading(false);
    }
  }

  function showError(title, text) {
    Toast.fire({
      title: title,
      text: text,
      icon: "error",
      confirmButtonText: getLang.ok,
      confirmButtonColor: getBizColorNumber(3),
    });
  }

  return (
    <WelcomeBackground>
    <div className="parent-div">
      <LoadingComponent isLoading={isLoading} />

      <LoginDivStyled>
        <LoginHeader
          title={getLang.sad_to_say_goodbye}
          subtitle={getLang.to_delete_your_account_we_need_to_confirm_your_identity}
        />
        <DivCenterStyled>
          <DivColorStyled isValid={isValid}>
            <StyledPhoneInput
              name="phone"
              required={true}
              autoFocus={true}
              country={bizObject.biz_country_code.toLowerCase()}
              enableTerritories={true}
              countryCodeEditable={false}
              autocompleteSearch={true}
              enableSearch={false}
              disableSearchIcon={true}
              inputStyle={{
                fontSize: '18px !important',
                background: getBizColorNumber(4),
                borderRadius: "10px",
                borderColor: 'transparent',
                height: "57px !important",
                color: getBizColorNumber(2)
              }}
              containerStyle={{
                height: "57px",
                borderRadius: "10px",
              }}
              // buttonStyle={buttonStyle}
              dropdownStyle={dropDownStyle}
              value={phoneNumber}
              onChange={handleOnChange}
            />
          </DivColorStyled>
        </DivCenterStyled>
        <TextStyled>{getLang.confirm_your_number}</TextStyled>
        <DivCenterStyled>
          <ReCAPTCHA
            sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
            ref={captchaRef}
            size="invisible"
          />
          <NextButtonStyled
            onClick={onSubmitPhoneNumber}
            disabled={isDisabled}
          >
          {getLang.next}
          </NextButtonStyled>
        </DivCenterStyled>
      </LoginDivStyled>
    </div>
  </WelcomeBackground>
  )

}

function showDeleteAlertMessage() {

  const getLang = getLanguage();

  Toast.fire({
    allowOutsideClick: false,
    title: getLang.sign_out,
    html: getLang.are_you_sure_you_wish_to_sign_out,
    confirmButtonText: getLang.yes,
    showCancelButton: true,
    cancelButtonText: getLang.cancel,
    reverseButtons: true,
    iconColor: getBizColorNumber(3),
    confirmButtonColor: getBizColorNumber(3),
  }).then(async (result) => {
    if (result.isConfirmed) {
      Toast.fire({
        title: getLang.please_wait,
        didOpen: () => {
          Swal.showLoading()
        }
      })

      deleteData()
    }
  })

async function deleteData() {
  const responseCode = await deleteUserData();

  if (responseCode === 1) {
    clearStoreData()
  }
}

}

export default DeleteUserDataByPhoneScreen;

