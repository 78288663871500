import React, { Component } from 'react';
import './GenericButtonCell.css';
import { getBizColorNumber } from '../../../../biz_styles/bizStyle';
import { IconStyledSvg } from '../../../../biz_styles/IconsStyles'

class GenericButtonCell extends Component {
    
    state = {
        title: "",
        image: ""
    } 
    
    render() { 

        return (

            <div className="generic-button-cell-holder" style={{backgroundColor: getBizColorNumber(4)}} onClick={this.props.action}>
                
                <div className='row'>

                    <div className='generic-button-image-container-view' style={{backgroundColor: getBizColorNumber(4)}}>
                    <IconStyledSvg name={this.props.image} cssName='fill-color3' size={32} />
                    </div>

                </div>
                
                    <div className='row'>
                        <span className='generic-button-title-label' style={{color: getBizColorNumber(2)}}>{this.props.title}</span>
                    </div>
            </div>
        );
    }
}
 
export default GenericButtonCell;