import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { getBizColorNumber } from '../../biz_styles/bizStyle';
import { IconStyledSvg } from '../../biz_styles/IconsStyles';
import { DivCenter2Styled, SpacerHorizontalStyled, SpacerStyled } from '../../biz_styles/ReusableStyles';
import { DrawerComponent } from '../../components/drawer/DrawerComponent';
import { inviteFriends } from '../../shared/static_helper/StaticHelper';
import { useStoreProvider } from '../../shared/providers/StoreContext'
import WebBrowserScreen from '../privacy_policy/WebBrowserScreen';
import './More.css'

export default function More() {

  /* Logic functions - start */
  const navigate = useNavigate();

  const bizObject = useStoreProvider().biz

  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [currentItemDataClicked, setCurrentItemDataClicked] = useState({});

  function handleItemClick(tabbarItem) {

    switch (tabbarItem.fu_id) {

      case '9':
      case '10':

        const obj = {
          mod_id: '35',
          level_no: '1',
          parent: '0',
          prod_id: tabbarItem.fu_info,
        }

        setCurrentItemDataClicked(obj);
        openDrawer();
        break;

      case '11':

        navigate(`../pages/customForms/${tabbarItem.fu_info}`);
        break;

      case '12':

        inviteFriends();
        break;

      default:

        break;
    }
  }

  function openDrawer() {

    setIsDrawerOpen(true);
  }

  function closeDrawer() {

    setIsDrawerOpen(false);
  }
  /* Logic functions - end */

  /* UI - start */
  /* UI - without UI functions - start */
  return (
    <div id='scroll-parent' style={{overflow: 'scroll', height: '100vh',padding: '0 3%' }}>

      <SpacerStyled height={'180'} />

      {listLayoutItems()}

      <DrawerComponent children={getCurrentDrawerChild()} isDrawerOpen={isDrawerOpen} closeDrawer={closeDrawer} />

    </div>
  )
  /* UI - without UI functions - end */

  /* UI - with UI functions - start */
  function listLayoutItems() {

    return bizObject.tabbar.filter(
      (pzArrayItem) =>
        pzArrayItem.fu_id === '1' ||
        pzArrayItem.fu_id === '2' ||
        pzArrayItem.fu_id === '4' ||
        pzArrayItem.fu_id === '9' ||
        pzArrayItem.fu_id === '10' ||
        pzArrayItem.fu_id === '11' ||
        pzArrayItem.fu_id === '12'
    ).map(
      (tabbarItem) =>

        <div key={tabbarItem.fu_id}>
          {layoutItem(tabbarItem)}
        </div>
    )
  }

  function layoutItem(tabbarItem) {

    return <div>

      <DivCenter2Styled
        onClick={() => handleItemClick(tabbarItem)}
        style={{ justifyContent: 'start' }}>

        <IconStyledSvg
          cssName={'fill-color2'}
          name={tabbarItem.fu_id + '.svg'}
          size='32' />

        <SpacerHorizontalStyled width='25' />

        {layoutItemLabel(tabbarItem)}

      </DivCenter2Styled>

      <SpacerStyled height='25' />

    </div>
  }

  function layoutItemLabel(tabbarItem) {

    if (tabbarItem.fu_id === '1') {

      return <a style={{ color: getBizColorNumber(2) }} href={`tel:${bizObject.biz_office_tele}`}>{tabbarItem.fu_label}</a>
    } else if (tabbarItem.fu_id === '2') {

      return <a style={{ color: getBizColorNumber(2) }} href={`sms:${bizObject.biz_office_tele}`}>{tabbarItem.fu_label}</a>
    } else if (tabbarItem.fu_id === '4') {

      return <a style={{ color: getBizColorNumber(2) }} href={`mailto:${bizObject.biz_email}?&subject=App Contact Us`}>{tabbarItem.fu_label}</a>
    } else {

      return <span style={{ color: getBizColorNumber(2) }}>{tabbarItem.fu_label}</span>
    }
  }

  function getCurrentDrawerChild() {

    return <WebBrowserScreen type='classic-url' currentObjParam={currentItemDataClicked} />
  }
  /* UI - with UI functions - end */
  /* UI - end */
}
