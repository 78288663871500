import { ReactSVG } from 'react-svg'
import { style } from 'glamor'
import { getBizColorNumber } from '../biz_styles/bizStyle'
import React from 'react';
import { getSvgUrlForFileName } from '../shared/static_helper/StaticHelper';
import { useStoreProvider } from '../shared/providers/StoreContext'

function getWaitingListSvgStyle() {
  const waitingListStyle = style({
    ' path': {
      fill: getBizColorNumber(2),
    }
  })

  return waitingListStyle
}

export function IconStyledSvg({ name, cssName, size, cssSecondName, color }) {

  return (
    <ReactSVG
      src={getSvgUrlForFileName(name)}
      loading={() => <div style={{ height: size, width: size }} />}
      useRequestCache={true}
      beforeInjection={(svg) => {
        if (color != undefined) {
          const [firstPathElement] = [...svg.querySelectorAll('path')]
          firstPathElement.setAttribute('fill', color)
        }
        svg.setAttribute('height', size)
        svg.setAttribute('width', size)
        svg.classList.add(cssName)
        if (cssSecondName != undefined) {
          svg.classList.add(cssSecondName)
        }
      }}
    />
  )
}

export function IconStyledSvgWaitingList({ name, cssName, size }) {
  return (
    <ReactSVG
      src={getSvgUrlForFileName(name)}
      beforeInjection={(svg) => {
        svg.setAttribute('height', size)
        svg.setAttribute('width', size)
      }}
      {...getWaitingListSvgStyle()} />
  )
}

export function ImageStyledSvg({ name, cssName, width, height, cssSecondName, color }) {

  return (
    <ReactSVG
      src={getSvgUrlForFileName(name)}
      loading={() => <div style={{ height: height, width: width }} />}
      useRequestCache={true}
      beforeInjection={(svg) => {
        if (color != undefined) {
          const [firstPathElement] = [...svg.querySelectorAll('path')]
          firstPathElement.setAttribute('fill', color)
        }
        svg.setAttribute('height', height)
        svg.setAttribute('width', width)
        svg.classList.add(cssName)
        if (cssSecondName != undefined) {
          svg.classList.add(cssSecondName)
        }
      }}
    />
  )
}
