import React from 'react';
import { PStyled, ButtonSimpleStyled } from '../../../../biz_styles/bizStyle';
import { IconStyledSvg } from '../../../../biz_styles/IconsStyles';
import { getLanguage } from '../../../../shared/redux/GlueStoreData';
import './ChangePhoneFormComponent.css';

export default function ChangePhoneFormComponent({ openDialog, isVerified }) {

    /* Logic functions - start */
    const getLang = getLanguage();

    function getVerifiedIcon() {

        return isVerified ? 'Notepad' : 'close';
    }

    function getVerifiedText() {

        return isVerified ? getLang.verified : getLang.unverified;
    }

    function getVerifiedColorText() {

        return isVerified ? '#4cc600' : '#ffe100';
    }

    function getVerifiedChange() {

        return isVerified ? getLang.change : getLang.action_validate_code;
    }
    /* Logic functions - end */

    /* UI - start */
    /* UI - without UI functions - start */
    return (
        <div className='div-phone-mobile-holder'>

            <div className='div-phone-mobile'>

                <IconStyledSvg
                    cssName={'fill-color3'}
                    name={getVerifiedIcon()}
                    size='21' />

                <PStyled
                    className='p-verified'
                    style={{ color: getVerifiedColorText() }}>
                    {getVerifiedText()}
                </PStyled>

                <ButtonSimpleStyled
                    className='button-change-phone-mobile'
                    onClick={openDialog}>
                    {getVerifiedChange()}
                </ButtonSimpleStyled>

            </div>

        </div>
    )
    /* UI - without UI functions - end */
    /* UI - end */
}