import React from 'react';
import "./ValueBar.css"

import { DrawerComponent } from '../../drawer/DrawerComponent';
import PersonalMembership from '../../../screens/personal_membership/PersonalMembership';
import PersonalPoints from '../../../screens/personal_points/PersonalPoints';
import { getBizColorNumber } from '../../../biz_styles/bizStyle';
import { getCustomer, getLanguage } from '../../../shared/redux/GlueStoreData';
import PersonalDataScreen from '../../../screens/personal_data/PersonalDataScreen';
import { store } from '../../../shared/redux/store'
import { convertTimestampToDateObject, membershipName, isLayout122, isDark, formattedNumber } from '../../../shared/static_helper/StaticHelper';
import { badgeTypeForEntityType } from '../../../shared/loyalty_helper/LoyaltyHelper';
import { IconStyledSvg } from '../../../biz_styles/IconsStyles';
import { BoosterFlameStyled } from '../../../biz_styles/AllPagesStyle';
import { TitleStyled, ColorSubtitleStyled, SpacerStyled, SubtitleStyled, MenuTitleStyled } from '../../../biz_styles/ReusableStyles';
import DialogTransitionComponent from '../../dialog_components/dialog_transition_component/DialogTransitionComponent';
import ProfilePopupScreen from '../../../screens/profile_popup/ProfilePopupScreen';
import EditProfileScreen from '../../../screens/edit_profile/EditProfileScreen';

class ValueBar extends React.Component {

    state = {
        isDrawerOpen: false,
        drawerType: "benefits",
        points: 500,
        badgeValue: 0,
        membershipValue: membershipName(),
        customerObject: getCustomer(),
        getLang: getLanguage(),
        isBoostetMode: false,
        isPopupOpen: false
    }

    valueBarHeight() {
        return 44;
    }

    navigationBarHeight() {
        return 44;
    }


    getMemberStr() {
        return this.state.membershipValue.replace(' ', '\n');
    }

    getPointsStr() {
        return formattedNumber(this.state.customerObject.cust_current_points) + '\n' + this.state.getLang.points;
    }

    openDrawerType = (type) => {
        this.setState({ drawerType: type, isDrawerOpen: true });
    }


    getCurrentDrawerChild() {

        switch (this.state.drawerType) {
            case "points":
                return <PersonalPoints drawerClosed={this.closeDrawer} />
            case "benefits":
                return <PersonalDataScreen contentType={this.state.drawerType} top_icon={'rewards'} label={this.state.getLang.redeem_now + '!'} api='benefits' rootElement='' noDataText={this.state.getLang.no_rewards} />
            case "member":
                return <PersonalMembership />
            default:

        }
    }

    closeDrawer = () => {
        this.setState({ isDrawerOpen: false });
    }

    componentDidMount() {
        store.subscribe(() => {
            this.setState({ customerObject: getCustomer() })
            this.setState({ membershipValue: membershipName() })
        })

        this.isPointsBoosterMode();
    }

    getBadgeValue() {
        return badgeTypeForEntityType('benefits');
    }

    isPointsBoosterMode() {

        const cust = getCustomer();

        let boosterEndTime = '';
        let boosterAmount = '';

        cust.cust_active_mods.forEach(dic => {
            if (dic['type'] === 'boost') {
                boosterEndTime = dic.data['end_unix'];
                boosterAmount = dic.data['cpb_amount'];
            }
        })

        if (boosterEndTime == null) {
            this.setState({ isBoostetMode: false });
        }

        const today = new Date();

        const date = (convertTimestampToDateObject(boosterEndTime * 1000));

        if (date > today) {
            this.setState({ isBoostetMode: true });
        }
    }

    valueBarYPosition() {

        if (this.props.anchor == 'bottom') {
            return -this.valueBarHeight();
        }

        if (!this.props.show) {
            return -this.valueBarHeight();
        }

        if (this.props.scrollPosition >= 0 && this.props.scrollPosition <= this.valueBarHeight()) {
            return -this.props.scrollPosition;
        }
        else if (this.props.scrollPosition < 0) {
            return 0;
        }
        else if (this.props.scrollPosition > this.valueBarHeight()) {
            return -this.valueBarHeight();
        }
    }

    navigationBarYPosition() {

        if (!this.props.show) {
            return 0;
        }

        const delta = this.navigationBarHeight();
        if (this.props.scrollPosition >= 0 && this.props.scrollPosition <= this.navigationBarHeight()) {
            return -this.props.scrollPosition + delta;
        }
        else if (this.props.scrollPosition < 0) {
            return 0 + delta;
        }
        else if (this.props.scrollPosition > this.navigationBarHeight()) {
            return -this.navigationBarHeight() + delta;
        }
    }

    navigationBarItemColor() {
        
        const delta = this.navigationBarHeight();

        var initialColor = getBizColorNumber(1);
        const color2 = getBizColorNumber(2);


        if (isDark(initialColor)) {
            initialColor = color2;
        }
            
        if (this.props.scrollPosition >= 1 && this.props.scrollPosition <= delta - 1) {


            const scrollPercent = ((this.props.scrollPosition) / delta)
            const color = this.interpolateColor(initialColor, color2, scrollPercent);
            
            return color;
        }
        else if (this.props.scrollPosition <= 0) {
            return initialColor;
        }
        else if (this.props.scrollPosition >= delta) {
            return color2;
        }
    }

    interpolateColor(color1, color2, percent) {

        const r1 = parseInt(color1.slice(1, 3), 16);
        const g1 = parseInt(color1.slice(3, 5), 16);
        const b1 = parseInt(color1.slice(5, 7), 16);
        const r2 = parseInt(color2.slice(1, 3), 16);
        const g2 = parseInt(color2.slice(3, 5), 16);
        const b2 = parseInt(color2.slice(5, 7), 16);
      
        const r = Math.round(r1 + (r2 - r1) * percent);
        const g = Math.round(g1 + (g2 - g1) * percent);
        const b = Math.round(b1 + (b2 - b1) * percent);
      
        return `rgb(${r},${g},${b})`;
      }

    navigationBarOpacity() {

        const delta = this.navigationBarHeight();

        if (this.props.scrollPosition >= 1 && this.props.scrollPosition <= delta - 1) {
            return (((this.props.scrollPosition) / delta));
        }
        else if (this.props.scrollPosition <= 0) {
            return 0;
        }
        else if (this.props.scrollPosition >= delta) {
            return 1;
        }
    }

    dialogScreen() {

        return <DialogTransitionComponent
            open={this.state.isPopupOpen}
            dialogClosed={() => this.setState({ isPopupOpen: false })}
            children={<ProfilePopupScreen dialogClosed={(id) => {

                if (id === 2) {
                    // Show my notifications.
                }

                this.setState({ isPopupOpen: false });

            }} />} />
    }

    avatarClicked = () => {
        this.setState({ isPopupOpen: true });
    }


    render() {

        return (

            <div className='top-value-bar-holder' style={{bottom: this.props.anchor == 'top' ? '' : 0, height: this.props.anchor == 'top' ? 88 : 0, background: 'linear-gradient( rgba(0, 0, 0, 0.75), transparent)' }}>

                <DrawerComponent children={this.getCurrentDrawerChild()} isDrawerOpen={this.state.isDrawerOpen} closeDrawer={this.closeDrawer}></DrawerComponent>

                {this.dialogScreen()}

                {/* Top Value Bar */}
                <div className='top-value-bar-bg' style={{ height: this.valueBarHeight(), boxShadow: this.props.anchor == 'bottom' ? '0px 0px 5px rgba(0, 0, 0, 0.75)' : '', backgroundColor: getBizColorNumber(4), marginTop: this.valueBarYPosition() }}>

                    {/* Member Section */}
                    <div className='member-container' onClick={() => this.openDrawerType("member")}>
                        <div className="icon-and-text-container">
                            <div className='svg-parent'>
                                <IconStyledSvg name="TrophyDuotune.svg" cssName='fill-color2' size={34} />
                            </div>
                            <p className="text-str" style={{ color: getBizColorNumber(2) }}>{this.getMemberStr()}</p>
                        </div>
                    </div>


                    {/* Points */}
                    <div className='points-container' onClick={() => this.openDrawerType("points")}>
                        <div className="icon-and-text-container">

                            <BoosterFlameStyled isHidden={!this.state.isBoostetMode} src='https://storage.googleapis.com/getglue/images/web_member/new_web_member/flame.gif' />

                            <div className='svg-parent'>
                                <IconStyledSvg name="StarDuotune.svg" cssName='fill-color2' size={34} cssSecondName='boosted' />
                            </div>

                            <p className="text-str" style={{ color: getBizColorNumber(2) }}>{this.getPointsStr()}</p>
                        </div>
                    </div>

                    {/* My Rewards Button */}
                    <div className='my-rewards-button-container' style={{ marginTop: this.props.anchor == 'top' ? '15px' : '-15px', width: this.props.anchor == 'top' ? '108px' : '83px' }} onClick={() => this.openDrawerType("benefits")}>

                        {this.props.anchor == 'top' ?
                            <div className='my-rewards-button' style={{ lineHeight: '20px', height: '20px', padding: '0px', width: '108px', borderRadius: '25px', backgroundColor: getBizColorNumber(3), color: getBizColorNumber(1) }}>{this.state.getLang.my_rewards.toUpperCase()}</div>
                            :
                            <div className='my-rewards-button' style={{ height: '83px', width: '83px', boxShadow: '0px 0px 20px rgba(0, 0, 0, 0.75)', borderRadius: '42px', backgroundColor: getBizColorNumber(3), color: getBizColorNumber(1) }}>

                                <SpacerStyled height={'7'} />
                                <IconStyledSvg name="benefits.svg" cssName='fill-color5' size={34} cssSecondName='boosted' />
                                <SpacerStyled height={'4'} />
                                <ColorSubtitleStyled color={getBizColorNumber(1)}>{this.state.getLang.redeem_here.toUpperCase()}</ColorSubtitleStyled>
                            </div>
                        }

                        {this.getBadgeValue() !== '0' ?
                            <span style={{ position: 'absolute', right: '-15px', top: '-12px' }} className='badge m-2 badge-danger'>{this.getBadgeValue()}</span>
                            : ''
                        }
                    </div>

                </div>


                {/* Navigation Bar */}
                {this.props.anchor == 'top' ?

                    <div style={{ position: 'absolute', top: this.navigationBarYPosition(), height: this.navigationBarHeight() + 'px', width: '100%' }}>

                        <div style={{ position: 'absolute', height: this.navigationBarHeight() + 'px', width: '100%', opacity: this.navigationBarOpacity(), backgroundColor: getBizColorNumber(4) }}></div>

                        <div>
                            <MenuTitleStyled style={{ color: this.navigationBarItemColor(), position: 'absolute', width: '100%', textAlign: 'center', marginTop: '12px' }}>{this.props.title}</MenuTitleStyled>
                        </div>

                        {this.props.showHamburgerButton ?
                            <div style={{ position: 'absolute', left: '20px', top: '8px' }} onClick={this.props.menuPressed}>
                                <IconStyledSvg name="hamburger_menu_icon.svg" color={this.navigationBarItemColor()} size={34} cssSecondName='boosted' />
                            </div>
                            :
                            null}

                        {this.props.showAvatar ?
                            <div style={{ position: 'absolute', right: '20px', top: '8px', float: 'right' }} onClick={this.avatarClicked}>
                                <IconStyledSvg name="mod31.svg" size={34} color={this.navigationBarItemColor()} />
                            </div>
                            :
                            null}
                    </div>

                    : null}


            </div>
        );
    }
}

export default ValueBar;