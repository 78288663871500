import React, { useState, useEffect } from "react";
import { getLevelData } from "../../../api/ApiRequests";
import { DivListStyled, PageTitleStyled, DivPaddingTop, DivXYCenterStyled } from "../../../biz_styles/IncentivesStyle";
import HorizontalScroller from '../../../components/horizontal_scroller/HorizontalScroller';
import StampCardItemCell from "./StampCardItemCell";
import { getModuleNameById } from '../../../shared/static_helper/StaticHelper'

function StampCards() {
  const [isLoadingData, setIsLoadingData] = useState(true);
  const [card, setCard] = useState([]);

  const params = {
    mod_id: "6",
    level_no: "1",
    parent: "0",
    qs: "",
    subcategory: "",
    prodId: "",
    itemsPerPage: "",
    existingItems: "0",
  };

  useEffect(() => {
    (async () => {
      setCard(await getLevelData(params));
      setIsLoadingData(false);
    })();
  }, []);


  return (
  <DivXYCenterStyled>
    <HorizontalScroller
              cellWidth={250}
              scrollerHeight={465}
              dataIsready={!isLoadingData}
                data={isLoadingData ? null : card.rows}
                showEmptyState={!isLoadingData && card.rows.length == 0}
              cellClass={obj => <StampCardItemCell obj={obj} key={obj.md_row_id}/>}
              />
    </DivXYCenterStyled>
  );
}

 

export default StampCards;
