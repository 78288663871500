import React from 'react'
import { DivCenterStyled } from '../../biz_styles/ReusableStyles'
import { getBizColorNumber, getBizBackgroundImage } from '../../biz_styles/bizStyle'
import { IconStyledSvg } from '../../biz_styles/IconsStyles';
import { TitleStyled, SubTitleStyled, BottomLabelStyled, DivContactUsStyled, ContactUsLabelStyled } from '../../biz_styles/NoBizStyle'
import { useStoreProvider } from '../../shared/providers/StoreContext'
import { getLanguage } from "../../shared/redux/GlueStoreData";

// const screenBackground = () => {

//   return (
//     <div>
//       <div style={{ background: getBizColorNumber(1), opacity: 0.5 }} className="image-container">
//         <img style={{ background: getBizColorNumber(1), opacity: 0.5 }} className='bg_image_view' src={getBizBackgroundImage()} alt="" />
//       </div>
//       <div className="gradient" style={{ background: 'linear-gradient(rgba(0, 0, 0, 0),' + getBizColorNumber(1), opacity: 0.5 }} ></div>
//       <div className="bg-color-view" style={{ background: getBizColorNumber(1), opacity: 0.5 }}></div>
//     </div>
//   )
// }

function handleContactUs() {

}

function NoBizScreen() {

  const bizObject = useStoreProvider().biz
  const getLang = getLanguage();

  return (
    <div className="bg-color-view" style={{ height: '100%', marginTop: 0, background: getBizColorNumber(1), opacity: 0.7 }}>
      {/* {screenBackground()} */}

      <DivCenterStyled style={{ position: 'relative', top: 300 }}>

        <img src='https://storage.googleapis.com/getglue/images/web_member/sleeping.png' />

        <TitleStyled>{getLang.this_members_club_isnt_currently_active}</TitleStyled>
        <SubTitleStyled>{getLang.find_out_why}</SubTitleStyled>

        <DivContactUsStyled style={{ position: 'relative', textAlign: 'center' }}>
          <a href={`mailto:${bizObject.biz_email}?&subject=App Contact Us`}>
            <ContactUsLabelStyled onClick={() => handleContactUs()}>{getLang.contact_us}</ContactUsLabelStyled>
          </a>
        </DivContactUsStyled>

      </DivCenterStyled>
    </div>
  )
}

export default NoBizScreen;