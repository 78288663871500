import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import apiClient from "../../api/apiClient";
import { DivCenterStyled, Toast } from "../../biz_styles/bizStyle";
import {
  NextButtonStyled,
  EmailInputStyled,
  LoginDivStyled,
  NameInputStyled,
  WelcomeBackground,
  PrivacyPolicyText,
  CheckBoxText,
} from "../../biz_styles/LoginStyle";
import LoginHeader from "../login/LoginFormHeader";
import { useStoreProvider } from "../../shared/providers/StoreContext";
import LoadingComponent from "../../components/loading_components/LoadingComponent";
import ReCAPTCHA from "react-google-recaptcha";
import "./RegistrationScreen.css";
import { DrawerComponent } from "../../components/drawer/DrawerComponent";
import WebBrowserScreen from "../privacy_policy/WebBrowserScreen";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import { getBizColorNumber } from "../../biz_styles/bizStyle";
import { getLanguage, isLoggedIn } from "../../shared/redux/GlueStoreData";


function redirectIfNeeded() {
  const navigate = useNavigate();
  if (isLoggedIn()) {
      
      useEffect(() => {
          navigate('../main')
        }, []);
    }
}

function RegistrationFormEmail() {
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [isEmailValid, setIsEmailValid] = useState(true);
  const [isNameValid, setIsNameValid] = useState(true);
  const [isChecked, setIsChecked] = useState(false);
  const [isDisabled, setisDisabled] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const captchaRef = useRef();
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const getLang = getLanguage();




  const navigate = useNavigate();

  const bizObject = useStoreProvider().biz;

  const validateEmail = (e) => {
    return new RegExp(
      /[a-zA-Z0-9]+[\.]?([a-zA-Z0-9]+)?[\@][a-z]{3,15}[\.][a-z]{2,5}/g
    ).test(e); // eslint-disable-line
  };

  const validateName = (e) => {
    if (e.trim().length !== 0) {
      return true;
    }
    return false;
  };

  const handleNameChange = (e) => {
    e.preventDefault();
    setName(e.target.value);
    setIsNameValid(validateName(e.target.value));
  };
  const handleEmailChange = (e) => {
    e.preventDefault();
    setEmail(e.target.value);
    setIsEmailValid(validateEmail(e.target.value));
  };

  const handleCheckBoxChange = () => {
    if (name === "") {
      showError(getLang.sorry, getLang.please_enter_your_name);
    } else if (email === "") {
      showError(getLang.sorry, getLang.please_enter_your_email);
    } else {
      setIsChecked(!isChecked);
    }
  };

  useEffect(() => {
    setisDisabled(!isChecked);
  });

  useEffect(() => {
    createGuest();
  }, []);

  const onSubmit = async () => {
    const token = await captchaRef.current.executeAsync();
    if (isEmailValid && isNameValid) {
      if (isChecked) {
        setIsLoading(true);
        signUpByEmail(token);
      }
    } else {
      if (!isEmailValid) {
        showError(getLang.error, getLang.invalid_email)
        captchaRef.current.reset();
      } else {
        showError(getLang.error, getLang.invalid_name);
        captchaRef.current.reset();
      }
    }
  };

  async function signUpByEmail(captchaToken) {
    try {
      const postData = {
        name: name,
        email: email,
        token: captchaToken,
      };

      const res = await apiClient.post("/submitRegister", postData);

      if (res.status === 200) {
        if (res.data.responseCode === 1) {
          if (res.data.responseMessage === "sms_code_sent") {
            setIsLoading(false);
            navigate("../LoginFormSmsCode", { state: { email } });
          } else if (res.data.responseMessage === "email_code_sent") {
            setIsLoading(false);
            navigate("../verifyEmail", { state: { email } })
          } else {
            setIsLoading(false);
            showError(getLang.sorry, getLang.something_went_wrong_try_again)
          }
        } else {
          setIsLoading(false);
          showError(getLang.sorry, getLang.something_went_wrong_try_again)
        }
      }
    } catch (err) {
      setIsLoading(false);
      showError(getLang.sorry, getLang.something_went_wrong_try_again)
    }
  }

  async function createGuest() {
    try {
      const res = await apiClient.post("/createGuest");

      if (res.status === 200) {
        if (res.data.responseCode === 1) {
          // console.log("createGuest success");
        } else {
          showError(getLang.sorry, getLang.something_went_wrong_try_again)
        }
      }
    } catch (err) {
      showError(getLang.sorry, getLang.something_went_wrong_try_again)
    }
  }

  function showError(title, text) {
    Toast.fire({
      title: title,
      text: text,
      icon: "error",
      confirmButtonText: getLang.ok,
      confirmButtonColor: getBizColorNumber(3)
    });
  }
  function openDrawer() {
    setIsDrawerOpen(true);
  }

  function closeDrawer() {
    setIsDrawerOpen(false);
  }
  function getCurrentDrawerChild() {
    return <WebBrowserScreen type="privacy-policy" />;
  }

  return (
    <WelcomeBackground>
      {redirectIfNeeded()}
      <div className="parent-div">
        <LoadingComponent isLoading={isLoading} />
        <LoginDivStyled>
          <LoginHeader
            title={bizObject.biz_register_title}
            subtitle={bizObject.biz_register_message}
          />
          <DivCenterStyled>
            <NameInputStyled
              isNameValid={isNameValid}
              placeholder={getLang.full_name_placeholder}
              onChange={handleNameChange}
            />
          </DivCenterStyled>

          <DivCenterStyled>
            <EmailInputStyled
              isEmailValid={isEmailValid}
              placeholder={getLang.email_placeholder}
              onChange={handleEmailChange}
            />
          </DivCenterStyled>

          <DivCenterStyled>
            <FormControlLabel  className='checkbox-label'
              label=<CheckBoxText>
              {getLang.i_have_read_and_agree_to_the}{" "}
                <PrivacyPolicyText onClick={() => openDrawer()}>
                {getLang.privacy_policy}
                </PrivacyPolicyText>
              </CheckBoxText>
              control={
                <Checkbox
                  checked={isChecked}
                  onChange={handleCheckBoxChange}
                  sx={{
                    color: getBizColorNumber(3),
                    "&.Mui-checked": {
                      color: getBizColorNumber(3),
                    },
                  }}
                />
              }
            />
          </DivCenterStyled>

          <DivCenterStyled>
            <ReCAPTCHA
              sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
              ref={captchaRef}
              size="invisible"
            />
            <NextButtonStyled onClick={onSubmit} disabled={isDisabled}>
            {getLang.next}
            </NextButtonStyled>
          </DivCenterStyled>
        </LoginDivStyled>
      </div>
      <DrawerComponent
        children={getCurrentDrawerChild()}
        isDrawerOpen={isDrawerOpen}
        closeDrawer={closeDrawer}
      />
    </WelcomeBackground >
  );
}

export default RegistrationFormEmail;
