import React from "react";
import Dialog from '@mui/material/Dialog';
import { DialogContent } from "@mui/material";
import Slide from '@mui/material/Slide';

const Transition = React.forwardRef(function Transition(props, ref) {

    return <Slide direction="up" ref={ref} {...props} />;
});

export default function DialogTransitionComponent({ open, children, dialogClosed }) {

    /* UI - start */
    /* UI - without UI functions - start */
    return <Dialog
        open={open}
        TransitionComponent={Transition}
        onClose={dialogClosed}
        PaperProps={{
            style: {
                width: '550px',
                backgroundColor: 'transparent',
                boxShadow: 'none'
            },
        }}>

        <DialogContent
            style={{
                paddingLeft: '0px',
                paddingRight: '0px',
                paddingTop: '0px',
                paddingBottom: '0px'
            }}>

            {children}

        </DialogContent>

    </Dialog>
    /* UI - without UI functions - end */
    /* UI - end */
}