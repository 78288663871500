import React from 'react';
import './rating.css';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import Rating from '@mui/material/Rating';
import { useEffect, useState } from 'react';
import { BoldLabelStyled, LogoStyled, RowDivStyled, SpacerStyled, RegularLabelStyled, StarBoxStyled, StarImgStyled, StarTitleStyled, HolderDivStyled, SideAlignDiv, GlueTextField, ErrorLineStyled } from '../../biz_styles/mainStyle';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import { Checkbox } from '@mui/material';
import Button from '@mui/material-next/Button';
import { submitRating, addMemberFromRatingPagOnly, getLanguageCollection, directLoadBizData, addEasyReview } from '../../api/ApiRequests';
import { getLanguage } from '../../shared/redux/GlueStoreData';
import { getBizColorNumber, CustomPhoneInput, Toast } from '../../biz_styles/bizStyle';
import { useNavigate } from 'react-router-dom';
import LoadingComponent from '../../components/loading_components/LoadingComponent';
import { checkIfNullUndefined, getInjectedBizData } from '../../shared/static_helper/StaticHelper';
import { BottomContainerDivStyled, StyledPhoneInput } from '../../biz_styles/ReusableStyles';
import { isValidPhoneNumber, AsYouType } from "libphonenumber-js";
import PhoneInput from 'react-phone-input-2';
import './../../App.css';
import { PrivacyPolicyText, CheckBoxText } from "../../biz_styles/LoginStyle";
import { RatingGIFStyled } from '../../biz_styles/AllPagesStyle';
import { TextareaAutosize } from '@mui/material';


function rating() {

  useEffect(() => {
    getLanguageCollectionData()
  }, [])

  directLoadBizData()

  const StyledRating = styled(Rating)({
    '& .MuiRating-iconFilled ': {
      color: getBizColorNumber(3),
    },
  });

  const colorOpacity = 20;

  const containerStyle = {
    borderRadius: "0px",
    height: "57px"
  }


  const buttonStyle = {
    color: getBizColorNumber(2),
    backgroundColor: getBizColorNumber(4),
    borderRadius: "0px",
    borderColor: 'transparent',
  };

  const dropDownStyle = {
    color: getBizColorNumber(2),
    backgroundColor: getBizColorNumber(4),
    borderRadius: "0px",
    borderColor: 'transparent'
  };

  const defaultValue = isRTL() ? 1 : 5

  const navigate = useNavigate();

  const getLang = getLanguage();

  const [value, setValue] = useState(defaultValue)

  const [languageLoaded, setLanguageLoaded] = useState(false)

  const [showGIF, setShowGIF] = useState(false)

  const [isLoadingData, setIsLoadingData] = useState(true);

  const [fullName, setFullName] = useState('');

  const [custPhone, setCustPhone] = useState('');

  const [isValidName, setIsValidName] = useState(true);

  const [isValidPhone, setIsValidPhone] = useState(true);

  const [isClub, setIsClub] = useState(true);

  const [isClubButtonSelected, setIsClubButtonSelected] = useState(true);

  const [isNewsChecked, setIsNewsChecked] = useState(false);

  var errorMessage = ''

  const asYouType = new AsYouType();

  const [radioButtonDefaultValue, setRadioButtonDefaultValue] = useState(1);

  const bizData = getInjectedBizData();

  const showRegistration = bizData.show_registration == '1';

  const languagePrefix = bizData.biz_language;

  let customIcons = {};

  const [feedback, setFeedback] = useState('');

  const isValidFeedback = feedback.trim().length > 3

  let hideRatingElements = 0

  if ('hide_rating_elements' in bizData) {
    console.log('hide_rating_elements = ' + hideRatingElements)
    hideRatingElements = bizData.hide_rating_elements
  } else {
    console.log('Key does not exist')
  }


  useEffect(() => {
    updateButtonState();
  }, [fullName, custPhone, isNewsChecked, isValidName, isValidPhone, feedback])


  const handleNameOnChange = e => {
    setIsValidName(validateName(e.target.value))
    setFullName(e.target.value)
  }

  const handlePhoneOnChange = e => {
    setIsValidPhone(validatePhone(e.target.value))
    setCustPhone(e.target.value)
  }

  function handlePhoneNumberOnChange(value, data, event, formattedValue) {
    asYouType.input(formattedValue);
    if (formattedValue !== "") {
      setCustPhone(asYouType.getNumberValue());
      asYouType.reset();
    }
    setIsValidPhone(isValidPhoneNumber(formattedValue));

  }

  const handleFeedbackOnChange = e => {
    setFeedback(e.target.value)
  }

  function updateButtonState() {
    //Nothing to do here at the moment
  }

  async function getLanguageCollectionData() {
    if (languageLoaded) return
    const resData = await getLanguageCollection();

    if (!checkIfNullUndefined(resData)) {
      setIsLoadingData(false)
      setLanguageLoaded(true)
    }
  }


  function starBox(asset, title, selected) {

    return <StarBoxStyled backgroundColor={getBizColorNumber(3)} color={selected ? getBizColorNumber(3) : '#191C1A0F'}>

      <RowDivStyled>
        <StarImgStyled src={asset} />
      </RowDivStyled>

      <SpacerStyled height={'23'} />

      <StarTitleStyled color={selected ? getBizColorNumber(3) : getBizColorNumber(2)} dir={isRTL() ? "rtl" : "ltr"}>{title}</StarTitleStyled>

    </StarBoxStyled>
  }



  function isRTL() {
    return window.direction === 'rtl';
  }

  const handleChange = (event) => {
    setIsClubButtonSelected(event.target.value === '0' ? false : true)
    setRadioButtonDefaultValue(event.target.value)
    setIsClub(event.target.value === '0' ? false : true);
  };

  const validateFeedback = (e) => {
    if (e.trim().length > 3) {
      return true;
    }
    return false;
  };


  const validateName = (e) => {
    if (e.trim().length !== 0) {
      return true;
    }
    return false;
  };

  const validatePhone = (e) => {
    if (e.trim().length !== 0) {
      return true;
    }
    return false;
  };

  function radioButtonWithParams(value, label, isChecked) {
    return <FormControlLabel
      value={value}
      control={<Radio checked={isChecked}
        sx={{
          color: getBizColorNumber(2),
          '&.Mui-checked': {
            color: getBizColorNumber(3),
          },
        }} />
      }
      label={<RegularLabelStyled color={getBizColorNumber(2)}>{label}</RegularLabelStyled>}
      labelPlacement={isRTL() ? "start" : "finish"}
    />
  }

  function sideLabelWithText(text) {

    return <SideAlignDiv alignment={isRTL() ? "right" : "left"}>
      <RegularLabelStyled color={getBizColorNumber(2)} fontSize='14'>{text}</RegularLabelStyled>
    </SideAlignDiv>
  }

  function errorSideLabelWithText(text) {

    return <SideAlignDiv alignment={isRTL() ? "right" : "left"}>
      <RegularLabelStyled color={'red'} fontSize='14'>{text}</RegularLabelStyled>
    </SideAlignDiv>
  }


  function mainButtonTitle() {

    // return (isClub && showRegistration) ? getLang.rate_and_get_benefit : getLang.rate

    return (hideRatingElements == 1) ? getLang.get_benefit : getLang.rate_and_get_benefit

  }

  function stars() {
    if (isRTL()) {
      customIcons = {
        5: {
          icon: starBox('https://storage.googleapis.com/getglue/images/web_member/new_web_member/rating/star1.png', getLang.star1_text, value === 5),
          label: '1',
        },
        4: {
          icon: starBox('https://storage.googleapis.com/getglue/images/web_member/new_web_member/rating/star2.png', getLang.star2_text, value === 4),
          label: '2',
        },
        3: {
          icon: starBox('https://storage.googleapis.com/getglue/images/web_member/new_web_member/rating/star3.png', getLang.star3_text, value === 3),
          label: '3',
        },
        2: {
          icon: starBox('https://storage.googleapis.com/getglue/images/web_member/new_web_member/rating/star4.png', getLang.star4_text, value === 2),
          label: '4',
        },
        1: {
          icon: starBox('https://storage.googleapis.com/getglue/images/web_member/new_web_member/rating/star5.png', getLang.star5_text, value === 1),
          label: '5',
        },
      };
    }
    else {
      customIcons = {
        1: {
          icon: starBox('https://storage.googleapis.com/getglue/images/web_member/new_web_member/rating/star1.png', getLang.star1_text, value === 1),
          label: '1',
        },
        2: {
          icon: starBox('https://storage.googleapis.com/getglue/images/web_member/new_web_member/rating/star2.png', getLang.star2_text, value === 2),
          label: '2',
        },
        3: {
          icon: starBox('https://storage.googleapis.com/getglue/images/web_member/new_web_member/rating/star3.png', getLang.star3_text, value === 3),
          label: '3',
        },
        4: {
          icon: starBox('https://storage.googleapis.com/getglue/images/web_member/new_web_member/rating/star4.png', getLang.star4_text, value === 4),
          label: '4',
        },
        5: {
          icon: starBox('https://storage.googleapis.com/getglue/images/web_member/new_web_member/rating/star5.png', getLang.star5_text, value === 5),
          label: '5',
        },
      };
    }


    function IconContainer(props) {
      const { value, ...other } = props;
      return <span {...other}>{customIcons[value].icon}</span>;
    }


    return <div dir="ltr">
      <StyledRating
        name="highlight-selected-only"
        defaultValue={2}
        IconContainerComponent={IconContainer}
        getLabelText={(value) => customIcons[value].label}
        highlightSelectedOnly
        value={value}
        onChange={(event, newValue) => {
          if (newValue !== null) {
            setValue(newValue);
          }
        }}
      />
    </div>
  }

  function ratingElements() {

    return <div>
      {stars()}
      <SpacerStyled height={'32'} />

      <TextareaAutosize aria-label="minimum height" minRows={4} placeholder={getLang.we_would_appreciate_your_feedback} onChange={handleFeedbackOnChange} style={{ width: '90%', backgroundColor: getBizColorNumber(3) + 20, color: getBizColorNumber(2), padding: '2%' }} />

      <SpacerStyled height={'16'} />
    </div>

  }


  async function sendForm() {

    if (showRegistration) {
      if (!isClubButtonSelected) {
        showPleaseSelectError()
        return;
      } else if (!checkIfFormIsValid()) {
        showCheckFormError();
        return;
      }
    }

    setIsLoadingData(true);

    const params = {
      score: hideRatingElements == 1 ? '5' : customIcons[value].label,
      phone: custPhone,
      name: fullName,
      cust_review: feedback,
      send_news: isNewsChecked
    }

    let data;

    if (hideRatingElements == 1) {
      data = await addMemberFromRatingPagOnly(params)
    } else {
      data = await submitRating(params);
    }

    setIsLoadingData(false);

    // {"responseCode":1,"responseMessage":"","responseData":
    // {"rating_id":"2401","redirect_url":"https:\/\/diy.ink\/bc2f3fbeed191865","easy_uid":"254AF2A1-44DC-4320-B23F-33FE215668A3","easy_biz_id":"10131126"},"caller":"","version":"27.0.0"}
    const easyBizId = data.responseData.easy_biz_id
    const easyUID = data.responseData.easy_uid
    const redirectURL = data.responseData.redirect_url

    const easyReviewParams = {
      bizid: easyBizId,
      uid: easyUID,
      rating: hideRatingElements == 1 ? '5' : customIcons[value].label,
      comment: feedback
    }

    if (isValidFeedback && hideRatingElements == 0) {
      const reviewRes = addEasyReview(easyReviewParams)
    }

    window.location.href = redirectURL;

    // if (data.responseMessage === 'redirect_to_google') {

    //     if(typeof(window.rate_google) !== 'undefined'){
    //       showGoogleGIF()
    //       setTimeout(() => {
    //         const url = window.rate_google;
    //         window.location.href = url;
    //       }, 4500)

    //     } else {
    //       showThankYou()
    //     }

    //   }
    //   else if (data.responseMessage === 'send_feedback') {
    //     const ratingId = data.responseData;
    //     navigate(`../review/${ratingId}`)
    //   }

  }

  function showGoogleGIF() {

    setShowGIF(true)
  }


  function checkIfFormIsValid() {

    var formIsValid = true;

    const validName = (validateName(fullName));
    const validPhone = (isValidPhoneNumber(custPhone));

    setIsValidName(validName);
    setIsValidPhone(validPhone);

    // default error message.
    errorMessage = getLang.check_form

    if ((!isClub || !showRegistration) && isClubButtonSelected) {
      return formIsValid;
    }

    if (!validName || !validPhone) {
      formIsValid = false;
    }

    if (isClubButtonSelected) {
      if (isClub) {
        if (!isValidName) {
          formIsValid = false
          errorMessage = getLang.please_fill_valid_full_name
          return formIsValid
        }
        if (!isValidPhone) {
          formIsValid = false
          errorMessage = getLang.invalid_phone_number
          return formIsValid
        }

        // if (!isValidFeedback){
        //   formIsValid = false
        //   errorMessage = getLang.check_form
        //   return formIsValid
        // }


        if (!isNewsChecked) {
          formIsValid = false
          errorMessage = getLang.please_accept_benefits
          return formIsValid
        }
      }
    } else {
      formIsValid = false
    }


    return formIsValid;
  }

  function showPleaseSelectError() {
    Toast.fire({
      title: getLang.get_club_benefit,
      showDenyButton: true,
      text: '',
      icon: 'info',
      confirmButtonText: getLang.yes,
      denyButtonText: getLang.no,
    }).then((result) => {

      setIsClubButtonSelected(true)

      if (result.isConfirmed) {
        setRadioButtonDefaultValue('1')
        setIsClub(true);
      } else if (result.isDenied) {
        setRadioButtonDefaultValue('0')
        setIsClub(false);
      }
    })
  }


  function showCheckFormError() {
    Toast.fire({
      title: errorMessage,
      text: '',
      icon: 'error',
      confirmButtonColor: '#000000',
      confirmButtonText: getLang.close
    })
  }

  function showThankYou() {
    Toast.fire({
      title: getLang.contact_success,
      text: '',
      icon: 'success',
      confirmButtonColor: '#000000',
      confirmButtonText: getLang.ok
    })
  }

  function openUrlInNewTab(url) {
    window.open(url, '_blank');
  }

  function getGIFUrl() {

    if (languagePrefix === 'he') {
      return 'https://storage.googleapis.com/getglue/images/web_member/gifs/google_explain_he.gif'
    }
    return 'https://storage.googleapis.com/getglue/images/web_member/gifs/google_explain_en.gif'
  }

  return (

    languageLoaded === false ? <div className="splash"></div> :

      <div className='master-container' style={{ backgroundColor: getBizColorNumber(1) }}>

        <HolderDivStyled color={getBizColorNumber(1)}>

          <SpacerStyled height={'55'} />

          <RowDivStyled>

            <LogoStyled src={bizData.biz_logo}></LogoStyled>

          </RowDivStyled>

          <SpacerStyled height={'16'} />

          <RowDivStyled>

            <BoldLabelStyled color={getBizColorNumber(2)} className='bold-font' fontSize='22'>{bizData.name}</BoldLabelStyled>

            <SpacerStyled height={'16'} />
            <RegularLabelStyled color={getBizColorNumber(2)}>{getLang.thanks_for_being_a_customer}</RegularLabelStyled>
            <SpacerStyled height={'8'} />
            <RegularLabelStyled color={getBizColorNumber(2)}>{hideRatingElements == 1 ? getLang.biz_without_review_substitle : getLang.biz_review_substitle}</RegularLabelStyled>
          </RowDivStyled>
          <SpacerStyled height={'32'} />

          {hideRatingElements == 0 ? ratingElements() : null}

          {/* {showRegistration ?  
          
            <>
              {sideLabelWithText(getLang.get_club_benefit)}
                          
            <RadioGroup
              row
              aria-labelledby="demo-form-control-label-placement"
              name="position"
              value={radioButtonDefaultValue}
              defaultValue={radioButtonDefaultValue}
              onChange={handleChange}
              style={{ float: isRTL() ? "right" : "left" }}>
          
          {radioButtonWithParams('1', getLang.yes,isClubButtonSelected)}
          {radioButtonWithParams('0', getLang.no,!isClubButtonSelected)}
          

            </RadioGroup>
            </>
         : null} */}

          {/* <SpacerStyled height={'60'} /> */}


          {isClub && showRegistration ?
            <RowDivStyled>

              {sideLabelWithText(getLang.benefit_customer_name)}
              <GlueTextField fullWidth id="filled-basic" label={<RegularLabelStyled fontSize={18} color={getBizColorNumber(3)}>{getLang.full_name}</RegularLabelStyled>} variant="filled" onChange={handleNameOnChange} style={{ borderBottom: isValidName ? '' : '1px solid red' }} sx={{ input: { fontSize: '18px', backgroundColor: getBizColorNumber(3) + colorOpacity, color: getBizColorNumber(2) } }} />

              <SpacerStyled height={'30'} />


              {sideLabelWithText(getLang.benefit_owner_phone)}
              <StyledPhoneInput
                name="phoneNumber"
                type="text"
                country={bizData.biz_country_code.toLowerCase()}
                countryCodeEditable={false}
                enableTerritories={true}
                autocompleteSearch={true}
                enableSearch={false}
                disableSearchIcon={true}
                inputStyle={{
                  background: getBizColorNumber(3) + colorOpacity,
                  borderRadius: "0px",
                  borderColor: 'transparent',
                  height: "57px !important",
                  color: getBizColorNumber(2)
                }}
                containerStyle={{
                  height: "57px",
                  borderRadius: "0px",
                }}
                // buttonStyle={buttonStyle}
                dropdownStyle={dropDownStyle}
                value={custPhone}
                onChange={handlePhoneNumberOnChange}
              />

              <ErrorLineStyled color={isValidPhone ? 'transparent' : 'red'} />

              {/* <GlueTextField fullWidth id="filled-basic" label={<RegularLabelStyled color={getBizColorNumber(3)}>{getLang.phone}</RegularLabelStyled>} variant="filled" onChange={handlePhoneOnChange} style={{borderBottom:isValidPhone? '':'1px solid red'}} sx={{ input: { backgroundColor: getBizColorNumber(3) + colorOpacity , color: getBizColorNumber(2)}}} inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}/> */}

              <SpacerStyled height={'30'} />

              <FormControlLabel
                value="start"
                control={<Checkbox checked={isNewsChecked} onChange={(e) => setIsNewsChecked(!isNewsChecked)} inputProps={{ 'aria-label': 'controlled' }} sx={{
                  color: getBizColorNumber(3),
                  '&.Mui-checked': {
                    color: getBizColorNumber(3),
                  },
                }} />}
                label={<CheckBoxText>
                  {getLang.agree_to_news}{" "}
                  <PrivacyPolicyText onClick={() => openUrlInNewTab(bizData.bizPrivacyUrl)}>
                    {getLang.privacy_policy}
                  </PrivacyPolicyText>
                </CheckBoxText>}
                // label={<RegularLabelStyled color={getBizColorNumber(2)}>{getLang.agree_to_news}</RegularLabelStyled>}
                labelPlacement={isRTL() ? "start" : "end"}
                style={{ float: isRTL() ? "right" : "left", }}
              />

            </RowDivStyled>

            : null}

          <SpacerStyled height={'230'} />

          <RowDivStyled>

            <BottomContainerDivStyled height='100' style={{ zIndex: 1, position: 'fixed', bottom: 0, width: '100%', left: '0%', backgroundColor: 'transparent' }}>

              <div style={{ position: 'relative', bottom: 0, width: '100%', maxWidth: '550px', height: '100%', marginLeft: 'auto', marginRight: 'auto', backgroundColor: getBizColorNumber(1) }} >

                <Button
                  size="large"
                  variant="filled"
                  onClickCapture={() => sendForm()}
                  className='bold-font'
                  color='primary'
                  sx={{
                    position: 'relative',
                    bottom: -20,
                    width: '360px',
                    height: 55,
                    backgroundColor: getBizColorNumber(3),
                    color: getBizColorNumber(1),
                    // fontFamily: 'Alef-Bold',
                    fontSize: '16px',
                    ':hover': {
                      backgroundColor: getBizColorNumber(3),
                    },
                  }}>
                  {mainButtonTitle()}
                </Button>

              </div>

            </BottomContainerDivStyled>

          </RowDivStyled>
        </HolderDivStyled>
        <LoadingComponent isLoading={isLoadingData} />


        {/* GIF */}
        <div style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%', backgroundColor: getBizColorNumber(1), zIndex: showGIF ? 2 : -1 }}>

          <div style={{ color: getBizColorNumber(2), marginTop: '10%', marginBottom: '10%', fontSize: '24px' }}>{getLang.redirect_message}</div>

          <RatingGIFStyled src={getGIFUrl()} />

        </div>

      </div>
  );
}

export default rating;
