import React, { useRef, useState } from 'react';
import { useNavigate } from "react-router-dom"
import { Routes, Route } from 'react-router-dom';
import {loginRoutes , routes} from './routes'
import Main from './Main';
import Home from './screens/home/Home';
import WelcomeScreen from './screens/weclome/WelcomeScreen';
import { useStoreProvider } from './shared/providers/StoreContext'
import NoBizScreen from './screens/errors/NoBizScreen'
import {getInjectedBizData} from './shared/static_helper/StaticHelper'

const hasRating = getInjectedBizData().has_rating == '1'

const isBizActive = getInjectedBizData().is_active == '1'

function RouterProvider() {

  const navigate = useNavigate();

  return (
    
      <Routes>
        {
          loginRoutes.map(route => 
            <Route path={route.path} key={Math.random()} element={
              shouldPreventRating(route) ?
              <WelcomeScreen/> : <route.element/>} exact={route.exact} />
          )
        }
      <Route path="/main" element={<Main />}>
        <Route index element={<Home navigate={navigate} />} />
          {
            routes.map(route => 
              <Route path={route.path} key={Math.random()} element={<route.element/>} exact={route.exact} />
            )
        }
        </Route>
      </Routes>
  )
}

function shouldPreventRating(route) {
  if ((!isBizActive || !hasRating) && (route.path == 'rating' || route.path == 'review')){
    return true
  }
  return false
}

export default RouterProvider;