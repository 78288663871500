import React, { useState } from 'react';
import IconTint from 'react-icon-tint';
import UploadImageComponent from '../../../../components/upload_image_component/UploadImageComponent';
import { getBizColorNumber, H3Styled } from '../../../../biz_styles/bizStyle';
import { getLanguage } from '../../../../shared/redux/GlueStoreData';
import './TopFormComponent.css';

export default function TopFormComponent({ imageUrl, name, onChange }) {

    /* Logic functions - start */
    const getLang = getLanguage();

    const [imageUrlVar, setImageUrl] = useState(imageUrl);

    function onImageChanged(event) {

        if (event.target.files && event.target.files[0]) {

            let img = event.target.files[0];

            setImageUrl(URL.createObjectURL(img));
            onChange(img);
        }
    };
    /* Logic functions - end */

    /* UI - start */
    /* UI - without UI functions - start */
    return (
        <div className='div-top-form'>

            {imgProfile()}

            <div className='div-data-top-form'>

                {h1HelloName()}

                {h1ChangeImage()}

            </div>

        </div>
    )
    /* UI - without UI functions - end */

    /* UI - with functions - start */
    function imgProfile() {

        return imageUrlVar.includes('bbassets/avatars/avatar_') ?

            <IconTint
                className='img-edit-profile-top-form-component'
                src={imageUrlVar}
                color={getBizColorNumber(2)} /> :

            <img
                className='img-edit-profile-top-form-component'
                src={imageUrlVar}
                alt="" />
    }

    function h1HelloName() {

        var helloNameStr = getLang.hello + ', ' + name;

        return <H3Styled className='h1-hello-name'>{helloNameStr}</H3Styled>;
    }

    function h1ChangeImage() {

        return <UploadImageComponent
            text={getLang.change_image}
            style={{
                color: getBizColorNumber(3),
                background: getBizColorNumber(1),
                textTransform: 'uppercase',
                marginTop: '2%',
                fontSize: '13px',
                fontWeight: 'bold'
            }}
            onImageChanged={onImageChanged} />
    }
    /* UI - with functions - end */
    /* UI - end */
}