import React, { useState, useEffect } from "react";
import { getLevelData } from "../../../api/ApiRequests";
import { DivListStyled, PageTitleStyled, DivPaddingTop, DivXYCenterStyled } from "../../../biz_styles/IncentivesStyle";
import HorizontalScroller from '../../../components/horizontal_scroller/HorizontalScroller';
import { getPriceStringForPrice } from "../../../shared/loyalty_helper/LoyaltyHelper";
import { useStoreProvider } from "../../../shared/providers/StoreContext";
import CouponsCardItemCell from "./CouponsCardItemCell";
import { getModuleNameById } from '../../../shared/static_helper/StaticHelper'

function Coupons() {
  const [isLoadingData, setIsLoadingData] = useState(true);
  const [card, setCard] = useState([]);
  const bizObject = useStoreProvider().biz;

  const params = {
    mod_id: "26",
    level_no: "1",
    parent: "0",
    qs: "",
    subcategory: "",
    prodId: "",
    itemsPerPage: "",
    existingItems: "0",
  };

  useEffect(() => {
    (async () => {
      setCard(await getLevelData(params));
      setIsLoadingData(false);
    })();
  }, []);


  return (

    <DivXYCenterStyled>
    <HorizontalScroller
              cellWidth={250}
              scrollerHeight={465}
              dataIsready={!isLoadingData}
                data={isLoadingData ? null : card.rows}
                showEmptyState={!isLoadingData && card.rows.length == 0}
                cellClass={obj => <CouponsCardItemCell obj={obj} key={obj.md_row_id} price={getPriceStringForPrice(obj.price,bizObject.ess_currency) } />}
                />
    </DivXYCenterStyled>



      // <DivListStyled>
      //   <HorizontalScroller style={{ display: 'table-cell', verticalAlign: 'middle' }}
      //   cellWidth={327}
      //   scrollerHeight={417}
      //   dataIsready={!isLoadingData}
      //     data={isLoadingData ? null : card.rows}
      //     showEmptyState={!isLoadingData && card.rows.length == 0}
      //   cellClass={obj => <CouponsCardItemCell obj={obj} key={obj.md_row_id} price={getPriceStringForPrice(obj.price,bizObject.ess_currency) } />}
      //   />
      // </DivListStyled>
  );
}

export default Coupons;
