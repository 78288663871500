import axios from "axios";

//Debug
// export default axios.create({
//   baseURL: process.env.REACT_APP_BASE_URL_LOCAL,
//   headers: {
//     "Content-type": "application/json",
//     "sid": typeof(window.gsid) !== "undefined" ? window.gsid : "",
//     "biz_id": window.bizData.biz_hash,
//     "debug" : "true"
//   }
// });

// Prod
export default axios.create({
  baseURL: process.env.REACT_APP_BASE_URL_HOSTED,
  headers: {
    "Content-type": "application/json",
    "sid": typeof(window.gsid) !== "undefined" ? window.gsid : "",
    "biz_id": window.bizData.biz_hash,
    "debug" : "false"
  }
});