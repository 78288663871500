export const getBizAction = (type, key, payload) => ({
    type,
    key,
    payload
})

export const bizColorsChangedAction = (type, payload) => ({
    type,
    payload
})


export const error = type => ({
    type
})