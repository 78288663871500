import { store } from './store'
import { GetBizObject } from '../../objects/GetBizObject';
import { GetClientObject } from '../../objects/GetClientObject';
import { getCustomerAction } from "../../actions/customerActions"
import { setIsLoggedInAction } from '../../actions/accountActions'
import { IS_LOGGED_IN, CUSTOMER_REDIRECT_DATA } from "../../actions/actionTypes"
import { checkIfNullUndefined, getBizHashFromUrl } from '../static_helper/StaticHelper'

// lang functions
export const getLanguage = () => {
  return store.getState().languageReducer.results[0].languageData.responseData
};

// account functions
export const isLoggedIn = () => {
  return store.getState().accountReducer[getBizHashFromUrl()]
};

export const setIsLoggedIn = (loggedIn) => {
  store.dispatch(setIsLoggedInAction(IS_LOGGED_IN, getBizHashFromUrl(), loggedIn))
}

// biz functions
export const getBiz = () => {
  var bizObject = GetBizObject
  bizObject = store.getState().bizReducer[getBizHashFromUrl()];
  return bizObject
};

export const isBizColorsChanged = () => {
  return store.getState().bizColorsReducer.results[0].isBizColorsChanged
};

// customer functions
export const getCustomer = () => {
  var custObject = GetClientObject
  custObject = store.getState().customerReducer[getBizHashFromUrl()]

  return custObject
};

export const getRedirectData = () => {
  return store.getState().customerReducer[CUSTOMER_REDIRECT_DATA];
};

export const clearRedirectData = () => {
  store.dispatch(getCustomerAction(CUSTOMER_REDIRECT_DATA, CUSTOMER_REDIRECT_DATA, ""));
};

export function getStripePk() {

  var pk = 'error'

  if (!checkIfNullUndefined(getBiz())) {
    getBiz().paymentOptions.forEach(item => {
      if (item.name == 'stripe') {
        pk = item.data.pk
      }
      if (item.name == 'isracard360') {
        pk = item.data.bp_wallet_id
      }
    })
  }

  return pk
}

export function getCCProcessor(){
  var processor = "";
  getBiz().paymentOptions.forEach(item => {
    if (item.name == 'stripe' || item.name == 'isracard360') {
      processor =  item.name;
    }
  })

  return processor;
}

export function isCashEnabled() {

  var res = false

  getBiz().paymentOptions.forEach(item => {
    if (item.name === 'cash') {
      res = true
    }
  })

  return res
}

export function isCreditCardEnabled() {

  let cc = false;

  getBiz().paymentOptions.forEach(item => {
      if (item.name === 'stripe' || item.name === 'isracard360') {
          cc = true;
      }
  })

  return cc;
}

export function clearStoreData() {
  store.dispatch(setIsLoggedInAction(IS_LOGGED_IN, getBizHashFromUrl(), false))
  window.location.href = "/glue/biz/index/" + window.bizHash
}