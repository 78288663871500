import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import QRCodeComponent from "../../../components/qr_code_component/QRCodeComponent";
import LoadingComponent from "../../../components/loading_components/LoadingComponent";
import Cart from "../../Cart/Cart";
import DialogTransitionComponent from "../../../components/dialog_components/dialog_transition_component/DialogTransitionComponent";
import CancelSubscriptionComponent from "../../../components/cancel_subscription_component/CancelSubscriptionComponent";
import ConfirmationMessageComponent from "../../../components/confirmation_message_component/ConfirmationMessageComponent";
import { DrawerComponent } from "../../../components/drawer/DrawerComponent";
import { DivBackgroundStyled, FloatingButtonStyled } from "../../../biz_styles/ReusableStyles";
import { PStyled, P2Styled, H3Styled, POpacityStyled, getBizColorNumber, Toast } from "../../../biz_styles/bizStyle";
import { ScrollableDivStyled, SpacerStyled } from "../../../biz_styles/ReusableStyles";
import { calculateDiffDaysFromToday, getFormatedDateSQL, checkIfNullUndefined, checkIfNullUndefinedEmpty, convertTimestampToDate, getFormattedDate, valueBarSpacer, bottomNavigationViewSpacer, convertFromStringToBoolean } from "../../../shared/static_helper/StaticHelper";
import { getLevelData, cancelSubscription } from "../../../api/ApiRequests";
import { useStoreProvider } from '../../../shared/providers/StoreContext'
import { getLanguage } from "../../../shared/redux/GlueStoreData";
import { statusStringForStatus } from "../../../shared/loyalty_helper/LoyaltyHelper";


export default function SubscriptionMultipassInfoScreen({ fromPersonalZoneFromList, currentObjFromList }) {

    /* Logic functions - start */
    const getLang = getLanguage();
    const bizObject = useStoreProvider().biz

    const paramsFromUrl = useParams();

    const fromPersonalZone = getFromPersonalZoneFromRelevevantCase();
    const currentObj = getCurrentObjFromRelevevantCase();

    const currentModId = Number(currentObj.md_mod_id);
    const currentLevelNo = Number(currentObj.md_level_no) + 1;
    const currentRowId = Number(currentObj.md_row_id);

    var isSubscription = currentModId === 10;
    var keyVar = isSubscription ? 'csu' : 'cpp';
    var keyCustVar = isSubscription ? 'sub' : 'pass';

    const [isLoadingData, setIsLoadingData] = useState(!fromPersonalZone);
    const [obj, setObj] = useState(currentObj);
    const [isDrawerOpen, setIsDrawerOpen] = useState(false);
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const [stateDialog, setStateDialog] = useState('cancelSubscription');
    const [messageConfirmation, setMessageConfirmation] = useState('');

    var extraData = obj.extra_data;
    var connectedItems = typeof(extraData) !== "undefined" ? extraData.connected_items : [];
    var custData = obj['cust_' + keyCustVar + '_data'];

    const [showCancelSubscriptionButton, setShowCancelSubscriptionButton] = useState(getShowCancelSubscription());

    const params = {
        mod_id: currentModId,
        level_no: currentLevelNo,
        parent: currentRowId,
        qs: '',
        subcategory: '',
        prodId: '',
        itemsPerPage: '0',
        existingItems: '0',
    };

    useEffect(() => {

        if (!fromPersonalZone) {

            getLevelDataApi();
        }
    }, []);

    async function getLevelDataApi() {

        setIsLoadingData(true);

        const responseData = await getLevelData(params);

        if (!checkIfNullUndefined(responseData)) {

            var rows = responseData.rows[0];

            extraData = rows.extra_data;
            connectedItems = extraData.connected_items;
            custData = rows['cust_' + keyCustVar + '_data'];

            setObj(rows);

            setIsLoadingData(false);
        }
    }

    async function cancelSubscriptionApi(map) {

        setIsLoadingData(true);

        const responseData = await cancelSubscription(map);

        if (!checkIfNullUndefined(responseData)) {

            if (responseData.code === 1) {

                Toast.fire({
                    allowOutsideClick: false,
                    title: getLang.the_subscription_was_successfully_canceled,
                    icon: 'info',
                    confirmButtonText: getLang.ok_got_it,
                    iconColor: getBizColorNumber(3),
                    confirmButtonColor: getBizColorNumber(3),
                });

                setShowCancelSubscriptionButton(false);
            } else {

                setMessageConfirmation(getLang.unable_to_cancel_at_this_moment);
                setStateDialog('confirmationMessage');
            }

            setIsLoadingData(false);
        }
    }

    function getPriceValue() {

        return fromPersonalZone ? custData[keyVar + '_price'] : obj.md_price;
    }

    function getBillingTypeValue() {

        return fromPersonalZone ? custData.csu_billing_type : extraData.billing_type;
    }

    function getBillingPeriodUnitValue() {

        return fromPersonalZone ? custData.csu_billing_period_unit : extraData.billing_period_unit;
    }

    function getUsageRulePeriodUnit() {

        return fromPersonalZone ? (isSubscription ? custData.csu_usage_period_unit : custData.cpp_usage_rule_period) : extraData.usage_rule_period_unit;
    }

    function getUsageRuleCapacity() {

        return fromPersonalZone ? custData[keyVar + '_usage_capacity'] : extraData.usage_rule_capacity;
    }

    function getUsageRuleType() {

        return fromPersonalZone ? custData[keyVar + '_usage_rule'] : extraData.usage_rule_type;
    }

    function getUsagePeriod() {

        return fromPersonalZone ? (getCsuUsageEndTime() !== '' ? 'period' : 'period') : extraData.usage_period;
    }

    function getUsagePeriodUnit() {

        return fromPersonalZone ? (isSubscription ? custData.csu_usage_period_unit : custData.cpp_usage_rule_period) : extraData.usage_period_unit;
    }

    function getStartUsagePeriodTimestamp() {

        var usagePeriodStartVar = extraData.usage_period_start;

        return !checkIfNullUndefined(usagePeriodStartVar) ? usagePeriodStartVar : '';
    }

    function getEndUsagePeriodTimestamp() {

        var usagePeriodEndVar = extraData.usage_period_end;

        return !checkIfNullUndefined(usagePeriodEndVar) ? usagePeriodEndVar : '';
    }

    function getCreatedDate() {

        return fromPersonalZone ? custData[keyVar + '_purchase_time'] : obj.md_date;
    }

    function getCsuUsageEndTime() {

        var csuUsageEndTimeVar = custData[keyVar + '_usage_end_time'];

        return !checkIfNullUndefined(csuUsageEndTimeVar) ? csuUsageEndTimeVar : '';
    }

    function getTotalUses() {

        return fromPersonalZone ? custData.cpp_usage_capacity : extraData.total_uses;
    }

    function getCanCancel() {

        return extraData.can_cancel;
    }

    function getShowCancelSubscription() {

        return fromPersonalZone &&
            (custData[keyVar + '_status'] === 'active' ||
                custData[keyVar + '_status'] === 'frozen');
    }

    function getFromPersonalZoneFromRelevevantCase() {

        if (!checkIfNullUndefined(fromPersonalZoneFromList)) {

            return fromPersonalZoneFromList;
        } else {

            return convertFromStringToBoolean(paramsFromUrl.fromPersonalZone);
        }
    }

    function getCurrentObjFromRelevevantCase() {

        if (!checkIfNullUndefined(currentObjFromList)) {

            return currentObjFromList;
        } else {

            return paramsFromUrl;
        }
    }

    function getBillingPeriodFullLabel() {

        var billingPeriodFullLabel;

        if (isSubscription) {

            billingPeriodFullLabel = getPriceValue() + ' ' + bizObject.ess_currency + ' / ' + `${getBillingPeriodLabel()}`; // eslint-disable-line
        } else {

            billingPeriodFullLabel = getLang.number_entries.replaceAll('#number#', getTotalUses());
        }

        return billingPeriodFullLabel;
    }

    function getBillingPeriodLabel() {

        var billingReplace = '';

        if (getBillingTypeValue() === 'once') {

            billingReplace = getLang.one_time;
        } else {

            var csuBillingPeriodUnit = getBillingPeriodUnitValue();

            switch (csuBillingPeriodUnit) {

                case 'day':
                    billingReplace = getLang.day;
                    break;

                case 'hour':
                    billingReplace = getLang.hour;
                    break;

                default:
                    billingReplace = csuBillingPeriodUnit + getLang.ly;
            }
        }

        return billingReplace;
    }

    function getUsageLabel() {

        var usageValue;

        var usageRulePeriodUnit = getUsageRulePeriodUnit();
        var usageRuleCapacity = getUsageRuleCapacity();

        if (getUsageRuleType() === 'unlimited') {

            usageValue = getLang.unlimited;
        } else {

            if (!fromPersonalZone) {

                var replaceString;

                if (usageRulePeriodUnit === 'day') {

                    replaceString = getLang.day;
                } else if (!isSubscription && usageRulePeriodUnit === 'hour') {

                    replaceString = getLang.hour;
                } else {

                    replaceString = usageRulePeriodUnit + getLang.ly;
                }

                var usageFullText;

                if (usageRuleCapacity === '1') {

                    usageFullText = getLang.number_entry_a_time
                        .replaceAll('#number#', usageRuleCapacity)
                        .replaceAll('#time#', replaceString);
                } else {

                    usageFullText = getLang.number_uses_time
                        .replaceAll('#number#', usageRuleCapacity)
                        .replaceAll('#time#', replaceString);
                }

                usageValue = usageFullText;
            } else {

                var ruleStr = !isSubscription ? 'rule_' : '';

                var csuUsageUsesLeft = custData[keyVar + '_usage_' + ruleStr + 'uses_left'];
                var csuUsageNextRenew = custData[keyVar + '_usage_' + ruleStr + 'next_renew'];

                if (csuUsageUsesLeft > 0) {

                    var daysLeft = calculateDiffDaysFromToday(csuUsageNextRenew);
                    var usageValueText;

                    if (daysLeft === '0' || daysLeft === '') {

                        usageValueText = getLang.number_uses_left
                            .replaceAll('#number#', csuUsageUsesLeft)
                            .replaceAll('#item_type#', getLang.subscription);
                    } else {

                        usageValueText = getLang.number_uses_left_within
                            .replaceAll('#number#', csuUsageUsesLeft)
                            .replaceAll('#days_left#', daysLeft)
                            .replaceAll('#item_type#', isSubscription ? getLang.subscription : getLang.multipass);
                    }

                    if (daysLeft === '1') {

                        usageValueText = usageValueText.replaceAll('days', getLang.day);
                    }

                    usageValue = usageValueText;
                } else {

                    var endDate = getFormatedDateSQL(csuUsageNextRenew);

                    if (endDate === '') {

                        usageValue = getLang.reached_limitation_of_number_times_a_time
                            .replaceAll('#number#', usageRuleCapacity)
                            .replaceAll('#time#', usageRulePeriodUnit);
                    } else {

                        usageValue = getLang.reached_limitation_of_number_times_a_time
                            .replaceAll('#number#', usageRuleCapacity)
                            .replaceAll('#time#', usageRulePeriodUnit)
                            .replaceAll('#date#', endDate)
                    }
                }
            }
        }

        return usageValue;
    }

    function getIncludedListTitle() {

        var replaceString;

        var itemType = connectedItems.length === 0 ? 'other' : connectedItems[0].item_type;

        switch (itemType) {

            case 'service':
                replaceString = getLang.tab_services;
                break;

            case 'product':
                replaceString = getLang.products;
                break;

            case 'class':
                replaceString = getLang.classes;
                break;

            default:
                replaceString = getLang.items;
        }

        return getLang.included_type_list.replaceAll("#type#", replaceString);
    }

    function getValidThroughLabel() {

        var validThroughValue;
        var startTime;
        var endTime = '';
        var timeBuilder = '';

        var usagePeriod = getUsagePeriod();
        var usagePeriodUnit = getUsagePeriodUnit();

        if (usagePeriod === 'unlimited') {

            validThroughValue = getLang.unlimited_time;
        } else if (usagePeriod === 'limited') {

            var usagePeriodNumber = extraData.usage_period_number;

            var validThroughBuilder = usagePeriodNumber + ' ';

            if (usagePeriodUnit === 'day') {

                validThroughBuilder += getLang.day;
            } else {

                validThroughBuilder += usagePeriodUnit;
            }

            if (usagePeriodNumber !== '1') {

                validThroughBuilder += getLang.s;
            }

            validThroughValue = validThroughBuilder
        } else {

            var createdDate = getCreatedDate();

            if (!fromPersonalZone) {

                var startUsagePeriodTimestamp = getStartUsagePeriodTimestamp();
                var endUsagePeriodTimestamp = getEndUsagePeriodTimestamp();

                if (startUsagePeriodTimestamp === '') {

                    startTime = getFormatedDateSQL(createdDate);
                } else {

                    startTime = getFormattedDate(convertTimestampToDate(startUsagePeriodTimestamp * 1000), 'MMM dd, yyyy');
                }

                if (endUsagePeriodTimestamp !== '') {

                    endTime = getFormattedDate(convertTimestampToDate(endUsagePeriodTimestamp * 1000), 'MMM dd, yyyy');
                }

                if (endTime === '') {

                    timeBuilder += getLang.booking_search_from + ': ' + startTime;
                } else {

                    timeBuilder += startTime + ' - ' + endTime;
                }

                validThroughValue = timeBuilder;
            } else {

                var csuUsageStartTime = custData[keyVar + '_usage_start_time'];
                var csuUsageEndTime = getCsuUsageEndTime();

                if (csuUsageStartTime === '') {

                    startTime = getFormatedDateSQL(createdDate);
                } else {

                    startTime = getFormatedDateSQL(csuUsageStartTime);
                }

                if (csuUsageEndTime !== '') {

                    endTime = getFormatedDateSQL(csuUsageEndTime);
                }

                if (endTime === '') {

                    timeBuilder += getLang.booking_search_from + ': ' + startTime;
                } else {

                    timeBuilder += startTime + ' - ' + endTime;
                }

                validThroughValue = timeBuilder;
            }
        }

        return validThroughValue;
    }

    function getCancellationPolicyLabel() {

        var canCancelVar = getCanCancel() === '1';

        return canCancelVar ? getLang.self_cancellation_enabled : getLang.please_contact_to_cancel;
    }

    function cancelSubscriptionClicked() {

        var canCancelVar = getCanCancel() === '1';

        if (!canCancelVar) {

            const textToReplace = getLang.please_contact_app_name_owner.replaceAll("#app_name#", bizObject.biz_short_name);

            setMessageConfirmation(textToReplace);
            setStateDialog('confirmationMessage');
        } else {

            setStateDialog('cancelSubscription');
        }

        setIsDialogOpen(true);
    }

    function claimButtonClicked() {

        if (bizObject.paymentOptions.length === 0) {
            Toast.fire({
                allowOutsideClick: false,
                title: getLang.no_payment_options,
                icon: 'info',
                confirmButtonText: getLang.ok,
                iconColor: getBizColorNumber(3),
                confirmButtonColor: getBizColorNumber(3),
            });
            return;
        }


        if (isSubscription && !hasCreditCardOption()) {

            Toast.fire({
                allowOutsideClick: false,
                title: getLang.cc_required,
                icon: 'info',
                confirmButtonText: getLang.ok,
                iconColor: getBizColorNumber(3),
                confirmButtonColor: getBizColorNumber(3),
            });
            return;
        }

        setIsDrawerOpen(true);
    }

    function hasCreditCardOption() {

        let cc = false;

        bizObject.paymentOptions.forEach(item => {
            if (item.name === 'stripe' || item.name === 'isracard360') {
                cc = true;
            }
        })

        return cc;
    }

    function closeDrawer() {

        setIsDrawerOpen(false);
    }

    async function closeDialogCancelSubscription(isOk, feedback) {

        setIsDialogOpen(false);

        if (isOk) {

            const map = new Map();

            map['cust_sub_id'] = custData[keyVar + '_id'];
            map['cancellation_notes'] = feedback;

            await cancelSubscriptionApi(map);
        }
    }

    function closeDialogConfirmationMessage(isOk) {

        setIsDialogOpen(false);

        if (isOk) {

            const email = bizObject.biz_email;

            window.open(`mailto:${email}`);
        }
    }

    function getItemType() {

        var itemType = '';

        switch (currentModId) {

            case 10:

                itemType = 'subscription';
                break;

            case 11:

                itemType = 'multipass';
                break;
            default:
        }

        return itemType;
    }
    /* Logic functions - end */

    /* UI - start */
    /* UI - without UI functions - start */
    return (
        <DivBackgroundStyled>

            {isLoadingData ?

                <LoadingComponent isLoading={isLoadingData} /> :

                <ScrollableDivStyled className="info-page-holder"
                    height='100vh'
                    style={{ marginLeft: '20px', marginRight: '20px', marginBottom: '20px' }}>

                    {valueBarSpacer()}

                    {headerLayout()}

                    {spacer()}

                    {qrCodeLayout()}

                    {priceLayout()}

                    {descriptionLayout()}

                    <hr style={{ backgroundColor: getBizColorNumber(2) + '66' }} />

                    {billingLayout()}

                    {spacer()}

                    {usageLayout()}

                    {spacer()}

                    {connectedItemsLayout()}

                    {spacer()}

                    {validThroughLayout()}

                    {spacer()}

                    {disclaimerLayout()}

                    {cancellationPolicyLayout()}

                    {fromPersonalZone ?status():""}
                        
                    {floatingButton()}

                    {bottomNavigationViewSpacer()}

                </ScrollableDivStyled>
            }

            {drawerComponent()}

            {dialogComponent()}

        </DivBackgroundStyled>
    )
    /* UI - without UI functions - end */

    /* UI - with UI functions - start */

    function status(){
        return(
            <div>
                {fullParagraphLayout(getLang.status, statusStringForStatus(isSubscription ? obj.cust_sub_data.csu_status : obj.cust_pass_data.cpp_status))}
            </div>
        )
    }


    function headerLayout() {

        return <H3Styled
            textAlign='left'>
            {obj.md_head}
        </H3Styled>
    }

    function qrCodeLayout() {

        var csuQrUrl = fromPersonalZone ? custData[keyVar + '_qr_url'] : '';

        return fromPersonalZone &&
            custData[keyVar + '_status'] === 'active' &&
            !checkIfNullUndefinedEmpty(csuQrUrl) ?

            <div>

                <QRCodeComponent size={90} value={csuQrUrl} />

                {spacer()}

            </div> :

            <div />
    }

    function priceLayout() {

        return !fromPersonalZone ?

            <div>

                {paragraph2Label(getBillingPeriodFullLabel())}

                {spacer()}

            </div> :

            <div />
    }

    function descriptionLayout() {

        return paragraphLabel(obj.md_desc)
    }

    function billingLayout() {

        var titleVar = isSubscription ? getLang.billing : getLang.number_of_entries
        var labelVar = isSubscription ? getBillingPeriodFullLabel() : getTotalUses();

        return fullParagraphLayout(titleVar, labelVar)
    }

    function usageLayout() {

        return fullParagraphLayout(getLang.limitation, getUsageLabel());
    }

    function connectedItemsLayout() {

        return <div>

            {paragraphTitle(getIncludedListTitle())}

            {connectedItems
                .map(
                    (item) =>

                        <div key={item.ext_id}>
                            {paragraphLabel(item.title)}
                        </div>
                )}

        </div>
    }

    function validThroughLayout() {

        return fullParagraphLayout(getLang.valid_through, getValidThroughLabel());
    }

    function disclaimerLayout() {

        var disclaimerVar = obj.md_info;

        return disclaimerVar !== '' ?

            <div>

                {fullParagraphLayout(getLang.disclaimer, disclaimerVar)}

                {spacer()}

            </div> :

            <div />;
    }

    function cancellationPolicyLayout() {

        return isSubscription ?

            <div>

                {fullParagraphLayout(getLang.cancellation_policy, getCancellationPolicyLabel())}

                {getShowCancelSubscription() &&
                    showCancelSubscriptionButton ?

                    <P2Styled
                        onClick={cancelSubscriptionClicked}>
                        {getLang.cancel_my_subscription}
                    </P2Styled> :

                    <div />}

                {spacer()}

            </div> :

            <div />
    }

    function floatingButton() {

        return !fromPersonalZone ?

            <FloatingButtonStyled className="floating-btn" onClick={claimButtonClicked}>{getLang.purchase}</FloatingButtonStyled> :

            <div />
    }

    function drawerComponent() {

        return <DrawerComponent
            children={getCurrentDrawerChild()}
            isDrawerOpen={isDrawerOpen}
            closeDrawer={closeDrawer} />
    }

    function dialogComponent() {

        return <DialogTransitionComponent
            open={isDialogOpen}
            children={getCurrentDialogChild()}
            dialogClosed={() => setIsDialogOpen(false)} />
    }

    function getCurrentDrawerChild() {

        return <Cart itemType={getItemType()} itemId={currentRowId} onClose={closeDrawer} />
    }

    function getCurrentDialogChild() {

        var children;

        if (stateDialog === 'cancelSubscription') {

            children = getCancelSubscriptionComponent();
        } else {

            children = getConfirmationMessageComponent();
        }

        return children;
    }

    function getCancelSubscriptionComponent() {

        return <CancelSubscriptionComponent dialogClosed={closeDialogCancelSubscription} />
    }

    function getConfirmationMessageComponent() {

        return <ConfirmationMessageComponent
            message={messageConfirmation}
            buttonOkText={getLang.contact}
            buttonCancelText={getLang.close}
            dialogClosed={closeDialogConfirmationMessage} />
    }

    function fullParagraphLayout(title, label) {

        return <div>

            {paragraphTitle(title)}

            {paragraphLabel(label)}

        </div>
    }

    function paragraphTitle(title) {

        return <POpacityStyled
            opacity={'66'}
            textAlign='left'
            marginBottom={'0px'}>
            {title}
        </POpacityStyled>
    }

    function paragraphLabel(label) {

        return paragraphLabelStyle(label)
    }

    function paragraphLabelStyle(label, style) {

        return <PStyled
            style={style}
            textAlign='left'
            marginBottom='0px'>
            {label}
        </PStyled>
    }

    function paragraph2Label(label) {

        return paragraph2LabelStyle(label)
    }

    function paragraph2LabelStyle(label, style) {

        return <P2Styled
            style={style}
            textAlign='left'
            marginBottom='0px'>
            {label}
        </P2Styled>
    }

    function spacer() {

        return <SpacerStyled height='20' />
    }
    /* UI - with UI functions - end */
    /* UI - end */
}