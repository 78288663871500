import styled from 'styled-components'

export const ParentDivStyled = styled.div`
    text-align: center;
    width: 100%;
    height: 280px;
    background-color:#ffffff;`

export const HeaderDivStyled = styled.div`
    padding-top: 15px;
    background-color:#ffffff;`

export const TextDivStyled = styled.div`
    padding-top: 5px;
    background-color:#ffffff;`

export const ScratchCardTitleStyled = styled.h1`
    z-index: 2;
    position: absolute;
    top: 0px;
    left: 0px;
    color: white;
    pointer-events: none;
    width: 50%;
    padding: 30px;`