import styled from "styled-components";
import { getBizColorNumber } from './bizStyle';

export const HeaderDiv = styled.div`
  padding-top: 10%;
`;

export const TitleStyled = styled.h1`
    font-size: 1.5em;
    text-align: center;
    padding-top: 20px;
    padding-left: 20px;
    padding-right: 20px;
    color:${() => getBizColorNumber(2)};`

export const SubTitleStyled = styled.h4`
    font-size: 1em;
    text-align: center;
    padding-top: 20px;
    padding-left: 20px;
    padding-right: 20px;
    color:${() => getBizColorNumber(2)};`

export const BottomLabelStyled = styled.h5`
    font-size: 1.1em;
    text-align: center;
    padding-top: 20px;
    padding-left: 20px;
    padding-right: 20px;
    color:${() => getBizColorNumber(2)};`

    export const DivContactUsStyled = styled.div`
    position: relative;
    textAlign: right;
    padding: 20;`

    export const ContactUsLabelStyled = styled.span`
    text-decoration-line: underline;
    color: ${() => getBizColorNumber(3)};
    font-weight: bold;
    cursor: pointer;`