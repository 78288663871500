import React, { useState, useEffect } from 'react';
import { getBizColorNumber } from '../../biz_styles/bizStyle';
import InnerTabs from './Tabs'
import './PersonalPoints.css'
import { TitleStyled } from '../../biz_styles/PersonalPointsStyle'
import { DivCenterStyled } from '../../biz_styles/ReusableStyles'
import { getPersonalzone } from '../../api/ApiRequests'
import LoadingComponent from '../../components/loading_components/LoadingComponent'
import { IconStyledSvg } from '../../biz_styles/IconsStyles'
import { getLanguage } from '../../shared/redux/GlueStoreData';




export default function PersonalPoints({ drawerClosed }) {

    const [isLoadingData, setIsLoadingData] = useState(true);
    const [data, setData] = useState("")
    const getLang = getLanguage();


    useEffect(() => {
        (async () => {
            setData(await getPersonalzone("points"))
            setIsLoadingData(false)
        })()
    }, []);


    return (
        <div
            className='personal-points-holder'
            style={{ backgroundColor: getBizColorNumber(1) }}>

            <DivCenterStyled style={{ marginTop: 50 }}>
                <IconStyledSvg name="StarDuotune.svg" cssName='fill-color2' size={42} />
            </DivCenterStyled>

            <DivCenterStyled>
                <TitleStyled>{getLang.points}</TitleStyled>
            </DivCenterStyled>

            {isLoadingData ? <LoadingComponent isLoading={isLoadingData} /> : <InnerTabs pointsData={data.points} drawerClosed={drawerClosed} />}
        </div>
    )
}
