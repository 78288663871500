import * as React from "react";
import Tab from "@mui/material/Tab";
import { getBizColorNumber } from "../../biz_styles/bizStyle";
import { TabPanel } from "@mui/lab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import BalancePointsScreen from "./balance_points/BalancePointsScreen";
import HistoryPointsScreen from "./history_points/HistoryPointsScreen";
import ReferralsPointsScreen from "./referrals_points/ReferralsPointsScreen";
import { getBiz, getLanguage } from "../../shared/redux/GlueStoreData";
import {ScrollableDivStyled} from '../../biz_styles/ReusableStyles'
import { useStoreProvider } from '../../shared/providers/StoreContext'

function InnerTabs({ pointsData, drawerClosed }) {
  const getLang = getLanguage();
  const bizObject = useStoreProvider().biz
  const findReferralMoudle = bizObject.tabbar.filter(
    (moduleId) => moduleId.fu_id === "12"
  );
  const showReferrals = findReferralMoudle.length > 0;

  const [value, setValue] = React.useState("balance");

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <TabContext value={value}>
      <TabList
        value={value}
        onChange={handleChange}
        variant="fullWidth"
        sx={{
          "& .MuiTab-root": {
            backgroundColor: getBizColorNumber(4),
            color: getBizColorNumber(2) + 66,
          },
          "& .MuiTabs-indicator": { backgroundColor: getBizColorNumber(3) },
          "& .MuiTab-root.Mui-selected": { color: getBizColorNumber(2) },
        }}
      >
        <Tab value="balance" label={getLang.tab_balance} />
        {showReferrals ? <Tab value="referrals" label={getLang.tab_friends} /> : ""}
        <Tab value="history" label={getLang.tab_history} />
      </TabList>

      <ScrollableDivStyled id="scrollableDiv" height='calc(100% - 250px)' >

        <TabPanel value="balance">
          <BalancePointsScreen popularItemData={pointsData.popular_item} drawerClosed={drawerClosed} />
        </TabPanel>
        <TabPanel value="referrals">
          <ReferralsPointsScreen friendsData={pointsData.friends} />
        </TabPanel>
        <TabPanel value="history">
          <HistoryPointsScreen prevHistoryData={pointsData.history} />
        </TabPanel>

      </ScrollableDivStyled>
    </TabContext>
  );
}

export default InnerTabs;
