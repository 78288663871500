import React, { Component } from 'react';
import "../section_title_cell/SectionTitleCell.css"
import { P2Styled, getBizColorNumber } from '../../../../biz_styles/bizStyle';

class SectionTitleCell extends Component {
    state = {}
    render() {
        return (
            <div className='section-title-div'>
                <span className='section-title-label bold-font' style={{ color: getBizColorNumber(2) }}>{this.props.title}</span>
                {this.props.actionLabel ?
                    <P2Styled
                        className='float-end'
                        onClick={() => this.props.onActionClicked()}
                        style={{ textTransform: 'uppercase' }}>
                        {this.props.actionLabel}
                    </P2Styled> : null}
            </div>
        );
    }
}

export default SectionTitleCell;