import React from 'react';
import { useNavigate } from "react-router-dom"
import {
    ContainerCardStyled,
    CardImageStyled,
    DivTopImageStyled,
    CardTopImageStyled,
    LabelCardStyled,
    DivTitlecStyled,
    DivCenterStyled,
    DivDescStyled,
    DescTitleStyled,
    SvgIconStyle,
    DivCustomYStyled,
    SizeableFontPStyled
  } from "../../../biz_styles/IncentivesStyle";
  import { IconStyledSvg, ImageStyledSvg } from '../../../biz_styles/IconsStyles';
  import { getProgramTypeForCard } from '../../../shared/loyalty_helper/LoyaltyHelper'
import { setOpenMyLoyaltyCards } from '../../../shared/static_helper/StaticHelper';
import { getLanguage } from '../../../shared/redux/GlueStoreData';



function StampCardItemCell({obj}){

  const navigate = useNavigate();
  const getLang = getLanguage();

  function openInfoScreen() {
    const fromPersonalZone = false;
    const currentObj = obj;

    setOpenMyLoyaltyCards(false);

    navigate(`../pages/infoScreen/${fromPersonalZone}/${parseInt(currentObj.md_level_no)}/${currentObj.md_mod_id}/${currentObj.md_row_id}`,
      {
        state: {
          fromPersonalZone,
          currentObj
        }
      });
  }

  return (
    <ContainerCardStyled className="card-holder" width={250} height={465} onClick={() => openInfoScreen()}>

      <ImageStyledSvg cssName={'fill-color4'} name="incentive_card_custom.svg" width="250" height="465" />

      <DivTopImageStyled>
        <CardTopImageStyled src={obj.md_pic}></CardTopImageStyled>
        <LabelCardStyled className="card-label">{getProgramTypeForCard(obj)}</LabelCardStyled>
      </DivTopImageStyled>

      <DivTitlecStyled>
        <p>{obj.md_head}</p>
      </DivTitlecStyled>
      
      <DivCenterStyled className="card-status">
        <p>{obj.customer_card_stamp_no + "/" + obj.md_stamp_number + " " + getLang.punched}</p>
      </DivCenterStyled>


      <DivCustomYStyled className='row' top={335}>
          <IconStyledSvg cssName={'fill-color2'} name="book_new.svg" size='20' />
          <SizeableFontPStyled size={13} padding={6}>{getLang.description}</SizeableFontPStyled>                
      </DivCustomYStyled>

      <DivCustomYStyled top={370}>
          <SizeableFontPStyled size={13} opacity={0.5}> {obj.md_desc.length > 100 ? obj.md_desc.substring(0,100) + "..." : obj.md_desc } </SizeableFontPStyled>
      </DivCustomYStyled>

  </ContainerCardStyled>
  )
}
 
export default StampCardItemCell;