import React from 'react';
import ClassicInputComponent from '../../../../components/input_components/classic_input_component/ClassicInputComponent';
import './PhoneTextFieldFormComponent.css';

export default function PhoneTextFieldFormComponent({ onClick, onChange, defaultValue }) {

    /* UI - start */
    /* UI - without UI functions - start */
    return <ClassicInputComponent
        className='input-text-field-form ltr-input'
        onClick={onClick}
        onChange={onChange}
        defaultValue={defaultValue} />
    /* UI - without UI functions - end */
    /* UI - end */
}