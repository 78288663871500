import React from 'react';
import { getBizColorNumber, PStyled } from '../../../../biz_styles/bizStyle';
import './TitleFieldFormComponent.css';

class TitleFieldFormComponent extends React.Component {

    /* UI - start */
    /* UI - without UI functions - start */
    render() {

        return (
            <PStyled
                className='p-title-field-form'>
                <span
                    style={{ color: getBizColorNumber(3) }}>
                    {this.props.requireParam === '1' ? '* ' : ''}
                </span>
                {this.props.value}
            </PStyled>
        )
    }
    /* UI - without UI functions - end */
    /* UI - end */
}

export default TitleFieldFormComponent;