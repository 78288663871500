import ReactCodeInput from "react-code-input"
import React, { useState, useRef, useEffect } from 'react'
import { useNavigate, useLocation } from "react-router-dom"
import LoadingComponent from '../../components/loading_components/LoadingComponent'
import LoginFormHeader from '../login/LoginFormHeader'
import '../login/LoginScreen.css';
import { GET_CUSTOMER_DATA, IS_LOGGED_IN } from "../../actions/actionTypes"
import { store } from '../../shared/redux/store'
import { getCustomerAction } from "../../actions/customerActions"
import { setIsLoggedInAction } from '../../actions/accountActions'
import { WelcomeBackground, LoginDivStyled, NextButtonStyled } from '../../biz_styles/LoginStyle';
import { validatePhoneCodeForUserDataDeletion, validateClubMemberByEmail, resendPhoneCode, deleteClient } from "../../api/ApiRequests"
import { checkIfNullUndefined, getBizHashFromUrl } from "../../shared/static_helper/StaticHelper"
import ReCAPTCHA from "react-google-recaptcha"
import { getLanguage, isLoggedIn, clearStoreData } from '../../shared/redux/GlueStoreData'
import { getBizColorNumber, DivCenterStyled, Toast } from "../../biz_styles/bizStyle"
import Swal from "sweetalert2"

function DeleteUserDataSmsCode() {

    const [isLoading, setIsLoading] = useState(false);
    const [isDisabled, setisDisabled] = useState(true);
    const codeInputRef = useRef(undefined)
    const navigate = useNavigate();
    const captchaRef = useRef()
    const useLocationParam = useLocation();
    const useLocationParamState = useLocationParam.state;
    const getLang = getLanguage();
    var phoneNumber = ""
    var email = ""
    var sendTo = ""

    if (!checkIfNullUndefined(useLocationParamState)) {
        if (!checkIfNullUndefined(useLocationParamState.phoneNumber)) {
            phoneNumber = useLocationParamState.phoneNumber;
            sendTo = useLocationParamState.phoneNumber;
        }
        if (!checkIfNullUndefined(useLocationParamState.email)) {
            email = useLocationParamState.email;
            sendTo = useLocationParamState.email;
        }
    }

    function clearCodeInput() {
        for (var i = 0; i < 6; i++) {
            codeInputRef.current.state.input[i] = ""
        }
    }

    const handlePhoneCode = (e) => {
        if (String(e).replace(/[A-Za-z]/g, "").length === 6) {
            setIsLoading(true)
            validatePhone(String(e));
        }
    };

    async function validatePhone(code) {
        const res = await validatePhoneCodeForUserDataDeletion(code)
        if (res !== undefined) {

            store.dispatch(getCustomerAction(GET_CUSTOMER_DATA, getBizHashFromUrl(), res.responseData))
            store.dispatch(setIsLoggedInAction(IS_LOGGED_IN, getBizHashFromUrl(), false))
            setIsLoading(false)

            if (res.responseMessage === 'can_delete') {
                setisDisabled(false)
            } else {
                setisDisabled(true)
                showError(getLang.sorry, getLang.something_went_wrong_try_again)
                clearCodeInput()
            }

        } else {
            setisDisabled(true)
            setIsLoading(false)
            clearCodeInput()
        }
    }

    function onClickDelete() {
        showConfirmationAlert()
    }

    async function SendCodeAgain(phoneNumber) {
        setIsLoading(true)
        const token = await captchaRef.current.executeAsync();
        const res = await resendPhoneCode(phoneNumber, token)
        if (res === undefined) {
            setIsLoading(false)
            captchaRef.current.reset();
            showError(getLang.sorry, getLang.something_went_wrong_try_again)
        }
        setIsLoading(false)
        captchaRef.current.reset();
    }

    async function SendEmailAgain(email) {
        const token = await captchaRef.current.executeAsync();
        setIsLoading(true)
        const res = await validateClubMemberByEmail(email, token)
        if (res === undefined) {
            setIsLoading(false)
            captchaRef.current.reset();
            showError(getLang.sorry, getLang.something_went_wrong_try_again)
        }
        setIsLoading(false)
        captchaRef.current.reset();
    }

    function showConfirmationAlert() {
        Toast.fire({
            showDenyButton: true,
            text: getLang.sorry_just_to_make_sure,
            icon: 'info',
            confirmButtonText: getLang.yes_delete,
            denyButtonText: getLang.no_i_regretted,
            iconColor: getBizColorNumber(3),
            confirmButtonColor: getBizColorNumber(3)
        }).then((result) => {

            if (result.isConfirmed) {
                Toast.fire({
                    title: getLang.please_wait,
                    didOpen: () => {
                        Swal.showLoading()
                    }
                })
                deleteAccount()

            } else if (result.isDenied) {
                store.dispatch(setIsLoggedInAction(IS_LOGGED_IN, getBizHashFromUrl(), true))
                navigate("../main");
            }
        })
    }

    async function deleteAccount() {

        const responseCode = await deleteClient();

        if (responseCode === 1) {
            clearStoreData()
            navigate('../')
        }
    }

    function showError(title, text) {
        Toast.fire({
            title: title,
            text: text,
            icon: "error",
            confirmButtonText: getLang.ok,
            confirmButtonColor: getBizColorNumber(3),
        });
    }

    const props = {
        className: ReactCodeInput,
        inputStyle: {
            fontFamily: 'monospace',
            margin: '4px',
            width: '42px',
            borderRadius: '6px',
            fontSize: '25px',
            height: '42px',
            paddingLeft: '13px',
            backgroundColor: 'white',
            color: 'black',
            border: '1px solid lightgrey'
        }
    }

    return (
        <WelcomeBackground>
            <LoginDivStyled>
                <div className='parent-div'>
                    <LoadingComponent isLoading={isLoading} />

                    <DivCenterStyled>
                        <LoginFormHeader
                            title={getLang.delete_account}
                            subtitle={getLang.this_action_will_erase_your_data} />
                    </DivCenterStyled>

                    <ReactCodeInput
                        ref={codeInputRef}
                        autoFocus={true}
                        name="codeConfirmation"
                        inputMode="numeric"
                        fields={6}
                        type="text"
                        {...props}
                        onChange={(e) => handlePhoneCode(e)}
                    />

                    <div>
                        <ReCAPTCHA
                            sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
                            ref={captchaRef}
                            size="invisible"
                        />
                        <p onClick={phoneNumber === "" ? () => SendEmailAgain(email) : () => SendCodeAgain(phoneNumber)} style={{ color: getBizColorNumber(3), cursor: 'pointer' }}>{getLang.didnt_get_an_sms_send_again}</p>
                    </div>

                    <DivCenterStyled>
                        <NextButtonStyled
                            onClick={onClickDelete}
                            disabled={isDisabled} >
                            {getLang.delete}
                        </NextButtonStyled>
                    </DivCenterStyled>

                </div>
            </LoginDivStyled>
        </WelcomeBackground>

    )

}

export default DeleteUserDataSmsCode