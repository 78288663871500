import React, { useState } from "react";
import { getBizColorNumber, DivHorizontalStyled, PStyled, POpacityStyled } from "../../../biz_styles/bizStyle";
import { getDateFormatWithDateFormat } from "../../../shared/static_helper/StaticHelper";
import './HistoryPointsScreen.css'
import { InfiniteScroller } from "../../../components/Infinite_scroller/InfiniteScroller";
import { getMoreHistory } from "../../../api/ApiRequests"
import { getLanguage } from "../../../shared/redux/GlueStoreData";



export default function HistoryPointsScreen({ prevHistoryData }) {

    const [historyData, setHistoryData] = useState(prevHistoryData);
    const [hasMore, setHasMore] = React.useState(true);
    const getLang = getLanguage();


    async function callApi() {

        const dataFromApi = await getMoreHistory(historyData.length)
        setHistoryData(prevState => ([...prevState, ...dataFromApi]));

        if (dataFromApi.length < 10) {
            setHasMore(false)
        }

    }


    function nothingHereYetLayout() {

        return <div
            style={{
                display: 'flex',
                justifyContent: 'center'
            }}>

            <PStyled>{getLang.nothing_here_yet}</PStyled>

        </div>
    }

    function showHistoryList() {

        return historyData
            .map(
                (historyItem) =>

                    <div key={historyItem.entry_id}>
                        {cellForHistoryItem(historyItem)}
                    </div>
            );
    }

    function cellForHistoryItem(historyItem) {

        return <div>

            <DivHorizontalStyled>

                <div>

                    <PStyled
                        style={{ margin: '0px' }}>
                        <span style={{direction: 'ltr', unicodeBidi: 'plaintext'}}>{historyItem.points}</span> {getLang.points}
                    </PStyled>

                    <POpacityStyled
                        opacity={'66'}
                        style={{
                            fontSize: '13px',
                            margin: '0px'
                        }}>
                        {historyItem.description}
                    </POpacityStyled>

                </div>

                <POpacityStyled
                    className='p-date-list-layout-history-item-history-points'
                    opacity={'66'}
                    style={{ fontSize: '13px' }}>
                    {getDateFormatWithDateFormat(historyItem.date, 'll')}
                </POpacityStyled>

            </DivHorizontalStyled>

            <hr style={{ backgroundColor: getBizColorNumber(2) + '66' }} />

        </div>
    }

    return (
        <div >

            {historyData.length === 0 ?

                nothingHereYetLayout() :

                <InfiniteScroller
                    dataLength={historyData.length} //This is important field to render the next data
                    apiRequestMethod={() => callApi()} // This is the api function
                    hasMore={hasMore} // Indicator
                    children={showHistoryList} // This is the content component
                    scrollableTarget="scrollableDiv" /> // This is the parent id
            }
        </div>
    )

}