import React from 'react'
import { TitleStyled, SubTitleStyled } from '../../biz_styles/bizStyle';
import { HeaderDiv } from '../../biz_styles/LoginStyle';


function LoginHeader({ title, subtitle,candidate = "" }) {
    return (
        <HeaderDiv>
            <TitleStyled><div  class="bold-font">{title}</div></TitleStyled>
            <SubTitleStyled>{subtitle} <span className='align-left'>&#x200E;{candidate}</span></SubTitleStyled>
        </HeaderDiv>
    );
}

export default LoginHeader