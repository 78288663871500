// Language Types
export const GET_LANGUAGE_DATA = "GET_LANGUAGE_DATA";

// Biz Types
export const GET_BIZ_DATA = "GET_BIZ_DATA";
export const IS_BIZ_COLOR_CHANGED = "IS_BIZ_COLOR_CHANGED";

// Customer Types
export const GET_CUSTOMER_DATA = "GET_CUSTOMER_DATA";
export const CUSTOMER_REDIRECT_DATA = "CUSTOMER_REDIRECT_DATA"

// Account Types
export const IS_LOGGED_IN = "IS_LOGGED_IN";


// Login Types
export const SET_PHONE_NUMBER = "SET_PHONE_NUMBER";
export const SET_OTP = "SET_OTP";


