import React from 'react'
import { getBizColorNumber, DivHorizontalStyled, DivCenterStyled } from '../../../../biz_styles/bizStyle';
import { IconStyledSvg } from '../../../../biz_styles/IconsStyles'
import { DivCardStyled } from './AlsoYoursStlye'

export default function AlsoYoursCell(props) {

    return (
        <DivCardStyled >

            <DivHorizontalStyled>
                <div className='also_yours'>
                <IconStyledSvg name={props.img} cssName='fill-color3' size={32} />
                </div>
                <span style={{ color: getBizColorNumber(2), marginLeft: 10 ,marginTop:3}}>{props.title}</span>
            </DivHorizontalStyled>

        </DivCardStyled>

    );

}
