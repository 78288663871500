import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import apiClient from "../../api/apiClient";
import React, { useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { DivCenterStyled, Toast, getBizColorNumber } from "../../biz_styles/bizStyle";
import { NextButtonStyled, LoginDivStyled, DivColorStyled, WelcomeBackground } from "../../biz_styles/LoginStyle";
import { isValidPhoneNumber } from "libphonenumber-js";
import LoginHeader from "../login/LoginFormHeader";
import LoadingComponent from "../../components/loading_components/LoadingComponent";
import { useStoreProvider } from "../../shared/providers/StoreContext";
import "../login/LoginScreen.css";
import ReCAPTCHA from "react-google-recaptcha";
import { getLanguage } from "../../shared/redux/GlueStoreData";

function LoginFormPhone() {
  //const [countryCode, setCountryCode] = useState(Object);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [isValid, setIsValid] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [isDisabled, setisDisabled] = useState(true);
  const captchaRef = useRef();

  const navigate = useNavigate();
  const bizObject = useStoreProvider().biz;
  const getLang = getLanguage();
  // const emailMemberEnabled = bizObject.email_member_enabled === '0'

  // const bizMembershipMode = bizObject.biz_membership_mode === '1'

  function handleOnChange(value, data, event, formattedValue) {
    //setCountryCode(data.dialCode)
    setPhoneNumber(formattedValue);
    setIsValid(isValidPhoneNumber(formattedValue));

    if (isValidPhoneNumber(formattedValue)) {
      setisDisabled(false);
    } else {
      setisDisabled(true);
    }
  }

  const onSubmitPhoneNumber = async () => {
    const token = await captchaRef.current.executeAsync();
    if (isValid) {
      setIsLoading(true);
      submitExclusiveRegister(token);
    } else {
      showError(getLang.error, getLang.invalid_phone_number);
      captchaRef.current.reset();
    }
  };

  async function submitExclusiveRegister(captchaToken) {
    try {
      const postData = {
        phone: phoneNumber,
        token: captchaToken,
      };

      const res = await apiClient.post("/submitExclusiveRegister", postData);

      if (res.status === 200) {
        if (res.data.responseCode === 1) {
          setIsLoading(false);
          navigate("../LoginFormSmsCode",{ state: { phoneNumber }});
        } else {
          //to do handle api error//
          setIsLoading(false);
          navigate("../contactBizOwner");
        }
      } else {
        setIsLoading(false);
        showError(getLang.sorry, getLang.something_went_wrong_try_again)
        captchaRef.current.reset();
      }
    } catch (err) {
      showError(getLang.sorry, getLang.something_went_wrong_try_again)
      captchaRef.current.reset();
    }
  }

  function showError(title, text) {
    Toast.fire({
      title: title,
      text: text,
      icon: "error",
      confirmButtonColor: getBizColorNumber(3),
      confirmButtonText: getLang.ok,
    });
  }

  const styles = {
    color: getBizColorNumber(2),
    background: getBizColorNumber(4),
    borderRadius: "10px",
  };

  return (
    <WelcomeBackground>
      <LoadingComponent isLoading={isLoading} />

      <LoginDivStyled>
        <LoginHeader
          title={bizObject.biz_welcome_title}
          subtitle={getLang.lets_verify_identity_to_connect_to_your_members_profile}
        />
        <DivCenterStyled>
          <DivColorStyled isValid={isValid}>
            <PhoneInput
              name="phone"
              required={true}
              autoFocus={true}
              country={bizObject.biz_country_code.toLowerCase()}
              countryCodeEditable={false}
              autocompleteSearch={true}
              enableSearch={true}
              disableSearchIcon={true}
              inputStyle={styles}
              containerStyle={styles}
              buttonStyle={styles}
              value={phoneNumber}
              onChange={handleOnChange}
            />
          </DivColorStyled>
        </DivCenterStyled>

        <DivCenterStyled>
          <ReCAPTCHA
            sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
            ref={captchaRef}
            size="invisible"
          />
          <NextButtonStyled onClick={onSubmitPhoneNumber} disabled={isDisabled}>
          {getLang.next}
          </NextButtonStyled>
        </DivCenterStyled>
      </LoginDivStyled>
    </WelcomeBackground>
  );
}

export default LoginFormPhone;
