import { getLanguage } from "../redux/GlueStoreData";
import { getFormattedDate } from "../static_helper/StaticHelper";

export function getLabelForPunchPass(card) {

    const getLang = getLanguage();

    return card.cpp_uses_left + '\\' + card.cpp_usage_capacity + ' ' + getLang.uses_left;
}

export function getLabelForSubscription(card) {

    const getLang = getLanguage();

    var duration = '';

    switch (card.md_usage_period) {

        case 'period':

            var startDate = getFormattedDate(card.cust_sub_data.csu_usage_start_time);
            var endDate = getFormattedDate(card.cust_sub_data.csu_usage_end_time);

            duration = startDate + ' - ' + endDate;
            break;

        case 'limited':

            var number = card.md_usage_period_number;
            var unit = card.md_usage_period_unit;
            var isOne = number === '1';

            switch (unit) {

                case 'day':

                    unit = isOne ? getLang.day : getLang.days;
                    break;

                case 'week':

                    unit = isOne ? getLang.week : getLang.weeks;
                    break;

                case 'month':

                    unit = isOne ? getLang.month : getLang.months;
                    break;

                case 'year':

                    unit = isOne ? getLang.year : getLang.years;
                    break;
                default:
            }

            duration = number + ' ' + unit;
            break;

        case 'unlimited':

            duration = getLang.never_expires;
            break;

        default:
            duration = ''
            break;
    }

    return duration;
}
