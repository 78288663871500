import React, { Component } from "react";
import "./PersonalRewardCell.css";
import {
  getBizColorNumber,
  PColorStyled,
  TypeSpanStyled,
} from "../../../../biz_styles/bizStyle";
import IconTint from "react-icon-tint";
import QRCodeComponent from "../../../../components/qr_code_component/QRCodeComponent";
import BarcodeComponent from "../../../../components/barcode_component/BarCodeComponent";
import { IconStyledSvg } from "../../../../biz_styles/IconsStyles";
import { OverlayContainerStyled } from "../../../../biz_styles/PersonalDataStyle";
import { DivCenterStyled } from "../../../../biz_styles/ReusableStyles";
import { getLanguage } from "../../../../shared/redux/GlueStoreData";

class PersonalRewardCell extends Component {
  state = {
    getLang: getLanguage(),
    isPrepaidBenefit:  this.props.benefitType == "Points Shop" && this.props.benefitBehavior == "cart_discount"
  };

  
  
  

  render() {
    return (
      <div className="personal-reward-cell-holder" onClick={this.props.onClick}>
        <div
          className="personal-reward-container-view"
          style={{ backgroundColor: getBizColorNumber(4) }}
        >
          <div>
            <div className="image-holder">
              {this.props.benefitType === "punchpass" ||
              this.props.benefitType === "subscription" ||
              this.props.benefitType === "orders" ? (
                <IconTint
                  className="personal-reward-image"
                  src={this.props.image}
                  color={getBizColorNumber(3)}
                />
              ) : (
                <img
                  className="personal-reward-image"
                  src={this.props.image}
                  alt=""
                />
              )}
            </div>
          </div>

          

          <div className="personal-reward-info-row">
            <div className="personal-reward-info-holder">              
              <div
                className="personal-reward-title-label"
                style={{ color: getBizColorNumber(2) }}
              >
                {this.props.title}
              </div>
              <div className="personal-reward-image-container-view">
                <div className="personal-reward-icon">
                  <IconStyledSvg
                    cssName={"fill-color3"}
                    name={this.props.iconType}
                    size="24"
                  />
                </div>
                <span
                  className="personal-reward-type-label"
                  style={{ color: getBizColorNumber(2) }}
                >
                  {this.state.getLang.reward_type}: {this.state.isPrepaidBenefit ? this.state.getLang.prepaid_type : this.props.benefitTypeText}
                </span>
              </div>
              {!this.props.canUseNow ?
                <span className='personal-reward-no-more-uses-label'>{this.state.getLang.no_more_uses_for_now}</span>
                : ''}
            </div>
            {this.props.qr === undefined ? null : (
              <div className="personal-reward-qr-container">
                <QRCodeComponent
                  className="personal-reward-qr"
                  size={76}
                  value={this.props.qr}
                />
              </div>
            )}

            {this.props.typeLabel === undefined ? null : (
              <>
                <TypeSpanStyled style={{ left: 20, top: 170 }}>
                  {this.props.typeLabel}
                </TypeSpanStyled>
              </>
            )}
          </div>
          <div className="personal-reward-info-row">
            {this.props.barcode == '' ? ('') : (
              <BarcodeComponent value={this.props.barcode} height={70}/>
            ) }
          </div>
          

          {/* Overlay here */}
          <OverlayContainerStyled show={this.props.showOverlay}>
            <DivCenterStyled
              style={{ height: "inherit", width: "100%", display: "table" }}
            >
              <PColorStyled
                fontSize={22}
                color={getBizColorNumber(2)}
                style={{ display: "table-cell", verticalAlign: "middle" }}
              >
                {this.props.overlayText}
              </PColorStyled>
            </DivCenterStyled>
          </OverlayContainerStyled>
        </div>
      </div>
    );
  }
}

export default PersonalRewardCell;
