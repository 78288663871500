import React, { useState, useEffect } from 'react';
import { useParams } from "react-router-dom";
import Grid from "@mui/material/Grid";
import LoadingComponent from '../../components/loading_components/LoadingComponent';
import QRCodeComponent from '../../components/qr_code_component/QRCodeComponent'
import Cart from '../Cart/Cart';
import { DivBackgroundStyled, DivSideAlignStyled, ScrollableDivStyled } from '../../biz_styles/ReusableStyles';
import { getLevelData, claimFreeCoupon, getIsRedeemed, canClaimCoupon, getCoordinatesFromString } from '../../api/ApiRequests';
import { getBizColorNumber, DivCenterStyled, DivHorizontalStyled, DivCenterHorizontalStyled, H3Styled, H32Styled, PStyled, P3Styled, POpacityStyled, SpanPrivacyPolicyStyled, PhoneLabelStyled, Toast } from '../../biz_styles/bizStyle';
import { SpacerStyled, FloatingButtonStyled, DivCenter2Styled, DivDashedStyled } from '../../biz_styles/ReusableStyles';
import { DivImgItemProfileWrapper3Styled } from '../../biz_styles/ProfileStyle';
import { ImgCardStyled } from '../../biz_styles/PointsShopStyle';
import { checkIfNullUndefined, checkIfNullUndefinedEmpty, getFormatedDateSQL, getFormattedDate, openInNewTab, getSvgUrlForFileName, hyphenateText, returnImageURL, getRewardRedeemUrl, convertFromStringToBoolean, getOpenMyLoyaltyCards, valueBarSpacer, bottomNavigationViewSpacer } from '../../shared/static_helper/StaticHelper';
import { DrawerComponent } from '../../components/drawer/DrawerComponent';
import { useStoreProvider } from '../../shared/providers/StoreContext'
import { getPriceStringForPrice } from '../../shared/loyalty_helper/LoyaltyHelper';
import { getLanguage, clearRedirectData } from '../../shared/redux/GlueStoreData';
import ReactPlayer from 'react-player'
import HorizontalScroller from '../../components/horizontal_scroller/HorizontalScroller'
import GoogleMapReact from 'google-map-react';
import BarcodeComponent from "../../components/barcode_component/BarCodeComponent";

export default function InfoScreen({ fromPersonalZoneFromList, currentObjFromList }) {

    /* Logic functions - start */
    const getLang = getLanguage();
    const bizObject = useStoreProvider().biz

    const paramsFromUrl = useParams();

    const couponModId = 26;
    const stampCardModId = 6;

    const fromPersonalZone = getFromPersonalZoneFromRelevevantCase();
    const currentObj = getCurrentObjFromRelevevantCase();

    const currentModId = Number(currentObj.md_mod_id);
    const currentLevelNo = Number(currentObj.md_level_no) + 1;
    const currentRowId = Number(currentObj.md_row_id);

    const [screenData, setScreenData] = useState([]);
    const [isLoadingData, setIsLoadingData] = useState([]);
    const [isDrawerOpen, setIsDrawerOpen] = useState(false);
    const [redeemCodeStr, setRedeemCodeStr] = useState();
    const [mapData, setMapData] = useState([]);

    // Clear reward data (redirect data)
    useEffect(() => {
        clearRedirectData()
    }, []);
    

    const params = {
        mod_id: currentModId,
        level_no: currentLevelNo,
        parent: currentRowId,
        qs: '',
        subcategory: '',
        prodId: '',
        itemsPerPage: '0',
        existingItems: '0',
    };

    useEffect(() => {
        getLevelDataApi();
    }, [paramsFromUrl]);

    async function getLevelDataApi() {

        setIsLoadingData(true);

        var mapElementData;
        var mapDataExists = false

        const responseData = await getLevelData(params);

        if (!checkIfNullUndefined(responseData)) {

            const rows = responseData.rows;

            for (var i = 0; i < rows.length; i++) {
                if (rows[i].md_type === 'Map') {
                    mapElementData = rows[i]
                    mapDataExists = true
                }
                rows[i].index = i;
            }

            setScreenData(rows);

            if (fromPersonalZone &&
                checkIfNullUndefinedEmpty(rows[0].customer_card_redeemed_date)) {

                callGetIsRedeemedApi();
            }

            if (mapDataExists) {
                getCoordinates(mapElementData)
            } else {
                setIsLoadingData(false);
            }
        }
    }

    function callClaimFreeCouponApi(redeemCode) {

        const map = new Map();

        const header = getScreenDataFirstItem().header;

        map['itmName'] = !checkIfNullUndefinedEmpty(header) ? header.replaceAll("\\\\'", "'").replaceAll("\\\\\"", "\"") : header;
        map['item_id'] = getScreenDataFirstItem().item_id;
        map['redeemCode'] = redeemCode;
        map['couponID'] = currentRowId;
        map['amount'] = '0';

        claimFreeCouponApi(map);
    }

    async function claimFreeCouponApi(map) {

        setIsLoadingData(true);

        const responseCode = await claimFreeCoupon(map);

        if (!checkIfNullUndefined(responseCode)) {

            Toast.fire(
                getLang.its_yours + '!',
                getLang.you_can_see_it_in_your_personal_zone_under_my_rewards,
                'success'
            )

            setIsLoadingData(false);
        }
    }

    async function getCoordinates(mapElementData) {

        var array = mapElementData.md_info.split(',');
        const res = await getCoordinatesFromString(array[0], array[1], array[2], array[3], array[4]);

        if (!checkIfNullUndefined(res)) {
            setMapData(res)
            setIsLoadingData(false)
        }
    }

    function callGetIsRedeemedApi() {

        const map = new Map();

        map['redeemcode'] = getCustomerCardCode();

        getIsRedeemedApi(map);
    }

    async function getIsRedeemedApi(map) {

        const responseData = await getIsRedeemed(map);

        if (!checkIfNullUndefined(responseData)) {

            setRedeemCodeStr(responseData);
        }
    }

    function callCanClaimCouponApi() {

        const map = new Map();

        map['coupon_id'] = currentRowId;

        canClaimCouponApi(map);
    }

    async function canClaimCouponApi(map) {

        setIsLoadingData(true);

        const responseMessage = await canClaimCoupon(map);

        if (!checkIfNullUndefined(responseMessage)) {

            setIsLoadingData(false);

            if (responseMessage === 'exceeded_limit') {

                Toast.fire({
                    allowOutsideClick: false,
                    title: getLang.no_more_coupons_left_to_claim,
                    icon: 'info',
                    confirmButtonText: getLang.ok,
                    iconColor: getBizColorNumber(3),
                    confirmButtonColor: getBizColorNumber(3),
                });
            } else {

                handleClaimCouponByPriceCase();
            }
        }
    }

    function isFirstItem(item) {

        var isFirst = false;

        if (getIndexItem(item) === 0 &&
            getMdType(item) === 'Paragraph') {

            isFirst = true;
        }

        return isFirst;
    }

    function getScreenDataFirstItem() {

        return !checkIfNullUndefinedEmpty(screenData[0]) ? screenData[0] : {};
    }

    function getDataFromScreenData() {

        return currentObj.data;
    }

    function getProgramType() {

        return getDataFromScreenData().program_type;
    }

    function getExhausted() {

        var exhausted = getScreenDataFirstItem().exhausted;

        return !checkIfNullUndefinedEmpty(exhausted) ? exhausted : '';
    }

    function getCustomerCardQrLink() {

        var customerCardQrLink = getScreenDataFirstItem().customer_card_qr_link;

        return !checkIfNullUndefined(customerCardQrLink) ? customerCardQrLink : '';
    }

    function getCustomerCardCode() {

        var customerCardCode = getDataFromScreenData().customer_card_code;

        return !checkIfNullUndefined(customerCardCode) ? customerCardCode : '';
    }

    function getBarcode() {

        var barcode = getDataFromScreenData().bar_code;

        return !checkIfNullUndefined(barcode) ? barcode : '';
    }

    function getLimit() {

        var limit = getScreenDataFirstItem().limit;

        return !checkIfNullUndefined(limit) ? limit : '';
    }

    function getPrice() {

        return getScreenDataFirstItem().price;
    }

    function getIndexItem(item) {

        return item.index;
    }

    function getProductDescriptionHeaderText(item) {

        var labelKeyAndroid = getLabelKeyAndroid(item);

        return !checkIfNullUndefinedEmpty(labelKeyAndroid) ? labelKeyAndroid : getLang.description;
    }

    function getLabelKeyAndroid(item) {

        var labelKeyAndroid = item.label_key_android;

        return !checkIfNullUndefinedEmpty(labelKeyAndroid) ? getLang[labelKeyAndroid] : '';
    }

    function getLabelKeyIos(item) {

        var labelKeyIos = item.label_key_ios;

        return !checkIfNullUndefinedEmpty(labelKeyIos) ? getLang[labelKeyIos] : '';
    }

    function getLabelKeyWebMember(item) {

        var labelKeyWebMember = item.label_key_web_member;

        return !checkIfNullUndefinedEmpty(labelKeyWebMember) ? getLang[labelKeyWebMember] : '';
    }
    
    

    function getMdInfo(item) {

        return item.md_info.replaceAll("\\\\'", "'").replaceAll("\\\\\"", "\"");
    }

    function getmdInfo1(item) {

        var mdInfo1 = item.md_info1;

        return !checkIfNullUndefined(mdInfo1) ? mdInfo1.replaceAll("\\\\'", "'").replaceAll("\\\\\"", "\"") : '';
    }

    function getMinCartTotal(item) {

        var minCartTotal = item.min_cart_total;

        return !checkIfNullUndefined(minCartTotal) ? minCartTotal : '';
    }

    function getMdProgramType(item) {

        return item.md_program_type;
    }

    function getMdStampNumber(item) {

        return item.md_stamp_number;
    }

    function getMdRewardNumber(item) {

        return item.md_reward_number;
    }

    function getCouponType(item) {

        return item.cuponType;
    }

    function getMdPrice(item) {

        return item.md_price;
    }

    function getMdType(item) {

        return item.md_type;
    }

    function getMdDesc(item) {

        return item.md_desc;
    }

    function getCurrency(item) {

        return item.currency;
    }

    function couponIsCustom() {

        return getProgramType() === 'custom' || getProgramType() === 'other';
    }

    function getFromPersonalZoneFromRelevevantCase() {

        if (!checkIfNullUndefined(fromPersonalZoneFromList)) {

            return fromPersonalZoneFromList;
        } else {

            return convertFromStringToBoolean(paramsFromUrl.fromPersonalZone);
        }
    }

    function getCurrentObjFromRelevevantCase() {

        if (!checkIfNullUndefined(currentObjFromList)) {

            return currentObjFromList;
        } else {

            return paramsFromUrl;
        }
    }

    function getCouponStampCardTypeLabel(item) {

        var mdStampNumber = getMdStampNumber(item);
        var mdRewardNumber = getMdRewardNumber(item);

        var cardTypeLabel = '';

        if (currentModId === stampCardModId) {

            switch (getMdProgramType(item)) {

                case 'plus':

                    cardTypeLabel = mdStampNumber + ' + ' + mdRewardNumber;
                    break;

                case 'discount':

                    cardTypeLabel = mdStampNumber + ' + ' + mdRewardNumber + '%';
                    break;

                case 'gift':

                    cardTypeLabel = mdStampNumber + ' + 🎁';
                    break;
                default:
            }
        } else if (currentModId === couponModId) {

            cardTypeLabel = getCouponType(item);
        }

        return cardTypeLabel;
    }

    function getPriceText(item) {

        return getPriceStringForPrice(getMdPrice(item), getCurrency(item));
    }

    function getCouponClaimedText(item) {

        var leftLabel = ' ' + getLang.left;

        var numberOfClaimed = item.cuponClaimed;

        var couponClaimedText = numberOfClaimed + leftLabel;

        if (parseInt(numberOfClaimed) < 0) {

            leftLabel = parseInt(numberOfClaimed) === -1 ? getLang.action_claim : getLang.buy;

            couponClaimedText = leftLabel;
        }

        return couponClaimedText;
    }

    function getCouponTimeLeft(item) {

        var dtData = getLang.expired;

        var elementDescription;

        if (fromPersonalZone) {

            elementDescription = getDataFromScreenData().valid_to;
        } else {

            elementDescription = getMdInfo(item);
        }

        if (elementDescription !== '') {

            var today = new Date().getTime();
            var thatDay = new Date(elementDescription).getTime();
            var mins = Math.round((thatDay - today) / 1000 / 60);

            if (mins > 0) {

                dtData = mins + ' ' + getLang.minute + getMultiTime(mins);

                if (mins >= 60) {

                    mins = Math.round(mins / 60);
                    dtData = mins + ' ' + getLang.hour + getMultiTime(mins);

                    if (mins >= 24) {

                        mins = Math.round(mins / 24);
                        dtData = mins + ' ' + getLang.day + getMultiTime(mins);
                    }
                }

                dtData += ' (' + getFormatedDateSQL(thatDay) + ')';
            }
        } else {

            dtData = getLang.unlimited;
        }

        return dtData;
    }

    function getMultiTime(mins) {

        return (mins > 1) ? getLang.s : '';
    }

    function getTermsUrl(url) {

        if (!url.startsWith('http://') && !url.startsWith('https://')) {

            url = 'http://' + url;
        }

        return url;
    }

    function getStampClick() {

        Toast.fire({
            allowOutsideClick: false,
            title: '',
            html: getStampClickText(),
            icon: 'info',
            confirmButtonText: getLang.ok,
            iconColor: getBizColorNumber(3),
            confirmButtonColor: getBizColorNumber(3),
        });
    }

    function getStampClickText() {

        var textToReplace = getLang.you_get_stamps_and_points;

        return textToReplace.replaceAll('#bizname#', bizObject.biz_short_name);
    }

    function claimButtonClicked() {

        if (getLimit() > 0) {

            callCanClaimCouponApi();
        } else {

            handleClaimCouponByPriceCase();
        }
    }

    function handleClaimCouponByPriceCase() {

        if (getPrice() == '0') { // eslint-disable-line

            const redeemCodeVar = getScreenDataFirstItem().redeem_code;

            var redeemCode;

            if (!checkIfNullUndefinedEmpty(redeemCodeVar)) {

                const tsLong = String(Date.now());

                redeemCode = currentRowId + tsLong.substring(tsLong.length - 8);
            } else {

                redeemCode = redeemCodeVar;
            }

            callClaimFreeCouponApi(redeemCode);
        } else {

            if (bizObject.paymentOptions.length == 0) {
                Toast.fire({
                    allowOutsideClick: false,
                    title: getLang.no_payment_options,
                    icon: 'info',
                    confirmButtonText: getLang.ok,
                    iconColor: getBizColorNumber(3),
                    confirmButtonColor: getBizColorNumber(3),
                });
                return;
            }
            // accept_payments
            setIsDrawerOpen(true);
        }
    }

    function closeDrawer() {

        setIsDrawerOpen(false);
    }

    function getItemType() {

        var itemType = '';

        switch (parseInt(currentModId)) {

            case 9:

                itemType = 'product';
                break;

            case 26:

                itemType = 'coupon';
                break;
            default:
        }

        return itemType;
    }

    function getItemId() {

        return parseInt(currentRowId);
    }
    /* Logic functions - end */

    /* UI - start */
    /* UI - without UI functions - start */
    return (
        <DivBackgroundStyled  className="main-content-holder">

            {isLoadingData ?

                <LoadingComponent isLoading={isLoadingData} /> :

                <ScrollableDivStyled height='100vh'>

                    {valueBarSpacer()}

                    {screenElements()}

                    {spacer()}

                    {additionalElements()}

                    {bottomNavigationViewSpacer()}

                </ScrollableDivStyled>
            }

            {drawerComponent()}

        </DivBackgroundStyled>
    )
    /* UI - without UI functions - end */

    /* UI - with UI functions - start */
    function screenElements() {

        return screenData
            .map(
                (item) =>

                    <div key={getIndexItem(item)}>
                        {addElementToUi(item, isFirstItem(item))}
                    </div>
            )
    }

    function addElementToUi(item, isFirst) {

        var element;

        var functName = getMdType(item);

        switch (functName) {

            case 'Paragraph':
                element = paragraphElement(item, isFirst);
                break;

            case "large_text":
                element = largeTextElement(item);
                break;

            case 'coupon_image':
                element = couponImageElement(item);
                break;

            case 'product_name':
                element = productNameElement(item);
                break;

            case 'loyalty_stamps':
                element = loyaltyStampsElement(item);
                break;

            case 'coupon_price':
                element = couponPriceElement(item);
                break;

            case 'coupon_claimed':
                element = couponClaimedElement(item);
                break;

            case 'product_description':
                element = productDescriptionElement(item);
                break;

            case 'coupon_time_left':
                element = couponTimeLeftElement(item);
                break;

            case 'loyalty_valid':
                element = loyaltyValidElement(item);
                break;

            case 'coupon_minimum_cart':
                element = couponMinimumCartElement(item);
                break;

            case 'coupon_terms':
                element = couponTermsElement(item);
                break;

            case 'coupon_button':
                element = couponButtonElement(item);
                break;

            case 'Image':
                element = imageElement(item);
                break;

            case 'PDF':
                element = pdfElement(item);
                break;

            case 'Slider':
                element = sliderElement(item);
                break;

            case 'Web_Link':
                element = webLinkElement(item);
                break;

            case 'Phone':
                element = phoneElement(item);
                break;

            case 'Map':
                element = mapElement(item);
                break;

            case 'Audio':
                element = audioElement(item);
                break;

            case 'Video':
                element = videoElement(item);
                break;

            default:
        }

        var paddingElement = functName === 'coupon_image' ? '0px' : '20px';

        return <div style={{ paddingLeft: paddingElement, paddingRight: paddingElement }}>

            {element}

            {spacer()}

        </div>
    }

    function paragraphElement(item, isFirst) {

        var mdDesc = getMdDesc(item);
        var labelKeyAndroid = getLabelKeyAndroid(item);
        var labelKeyIos = getLabelKeyIos(item);
        var labelKeyWebMember = getLabelKeyWebMember(item);
        var mdInfo = getMdInfo(item);

        var headerText;

        var showHeaderText = true;
        var showDescriptionText = true;

        if (!checkIfNullUndefinedEmpty(mdDesc) ||
            !checkIfNullUndefinedEmpty(labelKeyAndroid) ||
            !checkIfNullUndefinedEmpty(labelKeyWebMember)) {

            if(labelKeyWebMember !== ''){

               headerText = labelKeyWebMember;
            }
            else if (labelKeyAndroid !== '') {

                headerText = labelKeyAndroid;
        
            }else{

                headerText = mdDesc;
            }
        } else {

            showHeaderText = false;
        }

        if (checkIfNullUndefinedEmpty(mdInfo)) {

            showDescriptionText = false;
        }

        return <div>

            {showHeaderText ?

                (isFirst ?

                    header3Label(headerText) :

                    paragraphTitle(headerText)) :

                <div />}

            {showDescriptionText ?

                paragraphLabel(mdInfo) :

                <div />}
        </div>
    }

    function imageElement(item) {

        return <div>

            <img src={item.md_pic} style={{ height: 200, width: '100%' }}>

            </img>

        </div>
    }

    function pdfElement(item) {

        return <div style={{ textAlign: 'left' }}>

            {paragraphTitle(item.md_head)}

            <SpanPrivacyPolicyStyled
                marginBottom='0px'
                onClick={() => openInNewTab(item.md_pic)}>
                {getMdDesc(item)}
            </SpanPrivacyPolicyStyled>

        </div>
    }

    function webLinkElement(item) {

        return <div style={{ textAlign: 'left' }}>

            {paragraphTitle(item.md_desc)}

            <SpanPrivacyPolicyStyled
                marginBottom='0px'
                onClick={() => openInNewTab(getTermsUrl(item.md_info))}>
                {getMdInfo(item)}
            </SpanPrivacyPolicyStyled>

        </div>
    }

    function phoneElement(item) {

        return <div style={{ textAlign: 'left' }}>

            {paragraphTitle(item.md_desc)}

            <PhoneLabelStyled
                marginBottom='0px'
                href={`tel:${getMdInfo(item)}`}>
                {getMdInfo(item)}
            </PhoneLabelStyled>

        </div>
    }

    function videoElement(item) {

        return <div>

            {paragraphTitle(item.md_desc)}

            <ReactPlayer
                url={getMdInfo(item)}
                height={'100%'}
                width={'100%'}
                volume={1}
                controls={true}
            />
        </div>
    }

    function audioElement(item) {

        return <div>

            {spacer()}

            {paragraphTitle(item.md_desc)}

            <ReactPlayer
                url={getMdInfo(item)}
                height={'100%'}
                width={'100%'}
                volume={1}
                controls={true}
            />
        </div>
    }

    function sliderElement(item) {

        var picsArray = []

        for (var i = 0; i < 10; i++) {
            const imageToInsert = item['md_pic' + String(i + 1)]
            if (!checkIfNullUndefinedEmpty(imageToInsert)) {
                picsArray.push(imageToInsert)
            }
        }

        return <div style={{ height: '100%', width: '100%' }}>

            <HorizontalScroller
                cellWidth={300}
                scrollerHeight={150}
                dataIsready={true}
                data={picsArray}
                cellClass={obj => !checkIfNullUndefinedEmpty(obj) ? <img key={Math.random()} style={{ height: 150, width: 300 }} src={obj}></img> :
                    <img key={Math.random()} style={{ height: 0, width: 0 }} />}
            />
        </div>
    }

    function mapElement(item) {

        const apiKey = 'AIzaSyAPQbjz_R6T0Kopawiw70i1wSoU_47i4sI'
        var coordinates = String(mapData).split(',');

        const defaultProps = {
            center: {
                lat: Number(coordinates[0]),
                lng: Number(coordinates[1])
            },
            zoom: 15,
            draggable: false
        };

        return (

            <div style={{ height: '400px', width: '100%', marginBottom: 16 }}>

                {paragraphTitle(item.md_desc)}

                {spacer10()}

                <GoogleMapReact
                    bootstrapURLKeys={{ key: apiKey }}
                    defaultCenter={defaultProps.center}
                    defaultZoom={defaultProps.zoom}
                    draggable={defaultProps.draggable}>

                </GoogleMapReact>
            </div>

        )
    }

    function largeTextElement(item) {

        return <div>

            {header3Label(getMdInfo(item))}

            {header32Label(getMdDesc(item))}

        </div>
    }

    function couponImageElement(item) {

        var imageUrl = item.biz_level_img['1'];
        var combinedURL = 'https://devapi.getglue.co/bizImages/' + imageUrl;

        return <div style={{ position: 'relative' }}>

            <ImgCardStyled
                style={{ height: '256px' }}
                src={returnImageURL(combinedURL, imageUrl)}
                alt='' />

            {currentModId === stampCardModId ?

                cardTypeLayout(item) :

                (getCouponType(item) === '' ?

                    <div /> :

                    cardTypeLayout(item))}

        </div>
    }

    function cardTypeLayout(item) {

        return <DivCenterStyled
            padding='0px'
            style={{ height: '40px', width: '120px', backgroundColor: getBizColorNumber(3), position: 'absolute', top: '180px', right: '0px' }}>

            <P3Styled>{getCouponStampCardTypeLabel(item)}</P3Styled>

        </DivCenterStyled>;
    }

    function productNameElement(item) {

        var mdDesc = getMdDesc(item);

        return <DivHorizontalStyled>

            {!checkIfNullUndefinedEmpty(mdDesc) ?
//qwe
                header3Label(mdDesc) :

                <div />
            }

        </DivHorizontalStyled>
    }

    function couponPriceElement(item) {

        var originaLPrice = item.md_original_price;
        var priceIsEqualsOriginalPrice = getMdPrice(item) == originaLPrice; // eslint-disable-line

        return <div>

            <DivHorizontalStyled>

                <H32Styled
                    style={{ color: !priceIsEqualsOriginalPrice ? 'red' : getBizColorNumber(3) }}>
                    {getPriceText(item)}
                </H32Styled>

                {!priceIsEqualsOriginalPrice ?

                    header3Label(originaLPrice + ' ' + getCurrency(item)) :

                    <div />
                }

            </DivHorizontalStyled>

            {spacer()}

        </div>
    }

    function loyaltyStampsElement(item) {

        return fromPersonalZone ?

            loyaltyQrCodeLayout(item) :

            loyaltyStampsLayout(item)
    }

    function loyaltyStampsLayout(item) {

        return <DivSideAlignStyled>

            <Grid
                container spacing={1} columns={6}
                style={{ width: '100%' }}>

                {loyaltyStampsLayoutList(item)}

            </Grid>

        </DivSideAlignStyled>;
    }

    function loyaltyStampsLayoutList(item) {

        const mdStampNumberForLoop = parseInt(getMdStampNumber(item)) + 2;

        var stampLayout = [];

        for (var i = 1; i < mdStampNumberForLoop; i++) {

            stampLayout.push(handleStapmLayout(i, item));
        }

        return stampLayout;
    }

    function handleStapmLayout(index, item) {

        var customerCardStampNo = parseInt(item.customer_card_stamp_no);
        var mdStampNumber = parseInt(getMdStampNumber(item));

        var stampLayout;

        if (index <= customerCardStampNo) {

            stampLayout = stapmLayout(index,
                index,
                getBizColorNumber(2),
                getBizColorNumber(2) + '33',
                {},
                undefined);
        } else if (index === (customerCardStampNo + 1) &&
            index !== (mdStampNumber + 1)) {

            stampLayout = stapmLayout(index,
                index,
                getBizColorNumber(1),
                getBizColorNumber(3),
                {},
                getStampClick);
        } else if (index < (mdStampNumber + 1)) {

            stampLayout = stapmLayout(index,
                index,
                getBizColorNumber(2) + '33',
                getBizColorNumber(4),
                { border: '1px solid ' + getBizColorNumber(2) + '33', borderRadius: '50px' },
                getStampClick);
        } else {

            var mdProgramType = getMdProgramType(item);
            var mdRewardNumber = getMdRewardNumber(item);

            var stampCardText = '';

            switch (mdProgramType) {

                case 'plus':

                    stampCardText = getLang.free;
                    break;

                case 'discount':

                    stampCardText = mdRewardNumber + '%';
                    break;

                case 'gift':

                    stampCardText = '🎁';
                    break;
                default:
            }

            stampLayout = stapmLayout(index,
                stampCardText,
                getBizColorNumber(3),
                getBizColorNumber(4),
                { backgroundImage: `url(${getSvgUrlForFileName('stampouter.svg')})` },
                undefined);
        }

        return stampLayout;
    }

    function stapmLayout(index, text, textColor, backgroundColor, style, click) {

        return <Grid
            item xs={1}
            direction='row'
            justifyContent='center'
            alignItems='center'
            key={index}>

            <DivImgItemProfileWrapper3Styled
                backgroundColor={backgroundColor}
                style={style}
                onClick={click}>

                <span
                    style={{ color: textColor, display: 'table-cell', verticalAlign: 'middle' }}>
                    {text}
                </span>

            </DivImgItemProfileWrapper3Styled>

        </Grid>
    }

    function loyaltyQrCodeLayout(item) {

        var loyaltyCodeLabel = '';
        var loyaltyCodeNumber = '';
        var qrCode = '';

        var customerCardRedeemed = getScreenDataFirstItem().customer_card_redeemed;
        var customerCardRedeemedDate = getScreenDataFirstItem().customer_card_redeemed_date;

        if (customerCardRedeemed === '0') {

            if (getMdProgramType(item) === 'other') {

                var customerCardCode = getCustomerCardCode();

                loyaltyCodeLabel = getLang.your_personal_code;
                loyaltyCodeNumber = customerCardCode;
                qrCode = getRewardRedeemUrl(customerCardCode);
            } else {

                loyaltyCodeLabel = getLang.redeemable_on_purchase;
            }
        } else {

            loyaltyCodeLabel = getLang.redeemed_on + ' ' + getFormatedDateSQL(customerCardRedeemedDate);
        }

        return <DivDashedStyled backgroundColor={getBizColorNumber(4)}>

            <DivCenterHorizontalStyled>

                <DivCenterStyled>

                    {paragraphLabel(loyaltyCodeLabel)}

                    {paragraphLabel(loyaltyCodeNumber)}

                </DivCenterStyled>

                <QRCodeComponent size={70} value={qrCode} />

            </DivCenterHorizontalStyled>

        </DivDashedStyled>
    }

    function couponClaimedElement(item) {

        return <div>

            

            {/* {!fromPersonalZone ?

                <>
                    {paragraphLabel(getLang.coupons_available)}
                    {paragraphTitleStyle(getCouponClaimedText(item), { fontSize: '15px' })}
                </>
                : <div />
            } */}

            {/* <hr style={{ backgroundColor: getBizColorNumber(2) + '66' }} /> */}

        </div>
    }

    function productDescriptionElement(item) {

        var mdInfo = getMdInfo(item);

        return <div>

            {paragraphTitle(getProductDescriptionHeaderText(item))}

            {!checkIfNullUndefinedEmpty(mdInfo) ?

                paragraphLabel(mdInfo) :

                <div />
            }

        </div>
    }

    function couponTimeLeftElement(item) {

        return fullParagraphLayout(getLang.time_left, getCouponTimeLeft(item))
    }

    function loyaltyValidElement(item) {

        var mdValidFrom = item.md_valid_from;
        var mdValidTo = item.md_valid_to;

        var loyaltyValidLabel = getFormatedDateSQL(mdValidFrom) + ' - ' + getFormatedDateSQL(mdValidTo);

        return mdValidTo !== '' ?

            fullParagraphLayout(getLang.expiration, loyaltyValidLabel) :

            <div />
    }

    function couponMinimumCartElement(item) {

        var couponMinimumCartText = getMinCartTotal(item) + ' ' + bizObject.ess_currency;

        return fullParagraphLayout(getLang.minimum_cart_total, couponMinimumCartText)
    }

    function couponTermsElement(item) {

        return <div>

            {paragraphTitle(getLang.terms_and_conditions)}

            <SpanPrivacyPolicyStyled
                marginBottom='0px'
                onClick={() => openInNewTab(getTermsUrl(getmdInfo1(item)))}>
                {getmdInfo1(item)}
            </SpanPrivacyPolicyStyled>

        </div>
    }

    function couponButtonElement(item) {

        var element;

        if (!fromPersonalZone &&
            getExhausted() === 0) {

            element = floatingButton();
        } else {

            element = redeemCodeCouponLayout(item);
        }

        return element;
    }

    function floatingButton() {

        return <FloatingButtonStyled className="floating-btn" onClick={claimButtonClicked}>{getPrice() == '0' ? getLang.action_claim : getLang.purchase}</FloatingButtonStyled>
    }

    function redeemCodeCouponLayout() {

        var couponCodeLabel = '';
        var couponCodeNumber = '';
        var qrCode = '';
        var barcodeValue = '';

        if (!checkIfNullUndefinedEmpty(redeemCodeStr)) {
            if(redeemCodeStr == "expired"){
                couponCodeLabel = getLang.expired;
            }
            else{
                couponCodeLabel = getLang.redeemed_on + ' ' + getFormatedDateSQL(redeemCodeStr * 1000);
            }            
        } else {

            if (!checkIfNullUndefined(redeemCodeStr)) {

                if (redeemCodeStr !== '') {
                    if(redeemCodeStr == "expired"){
                        couponCodeLabel = getLang.expired;
                    }
                    else{
                        couponCodeLabel = getFormattedDate(parseInt(redeemCodeStr), 'yyyy-MM-dd HH:mm:ss');
                    }                    
                } else {

                    if (couponIsCustom()) {

                        var customerCardCode = getCustomerCardCode();
                        barcodeValue = getBarcode();
                        
                        couponCodeLabel = getLang.coupon_id;
                        var displayCode = barcodeValue != '' ? barcodeValue : customerCardCode;
                        couponCodeNumber = hyphenateText(displayCode, 4);
                        qrCode = getRewardRedeemUrl(customerCardCode);
                        
                    } else {

                        couponCodeLabel = getLang.redeemable_on_purchase;
                    }
                }
            }
        }

        return !checkIfNullUndefinedEmpty(couponCodeLabel) ?

            <DivDashedStyled backgroundColor={getBizColorNumber(4)}>

                <DivCenterHorizontalStyled>

                    <DivCenterStyled>

                        {paragraphLabel(couponCodeLabel)}

                        {paragraphLabel(couponCodeNumber)}

                    </DivCenterStyled>

                    <QRCodeComponent size={70} value={qrCode} />

                </DivCenterHorizontalStyled>

                {barcode(barcodeValue)}

            </DivDashedStyled> :

            <div />
    }

    function additionalElements() {

        return <div style={{ paddingLeft: '20px', paddingRight: '20px' }}>

            {getOpenMyLoyaltyCards() &&
                currentModId !== couponModId &&
                getCustomerCardQrLink() !== '' ?

                redeemCodeStampCardLayout() :

                <div />}

        </div>
    }

    function redeemCodeStampCardLayout() {

        return <DivDashedStyled>

            <DivCenterHorizontalStyled>

                <DivCenterStyled>

                    {paragraphLabel(getLang.scan_to_stamp_when_you_visit_us)}

                </DivCenterStyled>

                <QRCodeComponent size={70} value={getCustomerCardQrLink()} />

            </DivCenterHorizontalStyled>

        </DivDashedStyled>
    }

    function drawerComponent() {

        return <DrawerComponent
            children={getCurrentDrawerChild()}
            isDrawerOpen={isDrawerOpen}
            closeDrawer={closeDrawer} />
    }

    function getCurrentDrawerChild() {

        return <Cart itemType={getItemType()} itemId={getItemId()} onClose={closeDrawer} />
    }

    function fullParagraphLayout(title, label) {

        return <div>

            {paragraphTitle(title)}

            {paragraphLabel(label)}

        </div>
    }

    function paragraphTitle(title) {

        return paragraphTitleStyle(title)
    }

    function paragraphTitleStyle(title, style) {

        return <POpacityStyled
            style={style}
            opacity={'66'}
            textAlign='left'
            marginBottom={'0px'}>
            {title}
        </POpacityStyled>
    }

    function paragraphLabel(label) {

        return paragraphLabelStyle(label)
    }

    function paragraphLabelStyle(label, style) {

        return <PStyled
            style={style}
            textAlign='left'
            marginBottom='0px'>
            {label}
        </PStyled>
    }

    function header3Label(label) {

        return <H3Styled
            textAlign='left'>
            {label}
        </H3Styled>
    }

    function header32Label(label) {

        return <H32Styled
            textAlign='left'>
            {label}
        </H32Styled>
    }

    function spacer() {

        return <SpacerStyled height='20' />
    }

    function spacer10() {

        return <SpacerStyled height='10' />
    }

    function barcode(value){
        return value == '' ? "" 
        : <DivCenterHorizontalStyled>
        
            <BarcodeComponent value={value} displayValue={false}/>

        </DivCenterHorizontalStyled>
    }
    /* UI - with UI functions - end */
    /* UI - end */
}