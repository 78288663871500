import React from 'react';
import ClassicInputComponent from '../../../../components/input_components/classic_input_component/ClassicInputComponent';
import './EmailTextFieldFormComponent.css';

class EmailTextFieldFormComponent extends React.Component {

    /* UI - start */
    /* UI - without UI functions - start */
    render() {

        return <ClassicInputComponent
            className='input-text-field-form ltr-input'
            defaultValue={this.props.defaultValue}
            onChange={this.props.onChange} />
    }
    /* UI - without UI functions - end */
    /* UI - end */
}

export default EmailTextFieldFormComponent;