import React, { useRef, useState } from "react";
import PhoneInput from 'react-phone-input-2'
import Checkbox from '@mui/material/Checkbox';
import TextFieldFormComponent from "../edit_profile/edit_profile_components/text_field_form_component/TextFieldFormComponent";
import DialogTransitionComponent from '../../components/dialog_components/dialog_transition_component/DialogTransitionComponent';
import apiClient from '../../api/apiClient'
import LoadingComponent from "../../components/loading_components/LoadingComponent";
import ConfirmationMessageComponent from "../../components/confirmation_message_component/ConfirmationMessageComponent";
import WebBrowserScreen from "../privacy_policy/WebBrowserScreen";
import ReCAPTCHA from "react-google-recaptcha"
import { DrawerComponent } from "../../components/drawer/DrawerComponent";
import { DivMainStyled, DivReadPrivacyPolicyStyled, DivButtonsStyled, ButtonStyled } from "../../biz_styles/ProfileStyle";
import { SpanPrivacyPolicyStyled, PStyled, P2Styled, getBizColorNumber, H3Styled, H5Styled, Toast } from "../../biz_styles/bizStyle";
import { isValidPhoneNumber } from 'libphonenumber-js'
import { store } from '../../shared/redux/store'
import { getCustomerAction } from "../../actions/customerActions"
import { GET_CUSTOMER_DATA } from "../../actions/actionTypes"
import { getBiz, getLanguage } from "../../shared/redux/GlueStoreData";
import { checkIfNullUndefined, getBizHashFromUrl } from "../../shared/static_helper/StaticHelper";
import { submitRegister } from "../../api/ApiRequests";
import './VerifyPhoneNumberScreen.css';

const VerifyPhoneNumberScreen = ({ dialogClosed }) => {

    /* Logic functions - start */
    const getLang = getLanguage();

    const captchaRef = useRef();

    const bizObject = getBiz()

    const [isSMS, setIsSMS] = useState(false);
    const [isAgreed, setIsAgreed] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [phoneNumber, setPhoneNumber] = useState('');
    const [smsCode, setSmsCode] = useState('');
    const [showDialogScreen, setShowDialogScreen] = useState(false);
    const [isDrawerOpen, setIsDrawerOpen] = useState(false);

    function dialogConfirmationMessageComponentClosed(isOk) {

        if (isOk) {
            validatePhoneCode(true);
        } else {
            setIsSMS(false);
        }

        setShowDialogScreen(false);
    }

    function handleCheckBoxOnChange(event) {

        setIsAgreed(event.target.checked)
    };

    async function handleVerifyClick() {

        if (isSMS) {

            if (smsCode.length === 6) {

                setIsLoading(true);
                validatePhoneCode(false);
            }
            else {
                showError('Error',getLang.invalid_code)
            }
        }
        else {

            if (isAgreed) {

                if (isValidPhoneNumber(phoneNumber)) {

                    setIsLoading(true);

                    const token = await captchaRef.current.executeAsync();

                    const resData =  await submitRegister(phoneNumber,token);

                    setIsLoading(false);

                    if (!checkIfNullUndefined(resData)) {

                        setIsSMS(true);
                    }
                    else {

                        showError(getLang.sorry, getLang.something_went_wrong_try_again);

                        captchaRef.current.reset();
                    }
                }
                else {
                    showError(getLang.error,getLang.invalid_phone_number)
                    
                    captchaRef.current.reset();
                }
            }
        }
    };

    function handlePhoneInputOnChange(value, data, event, formattedValue) {

        setPhoneNumber(formattedValue);
    }

    function handleChangeNumberClick() {

        setIsSMS(false);
    }

    async function validatePhoneCode(isReuse) {

        try {

            const postData = {
                code: smsCode,
                reuse_profile: isReuse ? 1 : 0
            };

            const res = await apiClient.post("/validateCode", postData);

            if (res.status === 200) {

                setIsLoading(false);

                if (res.data.responseCode === 1) {

                    if (res.data.responseData['cust_exist'] !== undefined && res.data.responseData['cust_exist'] !== null) {

                        setShowDialogScreen(true);
                    }
                    else {
                        store.dispatch(getCustomerAction(GET_CUSTOMER_DATA, getBizHashFromUrl(), res.data.responseData));
                        dialogClosed(true, phoneNumber);
                    }
                } else {
                    showError(getLang.error, getLang.incorrect_code_str)
                }
            } else {
                setIsLoading(false);
                showError(getLang.sorry, getLang.something_went_wrong_try_again);
            }

        } catch (err) {

            setIsLoading(false);
            showError(getLang.sorry, getLang.something_went_wrong_try_again);
        }
    }

    function openDrawer() {

        setIsDrawerOpen(true);
    }

    function closeDrawer() {

        setIsDrawerOpen(false);
    }

    function showError(title,text) {
        Toast.fire({
        title: title,
        text: text,
        icon: 'error',
        confirmButtonText: getLang.ok
        })
    }
    /* Logic functions - end */

    /* UI - start */
    /* UI - without UI functions - start */
    return (
        <DivMainStyled position='static' style={{padding: '5%', borderStyle:'solid', borderWidth: 2, borderColor: getBizColorNumber(2)}}>

            <LoadingComponent isLoading={isLoading} />

            {!isSMS ?
                verifyPhoneNumberUI() :
                verifyPhoneNumberSmsUI()}

            <DrawerComponent children={getCurrentDrawerChild()} isDrawerOpen={isDrawerOpen} closeDrawer={closeDrawer} zIndexVar='2000' />

        </DivMainStyled>
    );
    /* UI - without UI functions - end */

    /* UI - with UI functions - start */
    function verifyPhoneNumberUI() {

        return (
            <div>

                <H3Styled>{getLang.verify_phone_umber}</H3Styled>

                <PStyled>{getLang.type_your_phone_number}</PStyled>

                <PStyled>{getLang.mobile_phone_sms}</PStyled>

                <PhoneInput
                    name='phone'
                    required={true}
                    autoFocus={true}
                    country={bizObject.biz_country_code.toLowerCase()}
                    countryCodeEditable={false}
                    autocompleteSearch={true}
                    enableSearch={true}
                    disableSearchIcon={true}
                    value={phoneNumber}
                    onChange={handlePhoneInputOnChange} />

                <ReCAPTCHA
                    sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
                    ref={captchaRef}
                    size="invisible" />

                <DivReadPrivacyPolicyStyled>

                    <Checkbox
                        checked={isAgreed}
                        onChange={handleCheckBoxOnChange}
                        style={{
                            height: '20px',
                            paddingTop: '13px',
                            color: getBizColorNumber(2)
                        }} />

                    <PStyled>
                        {getLang.i_have_read_and_agree_to_the}
                        <SpanPrivacyPolicyStyled
                            onClick={openDrawer}>
                            {getLang.privacy_policy_terms}
                        </SpanPrivacyPolicyStyled>
                    </PStyled>

                </DivReadPrivacyPolicyStyled>

                {buttonsVerify()}

                {dialogConfirmationMessageComponent()}

            </div>
        )
    }

    function verifyPhoneNumberSmsUI() {

        return (
            <div>

                <PStyled>{getLang.we_have_sent_a_six_digits_code}</PStyled>

                <H5Styled>{phoneNumber}</H5Styled>

                <PStyled>{getLang.please_enter_the_received_code_here}</PStyled>

                <TextFieldFormComponent
                    placeholder='######'
                    onChange={(e) => setSmsCode(e.target.value)} />

                {buttonsVerify()}

                <P2Styled
                    className='p-change-number-verify-phone-number'
                    onClick={handleChangeNumberClick}>
                    {getLang.change_number}
                </P2Styled>

            </div>
        )
    }

    function buttonsVerify() {

        return (
            <DivButtonsStyled>

                <ButtonStyled
                    isVerify={false}
                    onClick={() => dialogClosed(false, '')}>
                    {getLang.not_now}
                </ButtonStyled>

                <ButtonStyled
                    style={{ marginLeft: '5%' }}
                    isVerify={true}
                    isAgreed={isAgreed}
                    onClick={handleVerifyClick}>
                    {getLang.action_validate_code}
                </ButtonStyled>

            </DivButtonsStyled>
        )
    }

    function dialogConfirmationMessageComponent() {

        return <DialogTransitionComponent
            open={showDialogScreen}
            dialogClosed={dialogConfirmationMessageComponentClosed}
            children={childrenOfdialogConfirmationMessageComponent()} />
    }

    function childrenOfdialogConfirmationMessageComponent() {

        return <ConfirmationMessageComponent
            message={getLang.you_already_have_an_existing_account}
            buttonOkText={getLang.ok}
            buttonCancelText={getLang.cancel}
            dialogClosed={dialogConfirmationMessageComponentClosed} />;
    }

    function getCurrentDrawerChild() {

        return <WebBrowserScreen type='privacy-policy' />
    }
    /* UI - with UI functions - end */
    /* UI - end */
}

export default VerifyPhoneNumberScreen;