import React, { useState, useEffect } from 'react';
import { List, ListItem, ListItemIcon, ListItemText, IconButton, Backdrop } from "@mui/material"
import Drawer from '@mui/material/Drawer';
import MenuIcon from '@mui/material/Menu';
import { IconStyledSvg } from '../../biz_styles/IconsStyles';
import { appMaxWidth } from '../../App';
import "./SideBar.css"
import { getBizColorNumber } from '../../biz_styles/bizStyle';
import { DivCenterStyled, SpacerStyled, SubtitleStyled } from '../../biz_styles/ReusableStyles';
import RowDiv from '../row_div/RowDiv';
import { getLanguage } from '../../shared/redux/GlueStoreData';
import { getInjectedBizData } from '../../shared/static_helper/StaticHelper';
import { useNavigate, useLocation } from "react-router-dom"
import { useStoreProvider } from '../../shared/providers/StoreContext'


const SideBar = ({ isOpen, closeDrawer }) => {
  // const [open, setOpen] = useState(true);

  const getLang = getLanguage();
  const bizObject = useStoreProvider().biz
  const navigate = useNavigate()
  const location = useLocation();

  const [selectedIndex, setSelectedIndex] = useState(0);

  // Used for set menu selection.
  useEffect(() => {

    for (var i = 0; i < bizObject.biz_navigation.length; i++) {

      if ('..' + location.pathname == '../main') {
        setSelectedIndex(0)
        return
      } else if ('..' + location.pathname == bizObject.biz_navigation[i].web_member_path) {
        setSelectedIndex(Number(bizObject.biz_navigation[i].nav_index) + 1)
        return
      }
    }

  })

  const handleDrawerOpen = () => {
    //setOpen(true);
  };

  const handleDrawerClose = () => {
    closeDrawer();
  };

  function navigateFromMenu(navItem) {
    if (navItem.nav_index == 1) {
      navigate('../main/profile');
    } else {
      navigate(navItem.web_member_path)
    }

    handleDrawerClose()
  }

  function navigateToHome() {
    navigate('../main')
    handleDrawerClose()
  }

  function shouldIgnoreSideMenuItem(item) {

    if (item.nav_entity_id == '31') {
      return true;
    }

    if (item.nav_entity_id == '0' && item.nav_selector == 'showModules') {
      return false;
    }

    if (item.nav_entity_id == '0' || item.nav_entity_id == '6' || item.nav_entity_id == '10' || item.nav_entity_id == '11' || item.nav_entity_id == '18' || item.nav_entity_id == '26') {
      return false;
    }

    return true;
  }

  return (
    <div>

      <IconButton
        edge="start"
        color="inherit"
        aria-label="menu"
        onClick={handleDrawerOpen}>
      </IconButton>

      <Backdrop sx={{ position: 'absolute', color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={isOpen} onClick={handleDrawerClose}>

        <Drawer style={{ width: '350px' }}
          variant="persistent"
          anchor="left"
          open={isOpen}
          PaperProps={{
            sx: { position: 'absolute', maxWidth: appMaxWidth, height: '100%', width: "80px", marginLeft: '0px', backgroundColor: getBizColorNumber(4) },
          }}>

          <SpacerStyled height={'20'} />

          <div>
            <IconButton onClick={handleDrawerClose}>
              <IconStyledSvg cssName={'fill-color2'} name="close.svg" size='24' />
            </IconButton>
          </div>

          <SpacerStyled height={'20'} />

          <List>

            <ListItem selected={selectedIndex === 0} onClick={navigateToHome}>
              <RowDiv >
                {selectedIndex === 0 ?
                  <DivCenterStyled>
                    <IconStyledSvg name='home.svg' cssName='fill-color3' size='24' />
                    <ListItemText style={{ color: getBizColorNumber(3) }} primary={bizObject.main_menu_label} />
                  </DivCenterStyled> :

                  <DivCenterStyled>
                    <IconStyledSvg name='home.svg' cssName='fill-color2' size='24' />
                    <ListItemText style={{ color: getBizColorNumber(2) }} primary={bizObject.main_menu_label} />
                  </DivCenterStyled>
                }
              </RowDiv>
            </ListItem>

            {bizObject.biz_navigation.map((item, index) => (
               shouldIgnoreSideMenuItem(item) ? null :

              <ListItem selected={selectedIndex === index + 1} key={item.nav_index} onClick={() => navigateFromMenu(item)}>
                <RowDiv>
                  {selectedIndex === index + 1 ?
                    <DivCenterStyled>
                      <IconStyledSvg name={item.nav_icon} cssName='fill-color3' size='24' />
                      <ListItemText style={{ color: getBizColorNumber(3) }} primary={item.nav_label} />
                    </DivCenterStyled>
                    :
                    <DivCenterStyled>
                      <IconStyledSvg name={item.nav_icon} cssName='fill-color2' size='24' />
                      <ListItemText style={{ color: getBizColorNumber(2) }} primary={item.nav_label} />
                    </DivCenterStyled>
                  }
                </RowDiv>
              </ListItem>
            ))}

          </List>
        </Drawer>
      </Backdrop>
    </div>
  );
};

export default SideBar;