import React, { useState } from 'react';
import Grid from "@mui/material/Grid";
import EditProfileScreen from '../edit_profile/EditProfileScreen';
import ItemProfileComponent from './profile_components/item_profile_component/ItemProfileComponent';
import WebBrowserScreen from '../privacy_policy/WebBrowserScreen';
import IconTint from 'react-icon-tint';
import { PStyled, P2Styled, SpanPrivacyPolicyStyled } from '../../biz_styles/bizStyle';
import { getBiz, getLanguage } from '../../shared/redux/GlueStoreData'
import { useStoreProvider } from '../../shared/providers/StoreContext'
import { getBizColorNumber } from '../../biz_styles/bizStyle';
import { DrawerComponent } from '../../components/drawer/DrawerComponent';
import { SpacerStyled } from '../../biz_styles/ReusableStyles'
import './ProfileScreen.css';

export default function ProfileScreen() {

    /* Logic functions - start */
    const getLang = getLanguage();
    const getBizObject = getBiz();
    const getCustomerObject = useStoreProvider().cust;

    const [showDialogEditProfileScreen, setShowDialogEditProfileScreen] = useState(false);
    const [isDrawerOpen, setIsDrawerOpen] = useState(false);

    function openEditProfileScreen() {

        setShowDialogEditProfileScreen(true);
    }

    function dialogClosed() {

        setShowDialogEditProfileScreen(false);
    }

    function closeDrawer() {

        setIsDrawerOpen(false);
    }

    function openDrawer() {

        setIsDrawerOpen(true);
    }
    /* Logic functions - end */

    /* UI - start */
    /* UI - without UI functions - start */
    return (
        <div className='div-profile'>

            <SpacerStyled height='40' />

            {topLayout()}

            <hr className='hr-line' />

            {gridLayout()}

            {bottomLayout()}

            {dialogEditProfileScreen()}

            <DrawerComponent children={getCurrentDrawerChild()} isDrawerOpen={isDrawerOpen} closeDrawer={closeDrawer} />
        </div>
    )
    /* UI - without UI functions - end */

    /* UI - with UI functions - start */
    function topLayout() {

        return <div className='div-top-layout'>

            {getCustomerObject.cust_pic.includes('bbassets/avatars/avatar_') ?

                <IconTint
                    className='img-profile'
                    src={getCustomerObject.cust_pic}
                    color={getBizColorNumber(2)} /> :

                <img
                    className='img-profile'
                    src={getCustomerObject.cust_pic}
                    alt="" />}

            <PStyled className='p-full-name'>
                {getCustomerObject.cust_first_name}
            </PStyled>

            <P2Styled
                className='p-edit-profile'
                onClick={openEditProfileScreen}>
                {getLang.edit_profile}
            </P2Styled>

        </div>;
    }

    function gridLayout() {

        return <div className='div-grid-layout'>

            <Grid
                container
                spacing={3}>

                {gridlayoutProfileItems()}

            </Grid>

        </div>;
    }

    function gridlayoutProfileItems() {

        return getBizObject.pzArray.filter(
            (pzArrayItem) =>
                pzArrayItem.type === "subscriptions" ||
                pzArrayItem.type === "punchpass" ||
                pzArrayItem.type === "loyalty_cards" ||
                pzArrayItem.type === "orders"
        ).map(
            (pzArrayItem) =>

                gridlayoutProfileItem(pzArrayItem)
        );
    }

    function gridlayoutProfileItem(pzArrayItem) {

        return <Grid
            item xs={4}
            container
            direction='row'
            justifyContent='space-around'
            alignItems='center'
            key={pzArrayItem.type}>

            <ItemProfileComponent
                getCustomerObject={getCustomerObject}
                pzArrayItem={pzArrayItem} />

        </Grid>;
    }

    function bottomLayout() {

        return <div>

            <SpanPrivacyPolicyStyled
                className='p-privacy-policy-profile'
                onClick={openDrawer}>
                {getLang.privacy_policy_terms}
            </SpanPrivacyPolicyStyled>

        </div>;
    }

    function dialogEditProfileScreen() {

        return <DrawerComponent
            isDrawerOpen={showDialogEditProfileScreen}
            closeDrawer={dialogClosed}
            children={<EditProfileScreen isDialog={true} dialogClosed={dialogClosed} />} />
    }

    function getCurrentDrawerChild() {

        return <WebBrowserScreen type='privacy-policy' />
    }
    /* UI - with UI functions - end */
    /* UI - end */
};
