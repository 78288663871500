import { StyledPhoneInput } from "../../biz_styles/ReusableStyles"
import apiClient from "../../api/apiClient";
import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { DivCenterStyled, Toast } from "../../biz_styles/bizStyle";
import {
  NextButtonStyled,
  LoginDivStyled,
  NameInputStyled,
  DivColorStyled,
  WelcomeBackground,
  PrivacyPolicyText,
  CheckBoxText,
  EmailInputStyled
} from "../../biz_styles/LoginStyle";
import { isValidPhoneNumber, AsYouType } from "libphonenumber-js";
import { useStoreProvider } from "../../shared/providers/StoreContext";
import LoginHeader from "../login/LoginFormHeader";
import LoadingComponent from "../../components/loading_components/LoadingComponent";
import "./RegistrationScreen.css";
import ReCAPTCHA from "react-google-recaptcha";
import { DrawerComponent } from "../../components/drawer/DrawerComponent";
import WebBrowserScreen from "../privacy_policy/WebBrowserScreen";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import { getBizColorNumber } from "../../biz_styles/bizStyle";
import { border, borderRadius } from "@mui/system";
import { getLanguage, isLoggedIn } from "../../shared/redux/GlueStoreData";
import { checkIfNullUndefinedEmpty } from "../../shared/static_helper/StaticHelper";
import { isLocalhost } from "../../shared/static_helper/StaticHelper";

function redirectIfNeeded() {
  const navigate = useNavigate();
  if (isLoggedIn()) {
      
      useEffect(() => {
          navigate('../main')
        }, []);
    }
}

function RegistrationFormPhone() {
  //const [countryCode, setCountryCode] = useState(Object);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [email,setEmail] = useState("");
  const [isValidEmail, setIsValidEmail] = useState(true);
  const [showEmailField, setShowEmailField] = useState(false);
  const [name, setName] = useState("");
  const [isNameValid, setIsNameValid] = useState(true);
  const [isValid, setIsValid] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [isChecked, setIsChecked] = useState(false);
  const [isDisabled, setisDisabled] = useState(true);
  const captchaRef = useRef();
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const getLang = getLanguage();

  const buttonRef = useRef(null);

  const navigate = useNavigate();
  const asYouType = new AsYouType();

  const bizObject = useStoreProvider().biz;
  const customerObject = useStoreProvider().cust
  //const emailMemberEnabled = bizObject.email_member_enabled === '0'

  //const bizMembershipMode = bizObject.biz_membership_mode === '1'

  useEffect(() => {
    if (!checkIfNullUndefinedEmpty(customerObject)){
      setPhoneNumber(customerObject.cust_phone1)
      setName(customerObject.cust_first_name)
      setIsChecked(true)
      setTimeout(() => {
        buttonRef.current.click();
    }, 1000)
      
    }
  }, []);

  const colorOpacity = 20;

  const dropDownStyle = {
    color: getBizColorNumber(2),
    backgroundColor: getBizColorNumber(4),
    borderRadius: "10px",
    borderColor: 'transparent'
  };


  function handleOnChange(value, data, event, formattedValue) {
    //setCountryCode(data.dialCode)
    asYouType.input(formattedValue);
    if (formattedValue !== "") {
      setPhoneNumber(asYouType.getNumberValue());
      asYouType.reset();
    }

    //setPhoneNumber(formattedValue)
    setIsValid(isValidPhoneNumber(formattedValue));
  }

  const handleNameChange = (e) => {
    e.preventDefault();
    setName(e.target.value);
    setIsNameValid(validateName(e.target.value));
  };
  const handleEmailChange = (e) => {
    e.preventDefault();
    setEmail(e.target.value);
    setIsValidEmail(validateEmail(e.target.value));
  };

  const validateName = (e) => {
    if (e.trim().length !== 0) {
      return true;
    }
    return false;
  };

  const validateEmail = (e) => {
    return new RegExp(
      /[a-zA-Z0-9]+[\.]?([a-zA-Z0-9]+)?[\@][a-z]{3,15}[\.][a-z]{2,5}/g
    ).test(e); // eslint-disable-line
  };

  const handleCheckBoxChange = () => {
    if (name === "") {
      showError(getLang.sorry, getLang.please_enter_your_name);
    } else if (phoneNumber === "") {
      showError(getLang.sorry, getLang.please_enter_your_phone);
    } else {
      setIsChecked(!isChecked);
    }
  };

  useEffect(() => {
    if(showEmailField){
      setisDisabled(!isValidEmail);
    }
    else{
      setisDisabled(!isChecked);
    }
  },[isChecked,isValidEmail]);

  const onSubmit = async () => {

    // if(isLocalhost && isChecked && isValid && isNameValid){
    //   setIsLoading(true);
    //   signUpByPhone("localhost");
    //   return;
    // }
    
    const token = await captchaRef.current.executeAsync();
    if (isValid && isNameValid) {
      if (isChecked) {
        setIsLoading(true);
        let phoneResult = await signUpByPhone(token);
        if(phoneResult !== undefined){
          if(phoneResult.responseMessage === "email_required"){
            setShowEmailField(true);
            setisDisabled(true);
          }
        }
      }
    } else {
      if (!isNameValid) {
        showError(getLang.error,getLang.invalid_name);
        captchaRef.current.reset();
      }
      else if(showEmailField && !isValidEmail){
        showError(getLang.error,getLang.invalid_email);
        captchaRef.current.reset();
      } 
      else {
        showError(getLang.error,getLang.invalid_phone_number);
        captchaRef.current.reset();
      }
    }
  };

  async function signUpByPhone(captchaToken) {
    try {
      const postData = {
        name: name,
        phone: phoneNumber,
        email: email,
        token: captchaToken,
      };

      const res = await apiClient.post("/submitRegister", postData);

      if (res.status === 200) {
        if (res.data.responseCode === 1) {
          setIsLoading(false);
          if(res.data.responseMessage == "email_sent"){

            const emailFromResponse = res.data.responseData.email;

            if(email == ""){
              setEmail(emailFromResponse);
              navigate("../LoginFormSmsCode",{ state: { email: emailFromResponse } });
            } else {
              navigate("../LoginFormSmsCode",{ state: { email } });
            }
            
          }
          else{
            navigate("../LoginFormSmsCode",{ state: { phoneNumber } });
          }
          
        } else {
          setIsLoading(false);
          if(res.data.responseMessage != "email_required"){
            showError(getLang.sorry, getLang.something_went_wrong_try_again)
            captchaRef.current.reset();
          }    
        }        
      } else {
        setIsLoading(false);
        showError(getLang.sorry, getLang.something_went_wrong_try_again)
        captchaRef.current.reset();
      }
      return res.data;
    } catch (err) {
      showError(getLang.sorry, getLang.something_went_wrong_try_again)
      captchaRef.current.reset();
    }
  }

  async function createGuest() {
    try {
      const res = await apiClient.post("/createGuest");

      if (res.status === 200) {
        if (res.data.responseCode === 1) {
          // console.log("createGuest success");
        } else {
          showError(getLang.sorry, getLang.something_went_wrong_try_again)
        }
      }
    } catch (err) {
      showError(getLang.sorry, getLang.something_went_wrong_try_again)
    }
  }

  function showError(title, text) {
    Toast.fire({
      title: title,
      text: text,
      icon: "error",
      confirmButtonText: getLang.ok,
    });
  }

  function openDrawer() {
    setIsDrawerOpen(true);
  }

  function closeDrawer() {
    setIsDrawerOpen(false);
  }
  function getCurrentDrawerChild() {
    return <WebBrowserScreen type="privacy-policy" />;
  }

  const styles = {
    color: getBizColorNumber(2),
    background: getBizColorNumber(4),
    borderRadius: "10px",
  };
  return (
    <WelcomeBackground>
      {redirectIfNeeded()}
      <div className="parent-div">
        <LoadingComponent isLoading={isLoading} />
        <LoginDivStyled>
          <LoginHeader
            title={bizObject.biz_register_title}
            subtitle={bizObject.biz_register_message}
          />
          {!showEmailField ? <>
          <DivCenterStyled>
            <NameInputStyled
              isNameValid={isNameValid}
              placeholder={getLang.full_name}
              onChange={handleNameChange}
              value={name}
            />
          </DivCenterStyled>
          <DivCenterStyled>
            <DivColorStyled isValid={isValid}>
              <StyledPhoneInput
                name="phone"
                required={true}
                autoFocus={true}
                enableTerritories={true}
                country={bizObject.biz_country_code.toLowerCase()}
                countryCodeEditable={false}
                autocompleteSearch={true}
                enableSearch={false}
                disableSearchIcon={true}
                inputStyle={{
                  background: getBizColorNumber(4),
                  borderRadius: "10px",
                  borderColor: 'transparent',
                  height: "57px !important",
                  color: getBizColorNumber(2)
                }}
                containerStyle={{
                  height: "57px",
                  borderRadius: "10px",
                }}
                // buttonStyle={buttonStyle}
                dropdownStyle={dropDownStyle}
                value={phoneNumber}
                onChange={handleOnChange}
              />
            </DivColorStyled>
          </DivCenterStyled>
          <DivCenterStyled>
            <FormControlLabel className='checkbox-label'
              label={<CheckBoxText>
              {getLang.i_have_read_and_agree_to_the}{" "}
                <PrivacyPolicyText onClick={() => openDrawer()}>
                {getLang.privacy_policy}
                </PrivacyPolicyText>
              </CheckBoxText>}
              control={
                <Checkbox
                  checked={isChecked}
                  onChange={handleCheckBoxChange}
                  sx={{
                    color: getBizColorNumber(3),
                    height:'42px',
                    width:'42px',
                    "&.Mui-checked": {
                      color: getBizColorNumber(3),
                    },
                  }}
                />
              }
            />
          </DivCenterStyled>
          </> : <DivCenterStyled>
            <EmailInputStyled
              isEmailValid={isValidEmail}
              placeholder={getLang.email_placeholder}
              onChange={handleEmailChange}
            />
          </DivCenterStyled>}
          <DivCenterStyled>
            <ReCAPTCHA
              sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
              ref={captchaRef}
              size="invisible"
            />
            <NextButtonStyled ref={buttonRef} onClick={onSubmit} disabled={isDisabled}>
            {getLang.next}
            </NextButtonStyled>
          </DivCenterStyled>
        </LoginDivStyled>
      </div>
      <DrawerComponent
        children={getCurrentDrawerChild()}
        isDrawerOpen={isDrawerOpen}
        closeDrawer={closeDrawer}
      />
    </WelcomeBackground>
  );
}

export default RegistrationFormPhone;
