import * as ActionTypes from "../actions/actionTypes";

const initialState = {
    results: [{ isBizColorsChanged: false }]
};

function bizColorsReducer(state = initialState, action) {

    switch (action.type) {

        case ActionTypes.IS_BIZ_COLOR_CHANGED:
            return {
                ...state,
                results: state.results.map((res, i) => i === 0 ? { ...res, isBizColorsChanged: action.payload } : res)
            };

        default:
            return state
    }
}

export default bizColorsReducer