import React, { Component } from 'react';
import { KeyPStyled ,ValuePStyled } from '../../../../biz_styles/ReusableStyles';

class KeyValueCell extends Component {
    state = {  } 
    render() { 
        return (

            <div className="key-value-holder">
                <KeyPStyled opacity={this.props.keyOpacity !== undefined ? this.props.keyOpacity : '66'} fontSize={this.props.fontSize !== undefined ? this.props.fontSize : '16px'}>{this.props.keyText}</KeyPStyled>
                <ValuePStyled fontSize={this.props.fontSize !== undefined ? this.props.fontSize : '16px'} width={this.props.width !== undefined ? this.props.width : 'auto'}>{this.props.valueText}</ValuePStyled>
            </div>

        );
    }
}
 
export default KeyValueCell;