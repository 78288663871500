import React from 'react';
import { DivHorizontalStyled, PColorStyled, PStyled } from '../../../../biz_styles/bizStyle';
import { POpacityStyled } from '../../../../biz_styles/CartStyle'
import { CellCardStyled, RowDivStyled, SpacerStyled} from '../../../../biz_styles/ReusableStyles'
import { ImgDynamicStyled } from '../../../../biz_styles/ProfileStyle';
import KeyValueCell from '../key_value_cell/KeyValueCell';
import { getBiz,getLanguage } from '../../../../shared/redux/GlueStoreData';


export default function ProductCell({ title, attributes, image, price, currency, isSubscriptionLayout }) {
    const biz = getBiz();
    const getLang = getLanguage()

    function showValueWithCurrency(value) {
        if(biz.biz_language == 'he'){
            return biz.ess_currency_symbol + parseFloat(value).toFixed(2);
        }
        else{
            return parseFloat(value).toFixed(2) + ' ' + biz.ess_currency;
        }
        
    }

        return (

            <CellCardStyled height='210'>

            <DivHorizontalStyled>
                
                    {isSubscriptionLayout ? null : <ImgDynamicStyled margin='20' width='117' height='78' src={image} />}
                    
                    <RowDivStyled marginLeft={isSubscriptionLayout ? '20' : '0' } marginRight='20'>
                    <PStyled marginTop='20px' style={{color:'black'}}>{ title }</PStyled>
                    <POpacityStyled marginTop='-14px' opacity={'66'} style={{ fontSize: '13px' }}>{attributes}</POpacityStyled>
                    <SpacerStyled height={'40'} />
                    {isSubscriptionLayout ? null : <KeyValueCell keyText={getLang.qty} valueText='1' />}
                    <SpacerStyled height={'20'} />
                    <KeyValueCell keyText={getLang.price} valueText={showValueWithCurrency(price)}/>
                    <SpacerStyled height={'70'} />
                    <PColorStyled color={(3)} float='right'>{getLang.use_a_reward}</PColorStyled>
                </RowDivStyled>
                
            </DivHorizontalStyled>
            
            </CellCardStyled>
        );
}
