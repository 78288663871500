import React, { useState, useEffect } from 'react'
import { useNavigate } from "react-router-dom"
import { getBizColorNumber, PStyled, DivHorizontalStyled, Toast } from '../../biz_styles/bizStyle'
import { ScrollableDivStyled, DivCenterStyled } from '../../biz_styles/ReusableStyles'
import { getPersonalzone } from '../../api/ApiRequests'
import { InfiniteScroller } from "../../components/Infinite_scroller/InfiniteScroller";
import { getFormatedDateSQL } from '../../shared/static_helper/StaticHelper';
import { DivCardStyled, LabelStyled } from '../../biz_styles/MyNotificationsStyle';
import { getLanguage } from '../../shared/redux/GlueStoreData';
import Skeleton from '@mui/material/Skeleton'
//import {showPushNotification} from '../../shared/notifications/PushManager.jsx'
function MyNotification({ closeDialog }) {

  const getLang = getLanguage();
  const [pushData, setPushData] = useState('');
  const [hasMore, setHasMore] = useState(true);
  const [isLoading, setIsLoading] = useState(true);

  const navigate = useNavigate();

  useEffect(() => {
    (async () => {
      const res = await getPersonalzone("push", pushData.length)
      setPushData(res.push.featured)
      setIsLoading(false)
    })()
  }, []);


  async function callApi() {

    const dataFromApi = await getPersonalzone('push', pushData.length)
    setPushData(prevState => ([...prevState, ...dataFromApi.push.featured]));

    if (dataFromApi.push.featured.length < 20) {
      setHasMore(false)
    }

  }

  function showPushesList() {

    return pushData
      .map(
        (pushItem) =>
          <div key={pushItem.push_id}>
            {cellForPushItem(pushItem)}
          </div>
      );
  }

  function handlePushClick(pushItem) {

    const fromPersonalZone = false
    const levelNo = 1

    switch (pushItem.push_mod_id) {

      case '0':
        // showPushNotification()
        Toast.fire({
          allowOutsideClick: false,
          title: pushItem.push_message,
          text: '',
          icon: 'info',
          confirmButtonText: getLang.ok,
          iconColor: getBizColorNumber(3),
          confirmButtonColor: getBizColorNumber(3),
        })
        break;

      case '26':
        navigate(`./pages/infoScreen/${fromPersonalZone}/${levelNo}/${pushItem.push_mod_id}/${pushItem.push_external_id}`)
        closeDialog()
        break;

      case '6':
        navigate(`./pages/infoScreen/${fromPersonalZone}/${levelNo}/${pushItem.push_mod_id}/${pushItem.push_external_id}`)
        closeDialog()
        break;

      case '29':
        navigate(`./pages/infoScreen/${fromPersonalZone}/${0}/${pushItem.push_mod_id}/${pushItem.push_external_id}`)
        closeDialog()
        break;

      case '9':
        // console.log('push is clicked -> open scratch card')
        break;

      default:
        // console.log('push ' + pushItem.push_id + ' is clicked')

    }

  }


  function cellForPushItem(pushItem) {

    return (

      <DivHorizontalStyled style={{ backgroundColor: getBizColorNumber(1) }}>

        <DivCardStyled className='notification-cell'>
          <div>
            <div>
              <span style={{ color: getBizColorNumber(2) }}>{pushItem.push_message}</span>
            </div>
            <div style={{ width: '100%', paddingTop: 15 }}>
              <span className='float-start' style={{ color: getBizColorNumber(2) + 66 }}>{getFormatedDateSQL(pushItem.push_sent_time)}</span>
              <span className='float-end' style={{ color: getBizColorNumber(3) }} onClick={() => handlePushClick(pushItem)}>{getLang.details}</span>
            </div>
          </div>
        </DivCardStyled>

      </DivHorizontalStyled>
    )
  }


  function nothingHereYetLayout() {

    return <div style={{ display: 'flex', justifyContent: 'center' }}>
      <PStyled>{getLang.nothing_here_yet}</PStyled>
    </div>
  }

  function showSkeleton() {

    return <div>
      <Skeleton animation="wave" variant="rectangular" width='92%' height={100} style={{ backgroundColor: getBizColorNumber(4), marginLeft: 20, marginRight: 20, marginTop: 30, marginBottom: 30, borderRadius: 10 }} />
      <Skeleton animation="wave" variant="rectangular" width='92%' height={130} style={{ backgroundColor: getBizColorNumber(4), marginLeft: 20, marginRight: 20, marginTop: 30, marginBottom: 30, borderRadius: 10 }} />
      <Skeleton animation="wave" variant="rectangular" width='92%' height={120} style={{ backgroundColor: getBizColorNumber(4), marginLeft: 20, marginRight: 20, marginTop: 30, marginBottom: 30, borderRadius: 10 }} />
      <Skeleton animation="wave" variant="rectangular" width='92%' height={130} style={{ backgroundColor: getBizColorNumber(4), marginLeft: 20, marginRight: 20, marginTop: 30, marginBottom: 30, borderRadius: 10 }} />
      <Skeleton animation="wave" variant="rectangular" width='92%' height={120} style={{ backgroundColor: getBizColorNumber(4), marginLeft: 20, marginRight: 20, marginTop: 30, marginBottom: 30, borderRadius: 10 }} />
    </div>

  }

  return (
    <DivCenterStyled style={{ paddingTop: 35, paddingBottom: 20, backgroundColor: getBizColorNumber(1) }} >

      <LabelStyled>{getLang.my_notifications}</LabelStyled>

      {isLoading ? showSkeleton() :

        <ScrollableDivStyled id="scrollableDiv" height='100vh' style={{ backgroundColor: getBizColorNumber(1), paddingTop: 20 }}>

          {pushData.length === 0 ? nothingHereYetLayout() :

            <InfiniteScroller
              dataLength={pushData.length} //This is important field to render the next data
              apiRequestMethod={() => callApi()} // This is the api function
              hasMore={hasMore} // Indicator
              children={showPushesList} // This is the content component
              scrollableTarget="scrollableDiv" /> // This is the parent id
          }

        </ScrollableDivStyled>
      }


    </DivCenterStyled>


  )
}

export default MyNotification;