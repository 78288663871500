import styled from 'styled-components'
import { getBizColorNumber } from './bizStyle';
import { PersonalContainerView, TitleStyled, PersonalCellHolder } from './ReusableStyles';


export const OrderPersonalContainerView = styled(PersonalContainerView)`
    padding: 15px 30px 15px 15px;
    box-shadow: 3px 3px 3px 3px rgb(0 0 0 / 10%);`

export const OrderPersonalCellHolder = styled(PersonalCellHolder)`
    background-color:${() => getBizColorNumber(1)};`

export const OrderTitle = styled(TitleStyled)`
    text-align: unset;`

export const PStyled = styled.p`
    color: ${() => getBizColorNumber(2)};
    font-size: 16px;`

export const TextAreaStyled = styled.textarea`
    background-color: ${() => getBizColorNumber(4)};
    color:  ${() => getBizColorNumber(2)};
    border: ${() => getBizColorNumber(2)};
    width: 100%;
    height: 13%;`

export const OrderImgDiv = styled.div`
    float: left;
    padding-right:10px;
    width: 35%;`

export const OrderImg = styled.img`
    width: 100%;`

export const QtyStyled = styled.p`
    color: ${() => getBizColorNumber(2)};`

export const PriceStyled = styled.p`
    color: ${() => getBizColorNumber(2)};`

export const ItemDetailsDiv = styled.div`
    width:${(props) => props.width}%;`

export const HrStyled = styled.hr`
    border: 1px solid ${() => getBizColorNumber(2)};

`









