import React from "react";
import { TailSpin } from 'react-loader-spinner'
import { getBizColorNumber } from "../../biz_styles/bizStyle";

export default function LoadingTailSpinComponent({ isLoading }) {

    return <div
        style={{
            display: 'flex',
            justifyContent: 'center',
        }}>

        <TailSpin
            height="30"
            width="30"
            color={getBizColorNumber(3)}
            ariaLabel="tail-spin-loading"
            radius="1"
            wrapperStyle={{}}
            wrapperClass=""
            visible={isLoading} />
            
    </div>
}