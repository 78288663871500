import axios from "axios";

export default axios.create({
    baseURL: process.env.REACT_APP_EASY_BASE_URL,
    headers: {
        'Access-Control-Allow-Origin': '*',
        "Content-type": "application/json",
        "sid": typeof (window.gsid) !== "undefined" ? window.gsid : "",
        "debug": "false"
    }
});