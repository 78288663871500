import { combineReducers } from 'redux'
import languageReducer from '../../reducers/languageReducer'
import bizReducer from '../../reducers/bizReducer'
import customerReducer from '../../reducers/customerReducer'
import accountReducer from '../../reducers/accountReducer'
import bizColorsReducer from '../../reducers/bizColorsReducer'

const rootReducer = combineReducers({ 
    languageReducer: languageReducer,
     bizReducer:bizReducer,
     customerReducer:customerReducer, 
     accountReducer:accountReducer,
     bizColorsReducer: bizColorsReducer });

export default rootReducer;