import React from 'react';
import { getBizColorNumber, PStyled } from '../../biz_styles/bizStyle';
import './ClassicRadioComponent.css';

class ClassicRadioComponent extends React.Component {

    /* UI - start */
    /* UI - without UI functions - start */
    render() {

        return (
            <div>

                <input
                    className={this.props.className}
                    type="radio"
                    value={this.props.value}
                    name={this.props.name}
                    defaultChecked={this.props.defaultChecked}
                    style={{
                        border: '2px solid ' + `${getBizColorNumber(3)}`,
                        '--backgroundCheckedBefore': getBizColorNumber(3)
                    }} />

                <PStyled>{this.props.valueStr}</PStyled>

            </div>
        )
    }
    /* UI - without UI functions - end */
    /* UI - end */
}

export default ClassicRadioComponent;