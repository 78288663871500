import React from "react";
import Countdown from 'react-countdown';
import { useNavigate } from "react-router-dom";
import { getBizColorNumber, DivHorizontalStyled, PStyled, P2Styled, P3Styled, POpacityStyled, H5Styled } from '../../../biz_styles/bizStyle'
import { DivMainStyled } from '../../../biz_styles/ProfileStyle'
import { useStoreProvider } from '../../../shared/providers/StoreContext'
import { convertTimestampToDateObject, isModuleExist } from "../../../shared/static_helper/StaticHelper";
import { buyWithPoints } from "../../../shared/loyalty_helper/LoyaltyHelper";
import { getLanguage } from "../../../shared/redux/GlueStoreData";
import './BalancePointsScreen.css'

export default function BalancePointsScreen({ popularItemData, drawerClosed }) {

    /* Logic functions - start */
    const navigate = useNavigate()

    const getLang = getLanguage();
    const customerObject = useStoreProvider().cust

    const getCustActiveMods = customerObject.cust_active_mods;
    const getCustActiveModsData = getCustActiveMods.length === 0 ? 0 : getCustActiveMods[0].data;
    const getPersonalZonePointsObjectPopularItem = popularItemData;

    const renderer = ({ total, days, hours, minutes, seconds, milliseconds, completed }) => {

        return timerLayout(days, hours, minutes, seconds);
    };

    function handleGetItClick(itemID, itemPoints, itemName) {

        buyWithPoints(itemID, itemPoints, itemName, customerObject);
    }

    function handleGoToPointsShopClick() {

        drawerClosed();
        navigate('/main/pages/pointsShop');
    }
    /* Logic functions - end */

    /* UI - start */
    /* UI - without UI functions - start */
    return (

        <DivMainStyled style={{ padding: '0px' }}>

            {currentBalanceLayout()}

            {getCustActiveMods.length > 0 ?
                pointsBoostLayout() :
                <div />}

            <hr style={{ backgroundColor: '#00000066' }} />

            {isModuleExist('18') ?
                popularFromPointsShop() :
                <div />}

        </DivMainStyled>
    )
    /* UI - without UI functions - end */

    /* UI - with UI functions - start */
    function currentBalanceLayout() {

        return <DivHorizontalStyled>

            <div>

                <PStyled>{getLang.current_balance}:</PStyled>

                <POpacityStyled
                    opacity={'66'}
                    style={{ fontSize: '13px' }}>
                    {getLang.total_gained_points} {customerObject.membership.total_points}
                </POpacityStyled>

            </div>

            <H5Styled className="margin-auto-end">
                {customerObject.cust_current_points}
            </H5Styled>

        </DivHorizontalStyled>;
    }

    function pointsBoostLayout() {

        return <DivHorizontalStyled>

            <div>

                <PStyled>{getLang.for_every_point_you_gain}</PStyled>

                <POpacityStyled
                    opacity={'66'}
                    style={{ fontSize: '13px' }}>
                    {getCustActiveModsData.cpb_amount + '% More'}
                </POpacityStyled>

            </div>

            <Countdown
                date={getCustActiveModsData.end_unix * 1000}
                renderer={renderer} />

        </DivHorizontalStyled>;
    }

    function timerLayout(days, hours, minutes, seconds) {

        return <DivHorizontalStyled style={{ marginLeft: 'auto' }}>

            {timerItemLayout(days, 'DAYS')}

            {timerItemLayout(hours, 'HOURS')}

            {timerItemLayout(minutes, 'MINS')}

            {timerItemLayout(seconds, 'SEC')}

        </DivHorizontalStyled>
    }

    function timerItemLayout(value, label) {

        return <div className='div-timer-item-balance-points'>

            <p style={{
                color: 'white',
                textAlign: 'center',
                fontSize: '15px'
            }}>
                {value}
            </p>

            <p style={{
                textAlign: 'center',
                fontSize: '13px',
                color: 'white'
            }}>
                {label}
            </p>

        </div>
    }

    function popularFromPointsShop() {

        return <div>

            <PStyled
                style={{ fontSize: '17px' }}>
                {getLang.popular_from_points_shop}
            </PStyled>

            <DivHorizontalStyled>

                <div className='div-pts-popular-from-points-shop-balance-points'>

                    <img
                        className='img-pts-popular-from-points-shop-balance-points'
                        src={getPersonalZonePointsObjectPopularItem.img}
                        alt='' />

                    <P3Styled
                        className='p-pts-popular-from-points-shop-balance-points'
                        style={{ backgroundColor: getBizColorNumber(3) }}>
                        {getPersonalZonePointsObjectPopularItem.points + ' ' + getLang.points_shorthand}
                    </P3Styled>

                </div>

                <div style={{ margin: '0 10px' }}>

                    <PStyled> {getPersonalZonePointsObjectPopularItem.text}</PStyled>

                    <P2Styled
                        style={{ textTransform: 'uppercase' }}
                        onClick={() => handleGetItClick(
                            getPersonalZonePointsObjectPopularItem.id,
                            getPersonalZonePointsObjectPopularItem.points,
                            getPersonalZonePointsObjectPopularItem.text)}>
                        {getLang.get_it}
                    </P2Styled>

                </div>

            </DivHorizontalStyled>

            <P2Styled
                onClick={() => handleGoToPointsShopClick()}
                style={{ textTransform: 'uppercase' }}>
                {getLang.go_to_points_shop}
            </P2Styled>

        </div>
    }
    /* UI - with UI functions - end */
    /* UI - end */
}