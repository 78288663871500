import styled from 'styled-components'
import { getBizColorNumber } from './bizStyle';

export const DivCardStyled = styled.div`
    position: relative;
    padding: 15px;
    height: auto;
    width: 100%;
    margin: 10px;
    background-color: ${() => getBizColorNumber(4)};
    border-radius: 10px;
    border-width: 1px;
    `

export const LabelStyled = styled.span`
    position: relative;
    font-size: 22px;
    font-weight: bold;
    color: ${() => getBizColorNumber(2)};
    margin-left: auto;
    margin-right: auto;
    `

