import InfiniteScroll from 'react-infinite-scroll-component';
import React from "react";
import LoadingTailSpinComponent from '../loading_components/LoadingTailSpinComponent';

export function InfiniteScroller({ dataLength, apiRequestMethod, hasMore, children, scrollableTarget }) {

    return (

        <InfiniteScroll
            dataLength={dataLength} //This is important field to render the next data
            next={() => apiRequestMethod()}
            hasMore={hasMore}
            scrollThreshold="80%"
            children={children()}
            loader={<LoadingTailSpinComponent isLoading={true} />}
            scrollableTarget={scrollableTarget} />
    )

}