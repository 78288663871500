import useCollapse from "react-collapsed";
import React from 'react'
import '../../App.css'


export default function CollapseComponent({ isActive, sectionTitle, children, onSectionClick}) {
  
  const [isExpanded, setExpanded] = React.useState(isActive);
  const { getToggleProps, getCollapseProps } = useCollapse({ isExpanded, onExpandStart: onSectionClick });
  
    React.useEffect(() => {
      setExpanded(isActive);
    }, [isActive, setExpanded]);

    return (
          <div className="collapsible">
  
              <div className="header"
              {...getToggleProps({
                onClick: () => setExpanded((x) => !x)
              })}>
              { sectionTitle}
              </div>
              
              <div {...getCollapseProps()}>
                  <div className="content">
                      { children}
                  </div>
              </div>
  
          </div>
    );
  }
  