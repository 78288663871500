import React from 'react'
import { getBizColorNumber, DivHorizontalStyled, POpacityStyled, PStyled } from '../../../biz_styles/bizStyle';
import { DivMainStyled } from '../../../biz_styles/ProfileStyle';
import { InviteButtonStyled } from '../../../biz_styles/PersonalPointsStyle';
import { getFormatedDateSQL, inviteFriends } from '../../../shared/static_helper/StaticHelper';
import { useStoreProvider } from '../../../shared/providers/StoreContext'
import { getLanguage } from '../../../shared/redux/GlueStoreData';
import './ReferralsPointsScreen.css'

export default function ReferralsPointsScreen({ friendsData }) {

    const getLang = getLanguage();
    const bizObject = useStoreProvider().biz

    const getPersonalZonePointsObjectReferrals = friendsData;

    function getTotalPoints() {

        var totalPoints = 0;

        for (var i = 0; i < getPersonalZonePointsObjectReferrals.length; i++) {
            totalPoints += parseInt(getPersonalZonePointsObjectReferrals[i].fr_earned_points);
        }

        return totalPoints;
    }

    function nothingHereYet() {

        return (
            <POpacityStyled opacity={'66'}>
                {getLang.invite_a_friend_to_become_a_member.replaceAll("#bizname#", bizObject.biz_short_name)}
            </POpacityStyled>
        )
    }

    function referralsList() {

        return (
            <div>
                <DivHorizontalStyled style={{ backgroundColor: getBizColorNumber(4), padding: '15px', marginTop: '15px', marginLeft: '15px', marginRight: '15px' }}>
                    <PStyled style={{ margin: '0px' }}>{getLang.total_points} <br /> {getLang.gained_by_friends}</PStyled>
                    <PStyled className='p-points-referrals-points' >{getTotalPoints()}</PStyled>
                </DivHorizontalStyled>

                <div style={{ marginTop: '30px' }}>
                    {getPersonalZonePointsObjectReferrals
                        .map(
                            (referralItem) =>

                                <div key={referralItem.fr_id}>
                                    {cellForData(referralItem)}
                                </div>
                        )}
                </div>
            </div>
        )

    }

    function cellForData(referralItem) {

        return (
            <div style={{ paddingLeft: '15px', paddingRight: '15px' }}>

                <PStyled style={{ fontSize: '18px' }}>
                    {referralItem.fr_name}
                </PStyled>

                <DivHorizontalStyled>
                    <PStyled style={{ fontSize: '14px', margin: '0px' }}>
                        {'Points: '}
                        <span style={{ fontSize: '16px' }}>
                            {referralItem.fr_earned_points}
                            {' '}{getLang.points_gained}
                        </span>
                    </PStyled>
                </DivHorizontalStyled>

                <DivHorizontalStyled>
                    <PStyled style={{ fontSize: '14px', margin: '0px' }}>
                        {'Joined: '}
                        <span style={{ fontSize: '16px' }}>
                            {getFormatedDateSQL(referralItem.fr_registration_date)}
                        </span>
                    </PStyled>
                </DivHorizontalStyled>

                <hr style={{ backgroundColor: getBizColorNumber(2) + '66' }} />
            </div>
        )
    }

    return (
        <DivMainStyled>
            {getPersonalZonePointsObjectReferrals.length === 0 ? nothingHereYet() : referralsList()}
            <DivMainStyled>
                <InviteButtonStyled
                    onClick={inviteFriends}>
                {getLang.send_invites}
            </InviteButtonStyled>
            </DivMainStyled>
        </DivMainStyled>
    )

}