import React from "react";
import { DivHorizontalStyled } from "../../biz_styles/bizStyle";
import { DivMainStyled } from "../../biz_styles/ProfileStyle";
import { H5ConfirmationMeesageStyled, PConfirmationButtonStyled } from "../../biz_styles/ConfirmationMessageStyle";

export default function ConfirmationMessageComponent({ message, buttonOkText, buttonCancelText, dialogClosed }) {

    return (
        <DivMainStyled>

            <H5ConfirmationMeesageStyled>{message}</H5ConfirmationMeesageStyled>

            <DivHorizontalStyled>

                <PConfirmationButtonStyled
                    isOk={true}
                    onClick={() => dialogClosed(true)}>
                    {buttonOkText}
                </PConfirmationButtonStyled>

                <PConfirmationButtonStyled
                    isOk={false}
                    onClick={() => dialogClosed(false)}>
                    {buttonCancelText}
                </PConfirmationButtonStyled>

            </DivHorizontalStyled>

        </DivMainStyled>
    )
}
