import styled from 'styled-components'
import { getBizColorNumber } from './bizStyle';

export const PointsShopPageStyled = styled.div`
    padding-top: 25%;
    height:90vh ;
    background:linear-gradient(rgba(255,255,255,0),${() => getBizColorNumber(1)});
    overflow: scroll;`
    
export const TitleStyled = styled.div`
    font-size:2rem;
    padding: 0 3%;
    color:${() => getBizColorNumber(2)};`

export const SubTitleStyled = styled.div`
    font-size:1.5rem;
    padding: 0 3%;
    color:${() => getBizColorNumber(2)};`

export const DivCardStyled = styled.div`
    position: relative;
    background-color:${() => getBizColorNumber(4)};
    margin: 3%;
    border-radius: 10px;
    cursor: pointer;
    box-shadow: 3px 3px 3px 3px rgb(0 0 0 / 10%);`

export const DivPointsItemCardStyled = styled(DivCardStyled)`
    height: 82px;
    `

export const DivImgCardStyled = styled.div`
    position: relative;
    width: 45%;
    padding: 0%;`

export const ImgCardStyled = styled.img`
    width: 100%;
    `
export const ImgPointsShopCardStyled = styled.img`
    width: 155px;
    height: 82px;
    border-radius: 0px 10px 10px 0px;
    `

export const DivLabelCardStyled = styled.div`
    position: absolute;
    bottom: 0%;
    max-width: 85%`

export const PointsLabelCardStyled = styled.p`
    position: absolute;
    padding-right: 4px;
    padding-left: 4px;
    bottom: 0;
    color:${() => getBizColorNumber(1)};
    background-color:${() => getBizColorNumber(3)};`

export const TitleCardStyled = styled.p`
    position: absolute;
    top: 10%;
    color:${() => getBizColorNumber(2)};`

export const BuyCardStyled = styled.p`
    position: absolute;
    bottom: 0;
    color:${() => getBizColorNumber(3)};`


