import styled from 'styled-components'
import { getBizColorNumber } from '../../../../biz_styles/bizStyle';

export const DivCardStyled = styled.div`
    text-align: left;
    position: relative;
    padding: 15px;
    height: 60px;
    margin: 25px;
    background-color: ${() => getBizColorNumber(4)};
    border-radius: 10px;
    border-width: 1px;
    display: block;`
