import React, { useState } from 'react'
import 'reactjs-popup/dist/index.css';
import ScratchCard from 'react-scratchcard-v2'
import Dialog from '@mui/material/Dialog';
import Slide from '@mui/material/Slide';
import InnerScratchCardComponent from './InnerScratchCardComponent'
import Confetti from 'react-confetti'
import { ScratchCardTitleStyled } from '../../biz_styles/ScratchCardStyle'

export default function ScratchCardComponent(props) {

    const [isOpen, setIsOpen] = useState(true)
    const [showCardText, setShowCardText] = useState(true)
    const [showConfetti, setShowConfetti] = useState(false)
    const cardData = props.cardData.card

    function playConfettiAnimation() {
        return (
            <Confetti
                width={window.innerWidth}
                height={window.innerHeight}
                numberOfPieces={100}
            />
        )
    }

    function hideCardTitle() {
        setShowCardText(false)
    }

    function hideCard() {
        props.showScratchCard(false)
    }

    var frontImage = ''

    if (cardData.back_type === 'color') {
        frontImage = props.newImg
    } else {
        frontImage = cardData.back_data
    }

    return (

        <Dialog PaperProps={{
            style: {
                position: 'absolute',
                width: '95%',
                backgroundColor: 'transparent',
                borderRadius: '22px',
                boxShadow: 'none'
            },
        }} open={isOpen} TransitionComponent={Transition} children={

            <div onTouchStart={() => hideCardTitle()} onMouseDown={() => hideCardTitle()}>

                {showCardText ? <ScratchCardTitleStyled className="scratch-card-title">{cardData.title}</ScratchCardTitleStyled> : ''}

                <ScratchCard
                    width={window.innerWidth * 0.955}
                    height={280}
                    image={frontImage}
                    children={
                        <div>
                            {showConfetti ? playConfettiAnimation() : ''}
                            <InnerScratchCardComponent cardData={props.cardData.card} onClose={hideCard}/>
                        </div>
                    }
                    finishPercent={70}
                    onComplete={() => {
                        setShowConfetti(true)
                        setTimeout(() => {
                            setIsOpen(false)
                            props.showScratchCard(false)
                        }, 5000)
                    }} />

            </div>

        }>

        </Dialog>

    )
}

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});