import { Toast, getBizColorNumber } from '../../biz_styles/bizStyle';
import { claimFromPointShop, getClientAfterUpdate } from "../../api/ApiRequests";
import { getCustomer, getLanguage } from '../redux/GlueStoreData';
import { GetClientObject } from '../../objects/GetClientObject';
import { convertTimestampToDate, getFormattedDate } from '../static_helper/StaticHelper';
import Swal from 'sweetalert2';

export function getProgramTypeForCard(card){
    
    if (card.md_program_type === 'gift') {
        return card.md_stamp_number + ' + 🎁'
    }
    else if (card.md_program_type === 'plus') {
        return card.md_stamp_number + ' + ' + card.md_reward_number
    }
    else if (card.md_program_type === 'discount') {
        if (card.md_stamp_number === '0') {
            return card.md_reward_number + '%'
        }
        else {
            return card.md_stamp_number + ' + ' + card.md_reward_number + '%'
        }
    }

    return ''
}

export function getRewardTypeStringForType(type) {
  
  const getLang = getLanguage();

  switch (type) {
    case "loyalty": {
      return getLang.reward_source_loyalty;
    }
    case "membership": {
      return getLang.reward_source_membership;
    }
    case "points_redeem": {
      return getLang.points_redeem;
    }
    case "scratch": {
      return getLang.scratch;
    }
    case "personal": {
      return getLang.personal;
    }
    case "coupon": {
      return getLang.coupon;
    }
      
    return "";    
  }
}

export function statusStringForStatus(status) {
     
  const getLang = getLanguage();
  
  switch (status) {
    
    case "active": {
      return getLang.active;
    }
    case "frozen": {
      return getLang.frozen;
    }
    case "user_canceled": case "cancelled": {
      return getLang.cancelled;
    }
    case "ended": {
      return getLang.ended;
    }
    case "expired": {
      return getLang.expired;
    }
      return "";
  }
}


export function buyWithPoints(itemID,itemPoints,itemName,customerObject) {

  const getLang = getLanguage();
    
    if(Number(customerObject.membership.current_points) < Number(itemPoints)){
        Toast.fire({
          title: getLang.sorry,
          text: getLang.you_dont_have_enough_points_to_claim_this_benefit,
          icon: 'error',
          confirmButtonText: getLang.ok_got_it
        })
      }else{
        Toast.fire({
            allowOutsideClick: false,
            title: getLang.are_you_sure_you_want_to_buy_this_item,
            html: getLang.name + ': '+itemName+'<br></br>' + getLang.points.toLowerCase() + ': ' +itemPoints,
            confirmButtonText: getLang.yes,
            cancelButtonText: getLang.cancel,
            showCancelButton: true,
            reverseButtons: false,
            iconColor: getBizColorNumber(3),
            confirmButtonColor: getBizColorNumber(3),
          }).then(async (result)=>{
            if(result.isConfirmed){
              Toast.fire({
                title: getLang.please_wait,
                didOpen: () => {
                  Swal.showLoading()
                }
              })
              var buy = await buyItem(itemID)
              if(buy){
                Toast.fire(
                  getLang.its_yours + '!',
                  getLang.you_can_see_it_in_your_personal_zone_under_my_rewards,
                  'success'
                )
              }else{
                Toast.fire(
                  getLang.sorry,
                  getLang.contact_failed_message                  ,
                  'error',
                )
              }
            }
          })
      }
}

async function buyItem(itemID) {
    const responseCode = await claimFromPointShop(itemID)
    if (responseCode === 1) {
      const result = await getClientAfterUpdate();
      if(result === 1){
        return true;
      }
      else{
        return false;
      }
    }
    else {
      return false;  
    }
}


export function getPriceStringForPrice(price, currency) {

  const getLang = getLanguage();
  const intPrice = parseInt(price);

  if (intPrice === 0) {
    return getLang.free;
  }
  
  return intPrice.toFixed(2) + ' ' + currency;
}

export function badgeTypeForEntityType(type) {
  
  const cust = getCustomer();

  return cust.badges[type]
}