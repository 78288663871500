import React from 'react';
import Barcode from 'react-barcode';

export default function BarcodeComponent({value, height, displayValue = true}){

    height = typeof(height) == 'undefined' ? 100 : height;

    return value === '' ?

        <div /> :

        <div className='barcode-holder' style={{backgroundColor: 'white', padding: '3px'}}>
            <Barcode 
                value={value}  displayValue={displayValue} height={height}/>
        </div>
}