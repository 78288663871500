import * as ActionTypes from "../actions/actionTypes";

function customerReducer(state = {}, action) {

    switch (action.type) {

        case ActionTypes.GET_CUSTOMER_DATA: {
            return {
                ...state,
                [action.key]: action.payload
            };
        }

        case ActionTypes.CUSTOMER_REDIRECT_DATA: {
            return {
                ...state,
                [action.key]: action.payload
            };
        }

        default:
            return state
    }
}

export default customerReducer