import React, { useState, useEffect } from "react";
import { getPointsShop } from "../../../api/ApiRequests";
import { useStoreProvider } from '../../../shared/providers/StoreContext'
import {
  TitleStyled,
  SubTitleStyled,
  PointsShopPageStyled,
  DivCardStyled,
  DivPointsItemCardStyled,
  DivImgCardStyled,
  ImgCardStyled,
  DivLabelCardStyled,
  PointsLabelCardStyled,
  TitleCardStyled,
  BuyCardStyled,
  ImgPointsShopCardStyled
} from "../../../biz_styles/PointsShopStyle";
import LoadingComponent from "../../../components/loading_components/LoadingComponent";
import { buyWithPoints } from "../../../shared/loyalty_helper/LoyaltyHelper";
import { getLanguage } from "../../../shared/redux/GlueStoreData";
import { hasTopValueBar } from "../../../shared/static_helper/StaticHelper";
import { SpacerStyled } from "../../../biz_styles/ReusableStyles";



function PointsShop() {
  const [isLoading, setIsLoading] = useState(true);
  const [card, setCard] = useState([]);
  const customerObject = useStoreProvider().cust
  const getLang = getLanguage();


  useEffect(() => {
    (async () => {
      setCard(await getPointsShop());
      setIsLoading(false);
    })();
  }, []);


  function handleClick(itemID,itemPoints,itemName) {
    buyWithPoints(itemID, itemPoints, itemName, customerObject);
  }

  return (
    <div>
      {isLoading ? 
        <LoadingComponent isLoading={isLoading} />
        : 
        <PointsShopPageStyled>
          <TitleStyled className="align-start" style={{marginTop: hasTopValueBar() ? '80px' : '40px'}}>
            <p>{getLang.points_balance +": " + customerObject.membership.current_points}</p>
          </TitleStyled>
          <SubTitleStyled className="align-start">
            <p>{getLang.offered_benefits}</p>
          </SubTitleStyled>
          {card.length === 0 ? 
            <TitleStyled>{getLang.nothig_here_yet}</TitleStyled>
           : 
            card.map((item) => 
                <DivPointsItemCardStyled className="points-shop-item-holder" onClick={()=>handleClick(item.id,item.points,item.text)} key={item.id}>

                    <div >
                    <PointsLabelCardStyled>{item.points + " " + getLang.points}</PointsLabelCardStyled>
                    <ImgPointsShopCardStyled className="points-shop-image" src={item.img}></ImgPointsShopCardStyled>
                    </div>

                    <div className="points-shop-item-info-holder">
                      <TitleCardStyled>{item.text}</TitleCardStyled>
                      <BuyCardStyled>{getLang.buy_now}</BuyCardStyled>
                    </div>

                       
                </DivPointsItemCardStyled>
            )
          }
        </PointsShopPageStyled>
      }
    </div>
  );
}

export default PointsShop;
