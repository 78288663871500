import React from 'react';
import ClassicInputComponent from '../../../../components/input_components/classic_input_component/ClassicInputComponent';
import './TextFieldFormComponent.css';

class TextFieldFormComponent extends React.Component {

    /* UI - start */
    /* UI - without UI functions - start */
    render() {

        return <ClassicInputComponent
            className='input-text-field-form'
            defaultValue={this.props.defaultValue}
            placeholder={this.props.placeholder}
            onChange={this.props.onChange} />
    }
    /* UI - without UI functions - end */
    /* UI - end */
}

export default TextFieldFormComponent;